export const kinds = [
  { value: "bluepoint_mod", label: "resultados_puntosazules" },
  { value: "infection_model", label: "resultados_modelo" },
  { value: "inspection", label: "inspecciones" },
  { value: "sprayed", label: "rociados" },
  { value: "vectorpoint_mod", label: "riesgo_inicial_final" },
  { value: "vectorpoint_radio", label: "radios" },
  { value: "advertisement", label: "publicidades" },
  { value: "complaint", label: "denuncias" },
];
