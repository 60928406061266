const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiYnJlZ3kiLCJhIjoiY2txd2lucmk1MDBxazJvbzcyeDZyMXBubyJ9.VdiuEyhmzBgJORxU-AUqMw";

const REACT_APP_ENDPOINT = "https://vi-api.innovalab.minsky.cc/v1/graphql";

const CHAGAS_ENDPOINT = "https://chagas-data-pipeline.innovalab.minsky.cc/api/reports";

const REACT_CHAGAS_INSPECTIONS_ENDPOINT = `${CHAGAS_ENDPOINT}/inspections`;

const REACT_CHAGAS_SPRAYED_ENDPOINT = `${CHAGAS_ENDPOINT}/sprayed`;

const REACT_CHAGAS_ADVERTISEMENT_ENDPOINT = `${CHAGAS_ENDPOINT}/advertisement`;

const REACT_CHAGAS_COMPLAINT_ENDPOINT = `${CHAGAS_ENDPOINT}/complaint`;

const REACT_CHAGAS_BLUEPOINT_ENDPOINT = `${CHAGAS_ENDPOINT}/bluepoint`;

const REACT_CHAGAS_VECTORPOINT_ENDPOINT = `${CHAGAS_ENDPOINT}/vectorpoint`;

const REACT_CHAGAS_INFECTIONMODEL_ENDPOINT = `${CHAGAS_ENDPOINT}/infectionmodel`;

//Firebase
const REACT_APP_FIREBASE_API_KEY = "AIzaSyDJbtB1nqUctYoulxhDGNbpCOpLoXYYQyM";
const REACT_APP_FIREBASE_AUTH_DOMAIN = "neo-vigilancia-integrada.firebaseapp.com";
const REACT_APP_FIREBASE_PROJECT_ID = "neo-vigilancia-integrada";
const REACT_APP_FIREBASE_STORAGE_BUCKET = "neo-vigilancia-integrada.appspot.com";
const REACT_APP_FIREBASE_SENDER_ID = "1015635075021";
const REACT_APP_FIREBASE_APP_ID = "1:1015635075021:web:e252286ba2ea0663a32e84";
const REACT_APP_FIREBASE_MEASUREMTE_ID = "G-FGEJZC9LXQ";

export {
  MAPBOX_ACCESS_TOKEN,
  REACT_APP_ENDPOINT,
  REACT_CHAGAS_INSPECTIONS_ENDPOINT,
  REACT_CHAGAS_SPRAYED_ENDPOINT,
  REACT_CHAGAS_ADVERTISEMENT_ENDPOINT,
  REACT_CHAGAS_COMPLAINT_ENDPOINT,
  REACT_CHAGAS_BLUEPOINT_ENDPOINT,
  REACT_CHAGAS_VECTORPOINT_ENDPOINT,
  REACT_CHAGAS_INFECTIONMODEL_ENDPOINT,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMTE_ID,
};
