import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/es";

const useFormate = () => {
  const formatDate = date => {
    dayjs.extend(localizedFormat);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.locale("es");

    return dayjs(date).tz("GMT").format("lll");
  };

  const kindName = (kind: string) => {
    switch (kind) {
      case "bluepoint_mod":
        return "resultados_puntosazules";
      case "infection_model":
        return "resultados_modelo";
      case "inspection":
        return "inspecciones";
      case "sprayed":
        return "rociados";
      case "vectorpoint_mod":
        return "riesgo_inicial_final";
      case "vectorpoint_radio":
        return "radios";
    }
  };

  const formateDatasetTitle = (selectedKind: string) => {
    const word = kindName(selectedKind).split("_");
    const kindTitle = word
      .map(item => {
        return item[0].toUpperCase() + item.substring(1);
      })
      .join(" ");

    return kindTitle;
  };

  // Obtiene el ultimo experimento por año
  const dateByYear = data => {
    return data.experiment
      .reduce((acc, curr) => {
        const currYear = new Date(curr.created_at).getFullYear();
        const accItem = acc.find(x => new Date(x.created_at).getFullYear() === currYear);

        if (!accItem) {
          acc.push(curr);
        } else {
          const accItemDate = new Date(accItem.created_at);
          const currItemDate = new Date(curr.created_at);

          if (currItemDate > accItemDate) {
            acc = acc.filter(x => new Date(x.created_at).getFullYear() !== currYear);
            acc.push(curr);
          }
        }

        return acc;
      }, [])
      .map(item => {
        return {
          value: item.id,
          label: formatDate(item.created_at),
        };
      });
  };

  return { formatDate, formateDatasetTitle, dateByYear };
};

export default useFormate;
