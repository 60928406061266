import { showNotification } from "@mantine/notifications";
import {
  REACT_CHAGAS_ADVERTISEMENT_ENDPOINT,
  REACT_CHAGAS_BLUEPOINT_ENDPOINT,
  REACT_CHAGAS_VECTORPOINT_ENDPOINT,
  REACT_CHAGAS_COMPLAINT_ENDPOINT,
  REACT_CHAGAS_INFECTIONMODEL_ENDPOINT,
  REACT_CHAGAS_INSPECTIONS_ENDPOINT,
  REACT_CHAGAS_SPRAYED_ENDPOINT,
} from "#root/config";

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}

type GetDataProps = {
  resource: string;
  groups: string[];
  advanced: boolean;
  setIsFetching: (isFetching: boolean) => void;
  experiments?: string[];
};

export const getDataToExport = async ({
  resource,
  groups,
  experiments,
  advanced,
  setIsFetching,
}: GetDataProps) => {
  const getToken = () => localStorage.getItem("token");
  const token = getToken();

  const groupParam =
    groups.length === 1
      ? groups[0]
      : groups.map(w => {
          return w;
        });

  const experimentsParam = experiments
    ? experiments.length === 1
      ? experiments[0]
      : experiments.map(e => {
          return e;
        })
    : "";

  const params = new URLSearchParams();

  // Agregar query params
  // Grupo
  if (Array.isArray(groupParam)) {
    for (const group of groupParam) {
      params.append("groups", group);
    }
  } else {
    params.append("group", groupParam);
  }

  // Experiments
  if (Array.isArray(experimentsParam)) {
    for (const experiment of experimentsParam) {
      params.append("experiments", experiment);
    }
  } else {
    params.append("experiment", experimentsParam);
  }

  // Opcion avanzada
  params.append("advanced", advanced ? "true" : "");

  // Tipo de reporte
  if (resource === "bluepoint_mod" || resource === "infection_model" || resource === "vectorpoint_mod") {
    params.append("kind", resource);
  }

  const query = params.toString();

  try {
    let URL = "";
    switch (resource) {
      case "inspection":
        URL += REACT_CHAGAS_INSPECTIONS_ENDPOINT;
        break;
      case "sprayed":
        URL += REACT_CHAGAS_SPRAYED_ENDPOINT;
        break;
      case "advertisement":
        URL += REACT_CHAGAS_ADVERTISEMENT_ENDPOINT;
        break;
      case "complaint":
        URL += REACT_CHAGAS_COMPLAINT_ENDPOINT;
        break;
      case "bluepoint_mod":
        URL += REACT_CHAGAS_BLUEPOINT_ENDPOINT;
        break;
      case "vectorpoint_mod":
        URL += REACT_CHAGAS_VECTORPOINT_ENDPOINT;
        break;
      case "infection_model":
        URL += REACT_CHAGAS_INFECTIONMODEL_ENDPOINT;
        break;
    }

    const options = {
      method: "GET",
      headers: {
        "hasura-auth": token ? `Bearer ${token}` : "",
      },
    };
    const res = await fetch(`${URL}?${query}`, options);
    const result = await res.text();

    // Retornar null si no hay registros
    if (!result.length) {
      setIsFetching(false);
      showNotification({
        title: "No hay datos!",
        message: "Seleccione otro grupo.",
      });
      return null;
    }

    setIsFetching(false);
    return result;
  } catch (e) {
    setIsFetching(false);
    showNotification({
      title: "Hubo un error!",
      message: "Intente nuevamente.",
    });
    return console.log("error", e);
  }
};

const formatDataToExport = (response: string) => {
  const data = response.split("\r\n").map(item => item.split(","));
  const columns = data[0];
  const finalData = data.slice(1);

  return finalData?.map((record: any) => {
    return columns.reduce((recordToDownload, column, index) => {
      return {
        ...recordToDownload,
        [column]: record[index],
      };
    }, {});
  });
};

export const makeCsv = async (response: string, filename: string) => {
  const rows = formatDataToExport(response);

  const separator: string = ";";
  const keys: string[] = Object.keys(rows[0]);

  const csvContent = `${keys.join(separator)}\n${rows
    .map(row =>
      keys
        .map(k => {
          let cell = row[k] === null || row[k] === undefined ? "" : row[k];

          cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');

          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        })
        .join(separator)
    )
    .join("\n")}`;

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveOrOpenBlob) {
    // In case of IE 10+
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
