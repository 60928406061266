import {
  createStyles,
  TextInput,
  PasswordInput,
  Button,
  Stack,
  Title,
  Image,
  Center,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Mail, Lock } from "tabler-icons-react";
import NVIisotipo from "#root/images/NVIisotipo.svg";
import { useContext, useState } from "react";
import { AuthContext } from "#root/context/AuthenticationContext";

const useStyles = createStyles(theme => ({
  pageContainer: {
    height: "100vh",
  },
  login: {
    height: "auto",
    width: 460,
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      padding: 20,
    },
  },
  form: {
    backgroundColor: theme.colors.gray[1],
    borderRadius: 6,
    padding: 20,
  },
}));

const Login = () => {
  const { classes } = useStyles();
  const { loginFirebase } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: value => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  type FormValues = typeof form.values;

  const handleLogin = ({ email, password }: FormValues) => {
    setIsLoading(true);
    loginFirebase({ email: email, password: password, setIsLoading: setIsLoading });
  };

  return (
    <Center className={classes.pageContainer}>
      <Stack className={classes.login}>
        <Stack>
          <Center>
            <Image width={120} src={NVIisotipo} alt="NVI Logo" />
          </Center>
          <Title align="center">Welcome</Title>
        </Stack>
        <Stack className={classes.form}>
          <form onSubmit={form.onSubmit(values => handleLogin(values))}>
            <Stack>
              <TextInput
                required
                label="Email"
                placeholder="your@email.com"
                icon={<Mail size={16} />}
                size="md"
                {...form.getInputProps("email")}
              />
              <PasswordInput
                required
                label="Password"
                placeholder="Password"
                icon={<Lock size={16} />}
                size="md"
                {...form.getInputProps("password")}
              />
              <Button loading={isLoading} size="md" type="submit">
                Login
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Center>
  );
};

export default Login;
