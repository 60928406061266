import { Tuple } from "@mantine/core";

export const nviBrand: Tuple<string, 10> = [
  "#FADEE0",
  "#F9B8BE",
  "#F28A94",
  "#F0717D",
  "#F35F6D",
  "#F55564",
  "#F43D4E",
  "#EF3C4C",
  "#DC3444",
  "#C82A39",
];
