import gql from "graphql-tag";
import * as Urql from "urql";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  float8: any;
  geography: any;
  geometry: any;
  json: any;
  jsonb: any;
  name: any;
  time: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]>;
  _gt?: InputMaybe<Scalars["Boolean"]>;
  _gte?: InputMaybe<Scalars["Boolean"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Boolean"]>;
  _lte?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Scalars["Boolean"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]>>;
};

/** columns and relationships of "Capture_Place" */
export type Capture_Place = {
  __typename?: "Capture_Place";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  domicileArea?: Maybe<Scalars["String"]>;
  domicileKind?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  sort?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "Capture_Place" */
export type Capture_Place_Aggregate = {
  __typename?: "Capture_Place_aggregate";
  aggregate?: Maybe<Capture_Place_Aggregate_Fields>;
  nodes: Array<Capture_Place>;
};

/** aggregate fields of "Capture_Place" */
export type Capture_Place_Aggregate_Fields = {
  __typename?: "Capture_Place_aggregate_fields";
  avg?: Maybe<Capture_Place_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Capture_Place_Max_Fields>;
  min?: Maybe<Capture_Place_Min_Fields>;
  stddev?: Maybe<Capture_Place_Stddev_Fields>;
  stddev_pop?: Maybe<Capture_Place_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Capture_Place_Stddev_Samp_Fields>;
  sum?: Maybe<Capture_Place_Sum_Fields>;
  var_pop?: Maybe<Capture_Place_Var_Pop_Fields>;
  var_samp?: Maybe<Capture_Place_Var_Samp_Fields>;
  variance?: Maybe<Capture_Place_Variance_Fields>;
};

/** aggregate fields of "Capture_Place" */
export type Capture_Place_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Capture_Place_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Capture_Place_Avg_Fields = {
  __typename?: "Capture_Place_avg_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Capture_Place". All fields are combined with a logical 'AND'. */
export type Capture_Place_Bool_Exp = {
  _and?: InputMaybe<Array<Capture_Place_Bool_Exp>>;
  _not?: InputMaybe<Capture_Place_Bool_Exp>;
  _or?: InputMaybe<Array<Capture_Place_Bool_Exp>>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  domicileArea?: InputMaybe<String_Comparison_Exp>;
  domicileKind?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  user_updated?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Capture_Place" */
export enum Capture_Place_Constraint {
  /** unique or primary key constraint on columns "id" */
  CapturePlacePkey = "Capture_Place_pkey",
}

/** input type for incrementing numeric columns in table "Capture_Place" */
export type Capture_Place_Inc_Input = {
  sort?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Capture_Place" */
export type Capture_Place_Insert_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  domicileArea?: InputMaybe<Scalars["String"]>;
  domicileKind?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Capture_Place_Max_Fields = {
  __typename?: "Capture_Place_max_fields";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  domicileArea?: Maybe<Scalars["String"]>;
  domicileKind?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Capture_Place_Min_Fields = {
  __typename?: "Capture_Place_min_fields";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  domicileArea?: Maybe<Scalars["String"]>;
  domicileKind?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "Capture_Place" */
export type Capture_Place_Mutation_Response = {
  __typename?: "Capture_Place_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Capture_Place>;
};

/** on_conflict condition type for table "Capture_Place" */
export type Capture_Place_On_Conflict = {
  constraint: Capture_Place_Constraint;
  update_columns?: Array<Capture_Place_Update_Column>;
  where?: InputMaybe<Capture_Place_Bool_Exp>;
};

/** Ordering options when selecting data from "Capture_Place". */
export type Capture_Place_Order_By = {
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  domicileArea?: InputMaybe<Order_By>;
  domicileKind?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Capture_Place */
export type Capture_Place_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Capture_Place" */
export enum Capture_Place_Select_Column {
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  DomicileArea = "domicileArea",
  /** column name */
  DomicileKind = "domicileKind",
  /** column name */
  Id = "id",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

/** input type for updating data in table "Capture_Place" */
export type Capture_Place_Set_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  domicileArea?: InputMaybe<Scalars["String"]>;
  domicileKind?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Capture_Place_Stddev_Fields = {
  __typename?: "Capture_Place_stddev_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Capture_Place_Stddev_Pop_Fields = {
  __typename?: "Capture_Place_stddev_pop_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Capture_Place_Stddev_Samp_Fields = {
  __typename?: "Capture_Place_stddev_samp_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Capture_Place" */
export type Capture_Place_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Capture_Place_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Capture_Place_Stream_Cursor_Value_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  domicileArea?: InputMaybe<Scalars["String"]>;
  domicileKind?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Capture_Place_Sum_Fields = {
  __typename?: "Capture_Place_sum_fields";
  sort?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Capture_Place" */
export enum Capture_Place_Update_Column {
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  DomicileArea = "domicileArea",
  /** column name */
  DomicileKind = "domicileKind",
  /** column name */
  Id = "id",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

export type Capture_Place_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Capture_Place_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Capture_Place_Set_Input>;
  /** filter the rows which have to be updated */
  where: Capture_Place_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Capture_Place_Var_Pop_Fields = {
  __typename?: "Capture_Place_var_pop_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Capture_Place_Var_Samp_Fields = {
  __typename?: "Capture_Place_var_samp_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Capture_Place_Variance_Fields = {
  __typename?: "Capture_Place_variance_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]>;
  _gt?: InputMaybe<Scalars["Int"]>;
  _gte?: InputMaybe<Scalars["Int"]>;
  _in?: InputMaybe<Array<Scalars["Int"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Int"]>;
  _lte?: InputMaybe<Scalars["Int"]>;
  _neq?: InputMaybe<Scalars["Int"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]>>;
};

export type MeResponse = {
  __typename?: "MeResponse";
  collaborator?: Maybe<Collaborator>;
  roleID?: Maybe<Scalars["String"]>;
  userID: Scalars["String"];
};

/** columns and relationships of "Number_Triatomines_Captured" */
export type Number_Triatomines_Captured = {
  __typename?: "Number_Triatomines_Captured";
  capturedFemale?: Maybe<Scalars["Int"]>;
  capturedMale?: Maybe<Scalars["Int"]>;
  capturedStage1?: Maybe<Scalars["Int"]>;
  capturedStage2?: Maybe<Scalars["Int"]>;
  capturedStage3?: Maybe<Scalars["Int"]>;
  capturedStage4?: Maybe<Scalars["Int"]>;
  capturedStage5?: Maybe<Scalars["Int"]>;
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  sort?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "Number_Triatomines_Captured" */
export type Number_Triatomines_Captured_Aggregate = {
  __typename?: "Number_Triatomines_Captured_aggregate";
  aggregate?: Maybe<Number_Triatomines_Captured_Aggregate_Fields>;
  nodes: Array<Number_Triatomines_Captured>;
};

/** aggregate fields of "Number_Triatomines_Captured" */
export type Number_Triatomines_Captured_Aggregate_Fields = {
  __typename?: "Number_Triatomines_Captured_aggregate_fields";
  avg?: Maybe<Number_Triatomines_Captured_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Number_Triatomines_Captured_Max_Fields>;
  min?: Maybe<Number_Triatomines_Captured_Min_Fields>;
  stddev?: Maybe<Number_Triatomines_Captured_Stddev_Fields>;
  stddev_pop?: Maybe<Number_Triatomines_Captured_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Number_Triatomines_Captured_Stddev_Samp_Fields>;
  sum?: Maybe<Number_Triatomines_Captured_Sum_Fields>;
  var_pop?: Maybe<Number_Triatomines_Captured_Var_Pop_Fields>;
  var_samp?: Maybe<Number_Triatomines_Captured_Var_Samp_Fields>;
  variance?: Maybe<Number_Triatomines_Captured_Variance_Fields>;
};

/** aggregate fields of "Number_Triatomines_Captured" */
export type Number_Triatomines_Captured_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Number_Triatomines_Captured_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Number_Triatomines_Captured_Avg_Fields = {
  __typename?: "Number_Triatomines_Captured_avg_fields";
  capturedFemale?: Maybe<Scalars["Float"]>;
  capturedMale?: Maybe<Scalars["Float"]>;
  capturedStage1?: Maybe<Scalars["Float"]>;
  capturedStage2?: Maybe<Scalars["Float"]>;
  capturedStage3?: Maybe<Scalars["Float"]>;
  capturedStage4?: Maybe<Scalars["Float"]>;
  capturedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Number_Triatomines_Captured". All fields are combined with a logical 'AND'. */
export type Number_Triatomines_Captured_Bool_Exp = {
  _and?: InputMaybe<Array<Number_Triatomines_Captured_Bool_Exp>>;
  _not?: InputMaybe<Number_Triatomines_Captured_Bool_Exp>;
  _or?: InputMaybe<Array<Number_Triatomines_Captured_Bool_Exp>>;
  capturedFemale?: InputMaybe<Int_Comparison_Exp>;
  capturedMale?: InputMaybe<Int_Comparison_Exp>;
  capturedStage1?: InputMaybe<Int_Comparison_Exp>;
  capturedStage2?: InputMaybe<Int_Comparison_Exp>;
  capturedStage3?: InputMaybe<Int_Comparison_Exp>;
  capturedStage4?: InputMaybe<Int_Comparison_Exp>;
  capturedStage5?: InputMaybe<Int_Comparison_Exp>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  user_updated?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Number_Triatomines_Captured" */
export enum Number_Triatomines_Captured_Constraint {
  /** unique or primary key constraint on columns "id" */
  NumberTriatominesCapturedPkey = "Number_Triatomines_Captured_pkey",
}

/** input type for incrementing numeric columns in table "Number_Triatomines_Captured" */
export type Number_Triatomines_Captured_Inc_Input = {
  capturedFemale?: InputMaybe<Scalars["Int"]>;
  capturedMale?: InputMaybe<Scalars["Int"]>;
  capturedStage1?: InputMaybe<Scalars["Int"]>;
  capturedStage2?: InputMaybe<Scalars["Int"]>;
  capturedStage3?: InputMaybe<Scalars["Int"]>;
  capturedStage4?: InputMaybe<Scalars["Int"]>;
  capturedStage5?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Number_Triatomines_Captured" */
export type Number_Triatomines_Captured_Insert_Input = {
  capturedFemale?: InputMaybe<Scalars["Int"]>;
  capturedMale?: InputMaybe<Scalars["Int"]>;
  capturedStage1?: InputMaybe<Scalars["Int"]>;
  capturedStage2?: InputMaybe<Scalars["Int"]>;
  capturedStage3?: InputMaybe<Scalars["Int"]>;
  capturedStage4?: InputMaybe<Scalars["Int"]>;
  capturedStage5?: InputMaybe<Scalars["Int"]>;
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Number_Triatomines_Captured_Max_Fields = {
  __typename?: "Number_Triatomines_Captured_max_fields";
  capturedFemale?: Maybe<Scalars["Int"]>;
  capturedMale?: Maybe<Scalars["Int"]>;
  capturedStage1?: Maybe<Scalars["Int"]>;
  capturedStage2?: Maybe<Scalars["Int"]>;
  capturedStage3?: Maybe<Scalars["Int"]>;
  capturedStage4?: Maybe<Scalars["Int"]>;
  capturedStage5?: Maybe<Scalars["Int"]>;
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Number_Triatomines_Captured_Min_Fields = {
  __typename?: "Number_Triatomines_Captured_min_fields";
  capturedFemale?: Maybe<Scalars["Int"]>;
  capturedMale?: Maybe<Scalars["Int"]>;
  capturedStage1?: Maybe<Scalars["Int"]>;
  capturedStage2?: Maybe<Scalars["Int"]>;
  capturedStage3?: Maybe<Scalars["Int"]>;
  capturedStage4?: Maybe<Scalars["Int"]>;
  capturedStage5?: Maybe<Scalars["Int"]>;
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "Number_Triatomines_Captured" */
export type Number_Triatomines_Captured_Mutation_Response = {
  __typename?: "Number_Triatomines_Captured_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Number_Triatomines_Captured>;
};

/** on_conflict condition type for table "Number_Triatomines_Captured" */
export type Number_Triatomines_Captured_On_Conflict = {
  constraint: Number_Triatomines_Captured_Constraint;
  update_columns?: Array<Number_Triatomines_Captured_Update_Column>;
  where?: InputMaybe<Number_Triatomines_Captured_Bool_Exp>;
};

/** Ordering options when selecting data from "Number_Triatomines_Captured". */
export type Number_Triatomines_Captured_Order_By = {
  capturedFemale?: InputMaybe<Order_By>;
  capturedMale?: InputMaybe<Order_By>;
  capturedStage1?: InputMaybe<Order_By>;
  capturedStage2?: InputMaybe<Order_By>;
  capturedStage3?: InputMaybe<Order_By>;
  capturedStage4?: InputMaybe<Order_By>;
  capturedStage5?: InputMaybe<Order_By>;
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Number_Triatomines_Captured */
export type Number_Triatomines_Captured_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Number_Triatomines_Captured" */
export enum Number_Triatomines_Captured_Select_Column {
  /** column name */
  CapturedFemale = "capturedFemale",
  /** column name */
  CapturedMale = "capturedMale",
  /** column name */
  CapturedStage1 = "capturedStage1",
  /** column name */
  CapturedStage2 = "capturedStage2",
  /** column name */
  CapturedStage3 = "capturedStage3",
  /** column name */
  CapturedStage4 = "capturedStage4",
  /** column name */
  CapturedStage5 = "capturedStage5",
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  Id = "id",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

/** input type for updating data in table "Number_Triatomines_Captured" */
export type Number_Triatomines_Captured_Set_Input = {
  capturedFemale?: InputMaybe<Scalars["Int"]>;
  capturedMale?: InputMaybe<Scalars["Int"]>;
  capturedStage1?: InputMaybe<Scalars["Int"]>;
  capturedStage2?: InputMaybe<Scalars["Int"]>;
  capturedStage3?: InputMaybe<Scalars["Int"]>;
  capturedStage4?: InputMaybe<Scalars["Int"]>;
  capturedStage5?: InputMaybe<Scalars["Int"]>;
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Number_Triatomines_Captured_Stddev_Fields = {
  __typename?: "Number_Triatomines_Captured_stddev_fields";
  capturedFemale?: Maybe<Scalars["Float"]>;
  capturedMale?: Maybe<Scalars["Float"]>;
  capturedStage1?: Maybe<Scalars["Float"]>;
  capturedStage2?: Maybe<Scalars["Float"]>;
  capturedStage3?: Maybe<Scalars["Float"]>;
  capturedStage4?: Maybe<Scalars["Float"]>;
  capturedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Number_Triatomines_Captured_Stddev_Pop_Fields = {
  __typename?: "Number_Triatomines_Captured_stddev_pop_fields";
  capturedFemale?: Maybe<Scalars["Float"]>;
  capturedMale?: Maybe<Scalars["Float"]>;
  capturedStage1?: Maybe<Scalars["Float"]>;
  capturedStage2?: Maybe<Scalars["Float"]>;
  capturedStage3?: Maybe<Scalars["Float"]>;
  capturedStage4?: Maybe<Scalars["Float"]>;
  capturedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Number_Triatomines_Captured_Stddev_Samp_Fields = {
  __typename?: "Number_Triatomines_Captured_stddev_samp_fields";
  capturedFemale?: Maybe<Scalars["Float"]>;
  capturedMale?: Maybe<Scalars["Float"]>;
  capturedStage1?: Maybe<Scalars["Float"]>;
  capturedStage2?: Maybe<Scalars["Float"]>;
  capturedStage3?: Maybe<Scalars["Float"]>;
  capturedStage4?: Maybe<Scalars["Float"]>;
  capturedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Number_Triatomines_Captured" */
export type Number_Triatomines_Captured_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Number_Triatomines_Captured_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Number_Triatomines_Captured_Stream_Cursor_Value_Input = {
  capturedFemale?: InputMaybe<Scalars["Int"]>;
  capturedMale?: InputMaybe<Scalars["Int"]>;
  capturedStage1?: InputMaybe<Scalars["Int"]>;
  capturedStage2?: InputMaybe<Scalars["Int"]>;
  capturedStage3?: InputMaybe<Scalars["Int"]>;
  capturedStage4?: InputMaybe<Scalars["Int"]>;
  capturedStage5?: InputMaybe<Scalars["Int"]>;
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Number_Triatomines_Captured_Sum_Fields = {
  __typename?: "Number_Triatomines_Captured_sum_fields";
  capturedFemale?: Maybe<Scalars["Int"]>;
  capturedMale?: Maybe<Scalars["Int"]>;
  capturedStage1?: Maybe<Scalars["Int"]>;
  capturedStage2?: Maybe<Scalars["Int"]>;
  capturedStage3?: Maybe<Scalars["Int"]>;
  capturedStage4?: Maybe<Scalars["Int"]>;
  capturedStage5?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Number_Triatomines_Captured" */
export enum Number_Triatomines_Captured_Update_Column {
  /** column name */
  CapturedFemale = "capturedFemale",
  /** column name */
  CapturedMale = "capturedMale",
  /** column name */
  CapturedStage1 = "capturedStage1",
  /** column name */
  CapturedStage2 = "capturedStage2",
  /** column name */
  CapturedStage3 = "capturedStage3",
  /** column name */
  CapturedStage4 = "capturedStage4",
  /** column name */
  CapturedStage5 = "capturedStage5",
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  Id = "id",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

export type Number_Triatomines_Captured_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Number_Triatomines_Captured_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Number_Triatomines_Captured_Set_Input>;
  /** filter the rows which have to be updated */
  where: Number_Triatomines_Captured_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Number_Triatomines_Captured_Var_Pop_Fields = {
  __typename?: "Number_Triatomines_Captured_var_pop_fields";
  capturedFemale?: Maybe<Scalars["Float"]>;
  capturedMale?: Maybe<Scalars["Float"]>;
  capturedStage1?: Maybe<Scalars["Float"]>;
  capturedStage2?: Maybe<Scalars["Float"]>;
  capturedStage3?: Maybe<Scalars["Float"]>;
  capturedStage4?: Maybe<Scalars["Float"]>;
  capturedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Number_Triatomines_Captured_Var_Samp_Fields = {
  __typename?: "Number_Triatomines_Captured_var_samp_fields";
  capturedFemale?: Maybe<Scalars["Float"]>;
  capturedMale?: Maybe<Scalars["Float"]>;
  capturedStage1?: Maybe<Scalars["Float"]>;
  capturedStage2?: Maybe<Scalars["Float"]>;
  capturedStage3?: Maybe<Scalars["Float"]>;
  capturedStage4?: Maybe<Scalars["Float"]>;
  capturedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Number_Triatomines_Captured_Variance_Fields = {
  __typename?: "Number_Triatomines_Captured_variance_fields";
  capturedFemale?: Maybe<Scalars["Float"]>;
  capturedMale?: Maybe<Scalars["Float"]>;
  capturedStage1?: Maybe<Scalars["Float"]>;
  capturedStage2?: Maybe<Scalars["Float"]>;
  capturedStage3?: Maybe<Scalars["Float"]>;
  capturedStage4?: Maybe<Scalars["Float"]>;
  capturedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Number_Triatomines_Inspected" */
export type Number_Triatomines_Inspected = {
  __typename?: "Number_Triatomines_Inspected";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  inspectedMale?: Maybe<Scalars["Int"]>;
  ispectedFemale?: Maybe<Scalars["Int"]>;
  ispectedStage2?: Maybe<Scalars["Int"]>;
  ispectedStage3?: Maybe<Scalars["Int"]>;
  ispectedStage4?: Maybe<Scalars["Int"]>;
  ispectedStage5?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "Number_Triatomines_Inspected" */
export type Number_Triatomines_Inspected_Aggregate = {
  __typename?: "Number_Triatomines_Inspected_aggregate";
  aggregate?: Maybe<Number_Triatomines_Inspected_Aggregate_Fields>;
  nodes: Array<Number_Triatomines_Inspected>;
};

/** aggregate fields of "Number_Triatomines_Inspected" */
export type Number_Triatomines_Inspected_Aggregate_Fields = {
  __typename?: "Number_Triatomines_Inspected_aggregate_fields";
  avg?: Maybe<Number_Triatomines_Inspected_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Number_Triatomines_Inspected_Max_Fields>;
  min?: Maybe<Number_Triatomines_Inspected_Min_Fields>;
  stddev?: Maybe<Number_Triatomines_Inspected_Stddev_Fields>;
  stddev_pop?: Maybe<Number_Triatomines_Inspected_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Number_Triatomines_Inspected_Stddev_Samp_Fields>;
  sum?: Maybe<Number_Triatomines_Inspected_Sum_Fields>;
  var_pop?: Maybe<Number_Triatomines_Inspected_Var_Pop_Fields>;
  var_samp?: Maybe<Number_Triatomines_Inspected_Var_Samp_Fields>;
  variance?: Maybe<Number_Triatomines_Inspected_Variance_Fields>;
};

/** aggregate fields of "Number_Triatomines_Inspected" */
export type Number_Triatomines_Inspected_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Number_Triatomines_Inspected_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Number_Triatomines_Inspected_Avg_Fields = {
  __typename?: "Number_Triatomines_Inspected_avg_fields";
  inspectedMale?: Maybe<Scalars["Float"]>;
  ispectedFemale?: Maybe<Scalars["Float"]>;
  ispectedStage2?: Maybe<Scalars["Float"]>;
  ispectedStage3?: Maybe<Scalars["Float"]>;
  ispectedStage4?: Maybe<Scalars["Float"]>;
  ispectedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Number_Triatomines_Inspected". All fields are combined with a logical 'AND'. */
export type Number_Triatomines_Inspected_Bool_Exp = {
  _and?: InputMaybe<Array<Number_Triatomines_Inspected_Bool_Exp>>;
  _not?: InputMaybe<Number_Triatomines_Inspected_Bool_Exp>;
  _or?: InputMaybe<Array<Number_Triatomines_Inspected_Bool_Exp>>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inspectedMale?: InputMaybe<Int_Comparison_Exp>;
  ispectedFemale?: InputMaybe<Int_Comparison_Exp>;
  ispectedStage2?: InputMaybe<Int_Comparison_Exp>;
  ispectedStage3?: InputMaybe<Int_Comparison_Exp>;
  ispectedStage4?: InputMaybe<Int_Comparison_Exp>;
  ispectedStage5?: InputMaybe<Int_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  user_updated?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Number_Triatomines_Inspected" */
export enum Number_Triatomines_Inspected_Constraint {
  /** unique or primary key constraint on columns "id" */
  NumberTriatominesInspectedPkey = "Number_Triatomines_Inspected_pkey",
}

/** input type for incrementing numeric columns in table "Number_Triatomines_Inspected" */
export type Number_Triatomines_Inspected_Inc_Input = {
  inspectedMale?: InputMaybe<Scalars["Int"]>;
  ispectedFemale?: InputMaybe<Scalars["Int"]>;
  ispectedStage2?: InputMaybe<Scalars["Int"]>;
  ispectedStage3?: InputMaybe<Scalars["Int"]>;
  ispectedStage4?: InputMaybe<Scalars["Int"]>;
  ispectedStage5?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Number_Triatomines_Inspected" */
export type Number_Triatomines_Inspected_Insert_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  inspectedMale?: InputMaybe<Scalars["Int"]>;
  ispectedFemale?: InputMaybe<Scalars["Int"]>;
  ispectedStage2?: InputMaybe<Scalars["Int"]>;
  ispectedStage3?: InputMaybe<Scalars["Int"]>;
  ispectedStage4?: InputMaybe<Scalars["Int"]>;
  ispectedStage5?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Number_Triatomines_Inspected_Max_Fields = {
  __typename?: "Number_Triatomines_Inspected_max_fields";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  inspectedMale?: Maybe<Scalars["Int"]>;
  ispectedFemale?: Maybe<Scalars["Int"]>;
  ispectedStage2?: Maybe<Scalars["Int"]>;
  ispectedStage3?: Maybe<Scalars["Int"]>;
  ispectedStage4?: Maybe<Scalars["Int"]>;
  ispectedStage5?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Number_Triatomines_Inspected_Min_Fields = {
  __typename?: "Number_Triatomines_Inspected_min_fields";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  inspectedMale?: Maybe<Scalars["Int"]>;
  ispectedFemale?: Maybe<Scalars["Int"]>;
  ispectedStage2?: Maybe<Scalars["Int"]>;
  ispectedStage3?: Maybe<Scalars["Int"]>;
  ispectedStage4?: Maybe<Scalars["Int"]>;
  ispectedStage5?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "Number_Triatomines_Inspected" */
export type Number_Triatomines_Inspected_Mutation_Response = {
  __typename?: "Number_Triatomines_Inspected_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Number_Triatomines_Inspected>;
};

/** on_conflict condition type for table "Number_Triatomines_Inspected" */
export type Number_Triatomines_Inspected_On_Conflict = {
  constraint: Number_Triatomines_Inspected_Constraint;
  update_columns?: Array<Number_Triatomines_Inspected_Update_Column>;
  where?: InputMaybe<Number_Triatomines_Inspected_Bool_Exp>;
};

/** Ordering options when selecting data from "Number_Triatomines_Inspected". */
export type Number_Triatomines_Inspected_Order_By = {
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inspectedMale?: InputMaybe<Order_By>;
  ispectedFemale?: InputMaybe<Order_By>;
  ispectedStage2?: InputMaybe<Order_By>;
  ispectedStage3?: InputMaybe<Order_By>;
  ispectedStage4?: InputMaybe<Order_By>;
  ispectedStage5?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Number_Triatomines_Inspected */
export type Number_Triatomines_Inspected_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Number_Triatomines_Inspected" */
export enum Number_Triatomines_Inspected_Select_Column {
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  Id = "id",
  /** column name */
  InspectedMale = "inspectedMale",
  /** column name */
  IspectedFemale = "ispectedFemale",
  /** column name */
  IspectedStage2 = "ispectedStage2",
  /** column name */
  IspectedStage3 = "ispectedStage3",
  /** column name */
  IspectedStage4 = "ispectedStage4",
  /** column name */
  IspectedStage5 = "ispectedStage5",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

/** input type for updating data in table "Number_Triatomines_Inspected" */
export type Number_Triatomines_Inspected_Set_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  inspectedMale?: InputMaybe<Scalars["Int"]>;
  ispectedFemale?: InputMaybe<Scalars["Int"]>;
  ispectedStage2?: InputMaybe<Scalars["Int"]>;
  ispectedStage3?: InputMaybe<Scalars["Int"]>;
  ispectedStage4?: InputMaybe<Scalars["Int"]>;
  ispectedStage5?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Number_Triatomines_Inspected_Stddev_Fields = {
  __typename?: "Number_Triatomines_Inspected_stddev_fields";
  inspectedMale?: Maybe<Scalars["Float"]>;
  ispectedFemale?: Maybe<Scalars["Float"]>;
  ispectedStage2?: Maybe<Scalars["Float"]>;
  ispectedStage3?: Maybe<Scalars["Float"]>;
  ispectedStage4?: Maybe<Scalars["Float"]>;
  ispectedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Number_Triatomines_Inspected_Stddev_Pop_Fields = {
  __typename?: "Number_Triatomines_Inspected_stddev_pop_fields";
  inspectedMale?: Maybe<Scalars["Float"]>;
  ispectedFemale?: Maybe<Scalars["Float"]>;
  ispectedStage2?: Maybe<Scalars["Float"]>;
  ispectedStage3?: Maybe<Scalars["Float"]>;
  ispectedStage4?: Maybe<Scalars["Float"]>;
  ispectedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Number_Triatomines_Inspected_Stddev_Samp_Fields = {
  __typename?: "Number_Triatomines_Inspected_stddev_samp_fields";
  inspectedMale?: Maybe<Scalars["Float"]>;
  ispectedFemale?: Maybe<Scalars["Float"]>;
  ispectedStage2?: Maybe<Scalars["Float"]>;
  ispectedStage3?: Maybe<Scalars["Float"]>;
  ispectedStage4?: Maybe<Scalars["Float"]>;
  ispectedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Number_Triatomines_Inspected" */
export type Number_Triatomines_Inspected_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Number_Triatomines_Inspected_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Number_Triatomines_Inspected_Stream_Cursor_Value_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  inspectedMale?: InputMaybe<Scalars["Int"]>;
  ispectedFemale?: InputMaybe<Scalars["Int"]>;
  ispectedStage2?: InputMaybe<Scalars["Int"]>;
  ispectedStage3?: InputMaybe<Scalars["Int"]>;
  ispectedStage4?: InputMaybe<Scalars["Int"]>;
  ispectedStage5?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Number_Triatomines_Inspected_Sum_Fields = {
  __typename?: "Number_Triatomines_Inspected_sum_fields";
  inspectedMale?: Maybe<Scalars["Int"]>;
  ispectedFemale?: Maybe<Scalars["Int"]>;
  ispectedStage2?: Maybe<Scalars["Int"]>;
  ispectedStage3?: Maybe<Scalars["Int"]>;
  ispectedStage4?: Maybe<Scalars["Int"]>;
  ispectedStage5?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Number_Triatomines_Inspected" */
export enum Number_Triatomines_Inspected_Update_Column {
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  Id = "id",
  /** column name */
  InspectedMale = "inspectedMale",
  /** column name */
  IspectedFemale = "ispectedFemale",
  /** column name */
  IspectedStage2 = "ispectedStage2",
  /** column name */
  IspectedStage3 = "ispectedStage3",
  /** column name */
  IspectedStage4 = "ispectedStage4",
  /** column name */
  IspectedStage5 = "ispectedStage5",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

export type Number_Triatomines_Inspected_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Number_Triatomines_Inspected_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Number_Triatomines_Inspected_Set_Input>;
  /** filter the rows which have to be updated */
  where: Number_Triatomines_Inspected_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Number_Triatomines_Inspected_Var_Pop_Fields = {
  __typename?: "Number_Triatomines_Inspected_var_pop_fields";
  inspectedMale?: Maybe<Scalars["Float"]>;
  ispectedFemale?: Maybe<Scalars["Float"]>;
  ispectedStage2?: Maybe<Scalars["Float"]>;
  ispectedStage3?: Maybe<Scalars["Float"]>;
  ispectedStage4?: Maybe<Scalars["Float"]>;
  ispectedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Number_Triatomines_Inspected_Var_Samp_Fields = {
  __typename?: "Number_Triatomines_Inspected_var_samp_fields";
  inspectedMale?: Maybe<Scalars["Float"]>;
  ispectedFemale?: Maybe<Scalars["Float"]>;
  ispectedStage2?: Maybe<Scalars["Float"]>;
  ispectedStage3?: Maybe<Scalars["Float"]>;
  ispectedStage4?: Maybe<Scalars["Float"]>;
  ispectedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Number_Triatomines_Inspected_Variance_Fields = {
  __typename?: "Number_Triatomines_Inspected_variance_fields";
  inspectedMale?: Maybe<Scalars["Float"]>;
  ispectedFemale?: Maybe<Scalars["Float"]>;
  ispectedStage2?: Maybe<Scalars["Float"]>;
  ispectedStage3?: Maybe<Scalars["Float"]>;
  ispectedStage4?: Maybe<Scalars["Float"]>;
  ispectedStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Number_Triatomines_Positive" */
export type Number_Triatomines_Positive = {
  __typename?: "Number_Triatomines_Positive";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  positiveFemale?: Maybe<Scalars["Int"]>;
  positiveMale?: Maybe<Scalars["Int"]>;
  positiveStage2?: Maybe<Scalars["Int"]>;
  positiveStage3?: Maybe<Scalars["Int"]>;
  positiveStage4?: Maybe<Scalars["Int"]>;
  positiveStage5?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "Number_Triatomines_Positive" */
export type Number_Triatomines_Positive_Aggregate = {
  __typename?: "Number_Triatomines_Positive_aggregate";
  aggregate?: Maybe<Number_Triatomines_Positive_Aggregate_Fields>;
  nodes: Array<Number_Triatomines_Positive>;
};

/** aggregate fields of "Number_Triatomines_Positive" */
export type Number_Triatomines_Positive_Aggregate_Fields = {
  __typename?: "Number_Triatomines_Positive_aggregate_fields";
  avg?: Maybe<Number_Triatomines_Positive_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Number_Triatomines_Positive_Max_Fields>;
  min?: Maybe<Number_Triatomines_Positive_Min_Fields>;
  stddev?: Maybe<Number_Triatomines_Positive_Stddev_Fields>;
  stddev_pop?: Maybe<Number_Triatomines_Positive_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Number_Triatomines_Positive_Stddev_Samp_Fields>;
  sum?: Maybe<Number_Triatomines_Positive_Sum_Fields>;
  var_pop?: Maybe<Number_Triatomines_Positive_Var_Pop_Fields>;
  var_samp?: Maybe<Number_Triatomines_Positive_Var_Samp_Fields>;
  variance?: Maybe<Number_Triatomines_Positive_Variance_Fields>;
};

/** aggregate fields of "Number_Triatomines_Positive" */
export type Number_Triatomines_Positive_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Number_Triatomines_Positive_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Number_Triatomines_Positive_Avg_Fields = {
  __typename?: "Number_Triatomines_Positive_avg_fields";
  positiveFemale?: Maybe<Scalars["Float"]>;
  positiveMale?: Maybe<Scalars["Float"]>;
  positiveStage2?: Maybe<Scalars["Float"]>;
  positiveStage3?: Maybe<Scalars["Float"]>;
  positiveStage4?: Maybe<Scalars["Float"]>;
  positiveStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Number_Triatomines_Positive". All fields are combined with a logical 'AND'. */
export type Number_Triatomines_Positive_Bool_Exp = {
  _and?: InputMaybe<Array<Number_Triatomines_Positive_Bool_Exp>>;
  _not?: InputMaybe<Number_Triatomines_Positive_Bool_Exp>;
  _or?: InputMaybe<Array<Number_Triatomines_Positive_Bool_Exp>>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  positiveFemale?: InputMaybe<Int_Comparison_Exp>;
  positiveMale?: InputMaybe<Int_Comparison_Exp>;
  positiveStage2?: InputMaybe<Int_Comparison_Exp>;
  positiveStage3?: InputMaybe<Int_Comparison_Exp>;
  positiveStage4?: InputMaybe<Int_Comparison_Exp>;
  positiveStage5?: InputMaybe<Int_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  user_updated?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Number_Triatomines_Positive" */
export enum Number_Triatomines_Positive_Constraint {
  /** unique or primary key constraint on columns "id" */
  NumberTriatominesPositivePkey = "Number_Triatomines_Positive_pkey",
}

/** input type for incrementing numeric columns in table "Number_Triatomines_Positive" */
export type Number_Triatomines_Positive_Inc_Input = {
  positiveFemale?: InputMaybe<Scalars["Int"]>;
  positiveMale?: InputMaybe<Scalars["Int"]>;
  positiveStage2?: InputMaybe<Scalars["Int"]>;
  positiveStage3?: InputMaybe<Scalars["Int"]>;
  positiveStage4?: InputMaybe<Scalars["Int"]>;
  positiveStage5?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Number_Triatomines_Positive" */
export type Number_Triatomines_Positive_Insert_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  positiveFemale?: InputMaybe<Scalars["Int"]>;
  positiveMale?: InputMaybe<Scalars["Int"]>;
  positiveStage2?: InputMaybe<Scalars["Int"]>;
  positiveStage3?: InputMaybe<Scalars["Int"]>;
  positiveStage4?: InputMaybe<Scalars["Int"]>;
  positiveStage5?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Number_Triatomines_Positive_Max_Fields = {
  __typename?: "Number_Triatomines_Positive_max_fields";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  positiveFemale?: Maybe<Scalars["Int"]>;
  positiveMale?: Maybe<Scalars["Int"]>;
  positiveStage2?: Maybe<Scalars["Int"]>;
  positiveStage3?: Maybe<Scalars["Int"]>;
  positiveStage4?: Maybe<Scalars["Int"]>;
  positiveStage5?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Number_Triatomines_Positive_Min_Fields = {
  __typename?: "Number_Triatomines_Positive_min_fields";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  positiveFemale?: Maybe<Scalars["Int"]>;
  positiveMale?: Maybe<Scalars["Int"]>;
  positiveStage2?: Maybe<Scalars["Int"]>;
  positiveStage3?: Maybe<Scalars["Int"]>;
  positiveStage4?: Maybe<Scalars["Int"]>;
  positiveStage5?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "Number_Triatomines_Positive" */
export type Number_Triatomines_Positive_Mutation_Response = {
  __typename?: "Number_Triatomines_Positive_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Number_Triatomines_Positive>;
};

/** on_conflict condition type for table "Number_Triatomines_Positive" */
export type Number_Triatomines_Positive_On_Conflict = {
  constraint: Number_Triatomines_Positive_Constraint;
  update_columns?: Array<Number_Triatomines_Positive_Update_Column>;
  where?: InputMaybe<Number_Triatomines_Positive_Bool_Exp>;
};

/** Ordering options when selecting data from "Number_Triatomines_Positive". */
export type Number_Triatomines_Positive_Order_By = {
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  positiveFemale?: InputMaybe<Order_By>;
  positiveMale?: InputMaybe<Order_By>;
  positiveStage2?: InputMaybe<Order_By>;
  positiveStage3?: InputMaybe<Order_By>;
  positiveStage4?: InputMaybe<Order_By>;
  positiveStage5?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Number_Triatomines_Positive */
export type Number_Triatomines_Positive_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Number_Triatomines_Positive" */
export enum Number_Triatomines_Positive_Select_Column {
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  Id = "id",
  /** column name */
  PositiveFemale = "positiveFemale",
  /** column name */
  PositiveMale = "positiveMale",
  /** column name */
  PositiveStage2 = "positiveStage2",
  /** column name */
  PositiveStage3 = "positiveStage3",
  /** column name */
  PositiveStage4 = "positiveStage4",
  /** column name */
  PositiveStage5 = "positiveStage5",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

/** input type for updating data in table "Number_Triatomines_Positive" */
export type Number_Triatomines_Positive_Set_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  positiveFemale?: InputMaybe<Scalars["Int"]>;
  positiveMale?: InputMaybe<Scalars["Int"]>;
  positiveStage2?: InputMaybe<Scalars["Int"]>;
  positiveStage3?: InputMaybe<Scalars["Int"]>;
  positiveStage4?: InputMaybe<Scalars["Int"]>;
  positiveStage5?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Number_Triatomines_Positive_Stddev_Fields = {
  __typename?: "Number_Triatomines_Positive_stddev_fields";
  positiveFemale?: Maybe<Scalars["Float"]>;
  positiveMale?: Maybe<Scalars["Float"]>;
  positiveStage2?: Maybe<Scalars["Float"]>;
  positiveStage3?: Maybe<Scalars["Float"]>;
  positiveStage4?: Maybe<Scalars["Float"]>;
  positiveStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Number_Triatomines_Positive_Stddev_Pop_Fields = {
  __typename?: "Number_Triatomines_Positive_stddev_pop_fields";
  positiveFemale?: Maybe<Scalars["Float"]>;
  positiveMale?: Maybe<Scalars["Float"]>;
  positiveStage2?: Maybe<Scalars["Float"]>;
  positiveStage3?: Maybe<Scalars["Float"]>;
  positiveStage4?: Maybe<Scalars["Float"]>;
  positiveStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Number_Triatomines_Positive_Stddev_Samp_Fields = {
  __typename?: "Number_Triatomines_Positive_stddev_samp_fields";
  positiveFemale?: Maybe<Scalars["Float"]>;
  positiveMale?: Maybe<Scalars["Float"]>;
  positiveStage2?: Maybe<Scalars["Float"]>;
  positiveStage3?: Maybe<Scalars["Float"]>;
  positiveStage4?: Maybe<Scalars["Float"]>;
  positiveStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Number_Triatomines_Positive" */
export type Number_Triatomines_Positive_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Number_Triatomines_Positive_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Number_Triatomines_Positive_Stream_Cursor_Value_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  positiveFemale?: InputMaybe<Scalars["Int"]>;
  positiveMale?: InputMaybe<Scalars["Int"]>;
  positiveStage2?: InputMaybe<Scalars["Int"]>;
  positiveStage3?: InputMaybe<Scalars["Int"]>;
  positiveStage4?: InputMaybe<Scalars["Int"]>;
  positiveStage5?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Number_Triatomines_Positive_Sum_Fields = {
  __typename?: "Number_Triatomines_Positive_sum_fields";
  positiveFemale?: Maybe<Scalars["Int"]>;
  positiveMale?: Maybe<Scalars["Int"]>;
  positiveStage2?: Maybe<Scalars["Int"]>;
  positiveStage3?: Maybe<Scalars["Int"]>;
  positiveStage4?: Maybe<Scalars["Int"]>;
  positiveStage5?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Number_Triatomines_Positive" */
export enum Number_Triatomines_Positive_Update_Column {
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  Id = "id",
  /** column name */
  PositiveFemale = "positiveFemale",
  /** column name */
  PositiveMale = "positiveMale",
  /** column name */
  PositiveStage2 = "positiveStage2",
  /** column name */
  PositiveStage3 = "positiveStage3",
  /** column name */
  PositiveStage4 = "positiveStage4",
  /** column name */
  PositiveStage5 = "positiveStage5",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

export type Number_Triatomines_Positive_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Number_Triatomines_Positive_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Number_Triatomines_Positive_Set_Input>;
  /** filter the rows which have to be updated */
  where: Number_Triatomines_Positive_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Number_Triatomines_Positive_Var_Pop_Fields = {
  __typename?: "Number_Triatomines_Positive_var_pop_fields";
  positiveFemale?: Maybe<Scalars["Float"]>;
  positiveMale?: Maybe<Scalars["Float"]>;
  positiveStage2?: Maybe<Scalars["Float"]>;
  positiveStage3?: Maybe<Scalars["Float"]>;
  positiveStage4?: Maybe<Scalars["Float"]>;
  positiveStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Number_Triatomines_Positive_Var_Samp_Fields = {
  __typename?: "Number_Triatomines_Positive_var_samp_fields";
  positiveFemale?: Maybe<Scalars["Float"]>;
  positiveMale?: Maybe<Scalars["Float"]>;
  positiveStage2?: Maybe<Scalars["Float"]>;
  positiveStage3?: Maybe<Scalars["Float"]>;
  positiveStage4?: Maybe<Scalars["Float"]>;
  positiveStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Number_Triatomines_Positive_Variance_Fields = {
  __typename?: "Number_Triatomines_Positive_variance_fields";
  positiveFemale?: Maybe<Scalars["Float"]>;
  positiveMale?: Maybe<Scalars["Float"]>;
  positiveStage2?: Maybe<Scalars["Float"]>;
  positiveStage3?: Maybe<Scalars["Float"]>;
  positiveStage4?: Maybe<Scalars["Float"]>;
  positiveStage5?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Place_Place" */
export type Place_Place = {
  __typename?: "Place_Place";
  Place_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["Int"];
  related_Place_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "Place_Place" */
export type Place_Place_Aggregate = {
  __typename?: "Place_Place_aggregate";
  aggregate?: Maybe<Place_Place_Aggregate_Fields>;
  nodes: Array<Place_Place>;
};

/** aggregate fields of "Place_Place" */
export type Place_Place_Aggregate_Fields = {
  __typename?: "Place_Place_aggregate_fields";
  avg?: Maybe<Place_Place_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Place_Place_Max_Fields>;
  min?: Maybe<Place_Place_Min_Fields>;
  stddev?: Maybe<Place_Place_Stddev_Fields>;
  stddev_pop?: Maybe<Place_Place_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Place_Place_Stddev_Samp_Fields>;
  sum?: Maybe<Place_Place_Sum_Fields>;
  var_pop?: Maybe<Place_Place_Var_Pop_Fields>;
  var_samp?: Maybe<Place_Place_Var_Samp_Fields>;
  variance?: Maybe<Place_Place_Variance_Fields>;
};

/** aggregate fields of "Place_Place" */
export type Place_Place_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Place_Place_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Place_Place_Avg_Fields = {
  __typename?: "Place_Place_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Place_Place". All fields are combined with a logical 'AND'. */
export type Place_Place_Bool_Exp = {
  Place_id?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Place_Place_Bool_Exp>>;
  _not?: InputMaybe<Place_Place_Bool_Exp>;
  _or?: InputMaybe<Array<Place_Place_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  related_Place_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Place_Place" */
export enum Place_Place_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlacePlacePkey = "Place_Place_pkey",
}

/** input type for incrementing numeric columns in table "Place_Place" */
export type Place_Place_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Place_Place" */
export type Place_Place_Insert_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
  related_Place_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Place_Place_Max_Fields = {
  __typename?: "Place_Place_max_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  related_Place_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Place_Place_Min_Fields = {
  __typename?: "Place_Place_min_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  related_Place_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "Place_Place" */
export type Place_Place_Mutation_Response = {
  __typename?: "Place_Place_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Place_Place>;
};

/** on_conflict condition type for table "Place_Place" */
export type Place_Place_On_Conflict = {
  constraint: Place_Place_Constraint;
  update_columns?: Array<Place_Place_Update_Column>;
  where?: InputMaybe<Place_Place_Bool_Exp>;
};

/** Ordering options when selecting data from "Place_Place". */
export type Place_Place_Order_By = {
  Place_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  related_Place_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Place_Place */
export type Place_Place_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "Place_Place" */
export enum Place_Place_Select_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  Id = "id",
  /** column name */
  RelatedPlaceId = "related_Place_id",
}

/** input type for updating data in table "Place_Place" */
export type Place_Place_Set_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
  related_Place_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Place_Place_Stddev_Fields = {
  __typename?: "Place_Place_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Place_Place_Stddev_Pop_Fields = {
  __typename?: "Place_Place_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Place_Place_Stddev_Samp_Fields = {
  __typename?: "Place_Place_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Place_Place" */
export type Place_Place_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Place_Place_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Place_Place_Stream_Cursor_Value_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
  related_Place_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Place_Place_Sum_Fields = {
  __typename?: "Place_Place_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Place_Place" */
export enum Place_Place_Update_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  Id = "id",
  /** column name */
  RelatedPlaceId = "related_Place_id",
}

export type Place_Place_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Place_Place_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Place_Place_Set_Input>;
  /** filter the rows which have to be updated */
  where: Place_Place_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Place_Place_Var_Pop_Fields = {
  __typename?: "Place_Place_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Place_Place_Var_Samp_Fields = {
  __typename?: "Place_Place_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Place_Place_Variance_Fields = {
  __typename?: "Place_Place_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Place_Triatomines_Experiment_Record" */
export type Place_Triatomines_Experiment_Record = {
  __typename?: "Place_Triatomines_Experiment_Record";
  Place_id?: Maybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["Int"];
};

/** aggregated selection of "Place_Triatomines_Experiment_Record" */
export type Place_Triatomines_Experiment_Record_Aggregate = {
  __typename?: "Place_Triatomines_Experiment_Record_aggregate";
  aggregate?: Maybe<Place_Triatomines_Experiment_Record_Aggregate_Fields>;
  nodes: Array<Place_Triatomines_Experiment_Record>;
};

/** aggregate fields of "Place_Triatomines_Experiment_Record" */
export type Place_Triatomines_Experiment_Record_Aggregate_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_aggregate_fields";
  avg?: Maybe<Place_Triatomines_Experiment_Record_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Place_Triatomines_Experiment_Record_Max_Fields>;
  min?: Maybe<Place_Triatomines_Experiment_Record_Min_Fields>;
  stddev?: Maybe<Place_Triatomines_Experiment_Record_Stddev_Fields>;
  stddev_pop?: Maybe<Place_Triatomines_Experiment_Record_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Place_Triatomines_Experiment_Record_Stddev_Samp_Fields>;
  sum?: Maybe<Place_Triatomines_Experiment_Record_Sum_Fields>;
  var_pop?: Maybe<Place_Triatomines_Experiment_Record_Var_Pop_Fields>;
  var_samp?: Maybe<Place_Triatomines_Experiment_Record_Var_Samp_Fields>;
  variance?: Maybe<Place_Triatomines_Experiment_Record_Variance_Fields>;
};

/** aggregate fields of "Place_Triatomines_Experiment_Record" */
export type Place_Triatomines_Experiment_Record_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Place_Triatomines_Experiment_Record_Avg_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Place_Triatomines_Experiment_Record". All fields are combined with a logical 'AND'. */
export type Place_Triatomines_Experiment_Record_Bool_Exp = {
  Place_id?: InputMaybe<Uuid_Comparison_Exp>;
  Triatomines_Experiment_Record_id?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Bool_Exp>>;
  _not?: InputMaybe<Place_Triatomines_Experiment_Record_Bool_Exp>;
  _or?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Place_Triatomines_Experiment_Record" */
export enum Place_Triatomines_Experiment_Record_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlaceTriatominesExperimentRecordPkey = "Place_Triatomines_Experiment_Record_pkey",
}

/** input type for incrementing numeric columns in table "Place_Triatomines_Experiment_Record" */
export type Place_Triatomines_Experiment_Record_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Place_Triatomines_Experiment_Record" */
export type Place_Triatomines_Experiment_Record_Insert_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Place_Triatomines_Experiment_Record_Max_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_max_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Place_Triatomines_Experiment_Record_Min_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_min_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "Place_Triatomines_Experiment_Record" */
export type Place_Triatomines_Experiment_Record_Mutation_Response = {
  __typename?: "Place_Triatomines_Experiment_Record_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Place_Triatomines_Experiment_Record>;
};

/** on_conflict condition type for table "Place_Triatomines_Experiment_Record" */
export type Place_Triatomines_Experiment_Record_On_Conflict = {
  constraint: Place_Triatomines_Experiment_Record_Constraint;
  update_columns?: Array<Place_Triatomines_Experiment_Record_Update_Column>;
  where?: InputMaybe<Place_Triatomines_Experiment_Record_Bool_Exp>;
};

/** Ordering options when selecting data from "Place_Triatomines_Experiment_Record". */
export type Place_Triatomines_Experiment_Record_Order_By = {
  Place_id?: InputMaybe<Order_By>;
  Triatomines_Experiment_Record_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Place_Triatomines_Experiment_Record */
export type Place_Triatomines_Experiment_Record_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "Place_Triatomines_Experiment_Record" */
export enum Place_Triatomines_Experiment_Record_Select_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  TriatominesExperimentRecordId = "Triatomines_Experiment_Record_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "Place_Triatomines_Experiment_Record" */
export type Place_Triatomines_Experiment_Record_Set_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Place_Triatomines_Experiment_Record_Stddev_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Place_Triatomines_Experiment_Record_Stddev_Pop_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Place_Triatomines_Experiment_Record_Stddev_Samp_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Place_Triatomines_Experiment_Record" */
export type Place_Triatomines_Experiment_Record_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Place_Triatomines_Experiment_Record_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Place_Triatomines_Experiment_Record_Stream_Cursor_Value_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Place_Triatomines_Experiment_Record_Sum_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Place_Triatomines_Experiment_Record" */
export enum Place_Triatomines_Experiment_Record_Update_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  TriatominesExperimentRecordId = "Triatomines_Experiment_Record_id",
  /** column name */
  Id = "id",
}

export type Place_Triatomines_Experiment_Record_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Place_Triatomines_Experiment_Record_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Place_Triatomines_Experiment_Record_Set_Input>;
  /** filter the rows which have to be updated */
  where: Place_Triatomines_Experiment_Record_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Place_Triatomines_Experiment_Record_Var_Pop_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Place_Triatomines_Experiment_Record_Var_Samp_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Place_Triatomines_Experiment_Record_Variance_Fields = {
  __typename?: "Place_Triatomines_Experiment_Record_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Place_children" */
export type Place_Children = {
  __typename?: "Place_children";
  Place_id?: Maybe<Scalars["uuid"]>;
  collection?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  item?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "Place_children" */
export type Place_Children_Aggregate = {
  __typename?: "Place_children_aggregate";
  aggregate?: Maybe<Place_Children_Aggregate_Fields>;
  nodes: Array<Place_Children>;
};

/** aggregate fields of "Place_children" */
export type Place_Children_Aggregate_Fields = {
  __typename?: "Place_children_aggregate_fields";
  avg?: Maybe<Place_Children_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Place_Children_Max_Fields>;
  min?: Maybe<Place_Children_Min_Fields>;
  stddev?: Maybe<Place_Children_Stddev_Fields>;
  stddev_pop?: Maybe<Place_Children_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Place_Children_Stddev_Samp_Fields>;
  sum?: Maybe<Place_Children_Sum_Fields>;
  var_pop?: Maybe<Place_Children_Var_Pop_Fields>;
  var_samp?: Maybe<Place_Children_Var_Samp_Fields>;
  variance?: Maybe<Place_Children_Variance_Fields>;
};

/** aggregate fields of "Place_children" */
export type Place_Children_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Place_Children_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Place_Children_Avg_Fields = {
  __typename?: "Place_children_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Place_children". All fields are combined with a logical 'AND'. */
export type Place_Children_Bool_Exp = {
  Place_id?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Place_Children_Bool_Exp>>;
  _not?: InputMaybe<Place_Children_Bool_Exp>;
  _or?: InputMaybe<Array<Place_Children_Bool_Exp>>;
  collection?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  item?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Place_children" */
export enum Place_Children_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlaceChildrenPkey = "Place_children_pkey",
}

/** input type for incrementing numeric columns in table "Place_children" */
export type Place_Children_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Place_children" */
export type Place_Children_Insert_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  collection?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  item?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Place_Children_Max_Fields = {
  __typename?: "Place_children_max_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  collection?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  item?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Place_Children_Min_Fields = {
  __typename?: "Place_children_min_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  collection?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  item?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Place_children" */
export type Place_Children_Mutation_Response = {
  __typename?: "Place_children_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Place_Children>;
};

/** on_conflict condition type for table "Place_children" */
export type Place_Children_On_Conflict = {
  constraint: Place_Children_Constraint;
  update_columns?: Array<Place_Children_Update_Column>;
  where?: InputMaybe<Place_Children_Bool_Exp>;
};

/** Ordering options when selecting data from "Place_children". */
export type Place_Children_Order_By = {
  Place_id?: InputMaybe<Order_By>;
  collection?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Place_children */
export type Place_Children_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "Place_children" */
export enum Place_Children_Select_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  Collection = "collection",
  /** column name */
  Id = "id",
  /** column name */
  Item = "item",
}

/** input type for updating data in table "Place_children" */
export type Place_Children_Set_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  collection?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  item?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Place_Children_Stddev_Fields = {
  __typename?: "Place_children_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Place_Children_Stddev_Pop_Fields = {
  __typename?: "Place_children_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Place_Children_Stddev_Samp_Fields = {
  __typename?: "Place_children_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Place_children" */
export type Place_Children_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Place_Children_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Place_Children_Stream_Cursor_Value_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  collection?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  item?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Place_Children_Sum_Fields = {
  __typename?: "Place_children_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Place_children" */
export enum Place_Children_Update_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  Collection = "collection",
  /** column name */
  Id = "id",
  /** column name */
  Item = "item",
}

export type Place_Children_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Place_Children_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Place_Children_Set_Input>;
  /** filter the rows which have to be updated */
  where: Place_Children_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Place_Children_Var_Pop_Fields = {
  __typename?: "Place_children_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Place_Children_Var_Samp_Fields = {
  __typename?: "Place_children_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Place_Children_Variance_Fields = {
  __typename?: "Place_children_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Place_files" */
export type Place_Files = {
  __typename?: "Place_files";
  Place_id?: Maybe<Scalars["uuid"]>;
  directus_files_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["Int"];
};

/** aggregated selection of "Place_files" */
export type Place_Files_Aggregate = {
  __typename?: "Place_files_aggregate";
  aggregate?: Maybe<Place_Files_Aggregate_Fields>;
  nodes: Array<Place_Files>;
};

/** aggregate fields of "Place_files" */
export type Place_Files_Aggregate_Fields = {
  __typename?: "Place_files_aggregate_fields";
  avg?: Maybe<Place_Files_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Place_Files_Max_Fields>;
  min?: Maybe<Place_Files_Min_Fields>;
  stddev?: Maybe<Place_Files_Stddev_Fields>;
  stddev_pop?: Maybe<Place_Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Place_Files_Stddev_Samp_Fields>;
  sum?: Maybe<Place_Files_Sum_Fields>;
  var_pop?: Maybe<Place_Files_Var_Pop_Fields>;
  var_samp?: Maybe<Place_Files_Var_Samp_Fields>;
  variance?: Maybe<Place_Files_Variance_Fields>;
};

/** aggregate fields of "Place_files" */
export type Place_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Place_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Place_Files_Avg_Fields = {
  __typename?: "Place_files_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Place_files". All fields are combined with a logical 'AND'. */
export type Place_Files_Bool_Exp = {
  Place_id?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Place_Files_Bool_Exp>>;
  _not?: InputMaybe<Place_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Place_Files_Bool_Exp>>;
  directus_files_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Place_files" */
export enum Place_Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlaceFilesPkey = "Place_files_pkey",
}

/** input type for incrementing numeric columns in table "Place_files" */
export type Place_Files_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Place_files" */
export type Place_Files_Insert_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  directus_files_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Place_Files_Max_Fields = {
  __typename?: "Place_files_max_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  directus_files_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Place_Files_Min_Fields = {
  __typename?: "Place_files_min_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  directus_files_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "Place_files" */
export type Place_Files_Mutation_Response = {
  __typename?: "Place_files_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Place_Files>;
};

/** on_conflict condition type for table "Place_files" */
export type Place_Files_On_Conflict = {
  constraint: Place_Files_Constraint;
  update_columns?: Array<Place_Files_Update_Column>;
  where?: InputMaybe<Place_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "Place_files". */
export type Place_Files_Order_By = {
  Place_id?: InputMaybe<Order_By>;
  directus_files_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Place_files */
export type Place_Files_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "Place_files" */
export enum Place_Files_Select_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  DirectusFilesId = "directus_files_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "Place_files" */
export type Place_Files_Set_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  directus_files_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Place_Files_Stddev_Fields = {
  __typename?: "Place_files_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Place_Files_Stddev_Pop_Fields = {
  __typename?: "Place_files_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Place_Files_Stddev_Samp_Fields = {
  __typename?: "Place_files_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Place_files" */
export type Place_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Place_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Place_Files_Stream_Cursor_Value_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  directus_files_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Place_Files_Sum_Fields = {
  __typename?: "Place_files_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Place_files" */
export enum Place_Files_Update_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  DirectusFilesId = "directus_files_id",
  /** column name */
  Id = "id",
}

export type Place_Files_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Place_Files_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Place_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Place_Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Place_Files_Var_Pop_Fields = {
  __typename?: "Place_files_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Place_Files_Var_Samp_Fields = {
  __typename?: "Place_files_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Place_Files_Variance_Fields = {
  __typename?: "Place_files_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Place" */
export type Place_Kind = {
  __typename?: "Place_kind";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  geometry?: Maybe<Scalars["geometry"]>;
  id: Scalars["uuid"];
  kind?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Scalars["json"]>;
  name?: Maybe<Scalars["String"]>;
  parent?: Maybe<Scalars["uuid"]>;
  sort?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "Place" */
export type Place_KindMetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "Place" */
export type Place_Kind_Aggregate = {
  __typename?: "Place_kind_aggregate";
  aggregate?: Maybe<Place_Kind_Aggregate_Fields>;
  nodes: Array<Place_Kind>;
};

/** aggregate fields of "Place" */
export type Place_Kind_Aggregate_Fields = {
  __typename?: "Place_kind_aggregate_fields";
  avg?: Maybe<Place_Kind_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Place_Kind_Max_Fields>;
  min?: Maybe<Place_Kind_Min_Fields>;
  stddev?: Maybe<Place_Kind_Stddev_Fields>;
  stddev_pop?: Maybe<Place_Kind_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Place_Kind_Stddev_Samp_Fields>;
  sum?: Maybe<Place_Kind_Sum_Fields>;
  var_pop?: Maybe<Place_Kind_Var_Pop_Fields>;
  var_samp?: Maybe<Place_Kind_Var_Samp_Fields>;
  variance?: Maybe<Place_Kind_Variance_Fields>;
};

/** aggregate fields of "Place" */
export type Place_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Place_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Place_Kind_Avg_Fields = {
  __typename?: "Place_kind_avg_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Place". All fields are combined with a logical 'AND'. */
export type Place_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Place_Kind_Bool_Exp>>;
  _not?: InputMaybe<Place_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Place_Kind_Bool_Exp>>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Json_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent?: InputMaybe<Uuid_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  user_updated?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Place" */
export enum Place_Kind_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlacePkey = "Place_pkey",
}

/** input type for incrementing numeric columns in table "Place" */
export type Place_Kind_Inc_Input = {
  sort?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Place" */
export type Place_Kind_Insert_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["json"]>;
  name?: InputMaybe<Scalars["String"]>;
  parent?: InputMaybe<Scalars["uuid"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Place_Kind_Max_Fields = {
  __typename?: "Place_kind_max_fields";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parent?: Maybe<Scalars["uuid"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Place_Kind_Min_Fields = {
  __typename?: "Place_kind_min_fields";
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parent?: Maybe<Scalars["uuid"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "Place" */
export type Place_Kind_Mutation_Response = {
  __typename?: "Place_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Place_Kind>;
};

/** on_conflict condition type for table "Place" */
export type Place_Kind_On_Conflict = {
  constraint: Place_Kind_Constraint;
  update_columns?: Array<Place_Kind_Update_Column>;
  where?: InputMaybe<Place_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "Place". */
export type Place_Kind_Order_By = {
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Place */
export type Place_Kind_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Place" */
export enum Place_Kind_Select_Column {
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  Description = "description",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Name = "name",
  /** column name */
  Parent = "parent",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

/** input type for updating data in table "Place" */
export type Place_Kind_Set_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["json"]>;
  name?: InputMaybe<Scalars["String"]>;
  parent?: InputMaybe<Scalars["uuid"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Place_Kind_Stddev_Fields = {
  __typename?: "Place_kind_stddev_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Place_Kind_Stddev_Pop_Fields = {
  __typename?: "Place_kind_stddev_pop_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Place_Kind_Stddev_Samp_Fields = {
  __typename?: "Place_kind_stddev_samp_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Place_kind" */
export type Place_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Place_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Place_Kind_Stream_Cursor_Value_Input = {
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["json"]>;
  name?: InputMaybe<Scalars["String"]>;
  parent?: InputMaybe<Scalars["uuid"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Place_Kind_Sum_Fields = {
  __typename?: "Place_kind_sum_fields";
  sort?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Place" */
export enum Place_Kind_Update_Column {
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  Description = "description",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Name = "name",
  /** column name */
  Parent = "parent",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

export type Place_Kind_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Place_Kind_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Place_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Place_Kind_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Place_Kind_Var_Pop_Fields = {
  __typename?: "Place_kind_var_pop_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Place_Kind_Var_Samp_Fields = {
  __typename?: "Place_kind_var_samp_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Place_Kind_Variance_Fields = {
  __typename?: "Place_kind_variance_fields";
  sort?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Place_record" */
export type Place_Record = {
  __typename?: "Place_record";
  Place_id?: Maybe<Scalars["uuid"]>;
  collection?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  item?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "Place_record" */
export type Place_Record_Aggregate = {
  __typename?: "Place_record_aggregate";
  aggregate?: Maybe<Place_Record_Aggregate_Fields>;
  nodes: Array<Place_Record>;
};

/** aggregate fields of "Place_record" */
export type Place_Record_Aggregate_Fields = {
  __typename?: "Place_record_aggregate_fields";
  avg?: Maybe<Place_Record_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Place_Record_Max_Fields>;
  min?: Maybe<Place_Record_Min_Fields>;
  stddev?: Maybe<Place_Record_Stddev_Fields>;
  stddev_pop?: Maybe<Place_Record_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Place_Record_Stddev_Samp_Fields>;
  sum?: Maybe<Place_Record_Sum_Fields>;
  var_pop?: Maybe<Place_Record_Var_Pop_Fields>;
  var_samp?: Maybe<Place_Record_Var_Samp_Fields>;
  variance?: Maybe<Place_Record_Variance_Fields>;
};

/** aggregate fields of "Place_record" */
export type Place_Record_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Place_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Place_Record_Avg_Fields = {
  __typename?: "Place_record_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Place_record". All fields are combined with a logical 'AND'. */
export type Place_Record_Bool_Exp = {
  Place_id?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Place_Record_Bool_Exp>>;
  _not?: InputMaybe<Place_Record_Bool_Exp>;
  _or?: InputMaybe<Array<Place_Record_Bool_Exp>>;
  collection?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  item?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Place_record" */
export enum Place_Record_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlaceRecordPkey = "Place_record_pkey",
}

/** input type for incrementing numeric columns in table "Place_record" */
export type Place_Record_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Place_record" */
export type Place_Record_Insert_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  collection?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  item?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Place_Record_Max_Fields = {
  __typename?: "Place_record_max_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  collection?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  item?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Place_Record_Min_Fields = {
  __typename?: "Place_record_min_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  collection?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  item?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Place_record" */
export type Place_Record_Mutation_Response = {
  __typename?: "Place_record_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Place_Record>;
};

/** on_conflict condition type for table "Place_record" */
export type Place_Record_On_Conflict = {
  constraint: Place_Record_Constraint;
  update_columns?: Array<Place_Record_Update_Column>;
  where?: InputMaybe<Place_Record_Bool_Exp>;
};

/** Ordering options when selecting data from "Place_record". */
export type Place_Record_Order_By = {
  Place_id?: InputMaybe<Order_By>;
  collection?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Place_record */
export type Place_Record_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "Place_record" */
export enum Place_Record_Select_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  Collection = "collection",
  /** column name */
  Id = "id",
  /** column name */
  Item = "item",
}

/** input type for updating data in table "Place_record" */
export type Place_Record_Set_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  collection?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  item?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Place_Record_Stddev_Fields = {
  __typename?: "Place_record_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Place_Record_Stddev_Pop_Fields = {
  __typename?: "Place_record_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Place_Record_Stddev_Samp_Fields = {
  __typename?: "Place_record_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Place_record" */
export type Place_Record_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Place_Record_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Place_Record_Stream_Cursor_Value_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  collection?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  item?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Place_Record_Sum_Fields = {
  __typename?: "Place_record_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Place_record" */
export enum Place_Record_Update_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  Collection = "collection",
  /** column name */
  Id = "id",
  /** column name */
  Item = "item",
}

export type Place_Record_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Place_Record_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Place_Record_Set_Input>;
  /** filter the rows which have to be updated */
  where: Place_Record_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Place_Record_Var_Pop_Fields = {
  __typename?: "Place_record_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Place_Record_Var_Samp_Fields = {
  __typename?: "Place_record_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Place_Record_Variance_Fields = {
  __typename?: "Place_record_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]>;
  _gt?: InputMaybe<Scalars["String"]>;
  _gte?: InputMaybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]>;
  _in?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]>;
  _lt?: InputMaybe<Scalars["String"]>;
  _lte?: InputMaybe<Scalars["String"]>;
  _neq?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]>;
  _nin?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "Triatomines" */
export type Triatomines = {
  __typename?: "Triatomines";
  apl_ins?: Maybe<Scalars["String"]>;
  cap_eta1: Scalars["Int"];
  cap_eta2: Scalars["Int"];
  cap_eta3: Scalars["Int"];
  cap_eta4: Scalars["Int"];
  cap_eta5: Scalars["Int"];
  cap_hem: Scalars["Int"];
  cap_mac: Scalars["Int"];
  cap_total: Scalars["Int"];
  codigo?: Maybe<Scalars["String"]>;
  created_at: Scalars["timestamptz"];
  digita: Scalars["String"];
  distrito: Scalars["Int"];
  exa_eta2: Scalars["Int"];
  exa_eta3: Scalars["Int"];
  exa_eta4: Scalars["Int"];
  exa_eta5: Scalars["Int"];
  exa_hem: Scalars["Int"];
  exa_mac: Scalars["Int"];
  exa_total: Scalars["Int"];
  fase: Scalars["Int"];
  fec_captura?: Maybe<Scalars["timestamptz"]>;
  fec_ingreso?: Maybe<Scalars["timestamptz"]>;
  fec_lectura?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  intra_peri?: Maybe<Scalars["String"]>;
  intra_peri_code?: Maybe<Scalars["String"]>;
  localidad: Scalars["String"];
  numero: Scalars["Int"];
  pos_eta2: Scalars["Int"];
  pos_eta3: Scalars["Int"];
  pos_eta4: Scalars["Int"];
  pos_eta5: Scalars["Int"];
  pos_hem: Scalars["Int"];
  pos_mac: Scalars["Int"];
  pos_total: Scalars["Int"];
  procedencia?: Maybe<Scalars["String"]>;
  procesado?: Maybe<Scalars["String"]>;
  provincia: Scalars["Int"];
  unicode: Scalars["String"];
  vivienda: Scalars["String"];
};

/** columns and relationships of "Triatomines_Experiment_Record" */
export type Triatomines_Experiment_Record = {
  __typename?: "Triatomines_Experiment_Record";
  captureDate?: Maybe<Scalars["timestamp"]>;
  capturePlace?: Maybe<Scalars["uuid"]>;
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  deliveredBy?: Maybe<Scalars["String"]>;
  entryDate?: Maybe<Scalars["timestamp"]>;
  externalCode?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  insecticideApplication?: Maybe<Scalars["Boolean"]>;
  numberTriatominesCaptured?: Maybe<Scalars["uuid"]>;
  numberTriatominesInspected?: Maybe<Scalars["uuid"]>;
  numberTriatominesPositive?: Maybe<Scalars["uuid"]>;
  observation?: Maybe<Scalars["String"]>;
  origin?: Maybe<Scalars["String"]>;
  phase?: Maybe<Scalars["Int"]>;
  place?: Maybe<Scalars["uuid"]>;
  processedBy?: Maybe<Scalars["String"]>;
  readDate?: Maybe<Scalars["timestamp"]>;
  sort?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  surveillanceCode?: Maybe<Scalars["Int"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "Triatomines_Experiment_Record_Place" */
export type Triatomines_Experiment_Record_Place = {
  __typename?: "Triatomines_Experiment_Record_Place";
  Place_id?: Maybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["Int"];
};

/** aggregated selection of "Triatomines_Experiment_Record_Place" */
export type Triatomines_Experiment_Record_Place_Aggregate = {
  __typename?: "Triatomines_Experiment_Record_Place_aggregate";
  aggregate?: Maybe<Triatomines_Experiment_Record_Place_Aggregate_Fields>;
  nodes: Array<Triatomines_Experiment_Record_Place>;
};

/** aggregate fields of "Triatomines_Experiment_Record_Place" */
export type Triatomines_Experiment_Record_Place_Aggregate_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_aggregate_fields";
  avg?: Maybe<Triatomines_Experiment_Record_Place_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Triatomines_Experiment_Record_Place_Max_Fields>;
  min?: Maybe<Triatomines_Experiment_Record_Place_Min_Fields>;
  stddev?: Maybe<Triatomines_Experiment_Record_Place_Stddev_Fields>;
  stddev_pop?: Maybe<Triatomines_Experiment_Record_Place_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Triatomines_Experiment_Record_Place_Stddev_Samp_Fields>;
  sum?: Maybe<Triatomines_Experiment_Record_Place_Sum_Fields>;
  var_pop?: Maybe<Triatomines_Experiment_Record_Place_Var_Pop_Fields>;
  var_samp?: Maybe<Triatomines_Experiment_Record_Place_Var_Samp_Fields>;
  variance?: Maybe<Triatomines_Experiment_Record_Place_Variance_Fields>;
};

/** aggregate fields of "Triatomines_Experiment_Record_Place" */
export type Triatomines_Experiment_Record_Place_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Triatomines_Experiment_Record_Place_Avg_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Triatomines_Experiment_Record_Place". All fields are combined with a logical 'AND'. */
export type Triatomines_Experiment_Record_Place_Bool_Exp = {
  Place_id?: InputMaybe<Uuid_Comparison_Exp>;
  Triatomines_Experiment_Record_id?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Bool_Exp>>;
  _not?: InputMaybe<Triatomines_Experiment_Record_Place_Bool_Exp>;
  _or?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Triatomines_Experiment_Record_Place" */
export enum Triatomines_Experiment_Record_Place_Constraint {
  /** unique or primary key constraint on columns "id" */
  TriatominesExperimentRecordPlacePkey = "Triatomines_Experiment_Record_Place_pkey",
}

/** input type for incrementing numeric columns in table "Triatomines_Experiment_Record_Place" */
export type Triatomines_Experiment_Record_Place_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Triatomines_Experiment_Record_Place" */
export type Triatomines_Experiment_Record_Place_Insert_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Triatomines_Experiment_Record_Place_Max_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_max_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Triatomines_Experiment_Record_Place_Min_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_min_fields";
  Place_id?: Maybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "Triatomines_Experiment_Record_Place" */
export type Triatomines_Experiment_Record_Place_Mutation_Response = {
  __typename?: "Triatomines_Experiment_Record_Place_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Triatomines_Experiment_Record_Place>;
};

/** on_conflict condition type for table "Triatomines_Experiment_Record_Place" */
export type Triatomines_Experiment_Record_Place_On_Conflict = {
  constraint: Triatomines_Experiment_Record_Place_Constraint;
  update_columns?: Array<Triatomines_Experiment_Record_Place_Update_Column>;
  where?: InputMaybe<Triatomines_Experiment_Record_Place_Bool_Exp>;
};

/** Ordering options when selecting data from "Triatomines_Experiment_Record_Place". */
export type Triatomines_Experiment_Record_Place_Order_By = {
  Place_id?: InputMaybe<Order_By>;
  Triatomines_Experiment_Record_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Triatomines_Experiment_Record_Place */
export type Triatomines_Experiment_Record_Place_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "Triatomines_Experiment_Record_Place" */
export enum Triatomines_Experiment_Record_Place_Select_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  TriatominesExperimentRecordId = "Triatomines_Experiment_Record_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "Triatomines_Experiment_Record_Place" */
export type Triatomines_Experiment_Record_Place_Set_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Triatomines_Experiment_Record_Place_Stddev_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Triatomines_Experiment_Record_Place_Stddev_Pop_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Triatomines_Experiment_Record_Place_Stddev_Samp_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Triatomines_Experiment_Record_Place" */
export type Triatomines_Experiment_Record_Place_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Triatomines_Experiment_Record_Place_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Triatomines_Experiment_Record_Place_Stream_Cursor_Value_Input = {
  Place_id?: InputMaybe<Scalars["uuid"]>;
  Triatomines_Experiment_Record_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Triatomines_Experiment_Record_Place_Sum_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Triatomines_Experiment_Record_Place" */
export enum Triatomines_Experiment_Record_Place_Update_Column {
  /** column name */
  PlaceId = "Place_id",
  /** column name */
  TriatominesExperimentRecordId = "Triatomines_Experiment_Record_id",
  /** column name */
  Id = "id",
}

export type Triatomines_Experiment_Record_Place_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Triatomines_Experiment_Record_Place_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Triatomines_Experiment_Record_Place_Set_Input>;
  /** filter the rows which have to be updated */
  where: Triatomines_Experiment_Record_Place_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Triatomines_Experiment_Record_Place_Var_Pop_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Triatomines_Experiment_Record_Place_Var_Samp_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Triatomines_Experiment_Record_Place_Variance_Fields = {
  __typename?: "Triatomines_Experiment_Record_Place_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregated selection of "Triatomines_Experiment_Record" */
export type Triatomines_Experiment_Record_Aggregate = {
  __typename?: "Triatomines_Experiment_Record_aggregate";
  aggregate?: Maybe<Triatomines_Experiment_Record_Aggregate_Fields>;
  nodes: Array<Triatomines_Experiment_Record>;
};

/** aggregate fields of "Triatomines_Experiment_Record" */
export type Triatomines_Experiment_Record_Aggregate_Fields = {
  __typename?: "Triatomines_Experiment_Record_aggregate_fields";
  avg?: Maybe<Triatomines_Experiment_Record_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Triatomines_Experiment_Record_Max_Fields>;
  min?: Maybe<Triatomines_Experiment_Record_Min_Fields>;
  stddev?: Maybe<Triatomines_Experiment_Record_Stddev_Fields>;
  stddev_pop?: Maybe<Triatomines_Experiment_Record_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Triatomines_Experiment_Record_Stddev_Samp_Fields>;
  sum?: Maybe<Triatomines_Experiment_Record_Sum_Fields>;
  var_pop?: Maybe<Triatomines_Experiment_Record_Var_Pop_Fields>;
  var_samp?: Maybe<Triatomines_Experiment_Record_Var_Samp_Fields>;
  variance?: Maybe<Triatomines_Experiment_Record_Variance_Fields>;
};

/** aggregate fields of "Triatomines_Experiment_Record" */
export type Triatomines_Experiment_Record_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Triatomines_Experiment_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Triatomines_Experiment_Record_Avg_Fields = {
  __typename?: "Triatomines_Experiment_Record_avg_fields";
  externalCode?: Maybe<Scalars["Float"]>;
  phase?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  surveillanceCode?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Triatomines_Experiment_Record". All fields are combined with a logical 'AND'. */
export type Triatomines_Experiment_Record_Bool_Exp = {
  _and?: InputMaybe<Array<Triatomines_Experiment_Record_Bool_Exp>>;
  _not?: InputMaybe<Triatomines_Experiment_Record_Bool_Exp>;
  _or?: InputMaybe<Array<Triatomines_Experiment_Record_Bool_Exp>>;
  captureDate?: InputMaybe<Timestamp_Comparison_Exp>;
  capturePlace?: InputMaybe<Uuid_Comparison_Exp>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliveredBy?: InputMaybe<String_Comparison_Exp>;
  entryDate?: InputMaybe<Timestamp_Comparison_Exp>;
  externalCode?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insecticideApplication?: InputMaybe<Boolean_Comparison_Exp>;
  numberTriatominesCaptured?: InputMaybe<Uuid_Comparison_Exp>;
  numberTriatominesInspected?: InputMaybe<Uuid_Comparison_Exp>;
  numberTriatominesPositive?: InputMaybe<Uuid_Comparison_Exp>;
  observation?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<String_Comparison_Exp>;
  phase?: InputMaybe<Int_Comparison_Exp>;
  place?: InputMaybe<Uuid_Comparison_Exp>;
  processedBy?: InputMaybe<String_Comparison_Exp>;
  readDate?: InputMaybe<Timestamp_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  surveillanceCode?: InputMaybe<Int_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  user_updated?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Triatomines_Experiment_Record" */
export enum Triatomines_Experiment_Record_Constraint {
  /** unique or primary key constraint on columns "id" */
  TriatominesExperimentRecordPkey = "Triatomines_Experiment_Record_pkey",
  /** unique or primary key constraint on columns "externalCode" */
  TriatominesExperimentRecordExternalcodeUnique = "triatomines_experiment_record_externalcode_unique",
}

/** columns and relationships of "Triatomines_Experiment_Record_directus_users" */
export type Triatomines_Experiment_Record_Directus_Users = {
  __typename?: "Triatomines_Experiment_Record_directus_users";
  Triatomines_Experiment_Record_id?: Maybe<Scalars["uuid"]>;
  directus_users_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["Int"];
};

/** aggregated selection of "Triatomines_Experiment_Record_directus_users" */
export type Triatomines_Experiment_Record_Directus_Users_Aggregate = {
  __typename?: "Triatomines_Experiment_Record_directus_users_aggregate";
  aggregate?: Maybe<Triatomines_Experiment_Record_Directus_Users_Aggregate_Fields>;
  nodes: Array<Triatomines_Experiment_Record_Directus_Users>;
};

/** aggregate fields of "Triatomines_Experiment_Record_directus_users" */
export type Triatomines_Experiment_Record_Directus_Users_Aggregate_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_aggregate_fields";
  avg?: Maybe<Triatomines_Experiment_Record_Directus_Users_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Triatomines_Experiment_Record_Directus_Users_Max_Fields>;
  min?: Maybe<Triatomines_Experiment_Record_Directus_Users_Min_Fields>;
  stddev?: Maybe<Triatomines_Experiment_Record_Directus_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Triatomines_Experiment_Record_Directus_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Triatomines_Experiment_Record_Directus_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Triatomines_Experiment_Record_Directus_Users_Sum_Fields>;
  var_pop?: Maybe<Triatomines_Experiment_Record_Directus_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Triatomines_Experiment_Record_Directus_Users_Var_Samp_Fields>;
  variance?: Maybe<Triatomines_Experiment_Record_Directus_Users_Variance_Fields>;
};

/** aggregate fields of "Triatomines_Experiment_Record_directus_users" */
export type Triatomines_Experiment_Record_Directus_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Triatomines_Experiment_Record_Directus_Users_Avg_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Triatomines_Experiment_Record_directus_users". All fields are combined with a logical 'AND'. */
export type Triatomines_Experiment_Record_Directus_Users_Bool_Exp = {
  Triatomines_Experiment_Record_id?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Bool_Exp>>;
  _not?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Bool_Exp>>;
  directus_users_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Triatomines_Experiment_Record_directus_users" */
export enum Triatomines_Experiment_Record_Directus_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  TriatominesExperimentRecordDirectusUsersPkey = "Triatomines_Experiment_Record_directus_users_pkey",
}

/** input type for incrementing numeric columns in table "Triatomines_Experiment_Record_directus_users" */
export type Triatomines_Experiment_Record_Directus_Users_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Triatomines_Experiment_Record_directus_users" */
export type Triatomines_Experiment_Record_Directus_Users_Insert_Input = {
  Triatomines_Experiment_Record_id?: InputMaybe<Scalars["uuid"]>;
  directus_users_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Triatomines_Experiment_Record_Directus_Users_Max_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_max_fields";
  Triatomines_Experiment_Record_id?: Maybe<Scalars["uuid"]>;
  directus_users_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Triatomines_Experiment_Record_Directus_Users_Min_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_min_fields";
  Triatomines_Experiment_Record_id?: Maybe<Scalars["uuid"]>;
  directus_users_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "Triatomines_Experiment_Record_directus_users" */
export type Triatomines_Experiment_Record_Directus_Users_Mutation_Response = {
  __typename?: "Triatomines_Experiment_Record_directus_users_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Triatomines_Experiment_Record_Directus_Users>;
};

/** on_conflict condition type for table "Triatomines_Experiment_Record_directus_users" */
export type Triatomines_Experiment_Record_Directus_Users_On_Conflict = {
  constraint: Triatomines_Experiment_Record_Directus_Users_Constraint;
  update_columns?: Array<Triatomines_Experiment_Record_Directus_Users_Update_Column>;
  where?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "Triatomines_Experiment_Record_directus_users". */
export type Triatomines_Experiment_Record_Directus_Users_Order_By = {
  Triatomines_Experiment_Record_id?: InputMaybe<Order_By>;
  directus_users_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Triatomines_Experiment_Record_directus_users */
export type Triatomines_Experiment_Record_Directus_Users_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "Triatomines_Experiment_Record_directus_users" */
export enum Triatomines_Experiment_Record_Directus_Users_Select_Column {
  /** column name */
  TriatominesExperimentRecordId = "Triatomines_Experiment_Record_id",
  /** column name */
  DirectusUsersId = "directus_users_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "Triatomines_Experiment_Record_directus_users" */
export type Triatomines_Experiment_Record_Directus_Users_Set_Input = {
  Triatomines_Experiment_Record_id?: InputMaybe<Scalars["uuid"]>;
  directus_users_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Triatomines_Experiment_Record_Directus_Users_Stddev_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Triatomines_Experiment_Record_Directus_Users_Stddev_Pop_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Triatomines_Experiment_Record_Directus_Users_Stddev_Samp_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Triatomines_Experiment_Record_directus_users" */
export type Triatomines_Experiment_Record_Directus_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Triatomines_Experiment_Record_Directus_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Triatomines_Experiment_Record_Directus_Users_Stream_Cursor_Value_Input = {
  Triatomines_Experiment_Record_id?: InputMaybe<Scalars["uuid"]>;
  directus_users_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Triatomines_Experiment_Record_Directus_Users_Sum_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Triatomines_Experiment_Record_directus_users" */
export enum Triatomines_Experiment_Record_Directus_Users_Update_Column {
  /** column name */
  TriatominesExperimentRecordId = "Triatomines_Experiment_Record_id",
  /** column name */
  DirectusUsersId = "directus_users_id",
  /** column name */
  Id = "id",
}

export type Triatomines_Experiment_Record_Directus_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Triatomines_Experiment_Record_Directus_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Triatomines_Experiment_Record_Directus_Users_Var_Pop_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Triatomines_Experiment_Record_Directus_Users_Var_Samp_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Triatomines_Experiment_Record_Directus_Users_Variance_Fields = {
  __typename?: "Triatomines_Experiment_Record_directus_users_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** input type for incrementing numeric columns in table "Triatomines_Experiment_Record" */
export type Triatomines_Experiment_Record_Inc_Input = {
  externalCode?: InputMaybe<Scalars["Int"]>;
  phase?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  surveillanceCode?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Triatomines_Experiment_Record" */
export type Triatomines_Experiment_Record_Insert_Input = {
  captureDate?: InputMaybe<Scalars["timestamp"]>;
  capturePlace?: InputMaybe<Scalars["uuid"]>;
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  deliveredBy?: InputMaybe<Scalars["String"]>;
  entryDate?: InputMaybe<Scalars["timestamp"]>;
  externalCode?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecticideApplication?: InputMaybe<Scalars["Boolean"]>;
  numberTriatominesCaptured?: InputMaybe<Scalars["uuid"]>;
  numberTriatominesInspected?: InputMaybe<Scalars["uuid"]>;
  numberTriatominesPositive?: InputMaybe<Scalars["uuid"]>;
  observation?: InputMaybe<Scalars["String"]>;
  origin?: InputMaybe<Scalars["String"]>;
  phase?: InputMaybe<Scalars["Int"]>;
  place?: InputMaybe<Scalars["uuid"]>;
  processedBy?: InputMaybe<Scalars["String"]>;
  readDate?: InputMaybe<Scalars["timestamp"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  surveillanceCode?: InputMaybe<Scalars["Int"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Triatomines_Experiment_Record_Max_Fields = {
  __typename?: "Triatomines_Experiment_Record_max_fields";
  captureDate?: Maybe<Scalars["timestamp"]>;
  capturePlace?: Maybe<Scalars["uuid"]>;
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  deliveredBy?: Maybe<Scalars["String"]>;
  entryDate?: Maybe<Scalars["timestamp"]>;
  externalCode?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  numberTriatominesCaptured?: Maybe<Scalars["uuid"]>;
  numberTriatominesInspected?: Maybe<Scalars["uuid"]>;
  numberTriatominesPositive?: Maybe<Scalars["uuid"]>;
  observation?: Maybe<Scalars["String"]>;
  origin?: Maybe<Scalars["String"]>;
  phase?: Maybe<Scalars["Int"]>;
  place?: Maybe<Scalars["uuid"]>;
  processedBy?: Maybe<Scalars["String"]>;
  readDate?: Maybe<Scalars["timestamp"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  surveillanceCode?: Maybe<Scalars["Int"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Triatomines_Experiment_Record_Min_Fields = {
  __typename?: "Triatomines_Experiment_Record_min_fields";
  captureDate?: Maybe<Scalars["timestamp"]>;
  capturePlace?: Maybe<Scalars["uuid"]>;
  date_created?: Maybe<Scalars["timestamptz"]>;
  date_updated?: Maybe<Scalars["timestamptz"]>;
  deliveredBy?: Maybe<Scalars["String"]>;
  entryDate?: Maybe<Scalars["timestamp"]>;
  externalCode?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  numberTriatominesCaptured?: Maybe<Scalars["uuid"]>;
  numberTriatominesInspected?: Maybe<Scalars["uuid"]>;
  numberTriatominesPositive?: Maybe<Scalars["uuid"]>;
  observation?: Maybe<Scalars["String"]>;
  origin?: Maybe<Scalars["String"]>;
  phase?: Maybe<Scalars["Int"]>;
  place?: Maybe<Scalars["uuid"]>;
  processedBy?: Maybe<Scalars["String"]>;
  readDate?: Maybe<Scalars["timestamp"]>;
  sort?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  surveillanceCode?: Maybe<Scalars["Int"]>;
  user_created?: Maybe<Scalars["uuid"]>;
  user_updated?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "Triatomines_Experiment_Record" */
export type Triatomines_Experiment_Record_Mutation_Response = {
  __typename?: "Triatomines_Experiment_Record_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Triatomines_Experiment_Record>;
};

/** on_conflict condition type for table "Triatomines_Experiment_Record" */
export type Triatomines_Experiment_Record_On_Conflict = {
  constraint: Triatomines_Experiment_Record_Constraint;
  update_columns?: Array<Triatomines_Experiment_Record_Update_Column>;
  where?: InputMaybe<Triatomines_Experiment_Record_Bool_Exp>;
};

/** Ordering options when selecting data from "Triatomines_Experiment_Record". */
export type Triatomines_Experiment_Record_Order_By = {
  captureDate?: InputMaybe<Order_By>;
  capturePlace?: InputMaybe<Order_By>;
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  deliveredBy?: InputMaybe<Order_By>;
  entryDate?: InputMaybe<Order_By>;
  externalCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insecticideApplication?: InputMaybe<Order_By>;
  numberTriatominesCaptured?: InputMaybe<Order_By>;
  numberTriatominesInspected?: InputMaybe<Order_By>;
  numberTriatominesPositive?: InputMaybe<Order_By>;
  observation?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  phase?: InputMaybe<Order_By>;
  place?: InputMaybe<Order_By>;
  processedBy?: InputMaybe<Order_By>;
  readDate?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  surveillanceCode?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Triatomines_Experiment_Record */
export type Triatomines_Experiment_Record_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Triatomines_Experiment_Record" */
export enum Triatomines_Experiment_Record_Select_Column {
  /** column name */
  CaptureDate = "captureDate",
  /** column name */
  CapturePlace = "capturePlace",
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  DeliveredBy = "deliveredBy",
  /** column name */
  EntryDate = "entryDate",
  /** column name */
  ExternalCode = "externalCode",
  /** column name */
  Id = "id",
  /** column name */
  InsecticideApplication = "insecticideApplication",
  /** column name */
  NumberTriatominesCaptured = "numberTriatominesCaptured",
  /** column name */
  NumberTriatominesInspected = "numberTriatominesInspected",
  /** column name */
  NumberTriatominesPositive = "numberTriatominesPositive",
  /** column name */
  Observation = "observation",
  /** column name */
  Origin = "origin",
  /** column name */
  Phase = "phase",
  /** column name */
  Place = "place",
  /** column name */
  ProcessedBy = "processedBy",
  /** column name */
  ReadDate = "readDate",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  SurveillanceCode = "surveillanceCode",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

/** input type for updating data in table "Triatomines_Experiment_Record" */
export type Triatomines_Experiment_Record_Set_Input = {
  captureDate?: InputMaybe<Scalars["timestamp"]>;
  capturePlace?: InputMaybe<Scalars["uuid"]>;
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  deliveredBy?: InputMaybe<Scalars["String"]>;
  entryDate?: InputMaybe<Scalars["timestamp"]>;
  externalCode?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecticideApplication?: InputMaybe<Scalars["Boolean"]>;
  numberTriatominesCaptured?: InputMaybe<Scalars["uuid"]>;
  numberTriatominesInspected?: InputMaybe<Scalars["uuid"]>;
  numberTriatominesPositive?: InputMaybe<Scalars["uuid"]>;
  observation?: InputMaybe<Scalars["String"]>;
  origin?: InputMaybe<Scalars["String"]>;
  phase?: InputMaybe<Scalars["Int"]>;
  place?: InputMaybe<Scalars["uuid"]>;
  processedBy?: InputMaybe<Scalars["String"]>;
  readDate?: InputMaybe<Scalars["timestamp"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  surveillanceCode?: InputMaybe<Scalars["Int"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Triatomines_Experiment_Record_Stddev_Fields = {
  __typename?: "Triatomines_Experiment_Record_stddev_fields";
  externalCode?: Maybe<Scalars["Float"]>;
  phase?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  surveillanceCode?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Triatomines_Experiment_Record_Stddev_Pop_Fields = {
  __typename?: "Triatomines_Experiment_Record_stddev_pop_fields";
  externalCode?: Maybe<Scalars["Float"]>;
  phase?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  surveillanceCode?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Triatomines_Experiment_Record_Stddev_Samp_Fields = {
  __typename?: "Triatomines_Experiment_Record_stddev_samp_fields";
  externalCode?: Maybe<Scalars["Float"]>;
  phase?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  surveillanceCode?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Triatomines_Experiment_Record" */
export type Triatomines_Experiment_Record_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Triatomines_Experiment_Record_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Triatomines_Experiment_Record_Stream_Cursor_Value_Input = {
  captureDate?: InputMaybe<Scalars["timestamp"]>;
  capturePlace?: InputMaybe<Scalars["uuid"]>;
  date_created?: InputMaybe<Scalars["timestamptz"]>;
  date_updated?: InputMaybe<Scalars["timestamptz"]>;
  deliveredBy?: InputMaybe<Scalars["String"]>;
  entryDate?: InputMaybe<Scalars["timestamp"]>;
  externalCode?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecticideApplication?: InputMaybe<Scalars["Boolean"]>;
  numberTriatominesCaptured?: InputMaybe<Scalars["uuid"]>;
  numberTriatominesInspected?: InputMaybe<Scalars["uuid"]>;
  numberTriatominesPositive?: InputMaybe<Scalars["uuid"]>;
  observation?: InputMaybe<Scalars["String"]>;
  origin?: InputMaybe<Scalars["String"]>;
  phase?: InputMaybe<Scalars["Int"]>;
  place?: InputMaybe<Scalars["uuid"]>;
  processedBy?: InputMaybe<Scalars["String"]>;
  readDate?: InputMaybe<Scalars["timestamp"]>;
  sort?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  surveillanceCode?: InputMaybe<Scalars["Int"]>;
  user_created?: InputMaybe<Scalars["uuid"]>;
  user_updated?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Triatomines_Experiment_Record_Sum_Fields = {
  __typename?: "Triatomines_Experiment_Record_sum_fields";
  externalCode?: Maybe<Scalars["Int"]>;
  phase?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
  surveillanceCode?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Triatomines_Experiment_Record" */
export enum Triatomines_Experiment_Record_Update_Column {
  /** column name */
  CaptureDate = "captureDate",
  /** column name */
  CapturePlace = "capturePlace",
  /** column name */
  DateCreated = "date_created",
  /** column name */
  DateUpdated = "date_updated",
  /** column name */
  DeliveredBy = "deliveredBy",
  /** column name */
  EntryDate = "entryDate",
  /** column name */
  ExternalCode = "externalCode",
  /** column name */
  Id = "id",
  /** column name */
  InsecticideApplication = "insecticideApplication",
  /** column name */
  NumberTriatominesCaptured = "numberTriatominesCaptured",
  /** column name */
  NumberTriatominesInspected = "numberTriatominesInspected",
  /** column name */
  NumberTriatominesPositive = "numberTriatominesPositive",
  /** column name */
  Observation = "observation",
  /** column name */
  Origin = "origin",
  /** column name */
  Phase = "phase",
  /** column name */
  Place = "place",
  /** column name */
  ProcessedBy = "processedBy",
  /** column name */
  ReadDate = "readDate",
  /** column name */
  Sort = "sort",
  /** column name */
  Status = "status",
  /** column name */
  SurveillanceCode = "surveillanceCode",
  /** column name */
  UserCreated = "user_created",
  /** column name */
  UserUpdated = "user_updated",
}

export type Triatomines_Experiment_Record_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Triatomines_Experiment_Record_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Triatomines_Experiment_Record_Set_Input>;
  /** filter the rows which have to be updated */
  where: Triatomines_Experiment_Record_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Triatomines_Experiment_Record_Var_Pop_Fields = {
  __typename?: "Triatomines_Experiment_Record_var_pop_fields";
  externalCode?: Maybe<Scalars["Float"]>;
  phase?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  surveillanceCode?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Triatomines_Experiment_Record_Var_Samp_Fields = {
  __typename?: "Triatomines_Experiment_Record_var_samp_fields";
  externalCode?: Maybe<Scalars["Float"]>;
  phase?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  surveillanceCode?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Triatomines_Experiment_Record_Variance_Fields = {
  __typename?: "Triatomines_Experiment_Record_variance_fields";
  externalCode?: Maybe<Scalars["Float"]>;
  phase?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  surveillanceCode?: Maybe<Scalars["Float"]>;
};

/** aggregated selection of "Triatomines" */
export type Triatomines_Aggregate = {
  __typename?: "Triatomines_aggregate";
  aggregate?: Maybe<Triatomines_Aggregate_Fields>;
  nodes: Array<Triatomines>;
};

/** aggregate fields of "Triatomines" */
export type Triatomines_Aggregate_Fields = {
  __typename?: "Triatomines_aggregate_fields";
  avg?: Maybe<Triatomines_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Triatomines_Max_Fields>;
  min?: Maybe<Triatomines_Min_Fields>;
  stddev?: Maybe<Triatomines_Stddev_Fields>;
  stddev_pop?: Maybe<Triatomines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Triatomines_Stddev_Samp_Fields>;
  sum?: Maybe<Triatomines_Sum_Fields>;
  var_pop?: Maybe<Triatomines_Var_Pop_Fields>;
  var_samp?: Maybe<Triatomines_Var_Samp_Fields>;
  variance?: Maybe<Triatomines_Variance_Fields>;
};

/** aggregate fields of "Triatomines" */
export type Triatomines_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Triatomines_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Triatomines_Avg_Fields = {
  __typename?: "Triatomines_avg_fields";
  cap_eta1?: Maybe<Scalars["Float"]>;
  cap_eta2?: Maybe<Scalars["Float"]>;
  cap_eta3?: Maybe<Scalars["Float"]>;
  cap_eta4?: Maybe<Scalars["Float"]>;
  cap_eta5?: Maybe<Scalars["Float"]>;
  cap_hem?: Maybe<Scalars["Float"]>;
  cap_mac?: Maybe<Scalars["Float"]>;
  cap_total?: Maybe<Scalars["Float"]>;
  distrito?: Maybe<Scalars["Float"]>;
  exa_eta2?: Maybe<Scalars["Float"]>;
  exa_eta3?: Maybe<Scalars["Float"]>;
  exa_eta4?: Maybe<Scalars["Float"]>;
  exa_eta5?: Maybe<Scalars["Float"]>;
  exa_hem?: Maybe<Scalars["Float"]>;
  exa_mac?: Maybe<Scalars["Float"]>;
  exa_total?: Maybe<Scalars["Float"]>;
  fase?: Maybe<Scalars["Float"]>;
  numero?: Maybe<Scalars["Float"]>;
  pos_eta2?: Maybe<Scalars["Float"]>;
  pos_eta3?: Maybe<Scalars["Float"]>;
  pos_eta4?: Maybe<Scalars["Float"]>;
  pos_eta5?: Maybe<Scalars["Float"]>;
  pos_hem?: Maybe<Scalars["Float"]>;
  pos_mac?: Maybe<Scalars["Float"]>;
  pos_total?: Maybe<Scalars["Float"]>;
  provincia?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Triatomines". All fields are combined with a logical 'AND'. */
export type Triatomines_Bool_Exp = {
  _and?: InputMaybe<Array<Triatomines_Bool_Exp>>;
  _not?: InputMaybe<Triatomines_Bool_Exp>;
  _or?: InputMaybe<Array<Triatomines_Bool_Exp>>;
  apl_ins?: InputMaybe<String_Comparison_Exp>;
  cap_eta1?: InputMaybe<Int_Comparison_Exp>;
  cap_eta2?: InputMaybe<Int_Comparison_Exp>;
  cap_eta3?: InputMaybe<Int_Comparison_Exp>;
  cap_eta4?: InputMaybe<Int_Comparison_Exp>;
  cap_eta5?: InputMaybe<Int_Comparison_Exp>;
  cap_hem?: InputMaybe<Int_Comparison_Exp>;
  cap_mac?: InputMaybe<Int_Comparison_Exp>;
  cap_total?: InputMaybe<Int_Comparison_Exp>;
  codigo?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digita?: InputMaybe<String_Comparison_Exp>;
  distrito?: InputMaybe<Int_Comparison_Exp>;
  exa_eta2?: InputMaybe<Int_Comparison_Exp>;
  exa_eta3?: InputMaybe<Int_Comparison_Exp>;
  exa_eta4?: InputMaybe<Int_Comparison_Exp>;
  exa_eta5?: InputMaybe<Int_Comparison_Exp>;
  exa_hem?: InputMaybe<Int_Comparison_Exp>;
  exa_mac?: InputMaybe<Int_Comparison_Exp>;
  exa_total?: InputMaybe<Int_Comparison_Exp>;
  fase?: InputMaybe<Int_Comparison_Exp>;
  fec_captura?: InputMaybe<Timestamptz_Comparison_Exp>;
  fec_ingreso?: InputMaybe<Timestamptz_Comparison_Exp>;
  fec_lectura?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  intra_peri?: InputMaybe<String_Comparison_Exp>;
  intra_peri_code?: InputMaybe<String_Comparison_Exp>;
  localidad?: InputMaybe<String_Comparison_Exp>;
  numero?: InputMaybe<Int_Comparison_Exp>;
  pos_eta2?: InputMaybe<Int_Comparison_Exp>;
  pos_eta3?: InputMaybe<Int_Comparison_Exp>;
  pos_eta4?: InputMaybe<Int_Comparison_Exp>;
  pos_eta5?: InputMaybe<Int_Comparison_Exp>;
  pos_hem?: InputMaybe<Int_Comparison_Exp>;
  pos_mac?: InputMaybe<Int_Comparison_Exp>;
  pos_total?: InputMaybe<Int_Comparison_Exp>;
  procedencia?: InputMaybe<String_Comparison_Exp>;
  procesado?: InputMaybe<String_Comparison_Exp>;
  provincia?: InputMaybe<Int_Comparison_Exp>;
  unicode?: InputMaybe<String_Comparison_Exp>;
  vivienda?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Triatomines" */
export enum Triatomines_Constraint {
  /** unique or primary key constraint on columns "id" */
  TriatominesPkey = "Triatomines_pkey",
}

/** input type for incrementing numeric columns in table "Triatomines" */
export type Triatomines_Inc_Input = {
  cap_eta1?: InputMaybe<Scalars["Int"]>;
  cap_eta2?: InputMaybe<Scalars["Int"]>;
  cap_eta3?: InputMaybe<Scalars["Int"]>;
  cap_eta4?: InputMaybe<Scalars["Int"]>;
  cap_eta5?: InputMaybe<Scalars["Int"]>;
  cap_hem?: InputMaybe<Scalars["Int"]>;
  cap_mac?: InputMaybe<Scalars["Int"]>;
  cap_total?: InputMaybe<Scalars["Int"]>;
  distrito?: InputMaybe<Scalars["Int"]>;
  exa_eta2?: InputMaybe<Scalars["Int"]>;
  exa_eta3?: InputMaybe<Scalars["Int"]>;
  exa_eta4?: InputMaybe<Scalars["Int"]>;
  exa_eta5?: InputMaybe<Scalars["Int"]>;
  exa_hem?: InputMaybe<Scalars["Int"]>;
  exa_mac?: InputMaybe<Scalars["Int"]>;
  exa_total?: InputMaybe<Scalars["Int"]>;
  fase?: InputMaybe<Scalars["Int"]>;
  numero?: InputMaybe<Scalars["Int"]>;
  pos_eta2?: InputMaybe<Scalars["Int"]>;
  pos_eta3?: InputMaybe<Scalars["Int"]>;
  pos_eta4?: InputMaybe<Scalars["Int"]>;
  pos_eta5?: InputMaybe<Scalars["Int"]>;
  pos_hem?: InputMaybe<Scalars["Int"]>;
  pos_mac?: InputMaybe<Scalars["Int"]>;
  pos_total?: InputMaybe<Scalars["Int"]>;
  provincia?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Triatomines" */
export type Triatomines_Insert_Input = {
  apl_ins?: InputMaybe<Scalars["String"]>;
  cap_eta1?: InputMaybe<Scalars["Int"]>;
  cap_eta2?: InputMaybe<Scalars["Int"]>;
  cap_eta3?: InputMaybe<Scalars["Int"]>;
  cap_eta4?: InputMaybe<Scalars["Int"]>;
  cap_eta5?: InputMaybe<Scalars["Int"]>;
  cap_hem?: InputMaybe<Scalars["Int"]>;
  cap_mac?: InputMaybe<Scalars["Int"]>;
  cap_total?: InputMaybe<Scalars["Int"]>;
  codigo?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  digita?: InputMaybe<Scalars["String"]>;
  distrito?: InputMaybe<Scalars["Int"]>;
  exa_eta2?: InputMaybe<Scalars["Int"]>;
  exa_eta3?: InputMaybe<Scalars["Int"]>;
  exa_eta4?: InputMaybe<Scalars["Int"]>;
  exa_eta5?: InputMaybe<Scalars["Int"]>;
  exa_hem?: InputMaybe<Scalars["Int"]>;
  exa_mac?: InputMaybe<Scalars["Int"]>;
  exa_total?: InputMaybe<Scalars["Int"]>;
  fase?: InputMaybe<Scalars["Int"]>;
  fec_captura?: InputMaybe<Scalars["timestamptz"]>;
  fec_ingreso?: InputMaybe<Scalars["timestamptz"]>;
  fec_lectura?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  intra_peri?: InputMaybe<Scalars["String"]>;
  intra_peri_code?: InputMaybe<Scalars["String"]>;
  localidad?: InputMaybe<Scalars["String"]>;
  numero?: InputMaybe<Scalars["Int"]>;
  pos_eta2?: InputMaybe<Scalars["Int"]>;
  pos_eta3?: InputMaybe<Scalars["Int"]>;
  pos_eta4?: InputMaybe<Scalars["Int"]>;
  pos_eta5?: InputMaybe<Scalars["Int"]>;
  pos_hem?: InputMaybe<Scalars["Int"]>;
  pos_mac?: InputMaybe<Scalars["Int"]>;
  pos_total?: InputMaybe<Scalars["Int"]>;
  procedencia?: InputMaybe<Scalars["String"]>;
  procesado?: InputMaybe<Scalars["String"]>;
  provincia?: InputMaybe<Scalars["Int"]>;
  unicode?: InputMaybe<Scalars["String"]>;
  vivienda?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Triatomines_Max_Fields = {
  __typename?: "Triatomines_max_fields";
  apl_ins?: Maybe<Scalars["String"]>;
  cap_eta1?: Maybe<Scalars["Int"]>;
  cap_eta2?: Maybe<Scalars["Int"]>;
  cap_eta3?: Maybe<Scalars["Int"]>;
  cap_eta4?: Maybe<Scalars["Int"]>;
  cap_eta5?: Maybe<Scalars["Int"]>;
  cap_hem?: Maybe<Scalars["Int"]>;
  cap_mac?: Maybe<Scalars["Int"]>;
  cap_total?: Maybe<Scalars["Int"]>;
  codigo?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  digita?: Maybe<Scalars["String"]>;
  distrito?: Maybe<Scalars["Int"]>;
  exa_eta2?: Maybe<Scalars["Int"]>;
  exa_eta3?: Maybe<Scalars["Int"]>;
  exa_eta4?: Maybe<Scalars["Int"]>;
  exa_eta5?: Maybe<Scalars["Int"]>;
  exa_hem?: Maybe<Scalars["Int"]>;
  exa_mac?: Maybe<Scalars["Int"]>;
  exa_total?: Maybe<Scalars["Int"]>;
  fase?: Maybe<Scalars["Int"]>;
  fec_captura?: Maybe<Scalars["timestamptz"]>;
  fec_ingreso?: Maybe<Scalars["timestamptz"]>;
  fec_lectura?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  intra_peri?: Maybe<Scalars["String"]>;
  intra_peri_code?: Maybe<Scalars["String"]>;
  localidad?: Maybe<Scalars["String"]>;
  numero?: Maybe<Scalars["Int"]>;
  pos_eta2?: Maybe<Scalars["Int"]>;
  pos_eta3?: Maybe<Scalars["Int"]>;
  pos_eta4?: Maybe<Scalars["Int"]>;
  pos_eta5?: Maybe<Scalars["Int"]>;
  pos_hem?: Maybe<Scalars["Int"]>;
  pos_mac?: Maybe<Scalars["Int"]>;
  pos_total?: Maybe<Scalars["Int"]>;
  procedencia?: Maybe<Scalars["String"]>;
  procesado?: Maybe<Scalars["String"]>;
  provincia?: Maybe<Scalars["Int"]>;
  unicode?: Maybe<Scalars["String"]>;
  vivienda?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Triatomines_Min_Fields = {
  __typename?: "Triatomines_min_fields";
  apl_ins?: Maybe<Scalars["String"]>;
  cap_eta1?: Maybe<Scalars["Int"]>;
  cap_eta2?: Maybe<Scalars["Int"]>;
  cap_eta3?: Maybe<Scalars["Int"]>;
  cap_eta4?: Maybe<Scalars["Int"]>;
  cap_eta5?: Maybe<Scalars["Int"]>;
  cap_hem?: Maybe<Scalars["Int"]>;
  cap_mac?: Maybe<Scalars["Int"]>;
  cap_total?: Maybe<Scalars["Int"]>;
  codigo?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  digita?: Maybe<Scalars["String"]>;
  distrito?: Maybe<Scalars["Int"]>;
  exa_eta2?: Maybe<Scalars["Int"]>;
  exa_eta3?: Maybe<Scalars["Int"]>;
  exa_eta4?: Maybe<Scalars["Int"]>;
  exa_eta5?: Maybe<Scalars["Int"]>;
  exa_hem?: Maybe<Scalars["Int"]>;
  exa_mac?: Maybe<Scalars["Int"]>;
  exa_total?: Maybe<Scalars["Int"]>;
  fase?: Maybe<Scalars["Int"]>;
  fec_captura?: Maybe<Scalars["timestamptz"]>;
  fec_ingreso?: Maybe<Scalars["timestamptz"]>;
  fec_lectura?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  intra_peri?: Maybe<Scalars["String"]>;
  intra_peri_code?: Maybe<Scalars["String"]>;
  localidad?: Maybe<Scalars["String"]>;
  numero?: Maybe<Scalars["Int"]>;
  pos_eta2?: Maybe<Scalars["Int"]>;
  pos_eta3?: Maybe<Scalars["Int"]>;
  pos_eta4?: Maybe<Scalars["Int"]>;
  pos_eta5?: Maybe<Scalars["Int"]>;
  pos_hem?: Maybe<Scalars["Int"]>;
  pos_mac?: Maybe<Scalars["Int"]>;
  pos_total?: Maybe<Scalars["Int"]>;
  procedencia?: Maybe<Scalars["String"]>;
  procesado?: Maybe<Scalars["String"]>;
  provincia?: Maybe<Scalars["Int"]>;
  unicode?: Maybe<Scalars["String"]>;
  vivienda?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Triatomines" */
export type Triatomines_Mutation_Response = {
  __typename?: "Triatomines_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Triatomines>;
};

/** on_conflict condition type for table "Triatomines" */
export type Triatomines_On_Conflict = {
  constraint: Triatomines_Constraint;
  update_columns?: Array<Triatomines_Update_Column>;
  where?: InputMaybe<Triatomines_Bool_Exp>;
};

/** Ordering options when selecting data from "Triatomines". */
export type Triatomines_Order_By = {
  apl_ins?: InputMaybe<Order_By>;
  cap_eta1?: InputMaybe<Order_By>;
  cap_eta2?: InputMaybe<Order_By>;
  cap_eta3?: InputMaybe<Order_By>;
  cap_eta4?: InputMaybe<Order_By>;
  cap_eta5?: InputMaybe<Order_By>;
  cap_hem?: InputMaybe<Order_By>;
  cap_mac?: InputMaybe<Order_By>;
  cap_total?: InputMaybe<Order_By>;
  codigo?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  digita?: InputMaybe<Order_By>;
  distrito?: InputMaybe<Order_By>;
  exa_eta2?: InputMaybe<Order_By>;
  exa_eta3?: InputMaybe<Order_By>;
  exa_eta4?: InputMaybe<Order_By>;
  exa_eta5?: InputMaybe<Order_By>;
  exa_hem?: InputMaybe<Order_By>;
  exa_mac?: InputMaybe<Order_By>;
  exa_total?: InputMaybe<Order_By>;
  fase?: InputMaybe<Order_By>;
  fec_captura?: InputMaybe<Order_By>;
  fec_ingreso?: InputMaybe<Order_By>;
  fec_lectura?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intra_peri?: InputMaybe<Order_By>;
  intra_peri_code?: InputMaybe<Order_By>;
  localidad?: InputMaybe<Order_By>;
  numero?: InputMaybe<Order_By>;
  pos_eta2?: InputMaybe<Order_By>;
  pos_eta3?: InputMaybe<Order_By>;
  pos_eta4?: InputMaybe<Order_By>;
  pos_eta5?: InputMaybe<Order_By>;
  pos_hem?: InputMaybe<Order_By>;
  pos_mac?: InputMaybe<Order_By>;
  pos_total?: InputMaybe<Order_By>;
  procedencia?: InputMaybe<Order_By>;
  procesado?: InputMaybe<Order_By>;
  provincia?: InputMaybe<Order_By>;
  unicode?: InputMaybe<Order_By>;
  vivienda?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Triatomines */
export type Triatomines_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Triatomines" */
export enum Triatomines_Select_Column {
  /** column name */
  AplIns = "apl_ins",
  /** column name */
  CapEta1 = "cap_eta1",
  /** column name */
  CapEta2 = "cap_eta2",
  /** column name */
  CapEta3 = "cap_eta3",
  /** column name */
  CapEta4 = "cap_eta4",
  /** column name */
  CapEta5 = "cap_eta5",
  /** column name */
  CapHem = "cap_hem",
  /** column name */
  CapMac = "cap_mac",
  /** column name */
  CapTotal = "cap_total",
  /** column name */
  Codigo = "codigo",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Digita = "digita",
  /** column name */
  Distrito = "distrito",
  /** column name */
  ExaEta2 = "exa_eta2",
  /** column name */
  ExaEta3 = "exa_eta3",
  /** column name */
  ExaEta4 = "exa_eta4",
  /** column name */
  ExaEta5 = "exa_eta5",
  /** column name */
  ExaHem = "exa_hem",
  /** column name */
  ExaMac = "exa_mac",
  /** column name */
  ExaTotal = "exa_total",
  /** column name */
  Fase = "fase",
  /** column name */
  FecCaptura = "fec_captura",
  /** column name */
  FecIngreso = "fec_ingreso",
  /** column name */
  FecLectura = "fec_lectura",
  /** column name */
  Id = "id",
  /** column name */
  IntraPeri = "intra_peri",
  /** column name */
  IntraPeriCode = "intra_peri_code",
  /** column name */
  Localidad = "localidad",
  /** column name */
  Numero = "numero",
  /** column name */
  PosEta2 = "pos_eta2",
  /** column name */
  PosEta3 = "pos_eta3",
  /** column name */
  PosEta4 = "pos_eta4",
  /** column name */
  PosEta5 = "pos_eta5",
  /** column name */
  PosHem = "pos_hem",
  /** column name */
  PosMac = "pos_mac",
  /** column name */
  PosTotal = "pos_total",
  /** column name */
  Procedencia = "procedencia",
  /** column name */
  Procesado = "procesado",
  /** column name */
  Provincia = "provincia",
  /** column name */
  Unicode = "unicode",
  /** column name */
  Vivienda = "vivienda",
}

/** input type for updating data in table "Triatomines" */
export type Triatomines_Set_Input = {
  apl_ins?: InputMaybe<Scalars["String"]>;
  cap_eta1?: InputMaybe<Scalars["Int"]>;
  cap_eta2?: InputMaybe<Scalars["Int"]>;
  cap_eta3?: InputMaybe<Scalars["Int"]>;
  cap_eta4?: InputMaybe<Scalars["Int"]>;
  cap_eta5?: InputMaybe<Scalars["Int"]>;
  cap_hem?: InputMaybe<Scalars["Int"]>;
  cap_mac?: InputMaybe<Scalars["Int"]>;
  cap_total?: InputMaybe<Scalars["Int"]>;
  codigo?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  digita?: InputMaybe<Scalars["String"]>;
  distrito?: InputMaybe<Scalars["Int"]>;
  exa_eta2?: InputMaybe<Scalars["Int"]>;
  exa_eta3?: InputMaybe<Scalars["Int"]>;
  exa_eta4?: InputMaybe<Scalars["Int"]>;
  exa_eta5?: InputMaybe<Scalars["Int"]>;
  exa_hem?: InputMaybe<Scalars["Int"]>;
  exa_mac?: InputMaybe<Scalars["Int"]>;
  exa_total?: InputMaybe<Scalars["Int"]>;
  fase?: InputMaybe<Scalars["Int"]>;
  fec_captura?: InputMaybe<Scalars["timestamptz"]>;
  fec_ingreso?: InputMaybe<Scalars["timestamptz"]>;
  fec_lectura?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  intra_peri?: InputMaybe<Scalars["String"]>;
  intra_peri_code?: InputMaybe<Scalars["String"]>;
  localidad?: InputMaybe<Scalars["String"]>;
  numero?: InputMaybe<Scalars["Int"]>;
  pos_eta2?: InputMaybe<Scalars["Int"]>;
  pos_eta3?: InputMaybe<Scalars["Int"]>;
  pos_eta4?: InputMaybe<Scalars["Int"]>;
  pos_eta5?: InputMaybe<Scalars["Int"]>;
  pos_hem?: InputMaybe<Scalars["Int"]>;
  pos_mac?: InputMaybe<Scalars["Int"]>;
  pos_total?: InputMaybe<Scalars["Int"]>;
  procedencia?: InputMaybe<Scalars["String"]>;
  procesado?: InputMaybe<Scalars["String"]>;
  provincia?: InputMaybe<Scalars["Int"]>;
  unicode?: InputMaybe<Scalars["String"]>;
  vivienda?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Triatomines_Stddev_Fields = {
  __typename?: "Triatomines_stddev_fields";
  cap_eta1?: Maybe<Scalars["Float"]>;
  cap_eta2?: Maybe<Scalars["Float"]>;
  cap_eta3?: Maybe<Scalars["Float"]>;
  cap_eta4?: Maybe<Scalars["Float"]>;
  cap_eta5?: Maybe<Scalars["Float"]>;
  cap_hem?: Maybe<Scalars["Float"]>;
  cap_mac?: Maybe<Scalars["Float"]>;
  cap_total?: Maybe<Scalars["Float"]>;
  distrito?: Maybe<Scalars["Float"]>;
  exa_eta2?: Maybe<Scalars["Float"]>;
  exa_eta3?: Maybe<Scalars["Float"]>;
  exa_eta4?: Maybe<Scalars["Float"]>;
  exa_eta5?: Maybe<Scalars["Float"]>;
  exa_hem?: Maybe<Scalars["Float"]>;
  exa_mac?: Maybe<Scalars["Float"]>;
  exa_total?: Maybe<Scalars["Float"]>;
  fase?: Maybe<Scalars["Float"]>;
  numero?: Maybe<Scalars["Float"]>;
  pos_eta2?: Maybe<Scalars["Float"]>;
  pos_eta3?: Maybe<Scalars["Float"]>;
  pos_eta4?: Maybe<Scalars["Float"]>;
  pos_eta5?: Maybe<Scalars["Float"]>;
  pos_hem?: Maybe<Scalars["Float"]>;
  pos_mac?: Maybe<Scalars["Float"]>;
  pos_total?: Maybe<Scalars["Float"]>;
  provincia?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Triatomines_Stddev_Pop_Fields = {
  __typename?: "Triatomines_stddev_pop_fields";
  cap_eta1?: Maybe<Scalars["Float"]>;
  cap_eta2?: Maybe<Scalars["Float"]>;
  cap_eta3?: Maybe<Scalars["Float"]>;
  cap_eta4?: Maybe<Scalars["Float"]>;
  cap_eta5?: Maybe<Scalars["Float"]>;
  cap_hem?: Maybe<Scalars["Float"]>;
  cap_mac?: Maybe<Scalars["Float"]>;
  cap_total?: Maybe<Scalars["Float"]>;
  distrito?: Maybe<Scalars["Float"]>;
  exa_eta2?: Maybe<Scalars["Float"]>;
  exa_eta3?: Maybe<Scalars["Float"]>;
  exa_eta4?: Maybe<Scalars["Float"]>;
  exa_eta5?: Maybe<Scalars["Float"]>;
  exa_hem?: Maybe<Scalars["Float"]>;
  exa_mac?: Maybe<Scalars["Float"]>;
  exa_total?: Maybe<Scalars["Float"]>;
  fase?: Maybe<Scalars["Float"]>;
  numero?: Maybe<Scalars["Float"]>;
  pos_eta2?: Maybe<Scalars["Float"]>;
  pos_eta3?: Maybe<Scalars["Float"]>;
  pos_eta4?: Maybe<Scalars["Float"]>;
  pos_eta5?: Maybe<Scalars["Float"]>;
  pos_hem?: Maybe<Scalars["Float"]>;
  pos_mac?: Maybe<Scalars["Float"]>;
  pos_total?: Maybe<Scalars["Float"]>;
  provincia?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Triatomines_Stddev_Samp_Fields = {
  __typename?: "Triatomines_stddev_samp_fields";
  cap_eta1?: Maybe<Scalars["Float"]>;
  cap_eta2?: Maybe<Scalars["Float"]>;
  cap_eta3?: Maybe<Scalars["Float"]>;
  cap_eta4?: Maybe<Scalars["Float"]>;
  cap_eta5?: Maybe<Scalars["Float"]>;
  cap_hem?: Maybe<Scalars["Float"]>;
  cap_mac?: Maybe<Scalars["Float"]>;
  cap_total?: Maybe<Scalars["Float"]>;
  distrito?: Maybe<Scalars["Float"]>;
  exa_eta2?: Maybe<Scalars["Float"]>;
  exa_eta3?: Maybe<Scalars["Float"]>;
  exa_eta4?: Maybe<Scalars["Float"]>;
  exa_eta5?: Maybe<Scalars["Float"]>;
  exa_hem?: Maybe<Scalars["Float"]>;
  exa_mac?: Maybe<Scalars["Float"]>;
  exa_total?: Maybe<Scalars["Float"]>;
  fase?: Maybe<Scalars["Float"]>;
  numero?: Maybe<Scalars["Float"]>;
  pos_eta2?: Maybe<Scalars["Float"]>;
  pos_eta3?: Maybe<Scalars["Float"]>;
  pos_eta4?: Maybe<Scalars["Float"]>;
  pos_eta5?: Maybe<Scalars["Float"]>;
  pos_hem?: Maybe<Scalars["Float"]>;
  pos_mac?: Maybe<Scalars["Float"]>;
  pos_total?: Maybe<Scalars["Float"]>;
  provincia?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Triatomines" */
export type Triatomines_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Triatomines_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Triatomines_Stream_Cursor_Value_Input = {
  apl_ins?: InputMaybe<Scalars["String"]>;
  cap_eta1?: InputMaybe<Scalars["Int"]>;
  cap_eta2?: InputMaybe<Scalars["Int"]>;
  cap_eta3?: InputMaybe<Scalars["Int"]>;
  cap_eta4?: InputMaybe<Scalars["Int"]>;
  cap_eta5?: InputMaybe<Scalars["Int"]>;
  cap_hem?: InputMaybe<Scalars["Int"]>;
  cap_mac?: InputMaybe<Scalars["Int"]>;
  cap_total?: InputMaybe<Scalars["Int"]>;
  codigo?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  digita?: InputMaybe<Scalars["String"]>;
  distrito?: InputMaybe<Scalars["Int"]>;
  exa_eta2?: InputMaybe<Scalars["Int"]>;
  exa_eta3?: InputMaybe<Scalars["Int"]>;
  exa_eta4?: InputMaybe<Scalars["Int"]>;
  exa_eta5?: InputMaybe<Scalars["Int"]>;
  exa_hem?: InputMaybe<Scalars["Int"]>;
  exa_mac?: InputMaybe<Scalars["Int"]>;
  exa_total?: InputMaybe<Scalars["Int"]>;
  fase?: InputMaybe<Scalars["Int"]>;
  fec_captura?: InputMaybe<Scalars["timestamptz"]>;
  fec_ingreso?: InputMaybe<Scalars["timestamptz"]>;
  fec_lectura?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  intra_peri?: InputMaybe<Scalars["String"]>;
  intra_peri_code?: InputMaybe<Scalars["String"]>;
  localidad?: InputMaybe<Scalars["String"]>;
  numero?: InputMaybe<Scalars["Int"]>;
  pos_eta2?: InputMaybe<Scalars["Int"]>;
  pos_eta3?: InputMaybe<Scalars["Int"]>;
  pos_eta4?: InputMaybe<Scalars["Int"]>;
  pos_eta5?: InputMaybe<Scalars["Int"]>;
  pos_hem?: InputMaybe<Scalars["Int"]>;
  pos_mac?: InputMaybe<Scalars["Int"]>;
  pos_total?: InputMaybe<Scalars["Int"]>;
  procedencia?: InputMaybe<Scalars["String"]>;
  procesado?: InputMaybe<Scalars["String"]>;
  provincia?: InputMaybe<Scalars["Int"]>;
  unicode?: InputMaybe<Scalars["String"]>;
  vivienda?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Triatomines_Sum_Fields = {
  __typename?: "Triatomines_sum_fields";
  cap_eta1?: Maybe<Scalars["Int"]>;
  cap_eta2?: Maybe<Scalars["Int"]>;
  cap_eta3?: Maybe<Scalars["Int"]>;
  cap_eta4?: Maybe<Scalars["Int"]>;
  cap_eta5?: Maybe<Scalars["Int"]>;
  cap_hem?: Maybe<Scalars["Int"]>;
  cap_mac?: Maybe<Scalars["Int"]>;
  cap_total?: Maybe<Scalars["Int"]>;
  distrito?: Maybe<Scalars["Int"]>;
  exa_eta2?: Maybe<Scalars["Int"]>;
  exa_eta3?: Maybe<Scalars["Int"]>;
  exa_eta4?: Maybe<Scalars["Int"]>;
  exa_eta5?: Maybe<Scalars["Int"]>;
  exa_hem?: Maybe<Scalars["Int"]>;
  exa_mac?: Maybe<Scalars["Int"]>;
  exa_total?: Maybe<Scalars["Int"]>;
  fase?: Maybe<Scalars["Int"]>;
  numero?: Maybe<Scalars["Int"]>;
  pos_eta2?: Maybe<Scalars["Int"]>;
  pos_eta3?: Maybe<Scalars["Int"]>;
  pos_eta4?: Maybe<Scalars["Int"]>;
  pos_eta5?: Maybe<Scalars["Int"]>;
  pos_hem?: Maybe<Scalars["Int"]>;
  pos_mac?: Maybe<Scalars["Int"]>;
  pos_total?: Maybe<Scalars["Int"]>;
  provincia?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Triatomines" */
export enum Triatomines_Update_Column {
  /** column name */
  AplIns = "apl_ins",
  /** column name */
  CapEta1 = "cap_eta1",
  /** column name */
  CapEta2 = "cap_eta2",
  /** column name */
  CapEta3 = "cap_eta3",
  /** column name */
  CapEta4 = "cap_eta4",
  /** column name */
  CapEta5 = "cap_eta5",
  /** column name */
  CapHem = "cap_hem",
  /** column name */
  CapMac = "cap_mac",
  /** column name */
  CapTotal = "cap_total",
  /** column name */
  Codigo = "codigo",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Digita = "digita",
  /** column name */
  Distrito = "distrito",
  /** column name */
  ExaEta2 = "exa_eta2",
  /** column name */
  ExaEta3 = "exa_eta3",
  /** column name */
  ExaEta4 = "exa_eta4",
  /** column name */
  ExaEta5 = "exa_eta5",
  /** column name */
  ExaHem = "exa_hem",
  /** column name */
  ExaMac = "exa_mac",
  /** column name */
  ExaTotal = "exa_total",
  /** column name */
  Fase = "fase",
  /** column name */
  FecCaptura = "fec_captura",
  /** column name */
  FecIngreso = "fec_ingreso",
  /** column name */
  FecLectura = "fec_lectura",
  /** column name */
  Id = "id",
  /** column name */
  IntraPeri = "intra_peri",
  /** column name */
  IntraPeriCode = "intra_peri_code",
  /** column name */
  Localidad = "localidad",
  /** column name */
  Numero = "numero",
  /** column name */
  PosEta2 = "pos_eta2",
  /** column name */
  PosEta3 = "pos_eta3",
  /** column name */
  PosEta4 = "pos_eta4",
  /** column name */
  PosEta5 = "pos_eta5",
  /** column name */
  PosHem = "pos_hem",
  /** column name */
  PosMac = "pos_mac",
  /** column name */
  PosTotal = "pos_total",
  /** column name */
  Procedencia = "procedencia",
  /** column name */
  Procesado = "procesado",
  /** column name */
  Provincia = "provincia",
  /** column name */
  Unicode = "unicode",
  /** column name */
  Vivienda = "vivienda",
}

export type Triatomines_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Triatomines_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Triatomines_Set_Input>;
  /** filter the rows which have to be updated */
  where: Triatomines_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Triatomines_Var_Pop_Fields = {
  __typename?: "Triatomines_var_pop_fields";
  cap_eta1?: Maybe<Scalars["Float"]>;
  cap_eta2?: Maybe<Scalars["Float"]>;
  cap_eta3?: Maybe<Scalars["Float"]>;
  cap_eta4?: Maybe<Scalars["Float"]>;
  cap_eta5?: Maybe<Scalars["Float"]>;
  cap_hem?: Maybe<Scalars["Float"]>;
  cap_mac?: Maybe<Scalars["Float"]>;
  cap_total?: Maybe<Scalars["Float"]>;
  distrito?: Maybe<Scalars["Float"]>;
  exa_eta2?: Maybe<Scalars["Float"]>;
  exa_eta3?: Maybe<Scalars["Float"]>;
  exa_eta4?: Maybe<Scalars["Float"]>;
  exa_eta5?: Maybe<Scalars["Float"]>;
  exa_hem?: Maybe<Scalars["Float"]>;
  exa_mac?: Maybe<Scalars["Float"]>;
  exa_total?: Maybe<Scalars["Float"]>;
  fase?: Maybe<Scalars["Float"]>;
  numero?: Maybe<Scalars["Float"]>;
  pos_eta2?: Maybe<Scalars["Float"]>;
  pos_eta3?: Maybe<Scalars["Float"]>;
  pos_eta4?: Maybe<Scalars["Float"]>;
  pos_eta5?: Maybe<Scalars["Float"]>;
  pos_hem?: Maybe<Scalars["Float"]>;
  pos_mac?: Maybe<Scalars["Float"]>;
  pos_total?: Maybe<Scalars["Float"]>;
  provincia?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Triatomines_Var_Samp_Fields = {
  __typename?: "Triatomines_var_samp_fields";
  cap_eta1?: Maybe<Scalars["Float"]>;
  cap_eta2?: Maybe<Scalars["Float"]>;
  cap_eta3?: Maybe<Scalars["Float"]>;
  cap_eta4?: Maybe<Scalars["Float"]>;
  cap_eta5?: Maybe<Scalars["Float"]>;
  cap_hem?: Maybe<Scalars["Float"]>;
  cap_mac?: Maybe<Scalars["Float"]>;
  cap_total?: Maybe<Scalars["Float"]>;
  distrito?: Maybe<Scalars["Float"]>;
  exa_eta2?: Maybe<Scalars["Float"]>;
  exa_eta3?: Maybe<Scalars["Float"]>;
  exa_eta4?: Maybe<Scalars["Float"]>;
  exa_eta5?: Maybe<Scalars["Float"]>;
  exa_hem?: Maybe<Scalars["Float"]>;
  exa_mac?: Maybe<Scalars["Float"]>;
  exa_total?: Maybe<Scalars["Float"]>;
  fase?: Maybe<Scalars["Float"]>;
  numero?: Maybe<Scalars["Float"]>;
  pos_eta2?: Maybe<Scalars["Float"]>;
  pos_eta3?: Maybe<Scalars["Float"]>;
  pos_eta4?: Maybe<Scalars["Float"]>;
  pos_eta5?: Maybe<Scalars["Float"]>;
  pos_hem?: Maybe<Scalars["Float"]>;
  pos_mac?: Maybe<Scalars["Float"]>;
  pos_total?: Maybe<Scalars["Float"]>;
  provincia?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Triatomines_Variance_Fields = {
  __typename?: "Triatomines_variance_fields";
  cap_eta1?: Maybe<Scalars["Float"]>;
  cap_eta2?: Maybe<Scalars["Float"]>;
  cap_eta3?: Maybe<Scalars["Float"]>;
  cap_eta4?: Maybe<Scalars["Float"]>;
  cap_eta5?: Maybe<Scalars["Float"]>;
  cap_hem?: Maybe<Scalars["Float"]>;
  cap_mac?: Maybe<Scalars["Float"]>;
  cap_total?: Maybe<Scalars["Float"]>;
  distrito?: Maybe<Scalars["Float"]>;
  exa_eta2?: Maybe<Scalars["Float"]>;
  exa_eta3?: Maybe<Scalars["Float"]>;
  exa_eta4?: Maybe<Scalars["Float"]>;
  exa_eta5?: Maybe<Scalars["Float"]>;
  exa_hem?: Maybe<Scalars["Float"]>;
  exa_mac?: Maybe<Scalars["Float"]>;
  exa_total?: Maybe<Scalars["Float"]>;
  fase?: Maybe<Scalars["Float"]>;
  numero?: Maybe<Scalars["Float"]>;
  pos_eta2?: Maybe<Scalars["Float"]>;
  pos_eta3?: Maybe<Scalars["Float"]>;
  pos_eta4?: Maybe<Scalars["Float"]>;
  pos_eta5?: Maybe<Scalars["Float"]>;
  pos_hem?: Maybe<Scalars["Float"]>;
  pos_mac?: Maybe<Scalars["Float"]>;
  pos_total?: Maybe<Scalars["Float"]>;
  provincia?: Maybe<Scalars["Float"]>;
};

export type XGeopoint = {
  __typename?: "XGeopoint";
  id: Scalars["uuid"];
  lat: Scalars["String"];
  lng: Scalars["String"];
  unicode: Scalars["String"];
};

/** columns and relationships of "advertisement" */
export type Advertisement = {
  __typename?: "advertisement";
  accepted?: Maybe<Scalars["Boolean"]>;
  app_state: Scalars["String"];
  /** An object relationship */
  collaborator?: Maybe<Collaborator>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  firebase_id?: Maybe<Scalars["String"]>;
  /** An object relationship */
  geopoint?: Maybe<Geopoint>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "advertisement" */
export type Advertisement_Aggregate = {
  __typename?: "advertisement_aggregate";
  aggregate?: Maybe<Advertisement_Aggregate_Fields>;
  nodes: Array<Advertisement>;
};

/** aggregate fields of "advertisement" */
export type Advertisement_Aggregate_Fields = {
  __typename?: "advertisement_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Advertisement_Max_Fields>;
  min?: Maybe<Advertisement_Min_Fields>;
};

/** aggregate fields of "advertisement" */
export type Advertisement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Advertisement_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "advertisement". All fields are combined with a logical 'AND'. */
export type Advertisement_Bool_Exp = {
  _and?: InputMaybe<Array<Advertisement_Bool_Exp>>;
  _not?: InputMaybe<Advertisement_Bool_Exp>;
  _or?: InputMaybe<Array<Advertisement_Bool_Exp>>;
  accepted?: InputMaybe<Boolean_Comparison_Exp>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  collaborator?: InputMaybe<Collaborator_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  geopoint?: InputMaybe<Geopoint_Bool_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "advertisement" */
export enum Advertisement_Constraint {
  /** unique or primary key constraint on columns "id" */
  AdvertisementPkey = "advertisement_pkey",
}

/** input type for inserting data into table "advertisement" */
export type Advertisement_Insert_Input = {
  accepted?: InputMaybe<Scalars["Boolean"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Advertisement_Max_Fields = {
  __typename?: "advertisement_max_fields";
  app_state?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Advertisement_Min_Fields = {
  __typename?: "advertisement_min_fields";
  app_state?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "advertisement" */
export type Advertisement_Mutation_Response = {
  __typename?: "advertisement_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Advertisement>;
};

/** on_conflict condition type for table "advertisement" */
export type Advertisement_On_Conflict = {
  constraint: Advertisement_Constraint;
  update_columns?: Array<Advertisement_Update_Column>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

/** Ordering options when selecting data from "advertisement". */
export type Advertisement_Order_By = {
  accepted?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  collaborator?: InputMaybe<Collaborator_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geopoint?: InputMaybe<Geopoint_Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: advertisement */
export type Advertisement_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "advertisement" */
export enum Advertisement_Select_Column {
  /** column name */
  Accepted = "accepted",
  /** column name */
  AppState = "app_state",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

/** input type for updating data in table "advertisement" */
export type Advertisement_Set_Input = {
  accepted?: InputMaybe<Scalars["Boolean"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "advertisement" */
export type Advertisement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Advertisement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Advertisement_Stream_Cursor_Value_Input = {
  accepted?: InputMaybe<Scalars["Boolean"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "advertisement" */
export enum Advertisement_Update_Column {
  /** column name */
  Accepted = "accepted",
  /** column name */
  AppState = "app_state",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

export type Advertisement_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Advertisement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Advertisement_Bool_Exp;
};

/** columns and relationships of "collaborator" */
export type Collaborator = {
  __typename?: "collaborator";
  auth_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["timestamptz"];
  dni?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  id: Scalars["uuid"];
  /** An array relationship */
  inspections: Array<Inspection>;
  /** An aggregate relationship */
  inspections_aggregate: Inspection_Aggregate;
  name?: Maybe<Scalars["String"]>;
  roles: Array<User_Roles>;
  roles_aggregate: User_Roles_Aggregate;
  /** An array relationship */
  sprayed: Array<Sprayed>;
  /** An aggregate relationship */
  sprayed_aggregate: Sprayed_Aggregate;
  updated_at: Scalars["timestamptz"];
  user?: Maybe<Users>;
  /** An array relationship */
  workspaces: Array<Workspace>;
  /** An aggregate relationship */
  workspaces_aggregate: Workspace_Aggregate;
};

/** columns and relationships of "collaborator" */
export type CollaboratorInspectionsArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorInspections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorRolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorSprayedArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorSprayed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** aggregated selection of "collaborator" */
export type Collaborator_Aggregate = {
  __typename?: "collaborator_aggregate";
  aggregate?: Maybe<Collaborator_Aggregate_Fields>;
  nodes: Array<Collaborator>;
};

/** aggregate fields of "collaborator" */
export type Collaborator_Aggregate_Fields = {
  __typename?: "collaborator_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Collaborator_Max_Fields>;
  min?: Maybe<Collaborator_Min_Fields>;
};

/** aggregate fields of "collaborator" */
export type Collaborator_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Collaborator_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "collaborator". All fields are combined with a logical 'AND'. */
export type Collaborator_Bool_Exp = {
  _and?: InputMaybe<Array<Collaborator_Bool_Exp>>;
  _not?: InputMaybe<Collaborator_Bool_Exp>;
  _or?: InputMaybe<Array<Collaborator_Bool_Exp>>;
  auth_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dni?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inspections?: InputMaybe<Inspection_Bool_Exp>;
  inspections_aggregate?: InputMaybe<Inspection_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sprayed?: InputMaybe<Sprayed_Bool_Exp>;
  sprayed_aggregate?: InputMaybe<Sprayed_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaces?: InputMaybe<Workspace_Bool_Exp>;
  workspaces_aggregate?: InputMaybe<Workspace_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "collaborator" */
export enum Collaborator_Constraint {
  /** unique or primary key constraint on columns "id" */
  CollaboratorPkey = "collaborator_pkey",
}

/** input type for inserting data into table "collaborator" */
export type Collaborator_Insert_Input = {
  auth_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  inspections?: InputMaybe<Inspection_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars["String"]>;
  sprayed?: InputMaybe<Sprayed_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspaces?: InputMaybe<Workspace_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Collaborator_Max_Fields = {
  __typename?: "collaborator_max_fields";
  auth_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dni?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Collaborator_Min_Fields = {
  __typename?: "collaborator_min_fields";
  auth_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dni?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "collaborator" */
export type Collaborator_Mutation_Response = {
  __typename?: "collaborator_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Collaborator>;
};

/** input type for inserting object relation for remote table "collaborator" */
export type Collaborator_Obj_Rel_Insert_Input = {
  data: Collaborator_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Collaborator_On_Conflict>;
};

/** on_conflict condition type for table "collaborator" */
export type Collaborator_On_Conflict = {
  constraint: Collaborator_Constraint;
  update_columns?: Array<Collaborator_Update_Column>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

/** Ordering options when selecting data from "collaborator". */
export type Collaborator_Order_By = {
  auth_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dni?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inspections_aggregate?: InputMaybe<Inspection_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  sprayed_aggregate?: InputMaybe<Sprayed_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspaces_aggregate?: InputMaybe<Workspace_Aggregate_Order_By>;
};

/** primary key columns input for table: collaborator */
export type Collaborator_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "collaborator" */
export enum Collaborator_Select_Column {
  /** column name */
  AuthId = "auth_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Dni = "dni",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "collaborator" */
export type Collaborator_Set_Input = {
  auth_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "collaborator" */
export type Collaborator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collaborator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Collaborator_Stream_Cursor_Value_Input = {
  auth_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "collaborator" */
export enum Collaborator_Update_Column {
  /** column name */
  AuthId = "auth_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Dni = "dni",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Collaborator_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Collaborator_Set_Input>;
  /** filter the rows which have to be updated */
  where: Collaborator_Bool_Exp;
};

/** columns and relationships of "complaint" */
export type Complaint = {
  __typename?: "complaint";
  address?: Maybe<Scalars["String"]>;
  app_state: Scalars["String"];
  code_number: Scalars["Int"];
  /** An object relationship */
  collaborator?: Maybe<Collaborator>;
  collaborator_id: Scalars["uuid"];
  complainer_name?: Maybe<Scalars["String"]>;
  complainer_phone?: Maybe<Scalars["String"]>;
  complaint_kind: Complaint_Kind_Enum;
  created_at: Scalars["timestamptz"];
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_code?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  /** An array relationship */
  geogroup: Array<Geogroup>;
  /** An aggregate relationship */
  geogroup_aggregate: Geogroup_Aggregate;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  geopoint?: Maybe<Geopoint>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  insect_image?: Maybe<Scalars["String"]>;
  insect_name?: Maybe<Scalars["String"]>;
  /** An array relationship */
  inspections: Array<Inspection>;
  /** An aggregate relationship */
  inspections_aggregate: Inspection_Aggregate;
  is_positive?: Maybe<Scalars["Boolean"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  place?: Maybe<Place>;
  reference?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  source: Complaint_Source_Enum;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  workspace_metadata?: Maybe<Workspace_Metadata>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "complaint" */
export type ComplaintGeogroupArgs = {
  distinct_on?: InputMaybe<Array<Geogroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geogroup_Order_By>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

/** columns and relationships of "complaint" */
export type ComplaintGeogroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geogroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geogroup_Order_By>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

/** columns and relationships of "complaint" */
export type ComplaintInspectionsArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** columns and relationships of "complaint" */
export type ComplaintInspections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** aggregated selection of "complaint" */
export type Complaint_Aggregate = {
  __typename?: "complaint_aggregate";
  aggregate?: Maybe<Complaint_Aggregate_Fields>;
  nodes: Array<Complaint>;
};

/** aggregate fields of "complaint" */
export type Complaint_Aggregate_Fields = {
  __typename?: "complaint_aggregate_fields";
  avg?: Maybe<Complaint_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Complaint_Max_Fields>;
  min?: Maybe<Complaint_Min_Fields>;
  stddev?: Maybe<Complaint_Stddev_Fields>;
  stddev_pop?: Maybe<Complaint_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Complaint_Stddev_Samp_Fields>;
  sum?: Maybe<Complaint_Sum_Fields>;
  var_pop?: Maybe<Complaint_Var_Pop_Fields>;
  var_samp?: Maybe<Complaint_Var_Samp_Fields>;
  variance?: Maybe<Complaint_Variance_Fields>;
};

/** aggregate fields of "complaint" */
export type Complaint_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Complaint_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Complaint_Avg_Fields = {
  __typename?: "complaint_avg_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "complaint". All fields are combined with a logical 'AND'. */
export type Complaint_Bool_Exp = {
  _and?: InputMaybe<Array<Complaint_Bool_Exp>>;
  _not?: InputMaybe<Complaint_Bool_Exp>;
  _or?: InputMaybe<Array<Complaint_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  code_number?: InputMaybe<Int_Comparison_Exp>;
  collaborator?: InputMaybe<Collaborator_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  complainer_name?: InputMaybe<String_Comparison_Exp>;
  complainer_phone?: InputMaybe<String_Comparison_Exp>;
  complaint_kind?: InputMaybe<Complaint_Kind_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  experiment_id?: InputMaybe<Uuid_Comparison_Exp>;
  firebase_code?: InputMaybe<Int_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  geogroup?: InputMaybe<Geogroup_Bool_Exp>;
  geogroup_aggregate?: InputMaybe<Geogroup_Aggregate_Bool_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint?: InputMaybe<Geopoint_Bool_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint_unicode?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insect_image?: InputMaybe<String_Comparison_Exp>;
  insect_name?: InputMaybe<String_Comparison_Exp>;
  inspections?: InputMaybe<Inspection_Bool_Exp>;
  inspections_aggregate?: InputMaybe<Inspection_Aggregate_Bool_Exp>;
  is_positive?: InputMaybe<Boolean_Comparison_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  place?: InputMaybe<Place_Bool_Exp>;
  reference?: InputMaybe<String_Comparison_Exp>;
  reported_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  source?: InputMaybe<Complaint_Source_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  workspace_metadata_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "complaint" */
export enum Complaint_Constraint {
  /** unique or primary key constraint on columns "id" */
  ComplaintPkey = "complaint_pkey",
}

/** input type for incrementing numeric columns in table "complaint" */
export type Complaint_Inc_Input = {
  code_number?: InputMaybe<Scalars["Int"]>;
  firebase_code?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "complaint" */
export type Complaint_Insert_Input = {
  address?: InputMaybe<Scalars["String"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  code_number?: InputMaybe<Scalars["Int"]>;
  collaborator?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complainer_name?: InputMaybe<Scalars["String"]>;
  complainer_phone?: InputMaybe<Scalars["String"]>;
  complaint_kind?: InputMaybe<Complaint_Kind_Enum>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_code?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup?: InputMaybe<Geogroup_Arr_Rel_Insert_Input>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insect_image?: InputMaybe<Scalars["String"]>;
  insect_name?: InputMaybe<Scalars["String"]>;
  inspections?: InputMaybe<Inspection_Arr_Rel_Insert_Input>;
  is_positive?: InputMaybe<Scalars["Boolean"]>;
  location_id?: InputMaybe<Scalars["uuid"]>;
  place?: InputMaybe<Place_Obj_Rel_Insert_Input>;
  reference?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  source?: InputMaybe<Complaint_Source_Enum>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Obj_Rel_Insert_Input>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
};

/** columns and relationships of "complaint_kind" */
export type Complaint_Kind = {
  __typename?: "complaint_kind";
  comment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "complaint_kind" */
export type Complaint_Kind_Aggregate = {
  __typename?: "complaint_kind_aggregate";
  aggregate?: Maybe<Complaint_Kind_Aggregate_Fields>;
  nodes: Array<Complaint_Kind>;
};

/** aggregate fields of "complaint_kind" */
export type Complaint_Kind_Aggregate_Fields = {
  __typename?: "complaint_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Complaint_Kind_Max_Fields>;
  min?: Maybe<Complaint_Kind_Min_Fields>;
};

/** aggregate fields of "complaint_kind" */
export type Complaint_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Complaint_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "complaint_kind". All fields are combined with a logical 'AND'. */
export type Complaint_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Complaint_Kind_Bool_Exp>>;
  _not?: InputMaybe<Complaint_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Complaint_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "complaint_kind" */
export enum Complaint_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  ComplaintKindPkey = "complaint_kind_pkey",
}

export enum Complaint_Kind_Enum {
  Verbal = "verbal",
  WithInsect = "with_insect",
}

/** Boolean expression to compare columns of type "complaint_kind_enum". All fields are combined with logical 'AND'. */
export type Complaint_Kind_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Complaint_Kind_Enum>;
  _in?: InputMaybe<Array<Complaint_Kind_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Complaint_Kind_Enum>;
  _nin?: InputMaybe<Array<Complaint_Kind_Enum>>;
};

/** input type for inserting data into table "complaint_kind" */
export type Complaint_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Complaint_Kind_Max_Fields = {
  __typename?: "complaint_kind_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Complaint_Kind_Min_Fields = {
  __typename?: "complaint_kind_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "complaint_kind" */
export type Complaint_Kind_Mutation_Response = {
  __typename?: "complaint_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Complaint_Kind>;
};

/** on_conflict condition type for table "complaint_kind" */
export type Complaint_Kind_On_Conflict = {
  constraint: Complaint_Kind_Constraint;
  update_columns?: Array<Complaint_Kind_Update_Column>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "complaint_kind". */
export type Complaint_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: complaint_kind */
export type Complaint_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "complaint_kind" */
export enum Complaint_Kind_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "complaint_kind" */
export type Complaint_Kind_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "complaint_kind" */
export type Complaint_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Complaint_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Complaint_Kind_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "complaint_kind" */
export enum Complaint_Kind_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Complaint_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Complaint_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Complaint_Kind_Bool_Exp;
};

/** aggregate max on columns */
export type Complaint_Max_Fields = {
  __typename?: "complaint_max_fields";
  address?: Maybe<Scalars["String"]>;
  app_state?: Maybe<Scalars["String"]>;
  code_number?: Maybe<Scalars["Int"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  complainer_name?: Maybe<Scalars["String"]>;
  complainer_phone?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_code?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  insect_image?: Maybe<Scalars["String"]>;
  insect_name?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  reference?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Complaint_Min_Fields = {
  __typename?: "complaint_min_fields";
  address?: Maybe<Scalars["String"]>;
  app_state?: Maybe<Scalars["String"]>;
  code_number?: Maybe<Scalars["Int"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  complainer_name?: Maybe<Scalars["String"]>;
  complainer_phone?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_code?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  insect_image?: Maybe<Scalars["String"]>;
  insect_name?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  reference?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "complaint" */
export type Complaint_Mutation_Response = {
  __typename?: "complaint_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Complaint>;
};

/** input type for inserting object relation for remote table "complaint" */
export type Complaint_Obj_Rel_Insert_Input = {
  data: Complaint_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Complaint_On_Conflict>;
};

/** on_conflict condition type for table "complaint" */
export type Complaint_On_Conflict = {
  constraint: Complaint_Constraint;
  update_columns?: Array<Complaint_Update_Column>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** Ordering options when selecting data from "complaint". */
export type Complaint_Order_By = {
  address?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  code_number?: InputMaybe<Order_By>;
  collaborator?: InputMaybe<Collaborator_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complainer_name?: InputMaybe<Order_By>;
  complainer_phone?: InputMaybe<Order_By>;
  complaint_kind?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_aggregate?: InputMaybe<Geogroup_Aggregate_Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint?: InputMaybe<Geopoint_Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insect_image?: InputMaybe<Order_By>;
  insect_name?: InputMaybe<Order_By>;
  inspections_aggregate?: InputMaybe<Inspection_Aggregate_Order_By>;
  is_positive?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  place?: InputMaybe<Place_Order_By>;
  reference?: InputMaybe<Order_By>;
  reported_at?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: complaint */
export type Complaint_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "complaint" */
export enum Complaint_Select_Column {
  /** column name */
  Address = "address",
  /** column name */
  AppState = "app_state",
  /** column name */
  CodeNumber = "code_number",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  ComplainerName = "complainer_name",
  /** column name */
  ComplainerPhone = "complainer_phone",
  /** column name */
  ComplaintKind = "complaint_kind",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FirebaseCode = "firebase_code",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  Id = "id",
  /** column name */
  InsectImage = "insect_image",
  /** column name */
  InsectName = "insect_name",
  /** column name */
  IsPositive = "is_positive",
  /** column name */
  LocationId = "location_id",
  /** column name */
  Reference = "reference",
  /** column name */
  ReportedAt = "reported_at",
  /** column name */
  Source = "source",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
}

/** input type for updating data in table "complaint" */
export type Complaint_Set_Input = {
  address?: InputMaybe<Scalars["String"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  code_number?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complainer_name?: InputMaybe<Scalars["String"]>;
  complainer_phone?: InputMaybe<Scalars["String"]>;
  complaint_kind?: InputMaybe<Complaint_Kind_Enum>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_code?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insect_image?: InputMaybe<Scalars["String"]>;
  insect_name?: InputMaybe<Scalars["String"]>;
  is_positive?: InputMaybe<Scalars["Boolean"]>;
  location_id?: InputMaybe<Scalars["uuid"]>;
  reference?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  source?: InputMaybe<Complaint_Source_Enum>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
};

/** columns and relationships of "complaint_source" */
export type Complaint_Source = {
  __typename?: "complaint_source";
  comment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "complaint_source" */
export type Complaint_Source_Aggregate = {
  __typename?: "complaint_source_aggregate";
  aggregate?: Maybe<Complaint_Source_Aggregate_Fields>;
  nodes: Array<Complaint_Source>;
};

/** aggregate fields of "complaint_source" */
export type Complaint_Source_Aggregate_Fields = {
  __typename?: "complaint_source_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Complaint_Source_Max_Fields>;
  min?: Maybe<Complaint_Source_Min_Fields>;
};

/** aggregate fields of "complaint_source" */
export type Complaint_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Complaint_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "complaint_source". All fields are combined with a logical 'AND'. */
export type Complaint_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Complaint_Source_Bool_Exp>>;
  _not?: InputMaybe<Complaint_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Complaint_Source_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "complaint_source" */
export enum Complaint_Source_Constraint {
  /** unique or primary key constraint on columns "value" */
  ComplaintMediaPkey = "complaint_media_pkey",
}

export enum Complaint_Source_Enum {
  HealthPost = "health_post",
  InspectorNotice = "inspector_notice",
  Phone = "phone",
  Street = "street",
  Unknown = "unknown",
  Whatsapp = "whatsapp",
}

/** Boolean expression to compare columns of type "complaint_source_enum". All fields are combined with logical 'AND'. */
export type Complaint_Source_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Complaint_Source_Enum>;
  _in?: InputMaybe<Array<Complaint_Source_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Complaint_Source_Enum>;
  _nin?: InputMaybe<Array<Complaint_Source_Enum>>;
};

/** input type for inserting data into table "complaint_source" */
export type Complaint_Source_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Complaint_Source_Max_Fields = {
  __typename?: "complaint_source_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Complaint_Source_Min_Fields = {
  __typename?: "complaint_source_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "complaint_source" */
export type Complaint_Source_Mutation_Response = {
  __typename?: "complaint_source_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Complaint_Source>;
};

/** on_conflict condition type for table "complaint_source" */
export type Complaint_Source_On_Conflict = {
  constraint: Complaint_Source_Constraint;
  update_columns?: Array<Complaint_Source_Update_Column>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "complaint_source". */
export type Complaint_Source_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: complaint_source */
export type Complaint_Source_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "complaint_source" */
export enum Complaint_Source_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "complaint_source" */
export type Complaint_Source_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "complaint_source" */
export type Complaint_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Complaint_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Complaint_Source_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "complaint_source" */
export enum Complaint_Source_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Complaint_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Complaint_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Complaint_Source_Bool_Exp;
};

/** aggregate stddev on columns */
export type Complaint_Stddev_Fields = {
  __typename?: "complaint_stddev_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Complaint_Stddev_Pop_Fields = {
  __typename?: "complaint_stddev_pop_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Complaint_Stddev_Samp_Fields = {
  __typename?: "complaint_stddev_samp_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "complaint" */
export type Complaint_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Complaint_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Complaint_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars["String"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  code_number?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complainer_name?: InputMaybe<Scalars["String"]>;
  complainer_phone?: InputMaybe<Scalars["String"]>;
  complaint_kind?: InputMaybe<Complaint_Kind_Enum>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_code?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insect_image?: InputMaybe<Scalars["String"]>;
  insect_name?: InputMaybe<Scalars["String"]>;
  is_positive?: InputMaybe<Scalars["Boolean"]>;
  location_id?: InputMaybe<Scalars["uuid"]>;
  reference?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  source?: InputMaybe<Complaint_Source_Enum>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Complaint_Sum_Fields = {
  __typename?: "complaint_sum_fields";
  code_number?: Maybe<Scalars["Int"]>;
  firebase_code?: Maybe<Scalars["Int"]>;
};

/** update columns of table "complaint" */
export enum Complaint_Update_Column {
  /** column name */
  Address = "address",
  /** column name */
  AppState = "app_state",
  /** column name */
  CodeNumber = "code_number",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  ComplainerName = "complainer_name",
  /** column name */
  ComplainerPhone = "complainer_phone",
  /** column name */
  ComplaintKind = "complaint_kind",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FirebaseCode = "firebase_code",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  Id = "id",
  /** column name */
  InsectImage = "insect_image",
  /** column name */
  InsectName = "insect_name",
  /** column name */
  IsPositive = "is_positive",
  /** column name */
  LocationId = "location_id",
  /** column name */
  Reference = "reference",
  /** column name */
  ReportedAt = "reported_at",
  /** column name */
  Source = "source",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
}

export type Complaint_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Complaint_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Complaint_Set_Input>;
  /** filter the rows which have to be updated */
  where: Complaint_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Complaint_Var_Pop_Fields = {
  __typename?: "complaint_var_pop_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Complaint_Var_Samp_Fields = {
  __typename?: "complaint_var_samp_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Complaint_Variance_Fields = {
  __typename?: "complaint_variance_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

export type Compute_Group_Args = {
  group_id?: InputMaybe<Scalars["uuid"]>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["date"]>;
  _gt?: InputMaybe<Scalars["date"]>;
  _gte?: InputMaybe<Scalars["date"]>;
  _in?: InputMaybe<Array<Scalars["date"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["date"]>;
  _lte?: InputMaybe<Scalars["date"]>;
  _neq?: InputMaybe<Scalars["date"]>;
  _nin?: InputMaybe<Array<Scalars["date"]>>;
};

/** columns and relationships of "distances" */
export type Distances = {
  __typename?: "distances";
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  from: Geopoint;
  from_id: Scalars["uuid"];
  id: Scalars["uuid"];
  kind: Scalars["String"];
  raw?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  to: Geopoint;
  to_id: Scalars["uuid"];
  unit: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  value: Scalars["float8"];
};

/** columns and relationships of "distances" */
export type DistancesRawArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "distances" */
export type Distances_Aggregate = {
  __typename?: "distances_aggregate";
  aggregate?: Maybe<Distances_Aggregate_Fields>;
  nodes: Array<Distances>;
};

/** aggregate fields of "distances" */
export type Distances_Aggregate_Fields = {
  __typename?: "distances_aggregate_fields";
  avg?: Maybe<Distances_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Distances_Max_Fields>;
  min?: Maybe<Distances_Min_Fields>;
  stddev?: Maybe<Distances_Stddev_Fields>;
  stddev_pop?: Maybe<Distances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Distances_Stddev_Samp_Fields>;
  sum?: Maybe<Distances_Sum_Fields>;
  var_pop?: Maybe<Distances_Var_Pop_Fields>;
  var_samp?: Maybe<Distances_Var_Samp_Fields>;
  variance?: Maybe<Distances_Variance_Fields>;
};

/** aggregate fields of "distances" */
export type Distances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Distances_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Distances_Append_Input = {
  raw?: InputMaybe<Scalars["jsonb"]>;
};

/** aggregate avg on columns */
export type Distances_Avg_Fields = {
  __typename?: "distances_avg_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "distances". All fields are combined with a logical 'AND'. */
export type Distances_Bool_Exp = {
  _and?: InputMaybe<Array<Distances_Bool_Exp>>;
  _not?: InputMaybe<Distances_Bool_Exp>;
  _or?: InputMaybe<Array<Distances_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from?: InputMaybe<Geopoint_Bool_Exp>;
  from_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  raw?: InputMaybe<Jsonb_Comparison_Exp>;
  to?: InputMaybe<Geopoint_Bool_Exp>;
  to_id?: InputMaybe<Uuid_Comparison_Exp>;
  unit?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "distances" */
export enum Distances_Constraint {
  /** unique or primary key constraint on columns "id" */
  DistancesPkey = "distances_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Distances_Delete_At_Path_Input = {
  raw?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Distances_Delete_Elem_Input = {
  raw?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Distances_Delete_Key_Input = {
  raw?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "distances" */
export type Distances_Inc_Input = {
  value?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "distances" */
export type Distances_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  from?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  from_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  raw?: InputMaybe<Scalars["jsonb"]>;
  to?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  to_id?: InputMaybe<Scalars["uuid"]>;
  unit?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Distances_Max_Fields = {
  __typename?: "distances_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  to_id?: Maybe<Scalars["uuid"]>;
  unit?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["float8"]>;
};

/** aggregate min on columns */
export type Distances_Min_Fields = {
  __typename?: "distances_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  to_id?: Maybe<Scalars["uuid"]>;
  unit?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["float8"]>;
};

/** response of any mutation on the table "distances" */
export type Distances_Mutation_Response = {
  __typename?: "distances_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Distances>;
};

/** on_conflict condition type for table "distances" */
export type Distances_On_Conflict = {
  constraint: Distances_Constraint;
  update_columns?: Array<Distances_Update_Column>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

/** Ordering options when selecting data from "distances". */
export type Distances_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from?: InputMaybe<Geopoint_Order_By>;
  from_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  raw?: InputMaybe<Order_By>;
  to?: InputMaybe<Geopoint_Order_By>;
  to_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: distances */
export type Distances_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Distances_Prepend_Input = {
  raw?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "distances" */
export enum Distances_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FromId = "from_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Raw = "raw",
  /** column name */
  ToId = "to_id",
  /** column name */
  Unit = "unit",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "distances" */
export type Distances_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  from_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  raw?: InputMaybe<Scalars["jsonb"]>;
  to_id?: InputMaybe<Scalars["uuid"]>;
  unit?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Distances_Stddev_Fields = {
  __typename?: "distances_stddev_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Distances_Stddev_Pop_Fields = {
  __typename?: "distances_stddev_pop_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Distances_Stddev_Samp_Fields = {
  __typename?: "distances_stddev_samp_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "distances" */
export type Distances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Distances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Distances_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  from_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  raw?: InputMaybe<Scalars["jsonb"]>;
  to_id?: InputMaybe<Scalars["uuid"]>;
  unit?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["float8"]>;
};

/** aggregate sum on columns */
export type Distances_Sum_Fields = {
  __typename?: "distances_sum_fields";
  value?: Maybe<Scalars["float8"]>;
};

/** update columns of table "distances" */
export enum Distances_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FromId = "from_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Raw = "raw",
  /** column name */
  ToId = "to_id",
  /** column name */
  Unit = "unit",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Distances_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Distances_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Distances_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Distances_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Distances_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Distances_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Distances_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Distances_Set_Input>;
  /** filter the rows which have to be updated */
  where: Distances_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Distances_Var_Pop_Fields = {
  __typename?: "distances_var_pop_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Distances_Var_Samp_Fields = {
  __typename?: "distances_var_samp_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Distances_Variance_Fields = {
  __typename?: "distances_variance_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "estate_kind" */
export type Estate_Kind = {
  __typename?: "estate_kind";
  comment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "estate_kind" */
export type Estate_Kind_Aggregate = {
  __typename?: "estate_kind_aggregate";
  aggregate?: Maybe<Estate_Kind_Aggregate_Fields>;
  nodes: Array<Estate_Kind>;
};

/** aggregate fields of "estate_kind" */
export type Estate_Kind_Aggregate_Fields = {
  __typename?: "estate_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Estate_Kind_Max_Fields>;
  min?: Maybe<Estate_Kind_Min_Fields>;
};

/** aggregate fields of "estate_kind" */
export type Estate_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Estate_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "estate_kind". All fields are combined with a logical 'AND'. */
export type Estate_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Estate_Kind_Bool_Exp>>;
  _not?: InputMaybe<Estate_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Estate_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "estate_kind" */
export enum Estate_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  EstateKindPkey = "estate_kind_pkey",
}

export enum Estate_Kind_Enum {
  EmptyLot = "empty_lot",
  Inhabited = "inhabited",
  PublicLot = "public_lot",
  RegularHouse = "regular_house",
}

/** Boolean expression to compare columns of type "estate_kind_enum". All fields are combined with logical 'AND'. */
export type Estate_Kind_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Estate_Kind_Enum>;
  _in?: InputMaybe<Array<Estate_Kind_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Estate_Kind_Enum>;
  _nin?: InputMaybe<Array<Estate_Kind_Enum>>;
};

/** input type for inserting data into table "estate_kind" */
export type Estate_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Estate_Kind_Max_Fields = {
  __typename?: "estate_kind_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Estate_Kind_Min_Fields = {
  __typename?: "estate_kind_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "estate_kind" */
export type Estate_Kind_Mutation_Response = {
  __typename?: "estate_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Estate_Kind>;
};

/** on_conflict condition type for table "estate_kind" */
export type Estate_Kind_On_Conflict = {
  constraint: Estate_Kind_Constraint;
  update_columns?: Array<Estate_Kind_Update_Column>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "estate_kind". */
export type Estate_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: estate_kind */
export type Estate_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "estate_kind" */
export enum Estate_Kind_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "estate_kind" */
export type Estate_Kind_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "estate_kind" */
export type Estate_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Estate_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Estate_Kind_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "estate_kind" */
export enum Estate_Kind_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Estate_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Estate_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Estate_Kind_Bool_Exp;
};

/** columns and relationships of "experiment" */
export type Experiment = {
  __typename?: "experiment";
  /** An array relationship */
  children: Array<Experiment>;
  /** An aggregate relationship */
  children_aggregate: Experiment_Aggregate;
  created_at: Scalars["timestamptz"];
  data_fit_size?: Maybe<Scalars["Int"]>;
  data_pred_size?: Maybe<Scalars["Int"]>;
  experiment_duration?: Maybe<Scalars["float8"]>;
  experiment_start_date?: Maybe<Scalars["time"]>;
  experiment_threads?: Maybe<Scalars["Int"]>;
  experiment_worker_cpus?: Maybe<Scalars["Int"]>;
  experiment_worker_id?: Maybe<Scalars["String"]>;
  experiment_worker_memory?: Maybe<Scalars["float8"]>;
  first_cut?: Maybe<Scalars["date"]>;
  fixed_intercept_kld?: Maybe<Scalars["float8"]>;
  fixed_intercept_mean?: Maybe<Scalars["float8"]>;
  fixed_intercept_sd?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["float8"]>;
  fourth_cut?: Maybe<Scalars["date"]>;
  /** An object relationship */
  geogroup: Geogroup;
  geogroup_id: Scalars["uuid"];
  grouprho_mean?: Maybe<Scalars["float8"]>;
  grouprho_mode?: Maybe<Scalars["float8"]>;
  grouprho_sd?: Maybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["float8"]>;
  id: Scalars["uuid"];
  infestation_probability_mean?: Maybe<Scalars["float8"]>;
  infestation_probability_sd?: Maybe<Scalars["float8"]>;
  intercept_precision?: Maybe<Scalars["float8"]>;
  intercept_prior?: Maybe<Scalars["float8"]>;
  kappa_mean?: Maybe<Scalars["float8"]>;
  kappa_sd?: Maybe<Scalars["float8"]>;
  kind?: Maybe<Scalars["String"]>;
  /** An object relationship */
  parent?: Maybe<Experiment>;
  parent_id?: Maybe<Scalars["uuid"]>;
  second_cut?: Maybe<Scalars["date"]>;
  tau_mean?: Maybe<Scalars["float8"]>;
  tau_sd?: Maybe<Scalars["float8"]>;
  theta_one_mean?: Maybe<Scalars["float8"]>;
  theta_one_precision?: Maybe<Scalars["float8"]>;
  theta_one_prior?: Maybe<Scalars["float8"]>;
  theta_one_sd?: Maybe<Scalars["float8"]>;
  theta_two_mean?: Maybe<Scalars["float8"]>;
  theta_two_precision?: Maybe<Scalars["float8"]>;
  theta_two_prior?: Maybe<Scalars["float8"]>;
  theta_two_sd?: Maybe<Scalars["float8"]>;
  third_cut?: Maybe<Scalars["date"]>;
  total_house_in_area?: Maybe<Scalars["Int"]>;
  total_inspected_houses?: Maybe<Scalars["Int"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Int"]>;
  total_sprayed_houses?: Maybe<Scalars["Int"]>;
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  workspaces: Array<Workspace_Metadata>;
  /** An aggregate relationship */
  workspaces_aggregate: Workspace_Metadata_Aggregate;
};

/** columns and relationships of "experiment" */
export type ExperimentChildrenArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** columns and relationships of "experiment" */
export type ExperimentChildren_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** columns and relationships of "experiment" */
export type ExperimentWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** columns and relationships of "experiment" */
export type ExperimentWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** aggregated selection of "experiment" */
export type Experiment_Aggregate = {
  __typename?: "experiment_aggregate";
  aggregate?: Maybe<Experiment_Aggregate_Fields>;
  nodes: Array<Experiment>;
};

export type Experiment_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Experiment_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Experiment_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Experiment_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Experiment_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Experiment_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Experiment_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Experiment_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Experiment_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Experiment_Aggregate_Bool_Exp_Var_Samp>;
};

export type Experiment_Aggregate_Bool_Exp_Avg = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Corr = {
  arguments: Experiment_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Experiment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Experiment_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Experiment_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Experiment_Aggregate_Bool_Exp_Max = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Min = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Sum = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "experiment" */
export type Experiment_Aggregate_Fields = {
  __typename?: "experiment_aggregate_fields";
  avg?: Maybe<Experiment_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Experiment_Max_Fields>;
  min?: Maybe<Experiment_Min_Fields>;
  stddev?: Maybe<Experiment_Stddev_Fields>;
  stddev_pop?: Maybe<Experiment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Experiment_Stddev_Samp_Fields>;
  sum?: Maybe<Experiment_Sum_Fields>;
  var_pop?: Maybe<Experiment_Var_Pop_Fields>;
  var_samp?: Maybe<Experiment_Var_Samp_Fields>;
  variance?: Maybe<Experiment_Variance_Fields>;
};

/** aggregate fields of "experiment" */
export type Experiment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Experiment_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "experiment" */
export type Experiment_Aggregate_Order_By = {
  avg?: InputMaybe<Experiment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Experiment_Max_Order_By>;
  min?: InputMaybe<Experiment_Min_Order_By>;
  stddev?: InputMaybe<Experiment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Experiment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Experiment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Experiment_Sum_Order_By>;
  var_pop?: InputMaybe<Experiment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Experiment_Var_Samp_Order_By>;
  variance?: InputMaybe<Experiment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "experiment" */
export type Experiment_Arr_Rel_Insert_Input = {
  data: Array<Experiment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Experiment_On_Conflict>;
};

/** aggregate avg on columns */
export type Experiment_Avg_Fields = {
  __typename?: "experiment_avg_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "experiment" */
export type Experiment_Avg_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "experiment". All fields are combined with a logical 'AND'. */
export type Experiment_Bool_Exp = {
  _and?: InputMaybe<Array<Experiment_Bool_Exp>>;
  _not?: InputMaybe<Experiment_Bool_Exp>;
  _or?: InputMaybe<Array<Experiment_Bool_Exp>>;
  children?: InputMaybe<Experiment_Bool_Exp>;
  children_aggregate?: InputMaybe<Experiment_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_fit_size?: InputMaybe<Int_Comparison_Exp>;
  data_pred_size?: InputMaybe<Int_Comparison_Exp>;
  experiment_duration?: InputMaybe<Float8_Comparison_Exp>;
  experiment_start_date?: InputMaybe<Time_Comparison_Exp>;
  experiment_threads?: InputMaybe<Int_Comparison_Exp>;
  experiment_worker_cpus?: InputMaybe<Int_Comparison_Exp>;
  experiment_worker_id?: InputMaybe<String_Comparison_Exp>;
  experiment_worker_memory?: InputMaybe<Float8_Comparison_Exp>;
  first_cut?: InputMaybe<Date_Comparison_Exp>;
  fixed_intercept_kld?: InputMaybe<Float8_Comparison_Exp>;
  fixed_intercept_mean?: InputMaybe<Float8_Comparison_Exp>;
  fixed_intercept_sd?: InputMaybe<Float8_Comparison_Exp>;
  fixed_not_sprayed_kld?: InputMaybe<Float8_Comparison_Exp>;
  fixed_not_sprayed_mean?: InputMaybe<Float8_Comparison_Exp>;
  fixed_not_sprayed_sd?: InputMaybe<Float8_Comparison_Exp>;
  fourth_cut?: InputMaybe<Date_Comparison_Exp>;
  geogroup?: InputMaybe<Geogroup_Bool_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  grouprho_mean?: InputMaybe<Float8_Comparison_Exp>;
  grouprho_mode?: InputMaybe<Float8_Comparison_Exp>;
  grouprho_sd?: InputMaybe<Float8_Comparison_Exp>;
  hyperpar_theta_one_mode?: InputMaybe<Float8_Comparison_Exp>;
  hyperpar_theta_two_mode?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  infestation_probability_mean?: InputMaybe<Float8_Comparison_Exp>;
  infestation_probability_sd?: InputMaybe<Float8_Comparison_Exp>;
  intercept_precision?: InputMaybe<Float8_Comparison_Exp>;
  intercept_prior?: InputMaybe<Float8_Comparison_Exp>;
  kappa_mean?: InputMaybe<Float8_Comparison_Exp>;
  kappa_sd?: InputMaybe<Float8_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  parent?: InputMaybe<Experiment_Bool_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  second_cut?: InputMaybe<Date_Comparison_Exp>;
  tau_mean?: InputMaybe<Float8_Comparison_Exp>;
  tau_sd?: InputMaybe<Float8_Comparison_Exp>;
  theta_one_mean?: InputMaybe<Float8_Comparison_Exp>;
  theta_one_precision?: InputMaybe<Float8_Comparison_Exp>;
  theta_one_prior?: InputMaybe<Float8_Comparison_Exp>;
  theta_one_sd?: InputMaybe<Float8_Comparison_Exp>;
  theta_two_mean?: InputMaybe<Float8_Comparison_Exp>;
  theta_two_precision?: InputMaybe<Float8_Comparison_Exp>;
  theta_two_prior?: InputMaybe<Float8_Comparison_Exp>;
  theta_two_sd?: InputMaybe<Float8_Comparison_Exp>;
  third_cut?: InputMaybe<Date_Comparison_Exp>;
  total_house_in_area?: InputMaybe<Int_Comparison_Exp>;
  total_inspected_houses?: InputMaybe<Int_Comparison_Exp>;
  total_positive_houses_in_area?: InputMaybe<Int_Comparison_Exp>;
  total_sprayed_houses?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaces?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  workspaces_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "experiment" */
export enum Experiment_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExperimentPkey = "experiment_pkey",
}

/** input type for incrementing numeric columns in table "experiment" */
export type Experiment_Inc_Input = {
  data_fit_size?: InputMaybe<Scalars["Int"]>;
  data_pred_size?: InputMaybe<Scalars["Int"]>;
  experiment_duration?: InputMaybe<Scalars["float8"]>;
  experiment_threads?: InputMaybe<Scalars["Int"]>;
  experiment_worker_cpus?: InputMaybe<Scalars["Int"]>;
  experiment_worker_memory?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_kld?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_mean?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_sd?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: InputMaybe<Scalars["float8"]>;
  grouprho_mean?: InputMaybe<Scalars["float8"]>;
  grouprho_mode?: InputMaybe<Scalars["float8"]>;
  grouprho_sd?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: InputMaybe<Scalars["float8"]>;
  infestation_probability_mean?: InputMaybe<Scalars["float8"]>;
  infestation_probability_sd?: InputMaybe<Scalars["float8"]>;
  intercept_precision?: InputMaybe<Scalars["float8"]>;
  intercept_prior?: InputMaybe<Scalars["float8"]>;
  kappa_mean?: InputMaybe<Scalars["float8"]>;
  kappa_sd?: InputMaybe<Scalars["float8"]>;
  tau_mean?: InputMaybe<Scalars["float8"]>;
  tau_sd?: InputMaybe<Scalars["float8"]>;
  theta_one_mean?: InputMaybe<Scalars["float8"]>;
  theta_one_precision?: InputMaybe<Scalars["float8"]>;
  theta_one_prior?: InputMaybe<Scalars["float8"]>;
  theta_one_sd?: InputMaybe<Scalars["float8"]>;
  theta_two_mean?: InputMaybe<Scalars["float8"]>;
  theta_two_precision?: InputMaybe<Scalars["float8"]>;
  theta_two_prior?: InputMaybe<Scalars["float8"]>;
  theta_two_sd?: InputMaybe<Scalars["float8"]>;
  total_house_in_area?: InputMaybe<Scalars["Int"]>;
  total_inspected_houses?: InputMaybe<Scalars["Int"]>;
  total_positive_houses_in_area?: InputMaybe<Scalars["Int"]>;
  total_sprayed_houses?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "experiment" */
export type Experiment_Insert_Input = {
  children?: InputMaybe<Experiment_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data_fit_size?: InputMaybe<Scalars["Int"]>;
  data_pred_size?: InputMaybe<Scalars["Int"]>;
  experiment_duration?: InputMaybe<Scalars["float8"]>;
  experiment_start_date?: InputMaybe<Scalars["time"]>;
  experiment_threads?: InputMaybe<Scalars["Int"]>;
  experiment_worker_cpus?: InputMaybe<Scalars["Int"]>;
  experiment_worker_id?: InputMaybe<Scalars["String"]>;
  experiment_worker_memory?: InputMaybe<Scalars["float8"]>;
  first_cut?: InputMaybe<Scalars["date"]>;
  fixed_intercept_kld?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_mean?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_sd?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: InputMaybe<Scalars["float8"]>;
  fourth_cut?: InputMaybe<Scalars["date"]>;
  geogroup?: InputMaybe<Geogroup_Obj_Rel_Insert_Input>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  grouprho_mean?: InputMaybe<Scalars["float8"]>;
  grouprho_mode?: InputMaybe<Scalars["float8"]>;
  grouprho_sd?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: InputMaybe<Scalars["float8"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  infestation_probability_mean?: InputMaybe<Scalars["float8"]>;
  infestation_probability_sd?: InputMaybe<Scalars["float8"]>;
  intercept_precision?: InputMaybe<Scalars["float8"]>;
  intercept_prior?: InputMaybe<Scalars["float8"]>;
  kappa_mean?: InputMaybe<Scalars["float8"]>;
  kappa_sd?: InputMaybe<Scalars["float8"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent?: InputMaybe<Experiment_Obj_Rel_Insert_Input>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  second_cut?: InputMaybe<Scalars["date"]>;
  tau_mean?: InputMaybe<Scalars["float8"]>;
  tau_sd?: InputMaybe<Scalars["float8"]>;
  theta_one_mean?: InputMaybe<Scalars["float8"]>;
  theta_one_precision?: InputMaybe<Scalars["float8"]>;
  theta_one_prior?: InputMaybe<Scalars["float8"]>;
  theta_one_sd?: InputMaybe<Scalars["float8"]>;
  theta_two_mean?: InputMaybe<Scalars["float8"]>;
  theta_two_precision?: InputMaybe<Scalars["float8"]>;
  theta_two_prior?: InputMaybe<Scalars["float8"]>;
  theta_two_sd?: InputMaybe<Scalars["float8"]>;
  third_cut?: InputMaybe<Scalars["date"]>;
  total_house_in_area?: InputMaybe<Scalars["Int"]>;
  total_inspected_houses?: InputMaybe<Scalars["Int"]>;
  total_positive_houses_in_area?: InputMaybe<Scalars["Int"]>;
  total_sprayed_houses?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspaces?: InputMaybe<Workspace_Metadata_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Experiment_Max_Fields = {
  __typename?: "experiment_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  data_fit_size?: Maybe<Scalars["Int"]>;
  data_pred_size?: Maybe<Scalars["Int"]>;
  experiment_duration?: Maybe<Scalars["float8"]>;
  experiment_threads?: Maybe<Scalars["Int"]>;
  experiment_worker_cpus?: Maybe<Scalars["Int"]>;
  experiment_worker_id?: Maybe<Scalars["String"]>;
  experiment_worker_memory?: Maybe<Scalars["float8"]>;
  first_cut?: Maybe<Scalars["date"]>;
  fixed_intercept_kld?: Maybe<Scalars["float8"]>;
  fixed_intercept_mean?: Maybe<Scalars["float8"]>;
  fixed_intercept_sd?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["float8"]>;
  fourth_cut?: Maybe<Scalars["date"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  grouprho_mean?: Maybe<Scalars["float8"]>;
  grouprho_mode?: Maybe<Scalars["float8"]>;
  grouprho_sd?: Maybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  infestation_probability_mean?: Maybe<Scalars["float8"]>;
  infestation_probability_sd?: Maybe<Scalars["float8"]>;
  intercept_precision?: Maybe<Scalars["float8"]>;
  intercept_prior?: Maybe<Scalars["float8"]>;
  kappa_mean?: Maybe<Scalars["float8"]>;
  kappa_sd?: Maybe<Scalars["float8"]>;
  kind?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  second_cut?: Maybe<Scalars["date"]>;
  tau_mean?: Maybe<Scalars["float8"]>;
  tau_sd?: Maybe<Scalars["float8"]>;
  theta_one_mean?: Maybe<Scalars["float8"]>;
  theta_one_precision?: Maybe<Scalars["float8"]>;
  theta_one_prior?: Maybe<Scalars["float8"]>;
  theta_one_sd?: Maybe<Scalars["float8"]>;
  theta_two_mean?: Maybe<Scalars["float8"]>;
  theta_two_precision?: Maybe<Scalars["float8"]>;
  theta_two_prior?: Maybe<Scalars["float8"]>;
  theta_two_sd?: Maybe<Scalars["float8"]>;
  third_cut?: Maybe<Scalars["date"]>;
  total_house_in_area?: Maybe<Scalars["Int"]>;
  total_inspected_houses?: Maybe<Scalars["Int"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Int"]>;
  total_sprayed_houses?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "experiment" */
export type Experiment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_id?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  first_cut?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  fourth_cut?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  second_cut?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  third_cut?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Experiment_Min_Fields = {
  __typename?: "experiment_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  data_fit_size?: Maybe<Scalars["Int"]>;
  data_pred_size?: Maybe<Scalars["Int"]>;
  experiment_duration?: Maybe<Scalars["float8"]>;
  experiment_threads?: Maybe<Scalars["Int"]>;
  experiment_worker_cpus?: Maybe<Scalars["Int"]>;
  experiment_worker_id?: Maybe<Scalars["String"]>;
  experiment_worker_memory?: Maybe<Scalars["float8"]>;
  first_cut?: Maybe<Scalars["date"]>;
  fixed_intercept_kld?: Maybe<Scalars["float8"]>;
  fixed_intercept_mean?: Maybe<Scalars["float8"]>;
  fixed_intercept_sd?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["float8"]>;
  fourth_cut?: Maybe<Scalars["date"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  grouprho_mean?: Maybe<Scalars["float8"]>;
  grouprho_mode?: Maybe<Scalars["float8"]>;
  grouprho_sd?: Maybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  infestation_probability_mean?: Maybe<Scalars["float8"]>;
  infestation_probability_sd?: Maybe<Scalars["float8"]>;
  intercept_precision?: Maybe<Scalars["float8"]>;
  intercept_prior?: Maybe<Scalars["float8"]>;
  kappa_mean?: Maybe<Scalars["float8"]>;
  kappa_sd?: Maybe<Scalars["float8"]>;
  kind?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  second_cut?: Maybe<Scalars["date"]>;
  tau_mean?: Maybe<Scalars["float8"]>;
  tau_sd?: Maybe<Scalars["float8"]>;
  theta_one_mean?: Maybe<Scalars["float8"]>;
  theta_one_precision?: Maybe<Scalars["float8"]>;
  theta_one_prior?: Maybe<Scalars["float8"]>;
  theta_one_sd?: Maybe<Scalars["float8"]>;
  theta_two_mean?: Maybe<Scalars["float8"]>;
  theta_two_precision?: Maybe<Scalars["float8"]>;
  theta_two_prior?: Maybe<Scalars["float8"]>;
  theta_two_sd?: Maybe<Scalars["float8"]>;
  third_cut?: Maybe<Scalars["date"]>;
  total_house_in_area?: Maybe<Scalars["Int"]>;
  total_inspected_houses?: Maybe<Scalars["Int"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Int"]>;
  total_sprayed_houses?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "experiment" */
export type Experiment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_id?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  first_cut?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  fourth_cut?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  second_cut?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  third_cut?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "experiment" */
export type Experiment_Mutation_Response = {
  __typename?: "experiment_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Experiment>;
};

/** input type for inserting object relation for remote table "experiment" */
export type Experiment_Obj_Rel_Insert_Input = {
  data: Experiment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Experiment_On_Conflict>;
};

/** on_conflict condition type for table "experiment" */
export type Experiment_On_Conflict = {
  constraint: Experiment_Constraint;
  update_columns?: Array<Experiment_Update_Column>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** Ordering options when selecting data from "experiment". */
export type Experiment_Order_By = {
  children_aggregate?: InputMaybe<Experiment_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_start_date?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_id?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  first_cut?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  fourth_cut?: InputMaybe<Order_By>;
  geogroup?: InputMaybe<Geogroup_Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent?: InputMaybe<Experiment_Order_By>;
  parent_id?: InputMaybe<Order_By>;
  second_cut?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  third_cut?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspaces_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Order_By>;
};

/** primary key columns input for table: experiment */
export type Experiment_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "experiment" */
export enum Experiment_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DataFitSize = "data_fit_size",
  /** column name */
  DataPredSize = "data_pred_size",
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentStartDate = "experiment_start_date",
  /** column name */
  ExperimentThreads = "experiment_threads",
  /** column name */
  ExperimentWorkerCpus = "experiment_worker_cpus",
  /** column name */
  ExperimentWorkerId = "experiment_worker_id",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FirstCut = "first_cut",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  FourthCut = "fourth_cut",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  Id = "id",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  Kind = "kind",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  SecondCut = "second_cut",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
  /** column name */
  ThirdCut = "third_cut",
  /** column name */
  TotalHouseInArea = "total_house_in_area",
  /** column name */
  TotalInspectedHouses = "total_inspected_houses",
  /** column name */
  TotalPositiveHousesInArea = "total_positive_houses_in_area",
  /** column name */
  TotalSprayedHouses = "total_sprayed_houses",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "experiment_aggregate_bool_exp_avg_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_corr_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_max_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_min_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_sum_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_var_samp_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** input type for updating data in table "experiment" */
export type Experiment_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data_fit_size?: InputMaybe<Scalars["Int"]>;
  data_pred_size?: InputMaybe<Scalars["Int"]>;
  experiment_duration?: InputMaybe<Scalars["float8"]>;
  experiment_start_date?: InputMaybe<Scalars["time"]>;
  experiment_threads?: InputMaybe<Scalars["Int"]>;
  experiment_worker_cpus?: InputMaybe<Scalars["Int"]>;
  experiment_worker_id?: InputMaybe<Scalars["String"]>;
  experiment_worker_memory?: InputMaybe<Scalars["float8"]>;
  first_cut?: InputMaybe<Scalars["date"]>;
  fixed_intercept_kld?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_mean?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_sd?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: InputMaybe<Scalars["float8"]>;
  fourth_cut?: InputMaybe<Scalars["date"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  grouprho_mean?: InputMaybe<Scalars["float8"]>;
  grouprho_mode?: InputMaybe<Scalars["float8"]>;
  grouprho_sd?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: InputMaybe<Scalars["float8"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  infestation_probability_mean?: InputMaybe<Scalars["float8"]>;
  infestation_probability_sd?: InputMaybe<Scalars["float8"]>;
  intercept_precision?: InputMaybe<Scalars["float8"]>;
  intercept_prior?: InputMaybe<Scalars["float8"]>;
  kappa_mean?: InputMaybe<Scalars["float8"]>;
  kappa_sd?: InputMaybe<Scalars["float8"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  second_cut?: InputMaybe<Scalars["date"]>;
  tau_mean?: InputMaybe<Scalars["float8"]>;
  tau_sd?: InputMaybe<Scalars["float8"]>;
  theta_one_mean?: InputMaybe<Scalars["float8"]>;
  theta_one_precision?: InputMaybe<Scalars["float8"]>;
  theta_one_prior?: InputMaybe<Scalars["float8"]>;
  theta_one_sd?: InputMaybe<Scalars["float8"]>;
  theta_two_mean?: InputMaybe<Scalars["float8"]>;
  theta_two_precision?: InputMaybe<Scalars["float8"]>;
  theta_two_prior?: InputMaybe<Scalars["float8"]>;
  theta_two_sd?: InputMaybe<Scalars["float8"]>;
  third_cut?: InputMaybe<Scalars["date"]>;
  total_house_in_area?: InputMaybe<Scalars["Int"]>;
  total_inspected_houses?: InputMaybe<Scalars["Int"]>;
  total_positive_houses_in_area?: InputMaybe<Scalars["Int"]>;
  total_sprayed_houses?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Experiment_Stddev_Fields = {
  __typename?: "experiment_stddev_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "experiment" */
export type Experiment_Stddev_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Experiment_Stddev_Pop_Fields = {
  __typename?: "experiment_stddev_pop_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "experiment" */
export type Experiment_Stddev_Pop_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Experiment_Stddev_Samp_Fields = {
  __typename?: "experiment_stddev_samp_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "experiment" */
export type Experiment_Stddev_Samp_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "experiment" */
export type Experiment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Experiment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Experiment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data_fit_size?: InputMaybe<Scalars["Int"]>;
  data_pred_size?: InputMaybe<Scalars["Int"]>;
  experiment_duration?: InputMaybe<Scalars["float8"]>;
  experiment_start_date?: InputMaybe<Scalars["time"]>;
  experiment_threads?: InputMaybe<Scalars["Int"]>;
  experiment_worker_cpus?: InputMaybe<Scalars["Int"]>;
  experiment_worker_id?: InputMaybe<Scalars["String"]>;
  experiment_worker_memory?: InputMaybe<Scalars["float8"]>;
  first_cut?: InputMaybe<Scalars["date"]>;
  fixed_intercept_kld?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_mean?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_sd?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: InputMaybe<Scalars["float8"]>;
  fourth_cut?: InputMaybe<Scalars["date"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  grouprho_mean?: InputMaybe<Scalars["float8"]>;
  grouprho_mode?: InputMaybe<Scalars["float8"]>;
  grouprho_sd?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: InputMaybe<Scalars["float8"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  infestation_probability_mean?: InputMaybe<Scalars["float8"]>;
  infestation_probability_sd?: InputMaybe<Scalars["float8"]>;
  intercept_precision?: InputMaybe<Scalars["float8"]>;
  intercept_prior?: InputMaybe<Scalars["float8"]>;
  kappa_mean?: InputMaybe<Scalars["float8"]>;
  kappa_sd?: InputMaybe<Scalars["float8"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  second_cut?: InputMaybe<Scalars["date"]>;
  tau_mean?: InputMaybe<Scalars["float8"]>;
  tau_sd?: InputMaybe<Scalars["float8"]>;
  theta_one_mean?: InputMaybe<Scalars["float8"]>;
  theta_one_precision?: InputMaybe<Scalars["float8"]>;
  theta_one_prior?: InputMaybe<Scalars["float8"]>;
  theta_one_sd?: InputMaybe<Scalars["float8"]>;
  theta_two_mean?: InputMaybe<Scalars["float8"]>;
  theta_two_precision?: InputMaybe<Scalars["float8"]>;
  theta_two_prior?: InputMaybe<Scalars["float8"]>;
  theta_two_sd?: InputMaybe<Scalars["float8"]>;
  third_cut?: InputMaybe<Scalars["date"]>;
  total_house_in_area?: InputMaybe<Scalars["Int"]>;
  total_inspected_houses?: InputMaybe<Scalars["Int"]>;
  total_positive_houses_in_area?: InputMaybe<Scalars["Int"]>;
  total_sprayed_houses?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Experiment_Sum_Fields = {
  __typename?: "experiment_sum_fields";
  data_fit_size?: Maybe<Scalars["Int"]>;
  data_pred_size?: Maybe<Scalars["Int"]>;
  experiment_duration?: Maybe<Scalars["float8"]>;
  experiment_threads?: Maybe<Scalars["Int"]>;
  experiment_worker_cpus?: Maybe<Scalars["Int"]>;
  experiment_worker_memory?: Maybe<Scalars["float8"]>;
  fixed_intercept_kld?: Maybe<Scalars["float8"]>;
  fixed_intercept_mean?: Maybe<Scalars["float8"]>;
  fixed_intercept_sd?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["float8"]>;
  grouprho_mean?: Maybe<Scalars["float8"]>;
  grouprho_mode?: Maybe<Scalars["float8"]>;
  grouprho_sd?: Maybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["float8"]>;
  infestation_probability_mean?: Maybe<Scalars["float8"]>;
  infestation_probability_sd?: Maybe<Scalars["float8"]>;
  intercept_precision?: Maybe<Scalars["float8"]>;
  intercept_prior?: Maybe<Scalars["float8"]>;
  kappa_mean?: Maybe<Scalars["float8"]>;
  kappa_sd?: Maybe<Scalars["float8"]>;
  tau_mean?: Maybe<Scalars["float8"]>;
  tau_sd?: Maybe<Scalars["float8"]>;
  theta_one_mean?: Maybe<Scalars["float8"]>;
  theta_one_precision?: Maybe<Scalars["float8"]>;
  theta_one_prior?: Maybe<Scalars["float8"]>;
  theta_one_sd?: Maybe<Scalars["float8"]>;
  theta_two_mean?: Maybe<Scalars["float8"]>;
  theta_two_precision?: Maybe<Scalars["float8"]>;
  theta_two_prior?: Maybe<Scalars["float8"]>;
  theta_two_sd?: Maybe<Scalars["float8"]>;
  total_house_in_area?: Maybe<Scalars["Int"]>;
  total_inspected_houses?: Maybe<Scalars["Int"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Int"]>;
  total_sprayed_houses?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "experiment" */
export type Experiment_Sum_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** update columns of table "experiment" */
export enum Experiment_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DataFitSize = "data_fit_size",
  /** column name */
  DataPredSize = "data_pred_size",
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentStartDate = "experiment_start_date",
  /** column name */
  ExperimentThreads = "experiment_threads",
  /** column name */
  ExperimentWorkerCpus = "experiment_worker_cpus",
  /** column name */
  ExperimentWorkerId = "experiment_worker_id",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FirstCut = "first_cut",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  FourthCut = "fourth_cut",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  Id = "id",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  Kind = "kind",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  SecondCut = "second_cut",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
  /** column name */
  ThirdCut = "third_cut",
  /** column name */
  TotalHouseInArea = "total_house_in_area",
  /** column name */
  TotalInspectedHouses = "total_inspected_houses",
  /** column name */
  TotalPositiveHousesInArea = "total_positive_houses_in_area",
  /** column name */
  TotalSprayedHouses = "total_sprayed_houses",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Experiment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Experiment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Experiment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Experiment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Experiment_Var_Pop_Fields = {
  __typename?: "experiment_var_pop_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "experiment" */
export type Experiment_Var_Pop_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Experiment_Var_Samp_Fields = {
  __typename?: "experiment_var_samp_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "experiment" */
export type Experiment_Var_Samp_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Experiment_Variance_Fields = {
  __typename?: "experiment_variance_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "experiment" */
export type Experiment_Variance_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["float8"]>;
  _gt?: InputMaybe<Scalars["float8"]>;
  _gte?: InputMaybe<Scalars["float8"]>;
  _in?: InputMaybe<Array<Scalars["float8"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["float8"]>;
  _lte?: InputMaybe<Scalars["float8"]>;
  _neq?: InputMaybe<Scalars["float8"]>;
  _nin?: InputMaybe<Array<Scalars["float8"]>>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** columns and relationships of "geography_columns" */
export type Geography_Columns = {
  __typename?: "geography_columns";
  coord_dimension?: Maybe<Scalars["Int"]>;
  f_geography_column?: Maybe<Scalars["name"]>;
  f_table_catalog?: Maybe<Scalars["name"]>;
  f_table_name?: Maybe<Scalars["name"]>;
  f_table_schema?: Maybe<Scalars["name"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "geography_columns" */
export type Geography_Columns_Aggregate = {
  __typename?: "geography_columns_aggregate";
  aggregate?: Maybe<Geography_Columns_Aggregate_Fields>;
  nodes: Array<Geography_Columns>;
};

/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_Fields = {
  __typename?: "geography_columns_aggregate_fields";
  avg?: Maybe<Geography_Columns_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Geography_Columns_Max_Fields>;
  min?: Maybe<Geography_Columns_Min_Fields>;
  stddev?: Maybe<Geography_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geography_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geography_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geography_Columns_Sum_Fields>;
  var_pop?: Maybe<Geography_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geography_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geography_Columns_Variance_Fields>;
};

/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Geography_Columns_Avg_Fields = {
  __typename?: "geography_columns_avg_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "geography_columns". All fields are combined with a logical 'AND'. */
export type Geography_Columns_Bool_Exp = {
  _and?: InputMaybe<Array<Geography_Columns_Bool_Exp>>;
  _not?: InputMaybe<Geography_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Geography_Columns_Bool_Exp>>;
  coord_dimension?: InputMaybe<Int_Comparison_Exp>;
  f_geography_column?: InputMaybe<Name_Comparison_Exp>;
  f_table_catalog?: InputMaybe<Name_Comparison_Exp>;
  f_table_name?: InputMaybe<Name_Comparison_Exp>;
  f_table_schema?: InputMaybe<Name_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Geography_Columns_Max_Fields = {
  __typename?: "geography_columns_max_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Geography_Columns_Min_Fields = {
  __typename?: "geography_columns_min_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** Ordering options when selecting data from "geography_columns". */
export type Geography_Columns_Order_By = {
  coord_dimension?: InputMaybe<Order_By>;
  f_geography_column?: InputMaybe<Order_By>;
  f_table_catalog?: InputMaybe<Order_By>;
  f_table_name?: InputMaybe<Order_By>;
  f_table_schema?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "geography_columns" */
export enum Geography_Columns_Select_Column {
  /** column name */
  CoordDimension = "coord_dimension",
  /** column name */
  FGeographyColumn = "f_geography_column",
  /** column name */
  FTableCatalog = "f_table_catalog",
  /** column name */
  FTableName = "f_table_name",
  /** column name */
  FTableSchema = "f_table_schema",
  /** column name */
  Srid = "srid",
  /** column name */
  Type = "type",
}

/** aggregate stddev on columns */
export type Geography_Columns_Stddev_Fields = {
  __typename?: "geography_columns_stddev_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Geography_Columns_Stddev_Pop_Fields = {
  __typename?: "geography_columns_stddev_pop_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Geography_Columns_Stddev_Samp_Fields = {
  __typename?: "geography_columns_stddev_samp_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "geography_columns" */
export type Geography_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geography_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geography_Columns_Stream_Cursor_Value_Input = {
  coord_dimension?: InputMaybe<Scalars["Int"]>;
  f_geography_column?: InputMaybe<Scalars["name"]>;
  f_table_catalog?: InputMaybe<Scalars["name"]>;
  f_table_name?: InputMaybe<Scalars["name"]>;
  f_table_schema?: InputMaybe<Scalars["name"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Geography_Columns_Sum_Fields = {
  __typename?: "geography_columns_sum_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  srid?: Maybe<Scalars["Int"]>;
};

/** aggregate var_pop on columns */
export type Geography_Columns_Var_Pop_Fields = {
  __typename?: "geography_columns_var_pop_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Geography_Columns_Var_Samp_Fields = {
  __typename?: "geography_columns_var_samp_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Geography_Columns_Variance_Fields = {
  __typename?: "geography_columns_variance_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars["geography"]>;
  _gt?: InputMaybe<Scalars["geography"]>;
  _gte?: InputMaybe<Scalars["geography"]>;
  _in?: InputMaybe<Array<Scalars["geography"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["geography"]>;
  _lte?: InputMaybe<Scalars["geography"]>;
  _neq?: InputMaybe<Scalars["geography"]>;
  _nin?: InputMaybe<Array<Scalars["geography"]>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars["geography"]>;
};

/** columns and relationships of "geogroup" */
export type Geogroup = {
  __typename?: "geogroup";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["jsonb"]>;
  /** An array relationship */
  experiments: Array<Experiment>;
  /** An aggregate relationship */
  experiments_aggregate: Experiment_Aggregate;
  external_code?: Maybe<Scalars["Int"]>;
  geometry?: Maybe<Scalars["geometry"]>;
  id: Scalars["uuid"];
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  workspace: Array<Workspace>;
  /** An aggregate relationship */
  workspace_aggregate: Workspace_Aggregate;
};

/** columns and relationships of "geogroup" */
export type GeogroupDescriptionArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "geogroup" */
export type GeogroupExperimentsArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** columns and relationships of "geogroup" */
export type GeogroupExperiments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** columns and relationships of "geogroup" */
export type GeogroupWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** columns and relationships of "geogroup" */
export type GeogroupWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** aggregated selection of "geogroup" */
export type Geogroup_Aggregate = {
  __typename?: "geogroup_aggregate";
  aggregate?: Maybe<Geogroup_Aggregate_Fields>;
  nodes: Array<Geogroup>;
};

export type Geogroup_Aggregate_Bool_Exp = {
  count?: InputMaybe<Geogroup_Aggregate_Bool_Exp_Count>;
};

export type Geogroup_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Geogroup_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Geogroup_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "geogroup" */
export type Geogroup_Aggregate_Fields = {
  __typename?: "geogroup_aggregate_fields";
  avg?: Maybe<Geogroup_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Geogroup_Max_Fields>;
  min?: Maybe<Geogroup_Min_Fields>;
  stddev?: Maybe<Geogroup_Stddev_Fields>;
  stddev_pop?: Maybe<Geogroup_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geogroup_Stddev_Samp_Fields>;
  sum?: Maybe<Geogroup_Sum_Fields>;
  var_pop?: Maybe<Geogroup_Var_Pop_Fields>;
  var_samp?: Maybe<Geogroup_Var_Samp_Fields>;
  variance?: Maybe<Geogroup_Variance_Fields>;
};

/** aggregate fields of "geogroup" */
export type Geogroup_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geogroup_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "geogroup" */
export type Geogroup_Aggregate_Order_By = {
  avg?: InputMaybe<Geogroup_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Geogroup_Max_Order_By>;
  min?: InputMaybe<Geogroup_Min_Order_By>;
  stddev?: InputMaybe<Geogroup_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Geogroup_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Geogroup_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Geogroup_Sum_Order_By>;
  var_pop?: InputMaybe<Geogroup_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Geogroup_Var_Samp_Order_By>;
  variance?: InputMaybe<Geogroup_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Geogroup_Append_Input = {
  description?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "geogroup" */
export type Geogroup_Arr_Rel_Insert_Input = {
  data: Array<Geogroup_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Geogroup_On_Conflict>;
};

/** aggregate avg on columns */
export type Geogroup_Avg_Fields = {
  __typename?: "geogroup_avg_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "geogroup" */
export type Geogroup_Avg_Order_By = {
  external_code?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "geogroup". All fields are combined with a logical 'AND'. */
export type Geogroup_Bool_Exp = {
  _and?: InputMaybe<Array<Geogroup_Bool_Exp>>;
  _not?: InputMaybe<Geogroup_Bool_Exp>;
  _or?: InputMaybe<Array<Geogroup_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  experiments?: InputMaybe<Experiment_Bool_Exp>;
  experiments_aggregate?: InputMaybe<Experiment_Aggregate_Bool_Exp>;
  external_code?: InputMaybe<Int_Comparison_Exp>;
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_aggregate?: InputMaybe<Workspace_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "geogroup" */
export enum Geogroup_Constraint {
  /** unique or primary key constraint on columns "name" */
  GeogroupNameKey = "geogroup_name_key",
  /** unique or primary key constraint on columns "id" */
  GeogroupPkId = "geogroup_pk_id",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Geogroup_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Geogroup_Delete_Elem_Input = {
  description?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Geogroup_Delete_Key_Input = {
  description?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "geogroup" */
export type Geogroup_Inc_Input = {
  external_code?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "geogroup" */
export type Geogroup_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["jsonb"]>;
  experiments?: InputMaybe<Experiment_Arr_Rel_Insert_Input>;
  external_code?: InputMaybe<Scalars["Int"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Geogroup_Max_Fields = {
  __typename?: "geogroup_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_code?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "geogroup" */
export type Geogroup_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Geogroup_Min_Fields = {
  __typename?: "geogroup_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_code?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "geogroup" */
export type Geogroup_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "geogroup" */
export type Geogroup_Mutation_Response = {
  __typename?: "geogroup_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Geogroup>;
};

/** input type for inserting object relation for remote table "geogroup" */
export type Geogroup_Obj_Rel_Insert_Input = {
  data: Geogroup_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Geogroup_On_Conflict>;
};

/** on_conflict condition type for table "geogroup" */
export type Geogroup_On_Conflict = {
  constraint: Geogroup_Constraint;
  update_columns?: Array<Geogroup_Update_Column>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

/** Ordering options when selecting data from "geogroup". */
export type Geogroup_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  experiments_aggregate?: InputMaybe<Experiment_Aggregate_Order_By>;
  external_code?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_aggregate?: InputMaybe<Workspace_Aggregate_Order_By>;
};

/** primary key columns input for table: geogroup */
export type Geogroup_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Geogroup_Prepend_Input = {
  description?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "geogroup" */
export enum Geogroup_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  ExternalCode = "external_code",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "geogroup" */
export type Geogroup_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["jsonb"]>;
  external_code?: InputMaybe<Scalars["Int"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Geogroup_Stddev_Fields = {
  __typename?: "geogroup_stddev_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "geogroup" */
export type Geogroup_Stddev_Order_By = {
  external_code?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Geogroup_Stddev_Pop_Fields = {
  __typename?: "geogroup_stddev_pop_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "geogroup" */
export type Geogroup_Stddev_Pop_Order_By = {
  external_code?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Geogroup_Stddev_Samp_Fields = {
  __typename?: "geogroup_stddev_samp_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "geogroup" */
export type Geogroup_Stddev_Samp_Order_By = {
  external_code?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "geogroup" */
export type Geogroup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geogroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geogroup_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["jsonb"]>;
  external_code?: InputMaybe<Scalars["Int"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Geogroup_Sum_Fields = {
  __typename?: "geogroup_sum_fields";
  external_code?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "geogroup" */
export type Geogroup_Sum_Order_By = {
  external_code?: InputMaybe<Order_By>;
};

/** update columns of table "geogroup" */
export enum Geogroup_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  ExternalCode = "external_code",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Geogroup_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Geogroup_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Geogroup_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Geogroup_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Geogroup_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Geogroup_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Geogroup_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Geogroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: Geogroup_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Geogroup_Var_Pop_Fields = {
  __typename?: "geogroup_var_pop_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "geogroup" */
export type Geogroup_Var_Pop_Order_By = {
  external_code?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Geogroup_Var_Samp_Fields = {
  __typename?: "geogroup_var_samp_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "geogroup" */
export type Geogroup_Var_Samp_Order_By = {
  external_code?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Geogroup_Variance_Fields = {
  __typename?: "geogroup_variance_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "geogroup" */
export type Geogroup_Variance_Order_By = {
  external_code?: InputMaybe<Order_By>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** columns and relationships of "geometry_columns" */
export type Geometry_Columns = {
  __typename?: "geometry_columns";
  coord_dimension?: Maybe<Scalars["Int"]>;
  f_geometry_column?: Maybe<Scalars["name"]>;
  f_table_catalog?: Maybe<Scalars["String"]>;
  f_table_name?: Maybe<Scalars["name"]>;
  f_table_schema?: Maybe<Scalars["name"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "geometry_columns" */
export type Geometry_Columns_Aggregate = {
  __typename?: "geometry_columns_aggregate";
  aggregate?: Maybe<Geometry_Columns_Aggregate_Fields>;
  nodes: Array<Geometry_Columns>;
};

/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_Fields = {
  __typename?: "geometry_columns_aggregate_fields";
  avg?: Maybe<Geometry_Columns_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Geometry_Columns_Max_Fields>;
  min?: Maybe<Geometry_Columns_Min_Fields>;
  stddev?: Maybe<Geometry_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geometry_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geometry_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geometry_Columns_Sum_Fields>;
  var_pop?: Maybe<Geometry_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geometry_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geometry_Columns_Variance_Fields>;
};

/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Geometry_Columns_Avg_Fields = {
  __typename?: "geometry_columns_avg_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "geometry_columns". All fields are combined with a logical 'AND'. */
export type Geometry_Columns_Bool_Exp = {
  _and?: InputMaybe<Array<Geometry_Columns_Bool_Exp>>;
  _not?: InputMaybe<Geometry_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Geometry_Columns_Bool_Exp>>;
  coord_dimension?: InputMaybe<Int_Comparison_Exp>;
  f_geometry_column?: InputMaybe<Name_Comparison_Exp>;
  f_table_catalog?: InputMaybe<String_Comparison_Exp>;
  f_table_name?: InputMaybe<Name_Comparison_Exp>;
  f_table_schema?: InputMaybe<Name_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "geometry_columns" */
export type Geometry_Columns_Inc_Input = {
  coord_dimension?: InputMaybe<Scalars["Int"]>;
  srid?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "geometry_columns" */
export type Geometry_Columns_Insert_Input = {
  coord_dimension?: InputMaybe<Scalars["Int"]>;
  f_geometry_column?: InputMaybe<Scalars["name"]>;
  f_table_catalog?: InputMaybe<Scalars["String"]>;
  f_table_name?: InputMaybe<Scalars["name"]>;
  f_table_schema?: InputMaybe<Scalars["name"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Geometry_Columns_Max_Fields = {
  __typename?: "geometry_columns_max_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  f_table_catalog?: Maybe<Scalars["String"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Geometry_Columns_Min_Fields = {
  __typename?: "geometry_columns_min_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  f_table_catalog?: Maybe<Scalars["String"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "geometry_columns" */
export type Geometry_Columns_Mutation_Response = {
  __typename?: "geometry_columns_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Geometry_Columns>;
};

/** Ordering options when selecting data from "geometry_columns". */
export type Geometry_Columns_Order_By = {
  coord_dimension?: InputMaybe<Order_By>;
  f_geometry_column?: InputMaybe<Order_By>;
  f_table_catalog?: InputMaybe<Order_By>;
  f_table_name?: InputMaybe<Order_By>;
  f_table_schema?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "geometry_columns" */
export enum Geometry_Columns_Select_Column {
  /** column name */
  CoordDimension = "coord_dimension",
  /** column name */
  FGeometryColumn = "f_geometry_column",
  /** column name */
  FTableCatalog = "f_table_catalog",
  /** column name */
  FTableName = "f_table_name",
  /** column name */
  FTableSchema = "f_table_schema",
  /** column name */
  Srid = "srid",
  /** column name */
  Type = "type",
}

/** input type for updating data in table "geometry_columns" */
export type Geometry_Columns_Set_Input = {
  coord_dimension?: InputMaybe<Scalars["Int"]>;
  f_geometry_column?: InputMaybe<Scalars["name"]>;
  f_table_catalog?: InputMaybe<Scalars["String"]>;
  f_table_name?: InputMaybe<Scalars["name"]>;
  f_table_schema?: InputMaybe<Scalars["name"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Geometry_Columns_Stddev_Fields = {
  __typename?: "geometry_columns_stddev_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Geometry_Columns_Stddev_Pop_Fields = {
  __typename?: "geometry_columns_stddev_pop_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Geometry_Columns_Stddev_Samp_Fields = {
  __typename?: "geometry_columns_stddev_samp_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "geometry_columns" */
export type Geometry_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geometry_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geometry_Columns_Stream_Cursor_Value_Input = {
  coord_dimension?: InputMaybe<Scalars["Int"]>;
  f_geometry_column?: InputMaybe<Scalars["name"]>;
  f_table_catalog?: InputMaybe<Scalars["String"]>;
  f_table_name?: InputMaybe<Scalars["name"]>;
  f_table_schema?: InputMaybe<Scalars["name"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Geometry_Columns_Sum_Fields = {
  __typename?: "geometry_columns_sum_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  srid?: Maybe<Scalars["Int"]>;
};

export type Geometry_Columns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Geometry_Columns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Geometry_Columns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Geometry_Columns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Geometry_Columns_Var_Pop_Fields = {
  __typename?: "geometry_columns_var_pop_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Geometry_Columns_Var_Samp_Fields = {
  __typename?: "geometry_columns_var_samp_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Geometry_Columns_Variance_Fields = {
  __typename?: "geometry_columns_variance_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars["geometry"]>;
  _gt?: InputMaybe<Scalars["geometry"]>;
  _gte?: InputMaybe<Scalars["geometry"]>;
  _in?: InputMaybe<Array<Scalars["geometry"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["geometry"]>;
  _lte?: InputMaybe<Scalars["geometry"]>;
  _neq?: InputMaybe<Scalars["geometry"]>;
  _nin?: InputMaybe<Array<Scalars["geometry"]>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars["geometry"]>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars["geometry"]>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars["geometry"]>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars["geometry"]>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars["geometry"]>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars["geometry"]>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars["geometry"]>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars["geometry"]>;
};

/** columns and relationships of "geopoint" */
export type Geopoint = {
  __typename?: "geopoint";
  created_at?: Maybe<Scalars["timestamptz"]>;
  geometry?: Maybe<Scalars["geometry"]>;
  id: Scalars["uuid"];
  lat: Scalars["float8"];
  lng: Scalars["float8"];
  unicode?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Geopoint_Aggregate = {
  __typename?: "geopoint_aggregate";
  aggregate?: Maybe<Geopoint_Aggregate_Fields>;
  nodes: Array<Geopoint>;
};

/** aggregate fields of "geopoint" */
export type Geopoint_Aggregate_Fields = {
  __typename?: "geopoint_aggregate_fields";
  avg?: Maybe<Geopoint_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Geopoint_Max_Fields>;
  min?: Maybe<Geopoint_Min_Fields>;
  stddev?: Maybe<Geopoint_Stddev_Fields>;
  stddev_pop?: Maybe<Geopoint_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geopoint_Stddev_Samp_Fields>;
  sum?: Maybe<Geopoint_Sum_Fields>;
  var_pop?: Maybe<Geopoint_Var_Pop_Fields>;
  var_samp?: Maybe<Geopoint_Var_Samp_Fields>;
  variance?: Maybe<Geopoint_Variance_Fields>;
};

/** aggregate fields of "geopoint" */
export type Geopoint_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geopoint_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Geopoint_Avg_Fields = {
  __typename?: "geopoint_avg_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "geopoint". All fields are combined with a logical 'AND'. */
export type Geopoint_Bool_Exp = {
  _and?: InputMaybe<Array<Geopoint_Bool_Exp>>;
  _not?: InputMaybe<Geopoint_Bool_Exp>;
  _or?: InputMaybe<Array<Geopoint_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lat?: InputMaybe<Float8_Comparison_Exp>;
  lng?: InputMaybe<Float8_Comparison_Exp>;
  unicode?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "geopoint" */
export enum Geopoint_Constraint {
  /** unique or primary key constraint on columns "id" */
  GeopointPkey = "geopoint_pkey",
}

/** input type for incrementing numeric columns in table "geopoint" */
export type Geopoint_Inc_Input = {
  lat?: InputMaybe<Scalars["float8"]>;
  lng?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "geopoint" */
export type Geopoint_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  lat?: InputMaybe<Scalars["float8"]>;
  lng?: InputMaybe<Scalars["float8"]>;
  unicode?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Geopoint_Max_Fields = {
  __typename?: "geopoint_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  lat?: Maybe<Scalars["float8"]>;
  lng?: Maybe<Scalars["float8"]>;
  unicode?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Geopoint_Min_Fields = {
  __typename?: "geopoint_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  lat?: Maybe<Scalars["float8"]>;
  lng?: Maybe<Scalars["float8"]>;
  unicode?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "geopoint" */
export type Geopoint_Mutation_Response = {
  __typename?: "geopoint_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Geopoint>;
};

/** input type for inserting object relation for remote table "geopoint" */
export type Geopoint_Obj_Rel_Insert_Input = {
  data: Geopoint_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Geopoint_On_Conflict>;
};

/** on_conflict condition type for table "geopoint" */
export type Geopoint_On_Conflict = {
  constraint: Geopoint_Constraint;
  update_columns?: Array<Geopoint_Update_Column>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

/** Ordering options when selecting data from "geopoint". */
export type Geopoint_Order_By = {
  created_at?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  unicode?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: geopoint */
export type Geopoint_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "geopoint" */
export enum Geopoint_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Lat = "lat",
  /** column name */
  Lng = "lng",
  /** column name */
  Unicode = "unicode",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "geopoint" */
export type Geopoint_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  lat?: InputMaybe<Scalars["float8"]>;
  lng?: InputMaybe<Scalars["float8"]>;
  unicode?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Geopoint_Stddev_Fields = {
  __typename?: "geopoint_stddev_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Geopoint_Stddev_Pop_Fields = {
  __typename?: "geopoint_stddev_pop_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Geopoint_Stddev_Samp_Fields = {
  __typename?: "geopoint_stddev_samp_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "geopoint" */
export type Geopoint_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geopoint_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geopoint_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  lat?: InputMaybe<Scalars["float8"]>;
  lng?: InputMaybe<Scalars["float8"]>;
  unicode?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Geopoint_Sum_Fields = {
  __typename?: "geopoint_sum_fields";
  lat?: Maybe<Scalars["float8"]>;
  lng?: Maybe<Scalars["float8"]>;
};

/** update columns of table "geopoint" */
export enum Geopoint_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Lat = "lat",
  /** column name */
  Lng = "lng",
  /** column name */
  Unicode = "unicode",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Geopoint_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Geopoint_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Geopoint_Set_Input>;
  /** filter the rows which have to be updated */
  where: Geopoint_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Geopoint_Var_Pop_Fields = {
  __typename?: "geopoint_var_pop_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Geopoint_Var_Samp_Fields = {
  __typename?: "geopoint_var_samp_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Geopoint_Variance_Fields = {
  __typename?: "geopoint_variance_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "inspection" */
export type Inspection = {
  __typename?: "inspection";
  app_state: Scalars["String"];
  associated_phone?: Maybe<Scalars["String"]>;
  cats_number?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  collaborator?: Maybe<Collaborator>;
  collaborator_id: Scalars["uuid"];
  /** An object relationship */
  complaint?: Maybe<Complaint>;
  created_at: Scalars["timestamptz"];
  dogs_number?: Maybe<Scalars["Int"]>;
  estate_kind: Estate_Kind_Enum;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  from_complaint?: Maybe<Scalars["uuid"]>;
  from_radio?: Maybe<Scalars["Boolean"]>;
  geogroup?: Maybe<Scalars["uuid"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  geopoint?: Maybe<Geopoint>;
  geopoint_id: Scalars["uuid"];
  geopoint_unicode?: Maybe<Scalars["String"]>;
  guinea_pigs_number?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  inspection_interview_kind?: Maybe<Inspection_Interview_Kind_Enum>;
  inspection_intra_event?: Maybe<Scalars["jsonb"]>;
  inspection_peri_event?: Maybe<Scalars["jsonb"]>;
  inspection_reluctant_kind?: Maybe<Inspection_Reluctant_Kind_Enum>;
  inspection_reluctant_other?: Maybe<Scalars["String"]>;
  inspection_return_expected_date?: Maybe<Scalars["timestamptz"]>;
  inspection_return_reason?: Maybe<Scalars["String"]>;
  issued_at?: Maybe<Scalars["timestamptz"]>;
  observations?: Maybe<Scalars["jsonb"]>;
  origin_from_complaint?: Maybe<Scalars["String"]>;
  other_animal_1_name?: Maybe<Scalars["String"]>;
  other_animal_1_number?: Maybe<Scalars["Int"]>;
  other_animal_2_name?: Maybe<Scalars["String"]>;
  other_animal_2_number?: Maybe<Scalars["Int"]>;
  other_animal_3_name?: Maybe<Scalars["String"]>;
  other_animal_3_number?: Maybe<Scalars["Int"]>;
  other_animal_4_name?: Maybe<Scalars["String"]>;
  other_animal_4_number?: Maybe<Scalars["Int"]>;
  other_animal_5_name?: Maybe<Scalars["String"]>;
  other_animal_5_number?: Maybe<Scalars["Int"]>;
  other_animal_name?: Maybe<Scalars["String"]>;
  other_animal_number?: Maybe<Scalars["Int"]>;
  people_number?: Maybe<Scalars["Int"]>;
  poultry_number?: Maybe<Scalars["Int"]>;
  property_characteristics?: Maybe<Scalars["String"]>;
  public_local_kind?: Maybe<Scalars["String"]>;
  rabbits_number?: Maybe<Scalars["Int"]>;
  radial_consequence?: Maybe<Scalars["String"]>;
  state?: Maybe<Inspection_State_Enum>;
  unicode_from_radio?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  workspace_metadata?: Maybe<Workspace_Metadata>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "inspection" */
export type InspectionInspection_Intra_EventArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "inspection" */
export type InspectionInspection_Peri_EventArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "inspection" */
export type InspectionObservationsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "inspection" */
export type Inspection_Aggregate = {
  __typename?: "inspection_aggregate";
  aggregate?: Maybe<Inspection_Aggregate_Fields>;
  nodes: Array<Inspection>;
};

export type Inspection_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Inspection_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Inspection_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Inspection_Aggregate_Bool_Exp_Count>;
};

export type Inspection_Aggregate_Bool_Exp_Bool_And = {
  arguments: Inspection_Select_Column_Inspection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Inspection_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inspection_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Inspection_Select_Column_Inspection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Inspection_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inspection_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Inspection_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Inspection_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inspection" */
export type Inspection_Aggregate_Fields = {
  __typename?: "inspection_aggregate_fields";
  avg?: Maybe<Inspection_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Inspection_Max_Fields>;
  min?: Maybe<Inspection_Min_Fields>;
  stddev?: Maybe<Inspection_Stddev_Fields>;
  stddev_pop?: Maybe<Inspection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inspection_Stddev_Samp_Fields>;
  sum?: Maybe<Inspection_Sum_Fields>;
  var_pop?: Maybe<Inspection_Var_Pop_Fields>;
  var_samp?: Maybe<Inspection_Var_Samp_Fields>;
  variance?: Maybe<Inspection_Variance_Fields>;
};

/** aggregate fields of "inspection" */
export type Inspection_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inspection_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inspection" */
export type Inspection_Aggregate_Order_By = {
  avg?: InputMaybe<Inspection_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Inspection_Max_Order_By>;
  min?: InputMaybe<Inspection_Min_Order_By>;
  stddev?: InputMaybe<Inspection_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Inspection_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Inspection_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Inspection_Sum_Order_By>;
  var_pop?: InputMaybe<Inspection_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Inspection_Var_Samp_Order_By>;
  variance?: InputMaybe<Inspection_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Inspection_Append_Input = {
  inspection_intra_event?: InputMaybe<Scalars["jsonb"]>;
  inspection_peri_event?: InputMaybe<Scalars["jsonb"]>;
  observations?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "inspection" */
export type Inspection_Arr_Rel_Insert_Input = {
  data: Array<Inspection_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Inspection_On_Conflict>;
};

/** aggregate avg on columns */
export type Inspection_Avg_Fields = {
  __typename?: "inspection_avg_fields";
  cats_number?: Maybe<Scalars["Float"]>;
  dogs_number?: Maybe<Scalars["Float"]>;
  guinea_pigs_number?: Maybe<Scalars["Float"]>;
  other_animal_1_number?: Maybe<Scalars["Float"]>;
  other_animal_2_number?: Maybe<Scalars["Float"]>;
  other_animal_3_number?: Maybe<Scalars["Float"]>;
  other_animal_4_number?: Maybe<Scalars["Float"]>;
  other_animal_5_number?: Maybe<Scalars["Float"]>;
  other_animal_number?: Maybe<Scalars["Float"]>;
  people_number?: Maybe<Scalars["Float"]>;
  poultry_number?: Maybe<Scalars["Float"]>;
  rabbits_number?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inspection" */
export type Inspection_Avg_Order_By = {
  cats_number?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inspection". All fields are combined with a logical 'AND'. */
export type Inspection_Bool_Exp = {
  _and?: InputMaybe<Array<Inspection_Bool_Exp>>;
  _not?: InputMaybe<Inspection_Bool_Exp>;
  _or?: InputMaybe<Array<Inspection_Bool_Exp>>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  associated_phone?: InputMaybe<String_Comparison_Exp>;
  cats_number?: InputMaybe<Int_Comparison_Exp>;
  collaborator?: InputMaybe<Collaborator_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  complaint?: InputMaybe<Complaint_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dogs_number?: InputMaybe<Int_Comparison_Exp>;
  estate_kind?: InputMaybe<Estate_Kind_Enum_Comparison_Exp>;
  experiment_id?: InputMaybe<Uuid_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  from_complaint?: InputMaybe<Uuid_Comparison_Exp>;
  from_radio?: InputMaybe<Boolean_Comparison_Exp>;
  geogroup?: InputMaybe<Uuid_Comparison_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint?: InputMaybe<Geopoint_Bool_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint_unicode?: InputMaybe<String_Comparison_Exp>;
  guinea_pigs_number?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inspection_interview_kind?: InputMaybe<Inspection_Interview_Kind_Enum_Comparison_Exp>;
  inspection_intra_event?: InputMaybe<Jsonb_Comparison_Exp>;
  inspection_peri_event?: InputMaybe<Jsonb_Comparison_Exp>;
  inspection_reluctant_kind?: InputMaybe<Inspection_Reluctant_Kind_Enum_Comparison_Exp>;
  inspection_reluctant_other?: InputMaybe<String_Comparison_Exp>;
  inspection_return_expected_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  inspection_return_reason?: InputMaybe<String_Comparison_Exp>;
  issued_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  observations?: InputMaybe<Jsonb_Comparison_Exp>;
  origin_from_complaint?: InputMaybe<String_Comparison_Exp>;
  other_animal_1_name?: InputMaybe<String_Comparison_Exp>;
  other_animal_1_number?: InputMaybe<Int_Comparison_Exp>;
  other_animal_2_name?: InputMaybe<String_Comparison_Exp>;
  other_animal_2_number?: InputMaybe<Int_Comparison_Exp>;
  other_animal_3_name?: InputMaybe<String_Comparison_Exp>;
  other_animal_3_number?: InputMaybe<Int_Comparison_Exp>;
  other_animal_4_name?: InputMaybe<String_Comparison_Exp>;
  other_animal_4_number?: InputMaybe<Int_Comparison_Exp>;
  other_animal_5_name?: InputMaybe<String_Comparison_Exp>;
  other_animal_5_number?: InputMaybe<Int_Comparison_Exp>;
  other_animal_name?: InputMaybe<String_Comparison_Exp>;
  other_animal_number?: InputMaybe<Int_Comparison_Exp>;
  people_number?: InputMaybe<Int_Comparison_Exp>;
  poultry_number?: InputMaybe<Int_Comparison_Exp>;
  property_characteristics?: InputMaybe<String_Comparison_Exp>;
  public_local_kind?: InputMaybe<String_Comparison_Exp>;
  rabbits_number?: InputMaybe<Int_Comparison_Exp>;
  radial_consequence?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<Inspection_State_Enum_Comparison_Exp>;
  unicode_from_radio?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  workspace_metadata_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "inspection" */
export enum Inspection_Constraint {
  /** unique or primary key constraint on columns "id" */
  InspectionPkey = "inspection_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Inspection_Delete_At_Path_Input = {
  inspection_intra_event?: InputMaybe<Array<Scalars["String"]>>;
  inspection_peri_event?: InputMaybe<Array<Scalars["String"]>>;
  observations?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Inspection_Delete_Elem_Input = {
  inspection_intra_event?: InputMaybe<Scalars["Int"]>;
  inspection_peri_event?: InputMaybe<Scalars["Int"]>;
  observations?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Inspection_Delete_Key_Input = {
  inspection_intra_event?: InputMaybe<Scalars["String"]>;
  inspection_peri_event?: InputMaybe<Scalars["String"]>;
  observations?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "inspection_event_kind" */
export type Inspection_Event_Kind = {
  __typename?: "inspection_event_kind";
  comment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "inspection_event_kind" */
export type Inspection_Event_Kind_Aggregate = {
  __typename?: "inspection_event_kind_aggregate";
  aggregate?: Maybe<Inspection_Event_Kind_Aggregate_Fields>;
  nodes: Array<Inspection_Event_Kind>;
};

/** aggregate fields of "inspection_event_kind" */
export type Inspection_Event_Kind_Aggregate_Fields = {
  __typename?: "inspection_event_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Inspection_Event_Kind_Max_Fields>;
  min?: Maybe<Inspection_Event_Kind_Min_Fields>;
};

/** aggregate fields of "inspection_event_kind" */
export type Inspection_Event_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inspection_Event_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "inspection_event_kind". All fields are combined with a logical 'AND'. */
export type Inspection_Event_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Inspection_Event_Kind_Bool_Exp>>;
  _not?: InputMaybe<Inspection_Event_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Inspection_Event_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inspection_event_kind" */
export enum Inspection_Event_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  InspectionEventKindPkey = "inspection_event_kind_pkey",
}

/** input type for inserting data into table "inspection_event_kind" */
export type Inspection_Event_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inspection_Event_Kind_Max_Fields = {
  __typename?: "inspection_event_kind_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Inspection_Event_Kind_Min_Fields = {
  __typename?: "inspection_event_kind_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "inspection_event_kind" */
export type Inspection_Event_Kind_Mutation_Response = {
  __typename?: "inspection_event_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inspection_Event_Kind>;
};

/** on_conflict condition type for table "inspection_event_kind" */
export type Inspection_Event_Kind_On_Conflict = {
  constraint: Inspection_Event_Kind_Constraint;
  update_columns?: Array<Inspection_Event_Kind_Update_Column>;
  where?: InputMaybe<Inspection_Event_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "inspection_event_kind". */
export type Inspection_Event_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inspection_event_kind */
export type Inspection_Event_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "inspection_event_kind" */
export enum Inspection_Event_Kind_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "inspection_event_kind" */
export type Inspection_Event_Kind_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "inspection_event_kind" */
export type Inspection_Event_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inspection_Event_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inspection_Event_Kind_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "inspection_event_kind" */
export enum Inspection_Event_Kind_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Inspection_Event_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inspection_Event_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inspection_Event_Kind_Bool_Exp;
};

/** input type for incrementing numeric columns in table "inspection" */
export type Inspection_Inc_Input = {
  cats_number?: InputMaybe<Scalars["Int"]>;
  dogs_number?: InputMaybe<Scalars["Int"]>;
  guinea_pigs_number?: InputMaybe<Scalars["Int"]>;
  other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  other_animal_number?: InputMaybe<Scalars["Int"]>;
  people_number?: InputMaybe<Scalars["Int"]>;
  poultry_number?: InputMaybe<Scalars["Int"]>;
  rabbits_number?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inspection" */
export type Inspection_Insert_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  associated_phone?: InputMaybe<Scalars["String"]>;
  cats_number?: InputMaybe<Scalars["Int"]>;
  collaborator?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complaint?: InputMaybe<Complaint_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dogs_number?: InputMaybe<Scalars["Int"]>;
  estate_kind?: InputMaybe<Estate_Kind_Enum>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  from_complaint?: InputMaybe<Scalars["uuid"]>;
  from_radio?: InputMaybe<Scalars["Boolean"]>;
  geogroup?: InputMaybe<Scalars["uuid"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  guinea_pigs_number?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  inspection_interview_kind?: InputMaybe<Inspection_Interview_Kind_Enum>;
  inspection_intra_event?: InputMaybe<Scalars["jsonb"]>;
  inspection_peri_event?: InputMaybe<Scalars["jsonb"]>;
  inspection_reluctant_kind?: InputMaybe<Inspection_Reluctant_Kind_Enum>;
  inspection_reluctant_other?: InputMaybe<Scalars["String"]>;
  inspection_return_expected_date?: InputMaybe<Scalars["timestamptz"]>;
  inspection_return_reason?: InputMaybe<Scalars["String"]>;
  issued_at?: InputMaybe<Scalars["timestamptz"]>;
  observations?: InputMaybe<Scalars["jsonb"]>;
  origin_from_complaint?: InputMaybe<Scalars["String"]>;
  other_animal_1_name?: InputMaybe<Scalars["String"]>;
  other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  other_animal_2_name?: InputMaybe<Scalars["String"]>;
  other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  other_animal_3_name?: InputMaybe<Scalars["String"]>;
  other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  other_animal_4_name?: InputMaybe<Scalars["String"]>;
  other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  other_animal_5_name?: InputMaybe<Scalars["String"]>;
  other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  other_animal_name?: InputMaybe<Scalars["String"]>;
  other_animal_number?: InputMaybe<Scalars["Int"]>;
  people_number?: InputMaybe<Scalars["Int"]>;
  poultry_number?: InputMaybe<Scalars["Int"]>;
  property_characteristics?: InputMaybe<Scalars["String"]>;
  public_local_kind?: InputMaybe<Scalars["String"]>;
  rabbits_number?: InputMaybe<Scalars["Int"]>;
  radial_consequence?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Inspection_State_Enum>;
  unicode_from_radio?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Obj_Rel_Insert_Input>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
};

/** columns and relationships of "inspection_interview_kind" */
export type Inspection_Interview_Kind = {
  __typename?: "inspection_interview_kind";
  comment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "inspection_interview_kind" */
export type Inspection_Interview_Kind_Aggregate = {
  __typename?: "inspection_interview_kind_aggregate";
  aggregate?: Maybe<Inspection_Interview_Kind_Aggregate_Fields>;
  nodes: Array<Inspection_Interview_Kind>;
};

/** aggregate fields of "inspection_interview_kind" */
export type Inspection_Interview_Kind_Aggregate_Fields = {
  __typename?: "inspection_interview_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Inspection_Interview_Kind_Max_Fields>;
  min?: Maybe<Inspection_Interview_Kind_Min_Fields>;
};

/** aggregate fields of "inspection_interview_kind" */
export type Inspection_Interview_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inspection_Interview_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "inspection_interview_kind". All fields are combined with a logical 'AND'. */
export type Inspection_Interview_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Inspection_Interview_Kind_Bool_Exp>>;
  _not?: InputMaybe<Inspection_Interview_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Inspection_Interview_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inspection_interview_kind" */
export enum Inspection_Interview_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  InspectionInterviewKindPkey = "inspection_interview_kind_pkey",
}

export enum Inspection_Interview_Kind_Enum {
  DontKnow = "dont_know",
  No = "no",
  Yes = "yes",
}

/** Boolean expression to compare columns of type "inspection_interview_kind_enum". All fields are combined with logical 'AND'. */
export type Inspection_Interview_Kind_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Inspection_Interview_Kind_Enum>;
  _in?: InputMaybe<Array<Inspection_Interview_Kind_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Inspection_Interview_Kind_Enum>;
  _nin?: InputMaybe<Array<Inspection_Interview_Kind_Enum>>;
};

/** input type for inserting data into table "inspection_interview_kind" */
export type Inspection_Interview_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inspection_Interview_Kind_Max_Fields = {
  __typename?: "inspection_interview_kind_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Inspection_Interview_Kind_Min_Fields = {
  __typename?: "inspection_interview_kind_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "inspection_interview_kind" */
export type Inspection_Interview_Kind_Mutation_Response = {
  __typename?: "inspection_interview_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inspection_Interview_Kind>;
};

/** on_conflict condition type for table "inspection_interview_kind" */
export type Inspection_Interview_Kind_On_Conflict = {
  constraint: Inspection_Interview_Kind_Constraint;
  update_columns?: Array<Inspection_Interview_Kind_Update_Column>;
  where?: InputMaybe<Inspection_Interview_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "inspection_interview_kind". */
export type Inspection_Interview_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inspection_interview_kind */
export type Inspection_Interview_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "inspection_interview_kind" */
export enum Inspection_Interview_Kind_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "inspection_interview_kind" */
export type Inspection_Interview_Kind_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "inspection_interview_kind" */
export type Inspection_Interview_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inspection_Interview_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inspection_Interview_Kind_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "inspection_interview_kind" */
export enum Inspection_Interview_Kind_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Inspection_Interview_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inspection_Interview_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inspection_Interview_Kind_Bool_Exp;
};

/** aggregate max on columns */
export type Inspection_Max_Fields = {
  __typename?: "inspection_max_fields";
  app_state?: Maybe<Scalars["String"]>;
  associated_phone?: Maybe<Scalars["String"]>;
  cats_number?: Maybe<Scalars["Int"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dogs_number?: Maybe<Scalars["Int"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  from_complaint?: Maybe<Scalars["uuid"]>;
  geogroup?: Maybe<Scalars["uuid"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  guinea_pigs_number?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  inspection_reluctant_other?: Maybe<Scalars["String"]>;
  inspection_return_expected_date?: Maybe<Scalars["timestamptz"]>;
  inspection_return_reason?: Maybe<Scalars["String"]>;
  issued_at?: Maybe<Scalars["timestamptz"]>;
  origin_from_complaint?: Maybe<Scalars["String"]>;
  other_animal_1_name?: Maybe<Scalars["String"]>;
  other_animal_1_number?: Maybe<Scalars["Int"]>;
  other_animal_2_name?: Maybe<Scalars["String"]>;
  other_animal_2_number?: Maybe<Scalars["Int"]>;
  other_animal_3_name?: Maybe<Scalars["String"]>;
  other_animal_3_number?: Maybe<Scalars["Int"]>;
  other_animal_4_name?: Maybe<Scalars["String"]>;
  other_animal_4_number?: Maybe<Scalars["Int"]>;
  other_animal_5_name?: Maybe<Scalars["String"]>;
  other_animal_5_number?: Maybe<Scalars["Int"]>;
  other_animal_name?: Maybe<Scalars["String"]>;
  other_animal_number?: Maybe<Scalars["Int"]>;
  people_number?: Maybe<Scalars["Int"]>;
  poultry_number?: Maybe<Scalars["Int"]>;
  property_characteristics?: Maybe<Scalars["String"]>;
  public_local_kind?: Maybe<Scalars["String"]>;
  rabbits_number?: Maybe<Scalars["Int"]>;
  radial_consequence?: Maybe<Scalars["String"]>;
  unicode_from_radio?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "inspection" */
export type Inspection_Max_Order_By = {
  app_state?: InputMaybe<Order_By>;
  associated_phone?: InputMaybe<Order_By>;
  cats_number?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  from_complaint?: InputMaybe<Order_By>;
  geogroup?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inspection_reluctant_other?: InputMaybe<Order_By>;
  inspection_return_expected_date?: InputMaybe<Order_By>;
  inspection_return_reason?: InputMaybe<Order_By>;
  issued_at?: InputMaybe<Order_By>;
  origin_from_complaint?: InputMaybe<Order_By>;
  other_animal_1_name?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_name?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_name?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_name?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_name?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_name?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  property_characteristics?: InputMaybe<Order_By>;
  public_local_kind?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
  radial_consequence?: InputMaybe<Order_By>;
  unicode_from_radio?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Inspection_Min_Fields = {
  __typename?: "inspection_min_fields";
  app_state?: Maybe<Scalars["String"]>;
  associated_phone?: Maybe<Scalars["String"]>;
  cats_number?: Maybe<Scalars["Int"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dogs_number?: Maybe<Scalars["Int"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  from_complaint?: Maybe<Scalars["uuid"]>;
  geogroup?: Maybe<Scalars["uuid"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  guinea_pigs_number?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  inspection_reluctant_other?: Maybe<Scalars["String"]>;
  inspection_return_expected_date?: Maybe<Scalars["timestamptz"]>;
  inspection_return_reason?: Maybe<Scalars["String"]>;
  issued_at?: Maybe<Scalars["timestamptz"]>;
  origin_from_complaint?: Maybe<Scalars["String"]>;
  other_animal_1_name?: Maybe<Scalars["String"]>;
  other_animal_1_number?: Maybe<Scalars["Int"]>;
  other_animal_2_name?: Maybe<Scalars["String"]>;
  other_animal_2_number?: Maybe<Scalars["Int"]>;
  other_animal_3_name?: Maybe<Scalars["String"]>;
  other_animal_3_number?: Maybe<Scalars["Int"]>;
  other_animal_4_name?: Maybe<Scalars["String"]>;
  other_animal_4_number?: Maybe<Scalars["Int"]>;
  other_animal_5_name?: Maybe<Scalars["String"]>;
  other_animal_5_number?: Maybe<Scalars["Int"]>;
  other_animal_name?: Maybe<Scalars["String"]>;
  other_animal_number?: Maybe<Scalars["Int"]>;
  people_number?: Maybe<Scalars["Int"]>;
  poultry_number?: Maybe<Scalars["Int"]>;
  property_characteristics?: Maybe<Scalars["String"]>;
  public_local_kind?: Maybe<Scalars["String"]>;
  rabbits_number?: Maybe<Scalars["Int"]>;
  radial_consequence?: Maybe<Scalars["String"]>;
  unicode_from_radio?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "inspection" */
export type Inspection_Min_Order_By = {
  app_state?: InputMaybe<Order_By>;
  associated_phone?: InputMaybe<Order_By>;
  cats_number?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  from_complaint?: InputMaybe<Order_By>;
  geogroup?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inspection_reluctant_other?: InputMaybe<Order_By>;
  inspection_return_expected_date?: InputMaybe<Order_By>;
  inspection_return_reason?: InputMaybe<Order_By>;
  issued_at?: InputMaybe<Order_By>;
  origin_from_complaint?: InputMaybe<Order_By>;
  other_animal_1_name?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_name?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_name?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_name?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_name?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_name?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  property_characteristics?: InputMaybe<Order_By>;
  public_local_kind?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
  radial_consequence?: InputMaybe<Order_By>;
  unicode_from_radio?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "inspection" */
export type Inspection_Mutation_Response = {
  __typename?: "inspection_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inspection>;
};

/** on_conflict condition type for table "inspection" */
export type Inspection_On_Conflict = {
  constraint: Inspection_Constraint;
  update_columns?: Array<Inspection_Update_Column>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** Ordering options when selecting data from "inspection". */
export type Inspection_Order_By = {
  app_state?: InputMaybe<Order_By>;
  associated_phone?: InputMaybe<Order_By>;
  cats_number?: InputMaybe<Order_By>;
  collaborator?: InputMaybe<Collaborator_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complaint?: InputMaybe<Complaint_Order_By>;
  created_at?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  estate_kind?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  from_complaint?: InputMaybe<Order_By>;
  from_radio?: InputMaybe<Order_By>;
  geogroup?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint?: InputMaybe<Geopoint_Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inspection_interview_kind?: InputMaybe<Order_By>;
  inspection_intra_event?: InputMaybe<Order_By>;
  inspection_peri_event?: InputMaybe<Order_By>;
  inspection_reluctant_kind?: InputMaybe<Order_By>;
  inspection_reluctant_other?: InputMaybe<Order_By>;
  inspection_return_expected_date?: InputMaybe<Order_By>;
  inspection_return_reason?: InputMaybe<Order_By>;
  issued_at?: InputMaybe<Order_By>;
  observations?: InputMaybe<Order_By>;
  origin_from_complaint?: InputMaybe<Order_By>;
  other_animal_1_name?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_name?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_name?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_name?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_name?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_name?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  property_characteristics?: InputMaybe<Order_By>;
  public_local_kind?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
  radial_consequence?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  unicode_from_radio?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inspection */
export type Inspection_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** columns and relationships of "inspection_place_kind" */
export type Inspection_Place_Kind = {
  __typename?: "inspection_place_kind";
  comments?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "inspection_place_kind" */
export type Inspection_Place_Kind_Aggregate = {
  __typename?: "inspection_place_kind_aggregate";
  aggregate?: Maybe<Inspection_Place_Kind_Aggregate_Fields>;
  nodes: Array<Inspection_Place_Kind>;
};

/** aggregate fields of "inspection_place_kind" */
export type Inspection_Place_Kind_Aggregate_Fields = {
  __typename?: "inspection_place_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Inspection_Place_Kind_Max_Fields>;
  min?: Maybe<Inspection_Place_Kind_Min_Fields>;
};

/** aggregate fields of "inspection_place_kind" */
export type Inspection_Place_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inspection_Place_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "inspection_place_kind". All fields are combined with a logical 'AND'. */
export type Inspection_Place_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Inspection_Place_Kind_Bool_Exp>>;
  _not?: InputMaybe<Inspection_Place_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Inspection_Place_Kind_Bool_Exp>>;
  comments?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inspection_place_kind" */
export enum Inspection_Place_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  InspectionPlaceKindPkey = "inspection_place_kind_pkey",
}

/** input type for inserting data into table "inspection_place_kind" */
export type Inspection_Place_Kind_Insert_Input = {
  comments?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inspection_Place_Kind_Max_Fields = {
  __typename?: "inspection_place_kind_max_fields";
  comments?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Inspection_Place_Kind_Min_Fields = {
  __typename?: "inspection_place_kind_min_fields";
  comments?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "inspection_place_kind" */
export type Inspection_Place_Kind_Mutation_Response = {
  __typename?: "inspection_place_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inspection_Place_Kind>;
};

/** on_conflict condition type for table "inspection_place_kind" */
export type Inspection_Place_Kind_On_Conflict = {
  constraint: Inspection_Place_Kind_Constraint;
  update_columns?: Array<Inspection_Place_Kind_Update_Column>;
  where?: InputMaybe<Inspection_Place_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "inspection_place_kind". */
export type Inspection_Place_Kind_Order_By = {
  comments?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inspection_place_kind */
export type Inspection_Place_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "inspection_place_kind" */
export enum Inspection_Place_Kind_Select_Column {
  /** column name */
  Comments = "comments",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "inspection_place_kind" */
export type Inspection_Place_Kind_Set_Input = {
  comments?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "inspection_place_kind" */
export type Inspection_Place_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inspection_Place_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inspection_Place_Kind_Stream_Cursor_Value_Input = {
  comments?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "inspection_place_kind" */
export enum Inspection_Place_Kind_Update_Column {
  /** column name */
  Comments = "comments",
  /** column name */
  Value = "value",
}

export type Inspection_Place_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inspection_Place_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inspection_Place_Kind_Bool_Exp;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Inspection_Prepend_Input = {
  inspection_intra_event?: InputMaybe<Scalars["jsonb"]>;
  inspection_peri_event?: InputMaybe<Scalars["jsonb"]>;
  observations?: InputMaybe<Scalars["jsonb"]>;
};

/** columns and relationships of "inspection_reluctant_kind" */
export type Inspection_Reluctant_Kind = {
  __typename?: "inspection_reluctant_kind";
  comment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "inspection_reluctant_kind" */
export type Inspection_Reluctant_Kind_Aggregate = {
  __typename?: "inspection_reluctant_kind_aggregate";
  aggregate?: Maybe<Inspection_Reluctant_Kind_Aggregate_Fields>;
  nodes: Array<Inspection_Reluctant_Kind>;
};

/** aggregate fields of "inspection_reluctant_kind" */
export type Inspection_Reluctant_Kind_Aggregate_Fields = {
  __typename?: "inspection_reluctant_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Inspection_Reluctant_Kind_Max_Fields>;
  min?: Maybe<Inspection_Reluctant_Kind_Min_Fields>;
};

/** aggregate fields of "inspection_reluctant_kind" */
export type Inspection_Reluctant_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inspection_Reluctant_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "inspection_reluctant_kind". All fields are combined with a logical 'AND'. */
export type Inspection_Reluctant_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Inspection_Reluctant_Kind_Bool_Exp>>;
  _not?: InputMaybe<Inspection_Reluctant_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Inspection_Reluctant_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inspection_reluctant_kind" */
export enum Inspection_Reluctant_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  InspectionReluctantKindPkey = "inspection_reluctant_kind_pkey",
}

export enum Inspection_Reluctant_Kind_Enum {
  R1 = "R1",
  R2 = "R2",
  R3 = "R3",
  R4 = "R4",
  R5 = "R5",
  R6 = "R6",
  CleanHouse = "clean_house",
  DoNotHaveTimeWork = "do_not_have_time_work",
  NoReason = "no_reason",
  NoTrust = "no_trust",
  Other = "other",
  Tenants = "tenants",
}

/** Boolean expression to compare columns of type "inspection_reluctant_kind_enum". All fields are combined with logical 'AND'. */
export type Inspection_Reluctant_Kind_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Inspection_Reluctant_Kind_Enum>;
  _in?: InputMaybe<Array<Inspection_Reluctant_Kind_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Inspection_Reluctant_Kind_Enum>;
  _nin?: InputMaybe<Array<Inspection_Reluctant_Kind_Enum>>;
};

/** input type for inserting data into table "inspection_reluctant_kind" */
export type Inspection_Reluctant_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inspection_Reluctant_Kind_Max_Fields = {
  __typename?: "inspection_reluctant_kind_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Inspection_Reluctant_Kind_Min_Fields = {
  __typename?: "inspection_reluctant_kind_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "inspection_reluctant_kind" */
export type Inspection_Reluctant_Kind_Mutation_Response = {
  __typename?: "inspection_reluctant_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inspection_Reluctant_Kind>;
};

/** on_conflict condition type for table "inspection_reluctant_kind" */
export type Inspection_Reluctant_Kind_On_Conflict = {
  constraint: Inspection_Reluctant_Kind_Constraint;
  update_columns?: Array<Inspection_Reluctant_Kind_Update_Column>;
  where?: InputMaybe<Inspection_Reluctant_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "inspection_reluctant_kind". */
export type Inspection_Reluctant_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inspection_reluctant_kind */
export type Inspection_Reluctant_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "inspection_reluctant_kind" */
export enum Inspection_Reluctant_Kind_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "inspection_reluctant_kind" */
export type Inspection_Reluctant_Kind_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "inspection_reluctant_kind" */
export type Inspection_Reluctant_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inspection_Reluctant_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inspection_Reluctant_Kind_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "inspection_reluctant_kind" */
export enum Inspection_Reluctant_Kind_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Inspection_Reluctant_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inspection_Reluctant_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inspection_Reluctant_Kind_Bool_Exp;
};

/** select columns of table "inspection" */
export enum Inspection_Select_Column {
  /** column name */
  AppState = "app_state",
  /** column name */
  AssociatedPhone = "associated_phone",
  /** column name */
  CatsNumber = "cats_number",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DogsNumber = "dogs_number",
  /** column name */
  EstateKind = "estate_kind",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  FromComplaint = "from_complaint",
  /** column name */
  FromRadio = "from_radio",
  /** column name */
  Geogroup = "geogroup",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  GuineaPigsNumber = "guinea_pigs_number",
  /** column name */
  Id = "id",
  /** column name */
  InspectionInterviewKind = "inspection_interview_kind",
  /** column name */
  InspectionIntraEvent = "inspection_intra_event",
  /** column name */
  InspectionPeriEvent = "inspection_peri_event",
  /** column name */
  InspectionReluctantKind = "inspection_reluctant_kind",
  /** column name */
  InspectionReluctantOther = "inspection_reluctant_other",
  /** column name */
  InspectionReturnExpectedDate = "inspection_return_expected_date",
  /** column name */
  InspectionReturnReason = "inspection_return_reason",
  /** column name */
  IssuedAt = "issued_at",
  /** column name */
  Observations = "observations",
  /** column name */
  OriginFromComplaint = "origin_from_complaint",
  /** column name */
  OtherAnimal_1Name = "other_animal_1_name",
  /** column name */
  OtherAnimal_1Number = "other_animal_1_number",
  /** column name */
  OtherAnimal_2Name = "other_animal_2_name",
  /** column name */
  OtherAnimal_2Number = "other_animal_2_number",
  /** column name */
  OtherAnimal_3Name = "other_animal_3_name",
  /** column name */
  OtherAnimal_3Number = "other_animal_3_number",
  /** column name */
  OtherAnimal_4Name = "other_animal_4_name",
  /** column name */
  OtherAnimal_4Number = "other_animal_4_number",
  /** column name */
  OtherAnimal_5Name = "other_animal_5_name",
  /** column name */
  OtherAnimal_5Number = "other_animal_5_number",
  /** column name */
  OtherAnimalName = "other_animal_name",
  /** column name */
  OtherAnimalNumber = "other_animal_number",
  /** column name */
  PeopleNumber = "people_number",
  /** column name */
  PoultryNumber = "poultry_number",
  /** column name */
  PropertyCharacteristics = "property_characteristics",
  /** column name */
  PublicLocalKind = "public_local_kind",
  /** column name */
  RabbitsNumber = "rabbits_number",
  /** column name */
  RadialConsequence = "radial_consequence",
  /** column name */
  State = "state",
  /** column name */
  UnicodeFromRadio = "unicode_from_radio",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
}

/** select "inspection_aggregate_bool_exp_bool_and_arguments_columns" columns of table "inspection" */
export enum Inspection_Select_Column_Inspection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FromRadio = "from_radio",
}

/** select "inspection_aggregate_bool_exp_bool_or_arguments_columns" columns of table "inspection" */
export enum Inspection_Select_Column_Inspection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FromRadio = "from_radio",
}

/** input type for updating data in table "inspection" */
export type Inspection_Set_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  associated_phone?: InputMaybe<Scalars["String"]>;
  cats_number?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dogs_number?: InputMaybe<Scalars["Int"]>;
  estate_kind?: InputMaybe<Estate_Kind_Enum>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  from_complaint?: InputMaybe<Scalars["uuid"]>;
  from_radio?: InputMaybe<Scalars["Boolean"]>;
  geogroup?: InputMaybe<Scalars["uuid"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  guinea_pigs_number?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  inspection_interview_kind?: InputMaybe<Inspection_Interview_Kind_Enum>;
  inspection_intra_event?: InputMaybe<Scalars["jsonb"]>;
  inspection_peri_event?: InputMaybe<Scalars["jsonb"]>;
  inspection_reluctant_kind?: InputMaybe<Inspection_Reluctant_Kind_Enum>;
  inspection_reluctant_other?: InputMaybe<Scalars["String"]>;
  inspection_return_expected_date?: InputMaybe<Scalars["timestamptz"]>;
  inspection_return_reason?: InputMaybe<Scalars["String"]>;
  issued_at?: InputMaybe<Scalars["timestamptz"]>;
  observations?: InputMaybe<Scalars["jsonb"]>;
  origin_from_complaint?: InputMaybe<Scalars["String"]>;
  other_animal_1_name?: InputMaybe<Scalars["String"]>;
  other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  other_animal_2_name?: InputMaybe<Scalars["String"]>;
  other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  other_animal_3_name?: InputMaybe<Scalars["String"]>;
  other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  other_animal_4_name?: InputMaybe<Scalars["String"]>;
  other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  other_animal_5_name?: InputMaybe<Scalars["String"]>;
  other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  other_animal_name?: InputMaybe<Scalars["String"]>;
  other_animal_number?: InputMaybe<Scalars["Int"]>;
  people_number?: InputMaybe<Scalars["Int"]>;
  poultry_number?: InputMaybe<Scalars["Int"]>;
  property_characteristics?: InputMaybe<Scalars["String"]>;
  public_local_kind?: InputMaybe<Scalars["String"]>;
  rabbits_number?: InputMaybe<Scalars["Int"]>;
  radial_consequence?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Inspection_State_Enum>;
  unicode_from_radio?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
};

/** columns and relationships of "inspection_state" */
export type Inspection_State = {
  __typename?: "inspection_state";
  comment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "inspection_state" */
export type Inspection_State_Aggregate = {
  __typename?: "inspection_state_aggregate";
  aggregate?: Maybe<Inspection_State_Aggregate_Fields>;
  nodes: Array<Inspection_State>;
};

/** aggregate fields of "inspection_state" */
export type Inspection_State_Aggregate_Fields = {
  __typename?: "inspection_state_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Inspection_State_Max_Fields>;
  min?: Maybe<Inspection_State_Min_Fields>;
};

/** aggregate fields of "inspection_state" */
export type Inspection_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inspection_State_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "inspection_state". All fields are combined with a logical 'AND'. */
export type Inspection_State_Bool_Exp = {
  _and?: InputMaybe<Array<Inspection_State_Bool_Exp>>;
  _not?: InputMaybe<Inspection_State_Bool_Exp>;
  _or?: InputMaybe<Array<Inspection_State_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inspection_state" */
export enum Inspection_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  InspectionStatePkey = "inspection_state_pkey",
}

export enum Inspection_State_Enum {
  Closed = "closed",
  Inspected = "inspected",
  Interviewed = "interviewed",
  Reluctanted = "reluctanted",
  Returned = "returned",
}

/** Boolean expression to compare columns of type "inspection_state_enum". All fields are combined with logical 'AND'. */
export type Inspection_State_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Inspection_State_Enum>;
  _in?: InputMaybe<Array<Inspection_State_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Inspection_State_Enum>;
  _nin?: InputMaybe<Array<Inspection_State_Enum>>;
};

/** input type for inserting data into table "inspection_state" */
export type Inspection_State_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inspection_State_Max_Fields = {
  __typename?: "inspection_state_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Inspection_State_Min_Fields = {
  __typename?: "inspection_state_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "inspection_state" */
export type Inspection_State_Mutation_Response = {
  __typename?: "inspection_state_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inspection_State>;
};

/** on_conflict condition type for table "inspection_state" */
export type Inspection_State_On_Conflict = {
  constraint: Inspection_State_Constraint;
  update_columns?: Array<Inspection_State_Update_Column>;
  where?: InputMaybe<Inspection_State_Bool_Exp>;
};

/** Ordering options when selecting data from "inspection_state". */
export type Inspection_State_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inspection_state */
export type Inspection_State_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "inspection_state" */
export enum Inspection_State_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "inspection_state" */
export type Inspection_State_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "inspection_state" */
export type Inspection_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inspection_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inspection_State_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "inspection_state" */
export enum Inspection_State_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Inspection_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inspection_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inspection_State_Bool_Exp;
};

/** aggregate stddev on columns */
export type Inspection_Stddev_Fields = {
  __typename?: "inspection_stddev_fields";
  cats_number?: Maybe<Scalars["Float"]>;
  dogs_number?: Maybe<Scalars["Float"]>;
  guinea_pigs_number?: Maybe<Scalars["Float"]>;
  other_animal_1_number?: Maybe<Scalars["Float"]>;
  other_animal_2_number?: Maybe<Scalars["Float"]>;
  other_animal_3_number?: Maybe<Scalars["Float"]>;
  other_animal_4_number?: Maybe<Scalars["Float"]>;
  other_animal_5_number?: Maybe<Scalars["Float"]>;
  other_animal_number?: Maybe<Scalars["Float"]>;
  people_number?: Maybe<Scalars["Float"]>;
  poultry_number?: Maybe<Scalars["Float"]>;
  rabbits_number?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inspection" */
export type Inspection_Stddev_Order_By = {
  cats_number?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inspection_Stddev_Pop_Fields = {
  __typename?: "inspection_stddev_pop_fields";
  cats_number?: Maybe<Scalars["Float"]>;
  dogs_number?: Maybe<Scalars["Float"]>;
  guinea_pigs_number?: Maybe<Scalars["Float"]>;
  other_animal_1_number?: Maybe<Scalars["Float"]>;
  other_animal_2_number?: Maybe<Scalars["Float"]>;
  other_animal_3_number?: Maybe<Scalars["Float"]>;
  other_animal_4_number?: Maybe<Scalars["Float"]>;
  other_animal_5_number?: Maybe<Scalars["Float"]>;
  other_animal_number?: Maybe<Scalars["Float"]>;
  people_number?: Maybe<Scalars["Float"]>;
  poultry_number?: Maybe<Scalars["Float"]>;
  rabbits_number?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inspection" */
export type Inspection_Stddev_Pop_Order_By = {
  cats_number?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inspection_Stddev_Samp_Fields = {
  __typename?: "inspection_stddev_samp_fields";
  cats_number?: Maybe<Scalars["Float"]>;
  dogs_number?: Maybe<Scalars["Float"]>;
  guinea_pigs_number?: Maybe<Scalars["Float"]>;
  other_animal_1_number?: Maybe<Scalars["Float"]>;
  other_animal_2_number?: Maybe<Scalars["Float"]>;
  other_animal_3_number?: Maybe<Scalars["Float"]>;
  other_animal_4_number?: Maybe<Scalars["Float"]>;
  other_animal_5_number?: Maybe<Scalars["Float"]>;
  other_animal_number?: Maybe<Scalars["Float"]>;
  people_number?: Maybe<Scalars["Float"]>;
  poultry_number?: Maybe<Scalars["Float"]>;
  rabbits_number?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inspection" */
export type Inspection_Stddev_Samp_Order_By = {
  cats_number?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "inspection" */
export type Inspection_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inspection_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inspection_Stream_Cursor_Value_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  associated_phone?: InputMaybe<Scalars["String"]>;
  cats_number?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dogs_number?: InputMaybe<Scalars["Int"]>;
  estate_kind?: InputMaybe<Estate_Kind_Enum>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  from_complaint?: InputMaybe<Scalars["uuid"]>;
  from_radio?: InputMaybe<Scalars["Boolean"]>;
  geogroup?: InputMaybe<Scalars["uuid"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  guinea_pigs_number?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  inspection_interview_kind?: InputMaybe<Inspection_Interview_Kind_Enum>;
  inspection_intra_event?: InputMaybe<Scalars["jsonb"]>;
  inspection_peri_event?: InputMaybe<Scalars["jsonb"]>;
  inspection_reluctant_kind?: InputMaybe<Inspection_Reluctant_Kind_Enum>;
  inspection_reluctant_other?: InputMaybe<Scalars["String"]>;
  inspection_return_expected_date?: InputMaybe<Scalars["timestamptz"]>;
  inspection_return_reason?: InputMaybe<Scalars["String"]>;
  issued_at?: InputMaybe<Scalars["timestamptz"]>;
  observations?: InputMaybe<Scalars["jsonb"]>;
  origin_from_complaint?: InputMaybe<Scalars["String"]>;
  other_animal_1_name?: InputMaybe<Scalars["String"]>;
  other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  other_animal_2_name?: InputMaybe<Scalars["String"]>;
  other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  other_animal_3_name?: InputMaybe<Scalars["String"]>;
  other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  other_animal_4_name?: InputMaybe<Scalars["String"]>;
  other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  other_animal_5_name?: InputMaybe<Scalars["String"]>;
  other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  other_animal_name?: InputMaybe<Scalars["String"]>;
  other_animal_number?: InputMaybe<Scalars["Int"]>;
  people_number?: InputMaybe<Scalars["Int"]>;
  poultry_number?: InputMaybe<Scalars["Int"]>;
  property_characteristics?: InputMaybe<Scalars["String"]>;
  public_local_kind?: InputMaybe<Scalars["String"]>;
  rabbits_number?: InputMaybe<Scalars["Int"]>;
  radial_consequence?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Inspection_State_Enum>;
  unicode_from_radio?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Inspection_Sum_Fields = {
  __typename?: "inspection_sum_fields";
  cats_number?: Maybe<Scalars["Int"]>;
  dogs_number?: Maybe<Scalars["Int"]>;
  guinea_pigs_number?: Maybe<Scalars["Int"]>;
  other_animal_1_number?: Maybe<Scalars["Int"]>;
  other_animal_2_number?: Maybe<Scalars["Int"]>;
  other_animal_3_number?: Maybe<Scalars["Int"]>;
  other_animal_4_number?: Maybe<Scalars["Int"]>;
  other_animal_5_number?: Maybe<Scalars["Int"]>;
  other_animal_number?: Maybe<Scalars["Int"]>;
  people_number?: Maybe<Scalars["Int"]>;
  poultry_number?: Maybe<Scalars["Int"]>;
  rabbits_number?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inspection" */
export type Inspection_Sum_Order_By = {
  cats_number?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
};

/** update columns of table "inspection" */
export enum Inspection_Update_Column {
  /** column name */
  AppState = "app_state",
  /** column name */
  AssociatedPhone = "associated_phone",
  /** column name */
  CatsNumber = "cats_number",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DogsNumber = "dogs_number",
  /** column name */
  EstateKind = "estate_kind",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  FromComplaint = "from_complaint",
  /** column name */
  FromRadio = "from_radio",
  /** column name */
  Geogroup = "geogroup",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  GuineaPigsNumber = "guinea_pigs_number",
  /** column name */
  Id = "id",
  /** column name */
  InspectionInterviewKind = "inspection_interview_kind",
  /** column name */
  InspectionIntraEvent = "inspection_intra_event",
  /** column name */
  InspectionPeriEvent = "inspection_peri_event",
  /** column name */
  InspectionReluctantKind = "inspection_reluctant_kind",
  /** column name */
  InspectionReluctantOther = "inspection_reluctant_other",
  /** column name */
  InspectionReturnExpectedDate = "inspection_return_expected_date",
  /** column name */
  InspectionReturnReason = "inspection_return_reason",
  /** column name */
  IssuedAt = "issued_at",
  /** column name */
  Observations = "observations",
  /** column name */
  OriginFromComplaint = "origin_from_complaint",
  /** column name */
  OtherAnimal_1Name = "other_animal_1_name",
  /** column name */
  OtherAnimal_1Number = "other_animal_1_number",
  /** column name */
  OtherAnimal_2Name = "other_animal_2_name",
  /** column name */
  OtherAnimal_2Number = "other_animal_2_number",
  /** column name */
  OtherAnimal_3Name = "other_animal_3_name",
  /** column name */
  OtherAnimal_3Number = "other_animal_3_number",
  /** column name */
  OtherAnimal_4Name = "other_animal_4_name",
  /** column name */
  OtherAnimal_4Number = "other_animal_4_number",
  /** column name */
  OtherAnimal_5Name = "other_animal_5_name",
  /** column name */
  OtherAnimal_5Number = "other_animal_5_number",
  /** column name */
  OtherAnimalName = "other_animal_name",
  /** column name */
  OtherAnimalNumber = "other_animal_number",
  /** column name */
  PeopleNumber = "people_number",
  /** column name */
  PoultryNumber = "poultry_number",
  /** column name */
  PropertyCharacteristics = "property_characteristics",
  /** column name */
  PublicLocalKind = "public_local_kind",
  /** column name */
  RabbitsNumber = "rabbits_number",
  /** column name */
  RadialConsequence = "radial_consequence",
  /** column name */
  State = "state",
  /** column name */
  UnicodeFromRadio = "unicode_from_radio",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
}

export type Inspection_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Inspection_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Inspection_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Inspection_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Inspection_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Inspection_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Inspection_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inspection_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inspection_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inspection_Var_Pop_Fields = {
  __typename?: "inspection_var_pop_fields";
  cats_number?: Maybe<Scalars["Float"]>;
  dogs_number?: Maybe<Scalars["Float"]>;
  guinea_pigs_number?: Maybe<Scalars["Float"]>;
  other_animal_1_number?: Maybe<Scalars["Float"]>;
  other_animal_2_number?: Maybe<Scalars["Float"]>;
  other_animal_3_number?: Maybe<Scalars["Float"]>;
  other_animal_4_number?: Maybe<Scalars["Float"]>;
  other_animal_5_number?: Maybe<Scalars["Float"]>;
  other_animal_number?: Maybe<Scalars["Float"]>;
  people_number?: Maybe<Scalars["Float"]>;
  poultry_number?: Maybe<Scalars["Float"]>;
  rabbits_number?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inspection" */
export type Inspection_Var_Pop_Order_By = {
  cats_number?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inspection_Var_Samp_Fields = {
  __typename?: "inspection_var_samp_fields";
  cats_number?: Maybe<Scalars["Float"]>;
  dogs_number?: Maybe<Scalars["Float"]>;
  guinea_pigs_number?: Maybe<Scalars["Float"]>;
  other_animal_1_number?: Maybe<Scalars["Float"]>;
  other_animal_2_number?: Maybe<Scalars["Float"]>;
  other_animal_3_number?: Maybe<Scalars["Float"]>;
  other_animal_4_number?: Maybe<Scalars["Float"]>;
  other_animal_5_number?: Maybe<Scalars["Float"]>;
  other_animal_number?: Maybe<Scalars["Float"]>;
  people_number?: Maybe<Scalars["Float"]>;
  poultry_number?: Maybe<Scalars["Float"]>;
  rabbits_number?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inspection" */
export type Inspection_Var_Samp_Order_By = {
  cats_number?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Inspection_Variance_Fields = {
  __typename?: "inspection_variance_fields";
  cats_number?: Maybe<Scalars["Float"]>;
  dogs_number?: Maybe<Scalars["Float"]>;
  guinea_pigs_number?: Maybe<Scalars["Float"]>;
  other_animal_1_number?: Maybe<Scalars["Float"]>;
  other_animal_2_number?: Maybe<Scalars["Float"]>;
  other_animal_3_number?: Maybe<Scalars["Float"]>;
  other_animal_4_number?: Maybe<Scalars["Float"]>;
  other_animal_5_number?: Maybe<Scalars["Float"]>;
  other_animal_number?: Maybe<Scalars["Float"]>;
  people_number?: Maybe<Scalars["Float"]>;
  poultry_number?: Maybe<Scalars["Float"]>;
  rabbits_number?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inspection" */
export type Inspection_Variance_Order_By = {
  cats_number?: InputMaybe<Order_By>;
  dogs_number?: InputMaybe<Order_By>;
  guinea_pigs_number?: InputMaybe<Order_By>;
  other_animal_1_number?: InputMaybe<Order_By>;
  other_animal_2_number?: InputMaybe<Order_By>;
  other_animal_3_number?: InputMaybe<Order_By>;
  other_animal_4_number?: InputMaybe<Order_By>;
  other_animal_5_number?: InputMaybe<Order_By>;
  other_animal_number?: InputMaybe<Order_By>;
  people_number?: InputMaybe<Order_By>;
  poultry_number?: InputMaybe<Order_By>;
  rabbits_number?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["json"]>;
  _gt?: InputMaybe<Scalars["json"]>;
  _gte?: InputMaybe<Scalars["json"]>;
  _in?: InputMaybe<Array<Scalars["json"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["json"]>;
  _lte?: InputMaybe<Scalars["json"]>;
  _neq?: InputMaybe<Scalars["json"]>;
  _nin?: InputMaybe<Array<Scalars["json"]>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]>;
  _eq?: InputMaybe<Scalars["jsonb"]>;
  _gt?: InputMaybe<Scalars["jsonb"]>;
  _gte?: InputMaybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars["String"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["jsonb"]>;
  _lte?: InputMaybe<Scalars["jsonb"]>;
  _neq?: InputMaybe<Scalars["jsonb"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]>>;
};

/** columns and relationships of "microred" */
export type Microred = {
  __typename?: "microred";
  collection?: Maybe<Scalars["json"]>;
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "microred" */
export type MicroredCollectionArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "microred" */
export type Microred_Aggregate = {
  __typename?: "microred_aggregate";
  aggregate?: Maybe<Microred_Aggregate_Fields>;
  nodes: Array<Microred>;
};

/** aggregate fields of "microred" */
export type Microred_Aggregate_Fields = {
  __typename?: "microred_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Microred_Max_Fields>;
  min?: Maybe<Microred_Min_Fields>;
};

/** aggregate fields of "microred" */
export type Microred_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Microred_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "microred". All fields are combined with a logical 'AND'. */
export type Microred_Bool_Exp = {
  _and?: InputMaybe<Array<Microred_Bool_Exp>>;
  _not?: InputMaybe<Microred_Bool_Exp>;
  _or?: InputMaybe<Array<Microred_Bool_Exp>>;
  collection?: InputMaybe<Json_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "microred" */
export enum Microred_Constraint {
  /** unique or primary key constraint on columns "id" */
  MicroredPkey = "microred_pkey",
}

/** input type for inserting data into table "microred" */
export type Microred_Insert_Input = {
  collection?: InputMaybe<Scalars["json"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Microred_Max_Fields = {
  __typename?: "microred_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Microred_Min_Fields = {
  __typename?: "microred_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "microred" */
export type Microred_Mutation_Response = {
  __typename?: "microred_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Microred>;
};

/** on_conflict condition type for table "microred" */
export type Microred_On_Conflict = {
  constraint: Microred_Constraint;
  update_columns?: Array<Microred_Update_Column>;
  where?: InputMaybe<Microred_Bool_Exp>;
};

/** Ordering options when selecting data from "microred". */
export type Microred_Order_By = {
  collection?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: microred */
export type Microred_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "microred" */
export enum Microred_Select_Column {
  /** column name */
  Collection = "collection",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "microred" */
export type Microred_Set_Input = {
  collection?: InputMaybe<Scalars["json"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "microred" */
export type Microred_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Microred_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Microred_Stream_Cursor_Value_Input = {
  collection?: InputMaybe<Scalars["json"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "microred" */
export enum Microred_Update_Column {
  /** column name */
  Collection = "collection",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Microred_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Microred_Set_Input>;
  /** filter the rows which have to be updated */
  where: Microred_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** delete data from the table: "Capture_Place" */
  delete_Capture_Place?: Maybe<Capture_Place_Mutation_Response>;
  /** delete single row from the table: "Capture_Place" */
  delete_Capture_Place_by_pk?: Maybe<Capture_Place>;
  /** delete data from the table: "Number_Triatomines_Captured" */
  delete_Number_Triatomines_Captured?: Maybe<Number_Triatomines_Captured_Mutation_Response>;
  /** delete single row from the table: "Number_Triatomines_Captured" */
  delete_Number_Triatomines_Captured_by_pk?: Maybe<Number_Triatomines_Captured>;
  /** delete data from the table: "Number_Triatomines_Inspected" */
  delete_Number_Triatomines_Inspected?: Maybe<Number_Triatomines_Inspected_Mutation_Response>;
  /** delete single row from the table: "Number_Triatomines_Inspected" */
  delete_Number_Triatomines_Inspected_by_pk?: Maybe<Number_Triatomines_Inspected>;
  /** delete data from the table: "Number_Triatomines_Positive" */
  delete_Number_Triatomines_Positive?: Maybe<Number_Triatomines_Positive_Mutation_Response>;
  /** delete single row from the table: "Number_Triatomines_Positive" */
  delete_Number_Triatomines_Positive_by_pk?: Maybe<Number_Triatomines_Positive>;
  /** delete data from the table: "Place_Place" */
  delete_Place_Place?: Maybe<Place_Place_Mutation_Response>;
  /** delete single row from the table: "Place_Place" */
  delete_Place_Place_by_pk?: Maybe<Place_Place>;
  /** delete data from the table: "Place_Triatomines_Experiment_Record" */
  delete_Place_Triatomines_Experiment_Record?: Maybe<Place_Triatomines_Experiment_Record_Mutation_Response>;
  /** delete single row from the table: "Place_Triatomines_Experiment_Record" */
  delete_Place_Triatomines_Experiment_Record_by_pk?: Maybe<Place_Triatomines_Experiment_Record>;
  /** delete data from the table: "Place_children" */
  delete_Place_children?: Maybe<Place_Children_Mutation_Response>;
  /** delete single row from the table: "Place_children" */
  delete_Place_children_by_pk?: Maybe<Place_Children>;
  /** delete data from the table: "Place_files" */
  delete_Place_files?: Maybe<Place_Files_Mutation_Response>;
  /** delete single row from the table: "Place_files" */
  delete_Place_files_by_pk?: Maybe<Place_Files>;
  /** delete data from the table: "Place" */
  delete_Place_kind?: Maybe<Place_Kind_Mutation_Response>;
  /** delete single row from the table: "Place" */
  delete_Place_kind_by_pk?: Maybe<Place_Kind>;
  /** delete data from the table: "Place_record" */
  delete_Place_record?: Maybe<Place_Record_Mutation_Response>;
  /** delete single row from the table: "Place_record" */
  delete_Place_record_by_pk?: Maybe<Place_Record>;
  /** delete data from the table: "Triatomines" */
  delete_Triatomines?: Maybe<Triatomines_Mutation_Response>;
  /** delete data from the table: "Triatomines_Experiment_Record" */
  delete_Triatomines_Experiment_Record?: Maybe<Triatomines_Experiment_Record_Mutation_Response>;
  /** delete data from the table: "Triatomines_Experiment_Record_Place" */
  delete_Triatomines_Experiment_Record_Place?: Maybe<Triatomines_Experiment_Record_Place_Mutation_Response>;
  /** delete single row from the table: "Triatomines_Experiment_Record_Place" */
  delete_Triatomines_Experiment_Record_Place_by_pk?: Maybe<Triatomines_Experiment_Record_Place>;
  /** delete single row from the table: "Triatomines_Experiment_Record" */
  delete_Triatomines_Experiment_Record_by_pk?: Maybe<Triatomines_Experiment_Record>;
  /** delete data from the table: "Triatomines_Experiment_Record_directus_users" */
  delete_Triatomines_Experiment_Record_directus_users?: Maybe<Triatomines_Experiment_Record_Directus_Users_Mutation_Response>;
  /** delete single row from the table: "Triatomines_Experiment_Record_directus_users" */
  delete_Triatomines_Experiment_Record_directus_users_by_pk?: Maybe<Triatomines_Experiment_Record_Directus_Users>;
  /** delete single row from the table: "Triatomines" */
  delete_Triatomines_by_pk?: Maybe<Triatomines>;
  /** delete data from the table: "advertisement" */
  delete_advertisement?: Maybe<Advertisement_Mutation_Response>;
  /** delete single row from the table: "advertisement" */
  delete_advertisement_by_pk?: Maybe<Advertisement>;
  /** delete data from the table: "collaborator" */
  delete_collaborator?: Maybe<Collaborator_Mutation_Response>;
  /** delete single row from the table: "collaborator" */
  delete_collaborator_by_pk?: Maybe<Collaborator>;
  /** delete data from the table: "complaint" */
  delete_complaint?: Maybe<Complaint_Mutation_Response>;
  /** delete single row from the table: "complaint" */
  delete_complaint_by_pk?: Maybe<Complaint>;
  /** delete data from the table: "complaint_kind" */
  delete_complaint_kind?: Maybe<Complaint_Kind_Mutation_Response>;
  /** delete single row from the table: "complaint_kind" */
  delete_complaint_kind_by_pk?: Maybe<Complaint_Kind>;
  /** delete data from the table: "complaint_source" */
  delete_complaint_source?: Maybe<Complaint_Source_Mutation_Response>;
  /** delete single row from the table: "complaint_source" */
  delete_complaint_source_by_pk?: Maybe<Complaint_Source>;
  /** delete data from the table: "distances" */
  delete_distances?: Maybe<Distances_Mutation_Response>;
  /** delete single row from the table: "distances" */
  delete_distances_by_pk?: Maybe<Distances>;
  /** delete data from the table: "estate_kind" */
  delete_estate_kind?: Maybe<Estate_Kind_Mutation_Response>;
  /** delete single row from the table: "estate_kind" */
  delete_estate_kind_by_pk?: Maybe<Estate_Kind>;
  /** delete data from the table: "experiment" */
  delete_experiment?: Maybe<Experiment_Mutation_Response>;
  /** delete single row from the table: "experiment" */
  delete_experiment_by_pk?: Maybe<Experiment>;
  /** delete data from the table: "geogroup" */
  delete_geogroup?: Maybe<Geogroup_Mutation_Response>;
  /** delete single row from the table: "geogroup" */
  delete_geogroup_by_pk?: Maybe<Geogroup>;
  /** delete data from the table: "geometry_columns" */
  delete_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** delete data from the table: "geopoint" */
  delete_geopoint?: Maybe<Geopoint_Mutation_Response>;
  /** delete single row from the table: "geopoint" */
  delete_geopoint_by_pk?: Maybe<Geopoint>;
  /** delete data from the table: "inspection" */
  delete_inspection?: Maybe<Inspection_Mutation_Response>;
  /** delete single row from the table: "inspection" */
  delete_inspection_by_pk?: Maybe<Inspection>;
  /** delete data from the table: "inspection_event_kind" */
  delete_inspection_event_kind?: Maybe<Inspection_Event_Kind_Mutation_Response>;
  /** delete single row from the table: "inspection_event_kind" */
  delete_inspection_event_kind_by_pk?: Maybe<Inspection_Event_Kind>;
  /** delete data from the table: "inspection_interview_kind" */
  delete_inspection_interview_kind?: Maybe<Inspection_Interview_Kind_Mutation_Response>;
  /** delete single row from the table: "inspection_interview_kind" */
  delete_inspection_interview_kind_by_pk?: Maybe<Inspection_Interview_Kind>;
  /** delete data from the table: "inspection_place_kind" */
  delete_inspection_place_kind?: Maybe<Inspection_Place_Kind_Mutation_Response>;
  /** delete single row from the table: "inspection_place_kind" */
  delete_inspection_place_kind_by_pk?: Maybe<Inspection_Place_Kind>;
  /** delete data from the table: "inspection_reluctant_kind" */
  delete_inspection_reluctant_kind?: Maybe<Inspection_Reluctant_Kind_Mutation_Response>;
  /** delete single row from the table: "inspection_reluctant_kind" */
  delete_inspection_reluctant_kind_by_pk?: Maybe<Inspection_Reluctant_Kind>;
  /** delete data from the table: "inspection_state" */
  delete_inspection_state?: Maybe<Inspection_State_Mutation_Response>;
  /** delete single row from the table: "inspection_state" */
  delete_inspection_state_by_pk?: Maybe<Inspection_State>;
  /** delete data from the table: "microred" */
  delete_microred?: Maybe<Microred_Mutation_Response>;
  /** delete single row from the table: "microred" */
  delete_microred_by_pk?: Maybe<Microred>;
  /** delete data from the table: "observation" */
  delete_observation?: Maybe<Observation_Mutation_Response>;
  /** delete single row from the table: "observation" */
  delete_observation_by_pk?: Maybe<Observation>;
  /** delete data from the table: "place" */
  delete_place?: Maybe<Place_Mutation_Response>;
  /** delete single row from the table: "place" */
  delete_place_by_pk?: Maybe<Place>;
  /** delete data from the table: "role_children" */
  delete_role_children?: Maybe<Role_Children_Mutation_Response>;
  /** delete single row from the table: "role_children" */
  delete_role_children_by_pk?: Maybe<Role_Children>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** delete single row from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** delete data from the table: "sprayed" */
  delete_sprayed?: Maybe<Sprayed_Mutation_Response>;
  /** delete single row from the table: "sprayed" */
  delete_sprayed_by_pk?: Maybe<Sprayed>;
  /** delete data from the table: "sprayed_residual_treatment_kind" */
  delete_sprayed_residual_treatment_kind?: Maybe<Sprayed_Residual_Treatment_Kind_Mutation_Response>;
  /** delete single row from the table: "sprayed_residual_treatment_kind" */
  delete_sprayed_residual_treatment_kind_by_pk?: Maybe<Sprayed_Residual_Treatment_Kind>;
  /** delete data from the table: "sprayer" */
  delete_sprayer?: Maybe<Sprayer_Mutation_Response>;
  /** delete single row from the table: "sprayer" */
  delete_sprayer_by_pk?: Maybe<Sprayer>;
  /** delete data from the table: "user_roles" */
  delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** delete single row from the table: "user_roles" */
  delete_user_roles_by_pk?: Maybe<User_Roles>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "work_sessions" */
  delete_work_sessions?: Maybe<Work_Sessions_Mutation_Response>;
  /** delete single row from the table: "work_sessions" */
  delete_work_sessions_by_pk?: Maybe<Work_Sessions>;
  /** delete data from the table: "workspace" */
  delete_workspace?: Maybe<Workspace_Mutation_Response>;
  /** delete single row from the table: "workspace" */
  delete_workspace_by_pk?: Maybe<Workspace>;
  /** delete data from the table: "workspace_metadata" */
  delete_workspace_metadata?: Maybe<Workspace_Metadata_Mutation_Response>;
  /** delete single row from the table: "workspace_metadata" */
  delete_workspace_metadata_by_pk?: Maybe<Workspace_Metadata>;
  /** delete data from the table: "workspace_owners" */
  delete_workspace_owners?: Maybe<Workspace_Owners_Mutation_Response>;
  /** delete single row from the table: "workspace_owners" */
  delete_workspace_owners_by_pk?: Maybe<Workspace_Owners>;
  /** insert data into the table: "Capture_Place" */
  insert_Capture_Place?: Maybe<Capture_Place_Mutation_Response>;
  /** insert a single row into the table: "Capture_Place" */
  insert_Capture_Place_one?: Maybe<Capture_Place>;
  /** insert data into the table: "Number_Triatomines_Captured" */
  insert_Number_Triatomines_Captured?: Maybe<Number_Triatomines_Captured_Mutation_Response>;
  /** insert a single row into the table: "Number_Triatomines_Captured" */
  insert_Number_Triatomines_Captured_one?: Maybe<Number_Triatomines_Captured>;
  /** insert data into the table: "Number_Triatomines_Inspected" */
  insert_Number_Triatomines_Inspected?: Maybe<Number_Triatomines_Inspected_Mutation_Response>;
  /** insert a single row into the table: "Number_Triatomines_Inspected" */
  insert_Number_Triatomines_Inspected_one?: Maybe<Number_Triatomines_Inspected>;
  /** insert data into the table: "Number_Triatomines_Positive" */
  insert_Number_Triatomines_Positive?: Maybe<Number_Triatomines_Positive_Mutation_Response>;
  /** insert a single row into the table: "Number_Triatomines_Positive" */
  insert_Number_Triatomines_Positive_one?: Maybe<Number_Triatomines_Positive>;
  /** insert data into the table: "Place_Place" */
  insert_Place_Place?: Maybe<Place_Place_Mutation_Response>;
  /** insert a single row into the table: "Place_Place" */
  insert_Place_Place_one?: Maybe<Place_Place>;
  /** insert data into the table: "Place_Triatomines_Experiment_Record" */
  insert_Place_Triatomines_Experiment_Record?: Maybe<Place_Triatomines_Experiment_Record_Mutation_Response>;
  /** insert a single row into the table: "Place_Triatomines_Experiment_Record" */
  insert_Place_Triatomines_Experiment_Record_one?: Maybe<Place_Triatomines_Experiment_Record>;
  /** insert data into the table: "Place_children" */
  insert_Place_children?: Maybe<Place_Children_Mutation_Response>;
  /** insert a single row into the table: "Place_children" */
  insert_Place_children_one?: Maybe<Place_Children>;
  /** insert data into the table: "Place_files" */
  insert_Place_files?: Maybe<Place_Files_Mutation_Response>;
  /** insert a single row into the table: "Place_files" */
  insert_Place_files_one?: Maybe<Place_Files>;
  /** insert data into the table: "Place" */
  insert_Place_kind?: Maybe<Place_Kind_Mutation_Response>;
  /** insert a single row into the table: "Place" */
  insert_Place_kind_one?: Maybe<Place_Kind>;
  /** insert data into the table: "Place_record" */
  insert_Place_record?: Maybe<Place_Record_Mutation_Response>;
  /** insert a single row into the table: "Place_record" */
  insert_Place_record_one?: Maybe<Place_Record>;
  /** insert data into the table: "Triatomines" */
  insert_Triatomines?: Maybe<Triatomines_Mutation_Response>;
  /** insert data into the table: "Triatomines_Experiment_Record" */
  insert_Triatomines_Experiment_Record?: Maybe<Triatomines_Experiment_Record_Mutation_Response>;
  /** insert data into the table: "Triatomines_Experiment_Record_Place" */
  insert_Triatomines_Experiment_Record_Place?: Maybe<Triatomines_Experiment_Record_Place_Mutation_Response>;
  /** insert a single row into the table: "Triatomines_Experiment_Record_Place" */
  insert_Triatomines_Experiment_Record_Place_one?: Maybe<Triatomines_Experiment_Record_Place>;
  /** insert data into the table: "Triatomines_Experiment_Record_directus_users" */
  insert_Triatomines_Experiment_Record_directus_users?: Maybe<Triatomines_Experiment_Record_Directus_Users_Mutation_Response>;
  /** insert a single row into the table: "Triatomines_Experiment_Record_directus_users" */
  insert_Triatomines_Experiment_Record_directus_users_one?: Maybe<Triatomines_Experiment_Record_Directus_Users>;
  /** insert a single row into the table: "Triatomines_Experiment_Record" */
  insert_Triatomines_Experiment_Record_one?: Maybe<Triatomines_Experiment_Record>;
  /** insert a single row into the table: "Triatomines" */
  insert_Triatomines_one?: Maybe<Triatomines>;
  /** insert data into the table: "advertisement" */
  insert_advertisement?: Maybe<Advertisement_Mutation_Response>;
  /** insert a single row into the table: "advertisement" */
  insert_advertisement_one?: Maybe<Advertisement>;
  /** insert data into the table: "collaborator" */
  insert_collaborator?: Maybe<Collaborator_Mutation_Response>;
  /** insert a single row into the table: "collaborator" */
  insert_collaborator_one?: Maybe<Collaborator>;
  /** insert data into the table: "complaint" */
  insert_complaint?: Maybe<Complaint_Mutation_Response>;
  /** insert data into the table: "complaint_kind" */
  insert_complaint_kind?: Maybe<Complaint_Kind_Mutation_Response>;
  /** insert a single row into the table: "complaint_kind" */
  insert_complaint_kind_one?: Maybe<Complaint_Kind>;
  /** insert a single row into the table: "complaint" */
  insert_complaint_one?: Maybe<Complaint>;
  /** insert data into the table: "complaint_source" */
  insert_complaint_source?: Maybe<Complaint_Source_Mutation_Response>;
  /** insert a single row into the table: "complaint_source" */
  insert_complaint_source_one?: Maybe<Complaint_Source>;
  /** insert data into the table: "distances" */
  insert_distances?: Maybe<Distances_Mutation_Response>;
  /** insert a single row into the table: "distances" */
  insert_distances_one?: Maybe<Distances>;
  /** insert data into the table: "estate_kind" */
  insert_estate_kind?: Maybe<Estate_Kind_Mutation_Response>;
  /** insert a single row into the table: "estate_kind" */
  insert_estate_kind_one?: Maybe<Estate_Kind>;
  /** insert data into the table: "experiment" */
  insert_experiment?: Maybe<Experiment_Mutation_Response>;
  /** insert a single row into the table: "experiment" */
  insert_experiment_one?: Maybe<Experiment>;
  /** insert data into the table: "geogroup" */
  insert_geogroup?: Maybe<Geogroup_Mutation_Response>;
  /** insert a single row into the table: "geogroup" */
  insert_geogroup_one?: Maybe<Geogroup>;
  /** insert data into the table: "geometry_columns" */
  insert_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** insert a single row into the table: "geometry_columns" */
  insert_geometry_columns_one?: Maybe<Geometry_Columns>;
  /** insert data into the table: "geopoint" */
  insert_geopoint?: Maybe<Geopoint_Mutation_Response>;
  /** insert a single row into the table: "geopoint" */
  insert_geopoint_one?: Maybe<Geopoint>;
  /** insert data into the table: "inspection" */
  insert_inspection?: Maybe<Inspection_Mutation_Response>;
  /** insert data into the table: "inspection_event_kind" */
  insert_inspection_event_kind?: Maybe<Inspection_Event_Kind_Mutation_Response>;
  /** insert a single row into the table: "inspection_event_kind" */
  insert_inspection_event_kind_one?: Maybe<Inspection_Event_Kind>;
  /** insert data into the table: "inspection_interview_kind" */
  insert_inspection_interview_kind?: Maybe<Inspection_Interview_Kind_Mutation_Response>;
  /** insert a single row into the table: "inspection_interview_kind" */
  insert_inspection_interview_kind_one?: Maybe<Inspection_Interview_Kind>;
  /** insert a single row into the table: "inspection" */
  insert_inspection_one?: Maybe<Inspection>;
  /** insert data into the table: "inspection_place_kind" */
  insert_inspection_place_kind?: Maybe<Inspection_Place_Kind_Mutation_Response>;
  /** insert a single row into the table: "inspection_place_kind" */
  insert_inspection_place_kind_one?: Maybe<Inspection_Place_Kind>;
  /** insert data into the table: "inspection_reluctant_kind" */
  insert_inspection_reluctant_kind?: Maybe<Inspection_Reluctant_Kind_Mutation_Response>;
  /** insert a single row into the table: "inspection_reluctant_kind" */
  insert_inspection_reluctant_kind_one?: Maybe<Inspection_Reluctant_Kind>;
  /** insert data into the table: "inspection_state" */
  insert_inspection_state?: Maybe<Inspection_State_Mutation_Response>;
  /** insert a single row into the table: "inspection_state" */
  insert_inspection_state_one?: Maybe<Inspection_State>;
  /** insert data into the table: "microred" */
  insert_microred?: Maybe<Microred_Mutation_Response>;
  /** insert a single row into the table: "microred" */
  insert_microred_one?: Maybe<Microred>;
  /** insert data into the table: "observation" */
  insert_observation?: Maybe<Observation_Mutation_Response>;
  /** insert a single row into the table: "observation" */
  insert_observation_one?: Maybe<Observation>;
  /** insert data into the table: "place" */
  insert_place?: Maybe<Place_Mutation_Response>;
  /** insert a single row into the table: "place" */
  insert_place_one?: Maybe<Place>;
  /** insert data into the table: "role_children" */
  insert_role_children?: Maybe<Role_Children_Mutation_Response>;
  /** insert a single row into the table: "role_children" */
  insert_role_children_one?: Maybe<Role_Children>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** insert a single row into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys_one?: Maybe<Spatial_Ref_Sys>;
  /** insert data into the table: "sprayed" */
  insert_sprayed?: Maybe<Sprayed_Mutation_Response>;
  /** insert a single row into the table: "sprayed" */
  insert_sprayed_one?: Maybe<Sprayed>;
  /** insert data into the table: "sprayed_residual_treatment_kind" */
  insert_sprayed_residual_treatment_kind?: Maybe<Sprayed_Residual_Treatment_Kind_Mutation_Response>;
  /** insert a single row into the table: "sprayed_residual_treatment_kind" */
  insert_sprayed_residual_treatment_kind_one?: Maybe<Sprayed_Residual_Treatment_Kind>;
  /** insert data into the table: "sprayer" */
  insert_sprayer?: Maybe<Sprayer_Mutation_Response>;
  /** insert a single row into the table: "sprayer" */
  insert_sprayer_one?: Maybe<Sprayer>;
  /** insert data into the table: "user_roles" */
  insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** insert a single row into the table: "user_roles" */
  insert_user_roles_one?: Maybe<User_Roles>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "work_sessions" */
  insert_work_sessions?: Maybe<Work_Sessions_Mutation_Response>;
  /** insert a single row into the table: "work_sessions" */
  insert_work_sessions_one?: Maybe<Work_Sessions>;
  /** insert data into the table: "workspace" */
  insert_workspace?: Maybe<Workspace_Mutation_Response>;
  /** insert data into the table: "workspace_metadata" */
  insert_workspace_metadata?: Maybe<Workspace_Metadata_Mutation_Response>;
  /** insert a single row into the table: "workspace_metadata" */
  insert_workspace_metadata_one?: Maybe<Workspace_Metadata>;
  /** insert a single row into the table: "workspace" */
  insert_workspace_one?: Maybe<Workspace>;
  /** insert data into the table: "workspace_owners" */
  insert_workspace_owners?: Maybe<Workspace_Owners_Mutation_Response>;
  /** insert a single row into the table: "workspace_owners" */
  insert_workspace_owners_one?: Maybe<Workspace_Owners>;
  /** update data of the table: "Capture_Place" */
  update_Capture_Place?: Maybe<Capture_Place_Mutation_Response>;
  /** update single row of the table: "Capture_Place" */
  update_Capture_Place_by_pk?: Maybe<Capture_Place>;
  /** update multiples rows of table: "Capture_Place" */
  update_Capture_Place_many?: Maybe<Array<Maybe<Capture_Place_Mutation_Response>>>;
  /** update data of the table: "Number_Triatomines_Captured" */
  update_Number_Triatomines_Captured?: Maybe<Number_Triatomines_Captured_Mutation_Response>;
  /** update single row of the table: "Number_Triatomines_Captured" */
  update_Number_Triatomines_Captured_by_pk?: Maybe<Number_Triatomines_Captured>;
  /** update multiples rows of table: "Number_Triatomines_Captured" */
  update_Number_Triatomines_Captured_many?: Maybe<
    Array<Maybe<Number_Triatomines_Captured_Mutation_Response>>
  >;
  /** update data of the table: "Number_Triatomines_Inspected" */
  update_Number_Triatomines_Inspected?: Maybe<Number_Triatomines_Inspected_Mutation_Response>;
  /** update single row of the table: "Number_Triatomines_Inspected" */
  update_Number_Triatomines_Inspected_by_pk?: Maybe<Number_Triatomines_Inspected>;
  /** update multiples rows of table: "Number_Triatomines_Inspected" */
  update_Number_Triatomines_Inspected_many?: Maybe<
    Array<Maybe<Number_Triatomines_Inspected_Mutation_Response>>
  >;
  /** update data of the table: "Number_Triatomines_Positive" */
  update_Number_Triatomines_Positive?: Maybe<Number_Triatomines_Positive_Mutation_Response>;
  /** update single row of the table: "Number_Triatomines_Positive" */
  update_Number_Triatomines_Positive_by_pk?: Maybe<Number_Triatomines_Positive>;
  /** update multiples rows of table: "Number_Triatomines_Positive" */
  update_Number_Triatomines_Positive_many?: Maybe<
    Array<Maybe<Number_Triatomines_Positive_Mutation_Response>>
  >;
  /** update data of the table: "Place_Place" */
  update_Place_Place?: Maybe<Place_Place_Mutation_Response>;
  /** update single row of the table: "Place_Place" */
  update_Place_Place_by_pk?: Maybe<Place_Place>;
  /** update multiples rows of table: "Place_Place" */
  update_Place_Place_many?: Maybe<Array<Maybe<Place_Place_Mutation_Response>>>;
  /** update data of the table: "Place_Triatomines_Experiment_Record" */
  update_Place_Triatomines_Experiment_Record?: Maybe<Place_Triatomines_Experiment_Record_Mutation_Response>;
  /** update single row of the table: "Place_Triatomines_Experiment_Record" */
  update_Place_Triatomines_Experiment_Record_by_pk?: Maybe<Place_Triatomines_Experiment_Record>;
  /** update multiples rows of table: "Place_Triatomines_Experiment_Record" */
  update_Place_Triatomines_Experiment_Record_many?: Maybe<
    Array<Maybe<Place_Triatomines_Experiment_Record_Mutation_Response>>
  >;
  /** update data of the table: "Place_children" */
  update_Place_children?: Maybe<Place_Children_Mutation_Response>;
  /** update single row of the table: "Place_children" */
  update_Place_children_by_pk?: Maybe<Place_Children>;
  /** update multiples rows of table: "Place_children" */
  update_Place_children_many?: Maybe<Array<Maybe<Place_Children_Mutation_Response>>>;
  /** update data of the table: "Place_files" */
  update_Place_files?: Maybe<Place_Files_Mutation_Response>;
  /** update single row of the table: "Place_files" */
  update_Place_files_by_pk?: Maybe<Place_Files>;
  /** update multiples rows of table: "Place_files" */
  update_Place_files_many?: Maybe<Array<Maybe<Place_Files_Mutation_Response>>>;
  /** update data of the table: "Place" */
  update_Place_kind?: Maybe<Place_Kind_Mutation_Response>;
  /** update single row of the table: "Place" */
  update_Place_kind_by_pk?: Maybe<Place_Kind>;
  /** update multiples rows of table: "Place" */
  update_Place_kind_many?: Maybe<Array<Maybe<Place_Kind_Mutation_Response>>>;
  /** update data of the table: "Place_record" */
  update_Place_record?: Maybe<Place_Record_Mutation_Response>;
  /** update single row of the table: "Place_record" */
  update_Place_record_by_pk?: Maybe<Place_Record>;
  /** update multiples rows of table: "Place_record" */
  update_Place_record_many?: Maybe<Array<Maybe<Place_Record_Mutation_Response>>>;
  /** update data of the table: "Triatomines" */
  update_Triatomines?: Maybe<Triatomines_Mutation_Response>;
  /** update data of the table: "Triatomines_Experiment_Record" */
  update_Triatomines_Experiment_Record?: Maybe<Triatomines_Experiment_Record_Mutation_Response>;
  /** update data of the table: "Triatomines_Experiment_Record_Place" */
  update_Triatomines_Experiment_Record_Place?: Maybe<Triatomines_Experiment_Record_Place_Mutation_Response>;
  /** update single row of the table: "Triatomines_Experiment_Record_Place" */
  update_Triatomines_Experiment_Record_Place_by_pk?: Maybe<Triatomines_Experiment_Record_Place>;
  /** update multiples rows of table: "Triatomines_Experiment_Record_Place" */
  update_Triatomines_Experiment_Record_Place_many?: Maybe<
    Array<Maybe<Triatomines_Experiment_Record_Place_Mutation_Response>>
  >;
  /** update single row of the table: "Triatomines_Experiment_Record" */
  update_Triatomines_Experiment_Record_by_pk?: Maybe<Triatomines_Experiment_Record>;
  /** update data of the table: "Triatomines_Experiment_Record_directus_users" */
  update_Triatomines_Experiment_Record_directus_users?: Maybe<Triatomines_Experiment_Record_Directus_Users_Mutation_Response>;
  /** update single row of the table: "Triatomines_Experiment_Record_directus_users" */
  update_Triatomines_Experiment_Record_directus_users_by_pk?: Maybe<Triatomines_Experiment_Record_Directus_Users>;
  /** update multiples rows of table: "Triatomines_Experiment_Record_directus_users" */
  update_Triatomines_Experiment_Record_directus_users_many?: Maybe<
    Array<Maybe<Triatomines_Experiment_Record_Directus_Users_Mutation_Response>>
  >;
  /** update multiples rows of table: "Triatomines_Experiment_Record" */
  update_Triatomines_Experiment_Record_many?: Maybe<
    Array<Maybe<Triatomines_Experiment_Record_Mutation_Response>>
  >;
  /** update single row of the table: "Triatomines" */
  update_Triatomines_by_pk?: Maybe<Triatomines>;
  /** update multiples rows of table: "Triatomines" */
  update_Triatomines_many?: Maybe<Array<Maybe<Triatomines_Mutation_Response>>>;
  /** update data of the table: "advertisement" */
  update_advertisement?: Maybe<Advertisement_Mutation_Response>;
  /** update single row of the table: "advertisement" */
  update_advertisement_by_pk?: Maybe<Advertisement>;
  /** update multiples rows of table: "advertisement" */
  update_advertisement_many?: Maybe<Array<Maybe<Advertisement_Mutation_Response>>>;
  /** update data of the table: "collaborator" */
  update_collaborator?: Maybe<Collaborator_Mutation_Response>;
  /** update single row of the table: "collaborator" */
  update_collaborator_by_pk?: Maybe<Collaborator>;
  /** update multiples rows of table: "collaborator" */
  update_collaborator_many?: Maybe<Array<Maybe<Collaborator_Mutation_Response>>>;
  /** update data of the table: "complaint" */
  update_complaint?: Maybe<Complaint_Mutation_Response>;
  /** update single row of the table: "complaint" */
  update_complaint_by_pk?: Maybe<Complaint>;
  /** update data of the table: "complaint_kind" */
  update_complaint_kind?: Maybe<Complaint_Kind_Mutation_Response>;
  /** update single row of the table: "complaint_kind" */
  update_complaint_kind_by_pk?: Maybe<Complaint_Kind>;
  /** update multiples rows of table: "complaint_kind" */
  update_complaint_kind_many?: Maybe<Array<Maybe<Complaint_Kind_Mutation_Response>>>;
  /** update multiples rows of table: "complaint" */
  update_complaint_many?: Maybe<Array<Maybe<Complaint_Mutation_Response>>>;
  /** update data of the table: "complaint_source" */
  update_complaint_source?: Maybe<Complaint_Source_Mutation_Response>;
  /** update single row of the table: "complaint_source" */
  update_complaint_source_by_pk?: Maybe<Complaint_Source>;
  /** update multiples rows of table: "complaint_source" */
  update_complaint_source_many?: Maybe<Array<Maybe<Complaint_Source_Mutation_Response>>>;
  /** update data of the table: "distances" */
  update_distances?: Maybe<Distances_Mutation_Response>;
  /** update single row of the table: "distances" */
  update_distances_by_pk?: Maybe<Distances>;
  /** update multiples rows of table: "distances" */
  update_distances_many?: Maybe<Array<Maybe<Distances_Mutation_Response>>>;
  /** update data of the table: "estate_kind" */
  update_estate_kind?: Maybe<Estate_Kind_Mutation_Response>;
  /** update single row of the table: "estate_kind" */
  update_estate_kind_by_pk?: Maybe<Estate_Kind>;
  /** update multiples rows of table: "estate_kind" */
  update_estate_kind_many?: Maybe<Array<Maybe<Estate_Kind_Mutation_Response>>>;
  /** update data of the table: "experiment" */
  update_experiment?: Maybe<Experiment_Mutation_Response>;
  /** update single row of the table: "experiment" */
  update_experiment_by_pk?: Maybe<Experiment>;
  /** update multiples rows of table: "experiment" */
  update_experiment_many?: Maybe<Array<Maybe<Experiment_Mutation_Response>>>;
  /** update data of the table: "geogroup" */
  update_geogroup?: Maybe<Geogroup_Mutation_Response>;
  /** update single row of the table: "geogroup" */
  update_geogroup_by_pk?: Maybe<Geogroup>;
  /** update multiples rows of table: "geogroup" */
  update_geogroup_many?: Maybe<Array<Maybe<Geogroup_Mutation_Response>>>;
  /** update data of the table: "geometry_columns" */
  update_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** update multiples rows of table: "geometry_columns" */
  update_geometry_columns_many?: Maybe<Array<Maybe<Geometry_Columns_Mutation_Response>>>;
  /** update data of the table: "geopoint" */
  update_geopoint?: Maybe<Geopoint_Mutation_Response>;
  /** update single row of the table: "geopoint" */
  update_geopoint_by_pk?: Maybe<Geopoint>;
  /** update multiples rows of table: "geopoint" */
  update_geopoint_many?: Maybe<Array<Maybe<Geopoint_Mutation_Response>>>;
  /** update data of the table: "inspection" */
  update_inspection?: Maybe<Inspection_Mutation_Response>;
  /** update single row of the table: "inspection" */
  update_inspection_by_pk?: Maybe<Inspection>;
  /** update data of the table: "inspection_event_kind" */
  update_inspection_event_kind?: Maybe<Inspection_Event_Kind_Mutation_Response>;
  /** update single row of the table: "inspection_event_kind" */
  update_inspection_event_kind_by_pk?: Maybe<Inspection_Event_Kind>;
  /** update multiples rows of table: "inspection_event_kind" */
  update_inspection_event_kind_many?: Maybe<Array<Maybe<Inspection_Event_Kind_Mutation_Response>>>;
  /** update data of the table: "inspection_interview_kind" */
  update_inspection_interview_kind?: Maybe<Inspection_Interview_Kind_Mutation_Response>;
  /** update single row of the table: "inspection_interview_kind" */
  update_inspection_interview_kind_by_pk?: Maybe<Inspection_Interview_Kind>;
  /** update multiples rows of table: "inspection_interview_kind" */
  update_inspection_interview_kind_many?: Maybe<
    Array<Maybe<Inspection_Interview_Kind_Mutation_Response>>
  >;
  /** update multiples rows of table: "inspection" */
  update_inspection_many?: Maybe<Array<Maybe<Inspection_Mutation_Response>>>;
  /** update data of the table: "inspection_place_kind" */
  update_inspection_place_kind?: Maybe<Inspection_Place_Kind_Mutation_Response>;
  /** update single row of the table: "inspection_place_kind" */
  update_inspection_place_kind_by_pk?: Maybe<Inspection_Place_Kind>;
  /** update multiples rows of table: "inspection_place_kind" */
  update_inspection_place_kind_many?: Maybe<Array<Maybe<Inspection_Place_Kind_Mutation_Response>>>;
  /** update data of the table: "inspection_reluctant_kind" */
  update_inspection_reluctant_kind?: Maybe<Inspection_Reluctant_Kind_Mutation_Response>;
  /** update single row of the table: "inspection_reluctant_kind" */
  update_inspection_reluctant_kind_by_pk?: Maybe<Inspection_Reluctant_Kind>;
  /** update multiples rows of table: "inspection_reluctant_kind" */
  update_inspection_reluctant_kind_many?: Maybe<
    Array<Maybe<Inspection_Reluctant_Kind_Mutation_Response>>
  >;
  /** update data of the table: "inspection_state" */
  update_inspection_state?: Maybe<Inspection_State_Mutation_Response>;
  /** update single row of the table: "inspection_state" */
  update_inspection_state_by_pk?: Maybe<Inspection_State>;
  /** update multiples rows of table: "inspection_state" */
  update_inspection_state_many?: Maybe<Array<Maybe<Inspection_State_Mutation_Response>>>;
  /** update data of the table: "microred" */
  update_microred?: Maybe<Microred_Mutation_Response>;
  /** update single row of the table: "microred" */
  update_microred_by_pk?: Maybe<Microred>;
  /** update multiples rows of table: "microred" */
  update_microred_many?: Maybe<Array<Maybe<Microred_Mutation_Response>>>;
  /** update data of the table: "observation" */
  update_observation?: Maybe<Observation_Mutation_Response>;
  /** update single row of the table: "observation" */
  update_observation_by_pk?: Maybe<Observation>;
  /** update multiples rows of table: "observation" */
  update_observation_many?: Maybe<Array<Maybe<Observation_Mutation_Response>>>;
  /** update data of the table: "place" */
  update_place?: Maybe<Place_Mutation_Response>;
  /** update single row of the table: "place" */
  update_place_by_pk?: Maybe<Place>;
  /** update multiples rows of table: "place" */
  update_place_many?: Maybe<Array<Maybe<Place_Mutation_Response>>>;
  /** update data of the table: "role_children" */
  update_role_children?: Maybe<Role_Children_Mutation_Response>;
  /** update single row of the table: "role_children" */
  update_role_children_by_pk?: Maybe<Role_Children>;
  /** update multiples rows of table: "role_children" */
  update_role_children_many?: Maybe<Array<Maybe<Role_Children_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "spatial_ref_sys" */
  update_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** update single row of the table: "spatial_ref_sys" */
  update_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** update multiples rows of table: "spatial_ref_sys" */
  update_spatial_ref_sys_many?: Maybe<Array<Maybe<Spatial_Ref_Sys_Mutation_Response>>>;
  /** update data of the table: "sprayed" */
  update_sprayed?: Maybe<Sprayed_Mutation_Response>;
  /** update single row of the table: "sprayed" */
  update_sprayed_by_pk?: Maybe<Sprayed>;
  /** update multiples rows of table: "sprayed" */
  update_sprayed_many?: Maybe<Array<Maybe<Sprayed_Mutation_Response>>>;
  /** update data of the table: "sprayed_residual_treatment_kind" */
  update_sprayed_residual_treatment_kind?: Maybe<Sprayed_Residual_Treatment_Kind_Mutation_Response>;
  /** update single row of the table: "sprayed_residual_treatment_kind" */
  update_sprayed_residual_treatment_kind_by_pk?: Maybe<Sprayed_Residual_Treatment_Kind>;
  /** update multiples rows of table: "sprayed_residual_treatment_kind" */
  update_sprayed_residual_treatment_kind_many?: Maybe<
    Array<Maybe<Sprayed_Residual_Treatment_Kind_Mutation_Response>>
  >;
  /** update data of the table: "sprayer" */
  update_sprayer?: Maybe<Sprayer_Mutation_Response>;
  /** update single row of the table: "sprayer" */
  update_sprayer_by_pk?: Maybe<Sprayer>;
  /** update multiples rows of table: "sprayer" */
  update_sprayer_many?: Maybe<Array<Maybe<Sprayer_Mutation_Response>>>;
  /** update data of the table: "user_roles" */
  update_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** update single row of the table: "user_roles" */
  update_user_roles_by_pk?: Maybe<User_Roles>;
  /** update multiples rows of table: "user_roles" */
  update_user_roles_many?: Maybe<Array<Maybe<User_Roles_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "work_sessions" */
  update_work_sessions?: Maybe<Work_Sessions_Mutation_Response>;
  /** update single row of the table: "work_sessions" */
  update_work_sessions_by_pk?: Maybe<Work_Sessions>;
  /** update multiples rows of table: "work_sessions" */
  update_work_sessions_many?: Maybe<Array<Maybe<Work_Sessions_Mutation_Response>>>;
  /** update data of the table: "workspace" */
  update_workspace?: Maybe<Workspace_Mutation_Response>;
  /** update single row of the table: "workspace" */
  update_workspace_by_pk?: Maybe<Workspace>;
  /** update multiples rows of table: "workspace" */
  update_workspace_many?: Maybe<Array<Maybe<Workspace_Mutation_Response>>>;
  /** update data of the table: "workspace_metadata" */
  update_workspace_metadata?: Maybe<Workspace_Metadata_Mutation_Response>;
  /** update single row of the table: "workspace_metadata" */
  update_workspace_metadata_by_pk?: Maybe<Workspace_Metadata>;
  /** update multiples rows of table: "workspace_metadata" */
  update_workspace_metadata_many?: Maybe<Array<Maybe<Workspace_Metadata_Mutation_Response>>>;
  /** update data of the table: "workspace_owners" */
  update_workspace_owners?: Maybe<Workspace_Owners_Mutation_Response>;
  /** update single row of the table: "workspace_owners" */
  update_workspace_owners_by_pk?: Maybe<Workspace_Owners>;
  /** update multiples rows of table: "workspace_owners" */
  update_workspace_owners_many?: Maybe<Array<Maybe<Workspace_Owners_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootDelete_Capture_PlaceArgs = {
  where: Capture_Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Capture_Place_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Number_Triatomines_CapturedArgs = {
  where: Number_Triatomines_Captured_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Number_Triatomines_Captured_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Number_Triatomines_InspectedArgs = {
  where: Number_Triatomines_Inspected_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Number_Triatomines_Inspected_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Number_Triatomines_PositiveArgs = {
  where: Number_Triatomines_Positive_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Number_Triatomines_Positive_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Place_PlaceArgs = {
  where: Place_Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Place_Place_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Place_Triatomines_Experiment_RecordArgs = {
  where: Place_Triatomines_Experiment_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Place_Triatomines_Experiment_Record_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Place_ChildrenArgs = {
  where: Place_Children_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Place_Children_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Place_FilesArgs = {
  where: Place_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Place_Files_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Place_KindArgs = {
  where: Place_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Place_Kind_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Place_RecordArgs = {
  where: Place_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Place_Record_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_TriatominesArgs = {
  where: Triatomines_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Triatomines_Experiment_RecordArgs = {
  where: Triatomines_Experiment_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Triatomines_Experiment_Record_PlaceArgs = {
  where: Triatomines_Experiment_Record_Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Triatomines_Experiment_Record_Place_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Triatomines_Experiment_Record_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Triatomines_Experiment_Record_Directus_UsersArgs = {
  where: Triatomines_Experiment_Record_Directus_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Triatomines_Experiment_Record_Directus_Users_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Triatomines_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_AdvertisementArgs = {
  where: Advertisement_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Advertisement_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_CollaboratorArgs = {
  where: Collaborator_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Collaborator_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ComplaintArgs = {
  where: Complaint_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Complaint_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Complaint_KindArgs = {
  where: Complaint_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Complaint_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Complaint_SourceArgs = {
  where: Complaint_Source_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Complaint_Source_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_DistancesArgs = {
  where: Distances_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Distances_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Estate_KindArgs = {
  where: Estate_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Estate_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_ExperimentArgs = {
  where: Experiment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Experiment_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_GeogroupArgs = {
  where: Geogroup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Geogroup_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Geometry_ColumnsArgs = {
  where: Geometry_Columns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GeopointArgs = {
  where: Geopoint_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Geopoint_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_InspectionArgs = {
  where: Inspection_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inspection_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Inspection_Event_KindArgs = {
  where: Inspection_Event_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inspection_Event_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Inspection_Interview_KindArgs = {
  where: Inspection_Interview_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inspection_Interview_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Inspection_Place_KindArgs = {
  where: Inspection_Place_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inspection_Place_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Inspection_Reluctant_KindArgs = {
  where: Inspection_Reluctant_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inspection_Reluctant_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Inspection_StateArgs = {
  where: Inspection_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inspection_State_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_MicroredArgs = {
  where: Microred_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Microred_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ObservationArgs = {
  where: Observation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Observation_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_PlaceArgs = {
  where: Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Place_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Role_ChildrenArgs = {
  where: Role_Children_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Children_By_PkArgs = {
  parent_id: Scalars["uuid"];
  role_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_SysArgs = {
  where: Spatial_Ref_Sys_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_Sys_By_PkArgs = {
  srid: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_SprayedArgs = {
  where: Sprayed_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sprayed_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Sprayed_Residual_Treatment_KindArgs = {
  where: Sprayed_Residual_Treatment_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sprayed_Residual_Treatment_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_SprayerArgs = {
  where: Sprayer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sprayer_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_User_RolesArgs = {
  where: User_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Roles_By_PkArgs = {
  role_id: Scalars["uuid"];
  user_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Work_SessionsArgs = {
  where: Work_Sessions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Work_Sessions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_WorkspaceArgs = {
  where: Workspace_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Workspace_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Workspace_MetadataArgs = {
  where: Workspace_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Workspace_Metadata_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Workspace_OwnersArgs = {
  where: Workspace_Owners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Workspace_Owners_By_PkArgs = {
  owner_id: Scalars["uuid"];
  workspace_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootInsert_Capture_PlaceArgs = {
  objects: Array<Capture_Place_Insert_Input>;
  on_conflict?: InputMaybe<Capture_Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Capture_Place_OneArgs = {
  object: Capture_Place_Insert_Input;
  on_conflict?: InputMaybe<Capture_Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Number_Triatomines_CapturedArgs = {
  objects: Array<Number_Triatomines_Captured_Insert_Input>;
  on_conflict?: InputMaybe<Number_Triatomines_Captured_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Number_Triatomines_Captured_OneArgs = {
  object: Number_Triatomines_Captured_Insert_Input;
  on_conflict?: InputMaybe<Number_Triatomines_Captured_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Number_Triatomines_InspectedArgs = {
  objects: Array<Number_Triatomines_Inspected_Insert_Input>;
  on_conflict?: InputMaybe<Number_Triatomines_Inspected_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Number_Triatomines_Inspected_OneArgs = {
  object: Number_Triatomines_Inspected_Insert_Input;
  on_conflict?: InputMaybe<Number_Triatomines_Inspected_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Number_Triatomines_PositiveArgs = {
  objects: Array<Number_Triatomines_Positive_Insert_Input>;
  on_conflict?: InputMaybe<Number_Triatomines_Positive_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Number_Triatomines_Positive_OneArgs = {
  object: Number_Triatomines_Positive_Insert_Input;
  on_conflict?: InputMaybe<Number_Triatomines_Positive_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_PlaceArgs = {
  objects: Array<Place_Place_Insert_Input>;
  on_conflict?: InputMaybe<Place_Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_Place_OneArgs = {
  object: Place_Place_Insert_Input;
  on_conflict?: InputMaybe<Place_Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_Triatomines_Experiment_RecordArgs = {
  objects: Array<Place_Triatomines_Experiment_Record_Insert_Input>;
  on_conflict?: InputMaybe<Place_Triatomines_Experiment_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_Triatomines_Experiment_Record_OneArgs = {
  object: Place_Triatomines_Experiment_Record_Insert_Input;
  on_conflict?: InputMaybe<Place_Triatomines_Experiment_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_ChildrenArgs = {
  objects: Array<Place_Children_Insert_Input>;
  on_conflict?: InputMaybe<Place_Children_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_Children_OneArgs = {
  object: Place_Children_Insert_Input;
  on_conflict?: InputMaybe<Place_Children_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_FilesArgs = {
  objects: Array<Place_Files_Insert_Input>;
  on_conflict?: InputMaybe<Place_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_Files_OneArgs = {
  object: Place_Files_Insert_Input;
  on_conflict?: InputMaybe<Place_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_KindArgs = {
  objects: Array<Place_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Place_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_Kind_OneArgs = {
  object: Place_Kind_Insert_Input;
  on_conflict?: InputMaybe<Place_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_RecordArgs = {
  objects: Array<Place_Record_Insert_Input>;
  on_conflict?: InputMaybe<Place_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_Record_OneArgs = {
  object: Place_Record_Insert_Input;
  on_conflict?: InputMaybe<Place_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TriatominesArgs = {
  objects: Array<Triatomines_Insert_Input>;
  on_conflict?: InputMaybe<Triatomines_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Triatomines_Experiment_RecordArgs = {
  objects: Array<Triatomines_Experiment_Record_Insert_Input>;
  on_conflict?: InputMaybe<Triatomines_Experiment_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Triatomines_Experiment_Record_PlaceArgs = {
  objects: Array<Triatomines_Experiment_Record_Place_Insert_Input>;
  on_conflict?: InputMaybe<Triatomines_Experiment_Record_Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Triatomines_Experiment_Record_Place_OneArgs = {
  object: Triatomines_Experiment_Record_Place_Insert_Input;
  on_conflict?: InputMaybe<Triatomines_Experiment_Record_Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Triatomines_Experiment_Record_Directus_UsersArgs = {
  objects: Array<Triatomines_Experiment_Record_Directus_Users_Insert_Input>;
  on_conflict?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Triatomines_Experiment_Record_Directus_Users_OneArgs = {
  object: Triatomines_Experiment_Record_Directus_Users_Insert_Input;
  on_conflict?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Triatomines_Experiment_Record_OneArgs = {
  object: Triatomines_Experiment_Record_Insert_Input;
  on_conflict?: InputMaybe<Triatomines_Experiment_Record_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Triatomines_OneArgs = {
  object: Triatomines_Insert_Input;
  on_conflict?: InputMaybe<Triatomines_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AdvertisementArgs = {
  objects: Array<Advertisement_Insert_Input>;
  on_conflict?: InputMaybe<Advertisement_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Advertisement_OneArgs = {
  object: Advertisement_Insert_Input;
  on_conflict?: InputMaybe<Advertisement_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CollaboratorArgs = {
  objects: Array<Collaborator_Insert_Input>;
  on_conflict?: InputMaybe<Collaborator_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Collaborator_OneArgs = {
  object: Collaborator_Insert_Input;
  on_conflict?: InputMaybe<Collaborator_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ComplaintArgs = {
  objects: Array<Complaint_Insert_Input>;
  on_conflict?: InputMaybe<Complaint_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Complaint_KindArgs = {
  objects: Array<Complaint_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Complaint_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Complaint_Kind_OneArgs = {
  object: Complaint_Kind_Insert_Input;
  on_conflict?: InputMaybe<Complaint_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Complaint_OneArgs = {
  object: Complaint_Insert_Input;
  on_conflict?: InputMaybe<Complaint_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Complaint_SourceArgs = {
  objects: Array<Complaint_Source_Insert_Input>;
  on_conflict?: InputMaybe<Complaint_Source_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Complaint_Source_OneArgs = {
  object: Complaint_Source_Insert_Input;
  on_conflict?: InputMaybe<Complaint_Source_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DistancesArgs = {
  objects: Array<Distances_Insert_Input>;
  on_conflict?: InputMaybe<Distances_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Distances_OneArgs = {
  object: Distances_Insert_Input;
  on_conflict?: InputMaybe<Distances_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Estate_KindArgs = {
  objects: Array<Estate_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Estate_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Estate_Kind_OneArgs = {
  object: Estate_Kind_Insert_Input;
  on_conflict?: InputMaybe<Estate_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ExperimentArgs = {
  objects: Array<Experiment_Insert_Input>;
  on_conflict?: InputMaybe<Experiment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Experiment_OneArgs = {
  object: Experiment_Insert_Input;
  on_conflict?: InputMaybe<Experiment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GeogroupArgs = {
  objects: Array<Geogroup_Insert_Input>;
  on_conflict?: InputMaybe<Geogroup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Geogroup_OneArgs = {
  object: Geogroup_Insert_Input;
  on_conflict?: InputMaybe<Geogroup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Geometry_ColumnsArgs = {
  objects: Array<Geometry_Columns_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Geometry_Columns_OneArgs = {
  object: Geometry_Columns_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_GeopointArgs = {
  objects: Array<Geopoint_Insert_Input>;
  on_conflict?: InputMaybe<Geopoint_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Geopoint_OneArgs = {
  object: Geopoint_Insert_Input;
  on_conflict?: InputMaybe<Geopoint_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_InspectionArgs = {
  objects: Array<Inspection_Insert_Input>;
  on_conflict?: InputMaybe<Inspection_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_Event_KindArgs = {
  objects: Array<Inspection_Event_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Inspection_Event_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_Event_Kind_OneArgs = {
  object: Inspection_Event_Kind_Insert_Input;
  on_conflict?: InputMaybe<Inspection_Event_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_Interview_KindArgs = {
  objects: Array<Inspection_Interview_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Inspection_Interview_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_Interview_Kind_OneArgs = {
  object: Inspection_Interview_Kind_Insert_Input;
  on_conflict?: InputMaybe<Inspection_Interview_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_OneArgs = {
  object: Inspection_Insert_Input;
  on_conflict?: InputMaybe<Inspection_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_Place_KindArgs = {
  objects: Array<Inspection_Place_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Inspection_Place_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_Place_Kind_OneArgs = {
  object: Inspection_Place_Kind_Insert_Input;
  on_conflict?: InputMaybe<Inspection_Place_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_Reluctant_KindArgs = {
  objects: Array<Inspection_Reluctant_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Inspection_Reluctant_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_Reluctant_Kind_OneArgs = {
  object: Inspection_Reluctant_Kind_Insert_Input;
  on_conflict?: InputMaybe<Inspection_Reluctant_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_StateArgs = {
  objects: Array<Inspection_State_Insert_Input>;
  on_conflict?: InputMaybe<Inspection_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_State_OneArgs = {
  object: Inspection_State_Insert_Input;
  on_conflict?: InputMaybe<Inspection_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MicroredArgs = {
  objects: Array<Microred_Insert_Input>;
  on_conflict?: InputMaybe<Microred_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Microred_OneArgs = {
  object: Microred_Insert_Input;
  on_conflict?: InputMaybe<Microred_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ObservationArgs = {
  objects: Array<Observation_Insert_Input>;
  on_conflict?: InputMaybe<Observation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Observation_OneArgs = {
  object: Observation_Insert_Input;
  on_conflict?: InputMaybe<Observation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PlaceArgs = {
  objects: Array<Place_Insert_Input>;
  on_conflict?: InputMaybe<Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_OneArgs = {
  object: Place_Insert_Input;
  on_conflict?: InputMaybe<Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_ChildrenArgs = {
  objects: Array<Role_Children_Insert_Input>;
  on_conflict?: InputMaybe<Role_Children_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Children_OneArgs = {
  object: Role_Children_Insert_Input;
  on_conflict?: InputMaybe<Role_Children_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_SysArgs = {
  objects: Array<Spatial_Ref_Sys_Insert_Input>;
  on_conflict?: InputMaybe<Spatial_Ref_Sys_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_Sys_OneArgs = {
  object: Spatial_Ref_Sys_Insert_Input;
  on_conflict?: InputMaybe<Spatial_Ref_Sys_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SprayedArgs = {
  objects: Array<Sprayed_Insert_Input>;
  on_conflict?: InputMaybe<Sprayed_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sprayed_OneArgs = {
  object: Sprayed_Insert_Input;
  on_conflict?: InputMaybe<Sprayed_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sprayed_Residual_Treatment_KindArgs = {
  objects: Array<Sprayed_Residual_Treatment_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Sprayed_Residual_Treatment_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sprayed_Residual_Treatment_Kind_OneArgs = {
  object: Sprayed_Residual_Treatment_Kind_Insert_Input;
  on_conflict?: InputMaybe<Sprayed_Residual_Treatment_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SprayerArgs = {
  objects: Array<Sprayer_Insert_Input>;
  on_conflict?: InputMaybe<Sprayer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sprayer_OneArgs = {
  object: Sprayer_Insert_Input;
  on_conflict?: InputMaybe<Sprayer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_RolesArgs = {
  objects: Array<User_Roles_Insert_Input>;
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Roles_OneArgs = {
  object: User_Roles_Insert_Input;
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_SessionsArgs = {
  objects: Array<Work_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Work_Sessions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_Sessions_OneArgs = {
  object: Work_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Work_Sessions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WorkspaceArgs = {
  objects: Array<Workspace_Insert_Input>;
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_MetadataArgs = {
  objects: Array<Workspace_Metadata_Insert_Input>;
  on_conflict?: InputMaybe<Workspace_Metadata_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_Metadata_OneArgs = {
  object: Workspace_Metadata_Insert_Input;
  on_conflict?: InputMaybe<Workspace_Metadata_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_OneArgs = {
  object: Workspace_Insert_Input;
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_OwnersArgs = {
  objects: Array<Workspace_Owners_Insert_Input>;
  on_conflict?: InputMaybe<Workspace_Owners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_Owners_OneArgs = {
  object: Workspace_Owners_Insert_Input;
  on_conflict?: InputMaybe<Workspace_Owners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Capture_PlaceArgs = {
  _inc?: InputMaybe<Capture_Place_Inc_Input>;
  _set?: InputMaybe<Capture_Place_Set_Input>;
  where: Capture_Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Capture_Place_By_PkArgs = {
  _inc?: InputMaybe<Capture_Place_Inc_Input>;
  _set?: InputMaybe<Capture_Place_Set_Input>;
  pk_columns: Capture_Place_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Capture_Place_ManyArgs = {
  updates: Array<Capture_Place_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Triatomines_CapturedArgs = {
  _inc?: InputMaybe<Number_Triatomines_Captured_Inc_Input>;
  _set?: InputMaybe<Number_Triatomines_Captured_Set_Input>;
  where: Number_Triatomines_Captured_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Triatomines_Captured_By_PkArgs = {
  _inc?: InputMaybe<Number_Triatomines_Captured_Inc_Input>;
  _set?: InputMaybe<Number_Triatomines_Captured_Set_Input>;
  pk_columns: Number_Triatomines_Captured_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Triatomines_Captured_ManyArgs = {
  updates: Array<Number_Triatomines_Captured_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Triatomines_InspectedArgs = {
  _inc?: InputMaybe<Number_Triatomines_Inspected_Inc_Input>;
  _set?: InputMaybe<Number_Triatomines_Inspected_Set_Input>;
  where: Number_Triatomines_Inspected_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Triatomines_Inspected_By_PkArgs = {
  _inc?: InputMaybe<Number_Triatomines_Inspected_Inc_Input>;
  _set?: InputMaybe<Number_Triatomines_Inspected_Set_Input>;
  pk_columns: Number_Triatomines_Inspected_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Triatomines_Inspected_ManyArgs = {
  updates: Array<Number_Triatomines_Inspected_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Triatomines_PositiveArgs = {
  _inc?: InputMaybe<Number_Triatomines_Positive_Inc_Input>;
  _set?: InputMaybe<Number_Triatomines_Positive_Set_Input>;
  where: Number_Triatomines_Positive_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Triatomines_Positive_By_PkArgs = {
  _inc?: InputMaybe<Number_Triatomines_Positive_Inc_Input>;
  _set?: InputMaybe<Number_Triatomines_Positive_Set_Input>;
  pk_columns: Number_Triatomines_Positive_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Triatomines_Positive_ManyArgs = {
  updates: Array<Number_Triatomines_Positive_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Place_PlaceArgs = {
  _inc?: InputMaybe<Place_Place_Inc_Input>;
  _set?: InputMaybe<Place_Place_Set_Input>;
  where: Place_Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Place_By_PkArgs = {
  _inc?: InputMaybe<Place_Place_Inc_Input>;
  _set?: InputMaybe<Place_Place_Set_Input>;
  pk_columns: Place_Place_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Place_ManyArgs = {
  updates: Array<Place_Place_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Triatomines_Experiment_RecordArgs = {
  _inc?: InputMaybe<Place_Triatomines_Experiment_Record_Inc_Input>;
  _set?: InputMaybe<Place_Triatomines_Experiment_Record_Set_Input>;
  where: Place_Triatomines_Experiment_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Triatomines_Experiment_Record_By_PkArgs = {
  _inc?: InputMaybe<Place_Triatomines_Experiment_Record_Inc_Input>;
  _set?: InputMaybe<Place_Triatomines_Experiment_Record_Set_Input>;
  pk_columns: Place_Triatomines_Experiment_Record_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Triatomines_Experiment_Record_ManyArgs = {
  updates: Array<Place_Triatomines_Experiment_Record_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Place_ChildrenArgs = {
  _inc?: InputMaybe<Place_Children_Inc_Input>;
  _set?: InputMaybe<Place_Children_Set_Input>;
  where: Place_Children_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Children_By_PkArgs = {
  _inc?: InputMaybe<Place_Children_Inc_Input>;
  _set?: InputMaybe<Place_Children_Set_Input>;
  pk_columns: Place_Children_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Children_ManyArgs = {
  updates: Array<Place_Children_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Place_FilesArgs = {
  _inc?: InputMaybe<Place_Files_Inc_Input>;
  _set?: InputMaybe<Place_Files_Set_Input>;
  where: Place_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Files_By_PkArgs = {
  _inc?: InputMaybe<Place_Files_Inc_Input>;
  _set?: InputMaybe<Place_Files_Set_Input>;
  pk_columns: Place_Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Files_ManyArgs = {
  updates: Array<Place_Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Place_KindArgs = {
  _inc?: InputMaybe<Place_Kind_Inc_Input>;
  _set?: InputMaybe<Place_Kind_Set_Input>;
  where: Place_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Kind_By_PkArgs = {
  _inc?: InputMaybe<Place_Kind_Inc_Input>;
  _set?: InputMaybe<Place_Kind_Set_Input>;
  pk_columns: Place_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Kind_ManyArgs = {
  updates: Array<Place_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Place_RecordArgs = {
  _inc?: InputMaybe<Place_Record_Inc_Input>;
  _set?: InputMaybe<Place_Record_Set_Input>;
  where: Place_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Record_By_PkArgs = {
  _inc?: InputMaybe<Place_Record_Inc_Input>;
  _set?: InputMaybe<Place_Record_Set_Input>;
  pk_columns: Place_Record_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Place_Record_ManyArgs = {
  updates: Array<Place_Record_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TriatominesArgs = {
  _inc?: InputMaybe<Triatomines_Inc_Input>;
  _set?: InputMaybe<Triatomines_Set_Input>;
  where: Triatomines_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_Experiment_RecordArgs = {
  _inc?: InputMaybe<Triatomines_Experiment_Record_Inc_Input>;
  _set?: InputMaybe<Triatomines_Experiment_Record_Set_Input>;
  where: Triatomines_Experiment_Record_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_Experiment_Record_PlaceArgs = {
  _inc?: InputMaybe<Triatomines_Experiment_Record_Place_Inc_Input>;
  _set?: InputMaybe<Triatomines_Experiment_Record_Place_Set_Input>;
  where: Triatomines_Experiment_Record_Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_Experiment_Record_Place_By_PkArgs = {
  _inc?: InputMaybe<Triatomines_Experiment_Record_Place_Inc_Input>;
  _set?: InputMaybe<Triatomines_Experiment_Record_Place_Set_Input>;
  pk_columns: Triatomines_Experiment_Record_Place_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_Experiment_Record_Place_ManyArgs = {
  updates: Array<Triatomines_Experiment_Record_Place_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_Experiment_Record_By_PkArgs = {
  _inc?: InputMaybe<Triatomines_Experiment_Record_Inc_Input>;
  _set?: InputMaybe<Triatomines_Experiment_Record_Set_Input>;
  pk_columns: Triatomines_Experiment_Record_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_Experiment_Record_Directus_UsersArgs = {
  _inc?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Inc_Input>;
  _set?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Set_Input>;
  where: Triatomines_Experiment_Record_Directus_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_Experiment_Record_Directus_Users_By_PkArgs = {
  _inc?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Inc_Input>;
  _set?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Set_Input>;
  pk_columns: Triatomines_Experiment_Record_Directus_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_Experiment_Record_Directus_Users_ManyArgs = {
  updates: Array<Triatomines_Experiment_Record_Directus_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_Experiment_Record_ManyArgs = {
  updates: Array<Triatomines_Experiment_Record_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_By_PkArgs = {
  _inc?: InputMaybe<Triatomines_Inc_Input>;
  _set?: InputMaybe<Triatomines_Set_Input>;
  pk_columns: Triatomines_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Triatomines_ManyArgs = {
  updates: Array<Triatomines_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AdvertisementArgs = {
  _set?: InputMaybe<Advertisement_Set_Input>;
  where: Advertisement_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Advertisement_By_PkArgs = {
  _set?: InputMaybe<Advertisement_Set_Input>;
  pk_columns: Advertisement_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Advertisement_ManyArgs = {
  updates: Array<Advertisement_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CollaboratorArgs = {
  _set?: InputMaybe<Collaborator_Set_Input>;
  where: Collaborator_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Collaborator_By_PkArgs = {
  _set?: InputMaybe<Collaborator_Set_Input>;
  pk_columns: Collaborator_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Collaborator_ManyArgs = {
  updates: Array<Collaborator_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ComplaintArgs = {
  _inc?: InputMaybe<Complaint_Inc_Input>;
  _set?: InputMaybe<Complaint_Set_Input>;
  where: Complaint_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_By_PkArgs = {
  _inc?: InputMaybe<Complaint_Inc_Input>;
  _set?: InputMaybe<Complaint_Set_Input>;
  pk_columns: Complaint_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_KindArgs = {
  _set?: InputMaybe<Complaint_Kind_Set_Input>;
  where: Complaint_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_Kind_By_PkArgs = {
  _set?: InputMaybe<Complaint_Kind_Set_Input>;
  pk_columns: Complaint_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_Kind_ManyArgs = {
  updates: Array<Complaint_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_ManyArgs = {
  updates: Array<Complaint_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_SourceArgs = {
  _set?: InputMaybe<Complaint_Source_Set_Input>;
  where: Complaint_Source_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_Source_By_PkArgs = {
  _set?: InputMaybe<Complaint_Source_Set_Input>;
  pk_columns: Complaint_Source_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_Source_ManyArgs = {
  updates: Array<Complaint_Source_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DistancesArgs = {
  _append?: InputMaybe<Distances_Append_Input>;
  _delete_at_path?: InputMaybe<Distances_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Distances_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Distances_Delete_Key_Input>;
  _inc?: InputMaybe<Distances_Inc_Input>;
  _prepend?: InputMaybe<Distances_Prepend_Input>;
  _set?: InputMaybe<Distances_Set_Input>;
  where: Distances_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Distances_By_PkArgs = {
  _append?: InputMaybe<Distances_Append_Input>;
  _delete_at_path?: InputMaybe<Distances_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Distances_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Distances_Delete_Key_Input>;
  _inc?: InputMaybe<Distances_Inc_Input>;
  _prepend?: InputMaybe<Distances_Prepend_Input>;
  _set?: InputMaybe<Distances_Set_Input>;
  pk_columns: Distances_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Distances_ManyArgs = {
  updates: Array<Distances_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Estate_KindArgs = {
  _set?: InputMaybe<Estate_Kind_Set_Input>;
  where: Estate_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Estate_Kind_By_PkArgs = {
  _set?: InputMaybe<Estate_Kind_Set_Input>;
  pk_columns: Estate_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Estate_Kind_ManyArgs = {
  updates: Array<Estate_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ExperimentArgs = {
  _inc?: InputMaybe<Experiment_Inc_Input>;
  _set?: InputMaybe<Experiment_Set_Input>;
  where: Experiment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Experiment_By_PkArgs = {
  _inc?: InputMaybe<Experiment_Inc_Input>;
  _set?: InputMaybe<Experiment_Set_Input>;
  pk_columns: Experiment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Experiment_ManyArgs = {
  updates: Array<Experiment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GeogroupArgs = {
  _append?: InputMaybe<Geogroup_Append_Input>;
  _delete_at_path?: InputMaybe<Geogroup_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Geogroup_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Geogroup_Delete_Key_Input>;
  _inc?: InputMaybe<Geogroup_Inc_Input>;
  _prepend?: InputMaybe<Geogroup_Prepend_Input>;
  _set?: InputMaybe<Geogroup_Set_Input>;
  where: Geogroup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Geogroup_By_PkArgs = {
  _append?: InputMaybe<Geogroup_Append_Input>;
  _delete_at_path?: InputMaybe<Geogroup_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Geogroup_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Geogroup_Delete_Key_Input>;
  _inc?: InputMaybe<Geogroup_Inc_Input>;
  _prepend?: InputMaybe<Geogroup_Prepend_Input>;
  _set?: InputMaybe<Geogroup_Set_Input>;
  pk_columns: Geogroup_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Geogroup_ManyArgs = {
  updates: Array<Geogroup_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Geometry_ColumnsArgs = {
  _inc?: InputMaybe<Geometry_Columns_Inc_Input>;
  _set?: InputMaybe<Geometry_Columns_Set_Input>;
  where: Geometry_Columns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Geometry_Columns_ManyArgs = {
  updates: Array<Geometry_Columns_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GeopointArgs = {
  _inc?: InputMaybe<Geopoint_Inc_Input>;
  _set?: InputMaybe<Geopoint_Set_Input>;
  where: Geopoint_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Geopoint_By_PkArgs = {
  _inc?: InputMaybe<Geopoint_Inc_Input>;
  _set?: InputMaybe<Geopoint_Set_Input>;
  pk_columns: Geopoint_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Geopoint_ManyArgs = {
  updates: Array<Geopoint_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_InspectionArgs = {
  _append?: InputMaybe<Inspection_Append_Input>;
  _delete_at_path?: InputMaybe<Inspection_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Inspection_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Inspection_Delete_Key_Input>;
  _inc?: InputMaybe<Inspection_Inc_Input>;
  _prepend?: InputMaybe<Inspection_Prepend_Input>;
  _set?: InputMaybe<Inspection_Set_Input>;
  where: Inspection_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_By_PkArgs = {
  _append?: InputMaybe<Inspection_Append_Input>;
  _delete_at_path?: InputMaybe<Inspection_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Inspection_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Inspection_Delete_Key_Input>;
  _inc?: InputMaybe<Inspection_Inc_Input>;
  _prepend?: InputMaybe<Inspection_Prepend_Input>;
  _set?: InputMaybe<Inspection_Set_Input>;
  pk_columns: Inspection_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Event_KindArgs = {
  _set?: InputMaybe<Inspection_Event_Kind_Set_Input>;
  where: Inspection_Event_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Event_Kind_By_PkArgs = {
  _set?: InputMaybe<Inspection_Event_Kind_Set_Input>;
  pk_columns: Inspection_Event_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Event_Kind_ManyArgs = {
  updates: Array<Inspection_Event_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Interview_KindArgs = {
  _set?: InputMaybe<Inspection_Interview_Kind_Set_Input>;
  where: Inspection_Interview_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Interview_Kind_By_PkArgs = {
  _set?: InputMaybe<Inspection_Interview_Kind_Set_Input>;
  pk_columns: Inspection_Interview_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Interview_Kind_ManyArgs = {
  updates: Array<Inspection_Interview_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_ManyArgs = {
  updates: Array<Inspection_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Place_KindArgs = {
  _set?: InputMaybe<Inspection_Place_Kind_Set_Input>;
  where: Inspection_Place_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Place_Kind_By_PkArgs = {
  _set?: InputMaybe<Inspection_Place_Kind_Set_Input>;
  pk_columns: Inspection_Place_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Place_Kind_ManyArgs = {
  updates: Array<Inspection_Place_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Reluctant_KindArgs = {
  _set?: InputMaybe<Inspection_Reluctant_Kind_Set_Input>;
  where: Inspection_Reluctant_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Reluctant_Kind_By_PkArgs = {
  _set?: InputMaybe<Inspection_Reluctant_Kind_Set_Input>;
  pk_columns: Inspection_Reluctant_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_Reluctant_Kind_ManyArgs = {
  updates: Array<Inspection_Reluctant_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_StateArgs = {
  _set?: InputMaybe<Inspection_State_Set_Input>;
  where: Inspection_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_State_By_PkArgs = {
  _set?: InputMaybe<Inspection_State_Set_Input>;
  pk_columns: Inspection_State_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_State_ManyArgs = {
  updates: Array<Inspection_State_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_MicroredArgs = {
  _set?: InputMaybe<Microred_Set_Input>;
  where: Microred_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Microred_By_PkArgs = {
  _set?: InputMaybe<Microred_Set_Input>;
  pk_columns: Microred_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Microred_ManyArgs = {
  updates: Array<Microred_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ObservationArgs = {
  _set?: InputMaybe<Observation_Set_Input>;
  where: Observation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Observation_By_PkArgs = {
  _set?: InputMaybe<Observation_Set_Input>;
  pk_columns: Observation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Observation_ManyArgs = {
  updates: Array<Observation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PlaceArgs = {
  _set?: InputMaybe<Place_Set_Input>;
  where: Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Place_By_PkArgs = {
  _set?: InputMaybe<Place_Set_Input>;
  pk_columns: Place_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Place_ManyArgs = {
  updates: Array<Place_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Role_ChildrenArgs = {
  _set?: InputMaybe<Role_Children_Set_Input>;
  where: Role_Children_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Children_By_PkArgs = {
  _set?: InputMaybe<Role_Children_Set_Input>;
  pk_columns: Role_Children_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Children_ManyArgs = {
  updates: Array<Role_Children_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_SysArgs = {
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  where: Spatial_Ref_Sys_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_By_PkArgs = {
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  pk_columns: Spatial_Ref_Sys_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_ManyArgs = {
  updates: Array<Spatial_Ref_Sys_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SprayedArgs = {
  _append?: InputMaybe<Sprayed_Append_Input>;
  _delete_at_path?: InputMaybe<Sprayed_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sprayed_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sprayed_Delete_Key_Input>;
  _inc?: InputMaybe<Sprayed_Inc_Input>;
  _prepend?: InputMaybe<Sprayed_Prepend_Input>;
  _set?: InputMaybe<Sprayed_Set_Input>;
  where: Sprayed_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayed_By_PkArgs = {
  _append?: InputMaybe<Sprayed_Append_Input>;
  _delete_at_path?: InputMaybe<Sprayed_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sprayed_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sprayed_Delete_Key_Input>;
  _inc?: InputMaybe<Sprayed_Inc_Input>;
  _prepend?: InputMaybe<Sprayed_Prepend_Input>;
  _set?: InputMaybe<Sprayed_Set_Input>;
  pk_columns: Sprayed_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayed_ManyArgs = {
  updates: Array<Sprayed_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayed_Residual_Treatment_KindArgs = {
  _set?: InputMaybe<Sprayed_Residual_Treatment_Kind_Set_Input>;
  where: Sprayed_Residual_Treatment_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayed_Residual_Treatment_Kind_By_PkArgs = {
  _set?: InputMaybe<Sprayed_Residual_Treatment_Kind_Set_Input>;
  pk_columns: Sprayed_Residual_Treatment_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayed_Residual_Treatment_Kind_ManyArgs = {
  updates: Array<Sprayed_Residual_Treatment_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SprayerArgs = {
  _set?: InputMaybe<Sprayer_Set_Input>;
  where: Sprayer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayer_By_PkArgs = {
  _set?: InputMaybe<Sprayer_Set_Input>;
  pk_columns: Sprayer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayer_ManyArgs = {
  updates: Array<Sprayer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
  _set?: InputMaybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
  _set?: InputMaybe<User_Roles_Set_Input>;
  pk_columns: User_Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Roles_ManyArgs = {
  updates: Array<User_Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Work_SessionsArgs = {
  _inc?: InputMaybe<Work_Sessions_Inc_Input>;
  _set?: InputMaybe<Work_Sessions_Set_Input>;
  where: Work_Sessions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Sessions_By_PkArgs = {
  _inc?: InputMaybe<Work_Sessions_Inc_Input>;
  _set?: InputMaybe<Work_Sessions_Set_Input>;
  pk_columns: Work_Sessions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Sessions_ManyArgs = {
  updates: Array<Work_Sessions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_WorkspaceArgs = {
  _set?: InputMaybe<Workspace_Set_Input>;
  where: Workspace_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_By_PkArgs = {
  _set?: InputMaybe<Workspace_Set_Input>;
  pk_columns: Workspace_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_ManyArgs = {
  updates: Array<Workspace_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_MetadataArgs = {
  _append?: InputMaybe<Workspace_Metadata_Append_Input>;
  _delete_at_path?: InputMaybe<Workspace_Metadata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Workspace_Metadata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Workspace_Metadata_Delete_Key_Input>;
  _prepend?: InputMaybe<Workspace_Metadata_Prepend_Input>;
  _set?: InputMaybe<Workspace_Metadata_Set_Input>;
  where: Workspace_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_Metadata_By_PkArgs = {
  _append?: InputMaybe<Workspace_Metadata_Append_Input>;
  _delete_at_path?: InputMaybe<Workspace_Metadata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Workspace_Metadata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Workspace_Metadata_Delete_Key_Input>;
  _prepend?: InputMaybe<Workspace_Metadata_Prepend_Input>;
  _set?: InputMaybe<Workspace_Metadata_Set_Input>;
  pk_columns: Workspace_Metadata_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_Metadata_ManyArgs = {
  updates: Array<Workspace_Metadata_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_OwnersArgs = {
  _set?: InputMaybe<Workspace_Owners_Set_Input>;
  where: Workspace_Owners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_Owners_By_PkArgs = {
  _set?: InputMaybe<Workspace_Owners_Set_Input>;
  pk_columns: Workspace_Owners_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_Owners_ManyArgs = {
  updates: Array<Workspace_Owners_Updates>;
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["name"]>;
  _gt?: InputMaybe<Scalars["name"]>;
  _gte?: InputMaybe<Scalars["name"]>;
  _in?: InputMaybe<Array<Scalars["name"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["name"]>;
  _lte?: InputMaybe<Scalars["name"]>;
  _neq?: InputMaybe<Scalars["name"]>;
  _nin?: InputMaybe<Array<Scalars["name"]>>;
};

/** columns and relationships of "observation" */
export type Observation = {
  __typename?: "observation";
  app_state: Scalars["String"];
  /** An object relationship */
  collaborator?: Maybe<Collaborator>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  firebase_id?: Maybe<Scalars["String"]>;
  /** An object relationship */
  geopoint?: Maybe<Geopoint>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  observation?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "observation" */
export type Observation_Aggregate = {
  __typename?: "observation_aggregate";
  aggregate?: Maybe<Observation_Aggregate_Fields>;
  nodes: Array<Observation>;
};

/** aggregate fields of "observation" */
export type Observation_Aggregate_Fields = {
  __typename?: "observation_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Observation_Max_Fields>;
  min?: Maybe<Observation_Min_Fields>;
};

/** aggregate fields of "observation" */
export type Observation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Observation_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "observation". All fields are combined with a logical 'AND'. */
export type Observation_Bool_Exp = {
  _and?: InputMaybe<Array<Observation_Bool_Exp>>;
  _not?: InputMaybe<Observation_Bool_Exp>;
  _or?: InputMaybe<Array<Observation_Bool_Exp>>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  collaborator?: InputMaybe<Collaborator_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  geopoint?: InputMaybe<Geopoint_Bool_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  observation?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "observation" */
export enum Observation_Constraint {
  /** unique or primary key constraint on columns "id" */
  ObservationPkey = "observation_pkey",
}

/** input type for inserting data into table "observation" */
export type Observation_Insert_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  observation?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Observation_Max_Fields = {
  __typename?: "observation_max_fields";
  app_state?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  observation?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Observation_Min_Fields = {
  __typename?: "observation_min_fields";
  app_state?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  observation?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "observation" */
export type Observation_Mutation_Response = {
  __typename?: "observation_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Observation>;
};

/** on_conflict condition type for table "observation" */
export type Observation_On_Conflict = {
  constraint: Observation_Constraint;
  update_columns?: Array<Observation_Update_Column>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

/** Ordering options when selecting data from "observation". */
export type Observation_Order_By = {
  app_state?: InputMaybe<Order_By>;
  collaborator?: InputMaybe<Collaborator_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geopoint?: InputMaybe<Geopoint_Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  observation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: observation */
export type Observation_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "observation" */
export enum Observation_Select_Column {
  /** column name */
  AppState = "app_state",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  Observation = "observation",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

/** input type for updating data in table "observation" */
export type Observation_Set_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  observation?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "observation" */
export type Observation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Observation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Observation_Stream_Cursor_Value_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  observation?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "observation" */
export enum Observation_Update_Column {
  /** column name */
  AppState = "app_state",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  Observation = "observation",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

export type Observation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Observation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Observation_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "place" */
export type Place = {
  __typename?: "place";
  /** An array relationship */
  children: Array<Place>;
  /** An aggregate relationship */
  children_aggregate: Place_Aggregate;
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  geometry?: Maybe<Scalars["geometry"]>;
  id: Scalars["uuid"];
  kind?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** An object relationship */
  parent?: Maybe<Place>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "place" */
export type PlaceChildrenArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

/** columns and relationships of "place" */
export type PlaceChildren_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

/** aggregated selection of "place" */
export type Place_Aggregate = {
  __typename?: "place_aggregate";
  aggregate?: Maybe<Place_Aggregate_Fields>;
  nodes: Array<Place>;
};

export type Place_Aggregate_Bool_Exp = {
  count?: InputMaybe<Place_Aggregate_Bool_Exp_Count>;
};

export type Place_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Place_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Place_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "place" */
export type Place_Aggregate_Fields = {
  __typename?: "place_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Place_Max_Fields>;
  min?: Maybe<Place_Min_Fields>;
};

/** aggregate fields of "place" */
export type Place_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Place_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "place" */
export type Place_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Place_Max_Order_By>;
  min?: InputMaybe<Place_Min_Order_By>;
};

/** input type for inserting array relation for remote table "place" */
export type Place_Arr_Rel_Insert_Input = {
  data: Array<Place_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Place_On_Conflict>;
};

/** Boolean expression to filter rows from the table "place". All fields are combined with a logical 'AND'. */
export type Place_Bool_Exp = {
  _and?: InputMaybe<Array<Place_Bool_Exp>>;
  _not?: InputMaybe<Place_Bool_Exp>;
  _or?: InputMaybe<Array<Place_Bool_Exp>>;
  children?: InputMaybe<Place_Bool_Exp>;
  children_aggregate?: InputMaybe<Place_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent?: InputMaybe<Place_Bool_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "place" */
export enum Place_Constraint {
  /** unique or primary key constraint on columns "name", "kind", "parent_id" */
  PlaceKindNameParentIdKey = "place_kind_name_parent_id_key",
  /** unique or primary key constraint on columns "id" */
  PlacePkey = "place_pkey",
}

/** input type for inserting data into table "place" */
export type Place_Insert_Input = {
  children?: InputMaybe<Place_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  parent?: InputMaybe<Place_Obj_Rel_Insert_Input>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Place_Max_Fields = {
  __typename?: "place_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "place" */
export type Place_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Place_Min_Fields = {
  __typename?: "place_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "place" */
export type Place_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "place" */
export type Place_Mutation_Response = {
  __typename?: "place_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Place>;
};

/** input type for inserting object relation for remote table "place" */
export type Place_Obj_Rel_Insert_Input = {
  data: Place_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Place_On_Conflict>;
};

/** on_conflict condition type for table "place" */
export type Place_On_Conflict = {
  constraint: Place_Constraint;
  update_columns?: Array<Place_Update_Column>;
  where?: InputMaybe<Place_Bool_Exp>;
};

/** Ordering options when selecting data from "place". */
export type Place_Order_By = {
  children_aggregate?: InputMaybe<Place_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent?: InputMaybe<Place_Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: place */
export type Place_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "place" */
export enum Place_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Name = "name",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "place" */
export type Place_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "place" */
export type Place_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Place_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Place_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "place" */
export enum Place_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Name = "name",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Place_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Place_Set_Input>;
  /** filter the rows which have to be updated */
  where: Place_Bool_Exp;
};

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "Capture_Place" */
  Capture_Place: Array<Capture_Place>;
  /** fetch aggregated fields from the table: "Capture_Place" */
  Capture_Place_aggregate: Capture_Place_Aggregate;
  /** fetch data from the table: "Capture_Place" using primary key columns */
  Capture_Place_by_pk?: Maybe<Capture_Place>;
  /** fetch data from the table: "Number_Triatomines_Captured" */
  Number_Triatomines_Captured: Array<Number_Triatomines_Captured>;
  /** fetch aggregated fields from the table: "Number_Triatomines_Captured" */
  Number_Triatomines_Captured_aggregate: Number_Triatomines_Captured_Aggregate;
  /** fetch data from the table: "Number_Triatomines_Captured" using primary key columns */
  Number_Triatomines_Captured_by_pk?: Maybe<Number_Triatomines_Captured>;
  /** fetch data from the table: "Number_Triatomines_Inspected" */
  Number_Triatomines_Inspected: Array<Number_Triatomines_Inspected>;
  /** fetch aggregated fields from the table: "Number_Triatomines_Inspected" */
  Number_Triatomines_Inspected_aggregate: Number_Triatomines_Inspected_Aggregate;
  /** fetch data from the table: "Number_Triatomines_Inspected" using primary key columns */
  Number_Triatomines_Inspected_by_pk?: Maybe<Number_Triatomines_Inspected>;
  /** fetch data from the table: "Number_Triatomines_Positive" */
  Number_Triatomines_Positive: Array<Number_Triatomines_Positive>;
  /** fetch aggregated fields from the table: "Number_Triatomines_Positive" */
  Number_Triatomines_Positive_aggregate: Number_Triatomines_Positive_Aggregate;
  /** fetch data from the table: "Number_Triatomines_Positive" using primary key columns */
  Number_Triatomines_Positive_by_pk?: Maybe<Number_Triatomines_Positive>;
  /** fetch data from the table: "Place_Place" */
  Place_Place: Array<Place_Place>;
  /** fetch aggregated fields from the table: "Place_Place" */
  Place_Place_aggregate: Place_Place_Aggregate;
  /** fetch data from the table: "Place_Place" using primary key columns */
  Place_Place_by_pk?: Maybe<Place_Place>;
  /** fetch data from the table: "Place_Triatomines_Experiment_Record" */
  Place_Triatomines_Experiment_Record: Array<Place_Triatomines_Experiment_Record>;
  /** fetch aggregated fields from the table: "Place_Triatomines_Experiment_Record" */
  Place_Triatomines_Experiment_Record_aggregate: Place_Triatomines_Experiment_Record_Aggregate;
  /** fetch data from the table: "Place_Triatomines_Experiment_Record" using primary key columns */
  Place_Triatomines_Experiment_Record_by_pk?: Maybe<Place_Triatomines_Experiment_Record>;
  /** fetch data from the table: "Place_children" */
  Place_children: Array<Place_Children>;
  /** fetch aggregated fields from the table: "Place_children" */
  Place_children_aggregate: Place_Children_Aggregate;
  /** fetch data from the table: "Place_children" using primary key columns */
  Place_children_by_pk?: Maybe<Place_Children>;
  /** fetch data from the table: "Place_files" */
  Place_files: Array<Place_Files>;
  /** fetch aggregated fields from the table: "Place_files" */
  Place_files_aggregate: Place_Files_Aggregate;
  /** fetch data from the table: "Place_files" using primary key columns */
  Place_files_by_pk?: Maybe<Place_Files>;
  /** fetch data from the table: "Place" */
  Place_kind: Array<Place_Kind>;
  /** fetch aggregated fields from the table: "Place" */
  Place_kind_aggregate: Place_Kind_Aggregate;
  /** fetch data from the table: "Place" using primary key columns */
  Place_kind_by_pk?: Maybe<Place_Kind>;
  /** fetch data from the table: "Place_record" */
  Place_record: Array<Place_Record>;
  /** fetch aggregated fields from the table: "Place_record" */
  Place_record_aggregate: Place_Record_Aggregate;
  /** fetch data from the table: "Place_record" using primary key columns */
  Place_record_by_pk?: Maybe<Place_Record>;
  /** fetch data from the table: "Triatomines" */
  Triatomines: Array<Triatomines>;
  /** fetch data from the table: "Triatomines_Experiment_Record" */
  Triatomines_Experiment_Record: Array<Triatomines_Experiment_Record>;
  /** fetch data from the table: "Triatomines_Experiment_Record_Place" */
  Triatomines_Experiment_Record_Place: Array<Triatomines_Experiment_Record_Place>;
  /** fetch aggregated fields from the table: "Triatomines_Experiment_Record_Place" */
  Triatomines_Experiment_Record_Place_aggregate: Triatomines_Experiment_Record_Place_Aggregate;
  /** fetch data from the table: "Triatomines_Experiment_Record_Place" using primary key columns */
  Triatomines_Experiment_Record_Place_by_pk?: Maybe<Triatomines_Experiment_Record_Place>;
  /** fetch aggregated fields from the table: "Triatomines_Experiment_Record" */
  Triatomines_Experiment_Record_aggregate: Triatomines_Experiment_Record_Aggregate;
  /** fetch data from the table: "Triatomines_Experiment_Record" using primary key columns */
  Triatomines_Experiment_Record_by_pk?: Maybe<Triatomines_Experiment_Record>;
  /** fetch data from the table: "Triatomines_Experiment_Record_directus_users" */
  Triatomines_Experiment_Record_directus_users: Array<Triatomines_Experiment_Record_Directus_Users>;
  /** fetch aggregated fields from the table: "Triatomines_Experiment_Record_directus_users" */
  Triatomines_Experiment_Record_directus_users_aggregate: Triatomines_Experiment_Record_Directus_Users_Aggregate;
  /** fetch data from the table: "Triatomines_Experiment_Record_directus_users" using primary key columns */
  Triatomines_Experiment_Record_directus_users_by_pk?: Maybe<Triatomines_Experiment_Record_Directus_Users>;
  /** fetch aggregated fields from the table: "Triatomines" */
  Triatomines_aggregate: Triatomines_Aggregate;
  /** fetch data from the table: "Triatomines" using primary key columns */
  Triatomines_by_pk?: Maybe<Triatomines>;
  /** fetch data from the table: "advertisement" */
  advertisement: Array<Advertisement>;
  /** fetch aggregated fields from the table: "advertisement" */
  advertisement_aggregate: Advertisement_Aggregate;
  /** fetch data from the table: "advertisement" using primary key columns */
  advertisement_by_pk?: Maybe<Advertisement>;
  /** fetch data from the table: "collaborator" */
  collaborator: Array<Collaborator>;
  /** fetch aggregated fields from the table: "collaborator" */
  collaborator_aggregate: Collaborator_Aggregate;
  /** fetch data from the table: "collaborator" using primary key columns */
  collaborator_by_pk?: Maybe<Collaborator>;
  /** fetch data from the table: "complaint" */
  complaint: Array<Complaint>;
  /** fetch aggregated fields from the table: "complaint" */
  complaint_aggregate: Complaint_Aggregate;
  /** fetch data from the table: "complaint" using primary key columns */
  complaint_by_pk?: Maybe<Complaint>;
  /** fetch data from the table: "complaint_kind" */
  complaint_kind: Array<Complaint_Kind>;
  /** fetch aggregated fields from the table: "complaint_kind" */
  complaint_kind_aggregate: Complaint_Kind_Aggregate;
  /** fetch data from the table: "complaint_kind" using primary key columns */
  complaint_kind_by_pk?: Maybe<Complaint_Kind>;
  /** fetch data from the table: "complaint_source" */
  complaint_source: Array<Complaint_Source>;
  /** fetch aggregated fields from the table: "complaint_source" */
  complaint_source_aggregate: Complaint_Source_Aggregate;
  /** fetch data from the table: "complaint_source" using primary key columns */
  complaint_source_by_pk?: Maybe<Complaint_Source>;
  /** execute function "compute_group" which returns "geopoint" */
  compute_group: Array<Geopoint>;
  /** execute function "compute_group" and query aggregates on result of table type "geopoint" */
  compute_group_aggregate: Geopoint_Aggregate;
  /** fetch data from the table: "distances" */
  distances: Array<Distances>;
  /** fetch aggregated fields from the table: "distances" */
  distances_aggregate: Distances_Aggregate;
  /** fetch data from the table: "distances" using primary key columns */
  distances_by_pk?: Maybe<Distances>;
  /** fetch data from the table: "estate_kind" */
  estate_kind: Array<Estate_Kind>;
  /** fetch aggregated fields from the table: "estate_kind" */
  estate_kind_aggregate: Estate_Kind_Aggregate;
  /** fetch data from the table: "estate_kind" using primary key columns */
  estate_kind_by_pk?: Maybe<Estate_Kind>;
  /** fetch data from the table: "experiment" */
  experiment: Array<Experiment>;
  /** fetch aggregated fields from the table: "experiment" */
  experiment_aggregate: Experiment_Aggregate;
  /** fetch data from the table: "experiment" using primary key columns */
  experiment_by_pk?: Maybe<Experiment>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** An array relationship */
  geogroup: Array<Geogroup>;
  /** An aggregate relationship */
  geogroup_aggregate: Geogroup_Aggregate;
  /** fetch data from the table: "geogroup" using primary key columns */
  geogroup_by_pk?: Maybe<Geogroup>;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table: "geopoint" */
  geopoint: Array<Geopoint>;
  /** fetch aggregated fields from the table: "geopoint" */
  geopoint_aggregate: Geopoint_Aggregate;
  /** fetch data from the table: "geopoint" using primary key columns */
  geopoint_by_pk?: Maybe<Geopoint>;
  geopointsByGeogroup: Array<XGeopoint>;
  /** fetch data from the table: "inspection" */
  inspection: Array<Inspection>;
  /** fetch aggregated fields from the table: "inspection" */
  inspection_aggregate: Inspection_Aggregate;
  /** fetch data from the table: "inspection" using primary key columns */
  inspection_by_pk?: Maybe<Inspection>;
  /** fetch data from the table: "inspection_event_kind" */
  inspection_event_kind: Array<Inspection_Event_Kind>;
  /** fetch aggregated fields from the table: "inspection_event_kind" */
  inspection_event_kind_aggregate: Inspection_Event_Kind_Aggregate;
  /** fetch data from the table: "inspection_event_kind" using primary key columns */
  inspection_event_kind_by_pk?: Maybe<Inspection_Event_Kind>;
  /** fetch data from the table: "inspection_interview_kind" */
  inspection_interview_kind: Array<Inspection_Interview_Kind>;
  /** fetch aggregated fields from the table: "inspection_interview_kind" */
  inspection_interview_kind_aggregate: Inspection_Interview_Kind_Aggregate;
  /** fetch data from the table: "inspection_interview_kind" using primary key columns */
  inspection_interview_kind_by_pk?: Maybe<Inspection_Interview_Kind>;
  /** fetch data from the table: "inspection_place_kind" */
  inspection_place_kind: Array<Inspection_Place_Kind>;
  /** fetch aggregated fields from the table: "inspection_place_kind" */
  inspection_place_kind_aggregate: Inspection_Place_Kind_Aggregate;
  /** fetch data from the table: "inspection_place_kind" using primary key columns */
  inspection_place_kind_by_pk?: Maybe<Inspection_Place_Kind>;
  /** fetch data from the table: "inspection_reluctant_kind" */
  inspection_reluctant_kind: Array<Inspection_Reluctant_Kind>;
  /** fetch aggregated fields from the table: "inspection_reluctant_kind" */
  inspection_reluctant_kind_aggregate: Inspection_Reluctant_Kind_Aggregate;
  /** fetch data from the table: "inspection_reluctant_kind" using primary key columns */
  inspection_reluctant_kind_by_pk?: Maybe<Inspection_Reluctant_Kind>;
  /** fetch data from the table: "inspection_state" */
  inspection_state: Array<Inspection_State>;
  /** fetch aggregated fields from the table: "inspection_state" */
  inspection_state_aggregate: Inspection_State_Aggregate;
  /** fetch data from the table: "inspection_state" using primary key columns */
  inspection_state_by_pk?: Maybe<Inspection_State>;
  me?: Maybe<MeResponse>;
  /** fetch data from the table: "microred" */
  microred: Array<Microred>;
  /** fetch aggregated fields from the table: "microred" */
  microred_aggregate: Microred_Aggregate;
  /** fetch data from the table: "microred" using primary key columns */
  microred_by_pk?: Maybe<Microred>;
  /** fetch data from the table: "observation" */
  observation: Array<Observation>;
  /** fetch aggregated fields from the table: "observation" */
  observation_aggregate: Observation_Aggregate;
  /** fetch data from the table: "observation" using primary key columns */
  observation_by_pk?: Maybe<Observation>;
  /** fetch data from the table: "place" */
  place: Array<Place>;
  /** fetch aggregated fields from the table: "place" */
  place_aggregate: Place_Aggregate;
  /** fetch data from the table: "place" using primary key columns */
  place_by_pk?: Maybe<Place>;
  /** An array relationship */
  role_children: Array<Role_Children>;
  /** An aggregate relationship */
  role_children_aggregate: Role_Children_Aggregate;
  /** fetch data from the table: "role_children" using primary key columns */
  role_children_by_pk?: Maybe<Role_Children>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** An array relationship */
  sprayed: Array<Sprayed>;
  /** An aggregate relationship */
  sprayed_aggregate: Sprayed_Aggregate;
  /** fetch data from the table: "sprayed" using primary key columns */
  sprayed_by_pk?: Maybe<Sprayed>;
  /** fetch data from the table: "sprayed_residual_treatment_kind" */
  sprayed_residual_treatment_kind: Array<Sprayed_Residual_Treatment_Kind>;
  /** fetch aggregated fields from the table: "sprayed_residual_treatment_kind" */
  sprayed_residual_treatment_kind_aggregate: Sprayed_Residual_Treatment_Kind_Aggregate;
  /** fetch data from the table: "sprayed_residual_treatment_kind" using primary key columns */
  sprayed_residual_treatment_kind_by_pk?: Maybe<Sprayed_Residual_Treatment_Kind>;
  /** fetch data from the table: "sprayer" */
  sprayer: Array<Sprayer>;
  /** fetch aggregated fields from the table: "sprayer" */
  sprayer_aggregate: Sprayer_Aggregate;
  /** fetch data from the table: "sprayer" using primary key columns */
  sprayer_by_pk?: Maybe<Sprayer>;
  /** An array relationship */
  user_roles: Array<User_Roles>;
  /** An aggregate relationship */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "work_sessions" */
  work_sessions: Array<Work_Sessions>;
  /** fetch aggregated fields from the table: "work_sessions" */
  work_sessions_aggregate: Work_Sessions_Aggregate;
  /** fetch data from the table: "work_sessions" using primary key columns */
  work_sessions_by_pk?: Maybe<Work_Sessions>;
  /** An array relationship */
  workspace: Array<Workspace>;
  /** An aggregate relationship */
  workspace_aggregate: Workspace_Aggregate;
  /** fetch data from the table: "workspace" using primary key columns */
  workspace_by_pk?: Maybe<Workspace>;
  /** fetch data from the table: "workspace_metadata" */
  workspace_metadata: Array<Workspace_Metadata>;
  /** fetch aggregated fields from the table: "workspace_metadata" */
  workspace_metadata_aggregate: Workspace_Metadata_Aggregate;
  /** fetch data from the table: "workspace_metadata" using primary key columns */
  workspace_metadata_by_pk?: Maybe<Workspace_Metadata>;
  /** fetch data from the table: "workspace_owners" */
  workspace_owners: Array<Workspace_Owners>;
  /** fetch aggregated fields from the table: "workspace_owners" */
  workspace_owners_aggregate: Workspace_Owners_Aggregate;
  /** fetch data from the table: "workspace_owners" using primary key columns */
  workspace_owners_by_pk?: Maybe<Workspace_Owners>;
};

export type Query_RootCapture_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Capture_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Capture_Place_Order_By>>;
  where?: InputMaybe<Capture_Place_Bool_Exp>;
};

export type Query_RootCapture_Place_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Capture_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Capture_Place_Order_By>>;
  where?: InputMaybe<Capture_Place_Bool_Exp>;
};

export type Query_RootCapture_Place_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootNumber_Triatomines_CapturedArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Captured_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Captured_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Captured_Bool_Exp>;
};

export type Query_RootNumber_Triatomines_Captured_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Captured_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Captured_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Captured_Bool_Exp>;
};

export type Query_RootNumber_Triatomines_Captured_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootNumber_Triatomines_InspectedArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Inspected_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Inspected_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Inspected_Bool_Exp>;
};

export type Query_RootNumber_Triatomines_Inspected_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Inspected_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Inspected_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Inspected_Bool_Exp>;
};

export type Query_RootNumber_Triatomines_Inspected_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootNumber_Triatomines_PositiveArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Positive_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Positive_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Positive_Bool_Exp>;
};

export type Query_RootNumber_Triatomines_Positive_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Positive_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Positive_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Positive_Bool_Exp>;
};

export type Query_RootNumber_Triatomines_Positive_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPlace_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Place_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Place_Order_By>>;
  where?: InputMaybe<Place_Place_Bool_Exp>;
};

export type Query_RootPlace_Place_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Place_Order_By>>;
  where?: InputMaybe<Place_Place_Bool_Exp>;
};

export type Query_RootPlace_Place_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootPlace_Triatomines_Experiment_RecordArgs = {
  distinct_on?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Order_By>>;
  where?: InputMaybe<Place_Triatomines_Experiment_Record_Bool_Exp>;
};

export type Query_RootPlace_Triatomines_Experiment_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Order_By>>;
  where?: InputMaybe<Place_Triatomines_Experiment_Record_Bool_Exp>;
};

export type Query_RootPlace_Triatomines_Experiment_Record_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootPlace_ChildrenArgs = {
  distinct_on?: InputMaybe<Array<Place_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Children_Order_By>>;
  where?: InputMaybe<Place_Children_Bool_Exp>;
};

export type Query_RootPlace_Children_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Children_Order_By>>;
  where?: InputMaybe<Place_Children_Bool_Exp>;
};

export type Query_RootPlace_Children_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootPlace_FilesArgs = {
  distinct_on?: InputMaybe<Array<Place_Files_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Files_Order_By>>;
  where?: InputMaybe<Place_Files_Bool_Exp>;
};

export type Query_RootPlace_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Files_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Files_Order_By>>;
  where?: InputMaybe<Place_Files_Bool_Exp>;
};

export type Query_RootPlace_Files_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootPlace_KindArgs = {
  distinct_on?: InputMaybe<Array<Place_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Kind_Order_By>>;
  where?: InputMaybe<Place_Kind_Bool_Exp>;
};

export type Query_RootPlace_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Kind_Order_By>>;
  where?: InputMaybe<Place_Kind_Bool_Exp>;
};

export type Query_RootPlace_Kind_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPlace_RecordArgs = {
  distinct_on?: InputMaybe<Array<Place_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Record_Order_By>>;
  where?: InputMaybe<Place_Record_Bool_Exp>;
};

export type Query_RootPlace_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Record_Order_By>>;
  where?: InputMaybe<Place_Record_Bool_Exp>;
};

export type Query_RootPlace_Record_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootTriatominesArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Order_By>>;
  where?: InputMaybe<Triatomines_Bool_Exp>;
};

export type Query_RootTriatomines_Experiment_RecordArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Bool_Exp>;
};

export type Query_RootTriatomines_Experiment_Record_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Place_Bool_Exp>;
};

export type Query_RootTriatomines_Experiment_Record_Place_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Place_Bool_Exp>;
};

export type Query_RootTriatomines_Experiment_Record_Place_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootTriatomines_Experiment_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Bool_Exp>;
};

export type Query_RootTriatomines_Experiment_Record_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTriatomines_Experiment_Record_Directus_UsersArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Bool_Exp>;
};

export type Query_RootTriatomines_Experiment_Record_Directus_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Bool_Exp>;
};

export type Query_RootTriatomines_Experiment_Record_Directus_Users_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootTriatomines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Order_By>>;
  where?: InputMaybe<Triatomines_Bool_Exp>;
};

export type Query_RootTriatomines_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootAdvertisementArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

export type Query_RootAdvertisement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

export type Query_RootAdvertisement_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCollaboratorArgs = {
  distinct_on?: InputMaybe<Array<Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Collaborator_Order_By>>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

export type Query_RootCollaborator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Collaborator_Order_By>>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

export type Query_RootCollaborator_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootComplaintArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

export type Query_RootComplaint_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

export type Query_RootComplaint_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootComplaint_KindArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Kind_Order_By>>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

export type Query_RootComplaint_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Kind_Order_By>>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

export type Query_RootComplaint_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootComplaint_SourceArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Source_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Source_Order_By>>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

export type Query_RootComplaint_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Source_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Source_Order_By>>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

export type Query_RootComplaint_Source_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootCompute_GroupArgs = {
  args: Compute_Group_Args;
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Query_RootCompute_Group_AggregateArgs = {
  args: Compute_Group_Args;
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Query_RootDistancesArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

export type Query_RootDistances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

export type Query_RootDistances_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootEstate_KindArgs = {
  distinct_on?: InputMaybe<Array<Estate_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Estate_Kind_Order_By>>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

export type Query_RootEstate_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Estate_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Estate_Kind_Order_By>>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

export type Query_RootEstate_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootExperimentArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Query_RootExperiment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Query_RootExperiment_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootGeography_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};

export type Query_RootGeography_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};

export type Query_RootGeogroupArgs = {
  distinct_on?: InputMaybe<Array<Geogroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geogroup_Order_By>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

export type Query_RootGeogroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geogroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geogroup_Order_By>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

export type Query_RootGeogroup_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootGeometry_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};

export type Query_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};

export type Query_RootGeopointArgs = {
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Query_RootGeopoint_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Query_RootGeopoint_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootGeopointsByGeogroupArgs = {
  id: Scalars["uuid"];
};

export type Query_RootInspectionArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

export type Query_RootInspection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

export type Query_RootInspection_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootInspection_Event_KindArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Event_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Event_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Event_Kind_Bool_Exp>;
};

export type Query_RootInspection_Event_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Event_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Event_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Event_Kind_Bool_Exp>;
};

export type Query_RootInspection_Event_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootInspection_Interview_KindArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Interview_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Interview_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Interview_Kind_Bool_Exp>;
};

export type Query_RootInspection_Interview_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Interview_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Interview_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Interview_Kind_Bool_Exp>;
};

export type Query_RootInspection_Interview_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootInspection_Place_KindArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Place_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Place_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Place_Kind_Bool_Exp>;
};

export type Query_RootInspection_Place_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Place_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Place_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Place_Kind_Bool_Exp>;
};

export type Query_RootInspection_Place_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootInspection_Reluctant_KindArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Reluctant_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Reluctant_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Reluctant_Kind_Bool_Exp>;
};

export type Query_RootInspection_Reluctant_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Reluctant_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Reluctant_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Reluctant_Kind_Bool_Exp>;
};

export type Query_RootInspection_Reluctant_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootInspection_StateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_State_Order_By>>;
  where?: InputMaybe<Inspection_State_Bool_Exp>;
};

export type Query_RootInspection_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_State_Order_By>>;
  where?: InputMaybe<Inspection_State_Bool_Exp>;
};

export type Query_RootInspection_State_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootMicroredArgs = {
  distinct_on?: InputMaybe<Array<Microred_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Microred_Order_By>>;
  where?: InputMaybe<Microred_Bool_Exp>;
};

export type Query_RootMicrored_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Microred_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Microred_Order_By>>;
  where?: InputMaybe<Microred_Bool_Exp>;
};

export type Query_RootMicrored_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootObservationArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

export type Query_RootObservation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

export type Query_RootObservation_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPlaceArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

export type Query_RootPlace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

export type Query_RootPlace_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootRole_ChildrenArgs = {
  distinct_on?: InputMaybe<Array<Role_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Children_Order_By>>;
  where?: InputMaybe<Role_Children_Bool_Exp>;
};

export type Query_RootRole_Children_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Children_Order_By>>;
  where?: InputMaybe<Role_Children_Bool_Exp>;
};

export type Query_RootRole_Children_By_PkArgs = {
  parent_id: Scalars["uuid"];
  role_id: Scalars["uuid"];
};

export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Query_RootRoles_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSpatial_Ref_SysArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

export type Query_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

export type Query_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars["Int"];
};

export type Query_RootSprayedArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

export type Query_RootSprayed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

export type Query_RootSprayed_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSprayed_Residual_Treatment_KindArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Order_By>>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

export type Query_RootSprayed_Residual_Treatment_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Order_By>>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

export type Query_RootSprayed_Residual_Treatment_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootSprayerArgs = {
  distinct_on?: InputMaybe<Array<Sprayer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayer_Order_By>>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

export type Query_RootSprayer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayer_Order_By>>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

export type Query_RootSprayer_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Query_RootUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Query_RootUser_Roles_By_PkArgs = {
  role_id: Scalars["uuid"];
  user_id: Scalars["uuid"];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootWork_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

export type Query_RootWork_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

export type Query_RootWork_Sessions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

export type Query_RootWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

export type Query_RootWorkspace_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootWorkspace_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

export type Query_RootWorkspace_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

export type Query_RootWorkspace_Metadata_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootWorkspace_OwnersArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Owners_Order_By>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

export type Query_RootWorkspace_Owners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Owners_Order_By>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

export type Query_RootWorkspace_Owners_By_PkArgs = {
  owner_id: Scalars["uuid"];
  workspace_id: Scalars["uuid"];
};

/** columns and relationships of "role_children" */
export type Role_Children = {
  __typename?: "role_children";
  parent_id: Scalars["uuid"];
  /** An object relationship */
  role: Roles;
  /** An object relationship */
  roleByRoleId: Roles;
  role_id: Scalars["uuid"];
};

/** aggregated selection of "role_children" */
export type Role_Children_Aggregate = {
  __typename?: "role_children_aggregate";
  aggregate?: Maybe<Role_Children_Aggregate_Fields>;
  nodes: Array<Role_Children>;
};

export type Role_Children_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Children_Aggregate_Bool_Exp_Count>;
};

export type Role_Children_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Children_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Role_Children_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_children" */
export type Role_Children_Aggregate_Fields = {
  __typename?: "role_children_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Role_Children_Max_Fields>;
  min?: Maybe<Role_Children_Min_Fields>;
};

/** aggregate fields of "role_children" */
export type Role_Children_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Children_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "role_children" */
export type Role_Children_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Children_Max_Order_By>;
  min?: InputMaybe<Role_Children_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_children" */
export type Role_Children_Arr_Rel_Insert_Input = {
  data: Array<Role_Children_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Children_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_children". All fields are combined with a logical 'AND'. */
export type Role_Children_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Children_Bool_Exp>>;
  _not?: InputMaybe<Role_Children_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Children_Bool_Exp>>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  roleByRoleId?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_children" */
export enum Role_Children_Constraint {
  /** unique or primary key constraint on columns "role_id", "parent_id" */
  RoleChildrenPkey = "role_children_pkey",
}

/** input type for inserting data into table "role_children" */
export type Role_Children_Insert_Input = {
  parent_id?: InputMaybe<Scalars["uuid"]>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  roleByRoleId?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Role_Children_Max_Fields = {
  __typename?: "role_children_max_fields";
  parent_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "role_children" */
export type Role_Children_Max_Order_By = {
  parent_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Children_Min_Fields = {
  __typename?: "role_children_min_fields";
  parent_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "role_children" */
export type Role_Children_Min_Order_By = {
  parent_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_children" */
export type Role_Children_Mutation_Response = {
  __typename?: "role_children_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Children>;
};

/** on_conflict condition type for table "role_children" */
export type Role_Children_On_Conflict = {
  constraint: Role_Children_Constraint;
  update_columns?: Array<Role_Children_Update_Column>;
  where?: InputMaybe<Role_Children_Bool_Exp>;
};

/** Ordering options when selecting data from "role_children". */
export type Role_Children_Order_By = {
  parent_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  roleByRoleId?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_children */
export type Role_Children_Pk_Columns_Input = {
  parent_id: Scalars["uuid"];
  role_id: Scalars["uuid"];
};

/** select columns of table "role_children" */
export enum Role_Children_Select_Column {
  /** column name */
  ParentId = "parent_id",
  /** column name */
  RoleId = "role_id",
}

/** input type for updating data in table "role_children" */
export type Role_Children_Set_Input = {
  parent_id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "role_children" */
export type Role_Children_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Children_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Children_Stream_Cursor_Value_Input = {
  parent_id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "role_children" */
export enum Role_Children_Update_Column {
  /** column name */
  ParentId = "parent_id",
  /** column name */
  RoleId = "role_id",
}

export type Role_Children_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Children_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Children_Bool_Exp;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: "roles";
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  public: Scalars["Boolean"];
  /** An array relationship */
  roleChildrenByRoleId: Array<Role_Children>;
  /** An aggregate relationship */
  roleChildrenByRoleId_aggregate: Role_Children_Aggregate;
  /** An array relationship */
  role_children: Array<Role_Children>;
  /** An aggregate relationship */
  role_children_aggregate: Role_Children_Aggregate;
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  user_roles: Array<User_Roles>;
  /** An aggregate relationship */
  user_roles_aggregate: User_Roles_Aggregate;
};

/** columns and relationships of "roles" */
export type RolesRoleChildrenByRoleIdArgs = {
  distinct_on?: InputMaybe<Array<Role_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Children_Order_By>>;
  where?: InputMaybe<Role_Children_Bool_Exp>;
};

/** columns and relationships of "roles" */
export type RolesRoleChildrenByRoleId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Children_Order_By>>;
  where?: InputMaybe<Role_Children_Bool_Exp>;
};

/** columns and relationships of "roles" */
export type RolesRole_ChildrenArgs = {
  distinct_on?: InputMaybe<Array<Role_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Children_Order_By>>;
  where?: InputMaybe<Role_Children_Bool_Exp>;
};

/** columns and relationships of "roles" */
export type RolesRole_Children_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Children_Order_By>>;
  where?: InputMaybe<Role_Children_Bool_Exp>;
};

/** columns and relationships of "roles" */
export type RolesUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** columns and relationships of "roles" */
export type RolesUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: "roles_aggregate";
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: "roles_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  public?: InputMaybe<Boolean_Comparison_Exp>;
  roleChildrenByRoleId?: InputMaybe<Role_Children_Bool_Exp>;
  roleChildrenByRoleId_aggregate?: InputMaybe<Role_Children_Aggregate_Bool_Exp>;
  role_children?: InputMaybe<Role_Children_Bool_Exp>;
  role_children_aggregate?: InputMaybe<Role_Children_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_roles?: InputMaybe<User_Roles_Bool_Exp>;
  user_roles_aggregate?: InputMaybe<User_Roles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "name" */
  RolesNameKey = "roles_name_key",
  /** unique or primary key constraint on columns "id" */
  RolesPkey = "roles_pkey",
}

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  public?: InputMaybe<Scalars["Boolean"]>;
  roleChildrenByRoleId?: InputMaybe<Role_Children_Arr_Rel_Insert_Input>;
  role_children?: InputMaybe<Role_Children_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user_roles?: InputMaybe<User_Roles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: "roles_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: "roles_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: "roles_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  public?: InputMaybe<Order_By>;
  roleChildrenByRoleId_aggregate?: InputMaybe<Role_Children_Aggregate_Order_By>;
  role_children_aggregate?: InputMaybe<Role_Children_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_roles_aggregate?: InputMaybe<User_Roles_Aggregate_Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Public = "public",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  public?: InputMaybe<Scalars["Boolean"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  public?: InputMaybe<Scalars["Boolean"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Public = "public",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

/** columns and relationships of "spatial_ref_sys" */
export type Spatial_Ref_Sys = {
  __typename?: "spatial_ref_sys";
  auth_name?: Maybe<Scalars["String"]>;
  auth_srid?: Maybe<Scalars["Int"]>;
  proj4text?: Maybe<Scalars["String"]>;
  srid: Scalars["Int"];
  srtext?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate = {
  __typename?: "spatial_ref_sys_aggregate";
  aggregate?: Maybe<Spatial_Ref_Sys_Aggregate_Fields>;
  nodes: Array<Spatial_Ref_Sys>;
};

/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_Fields = {
  __typename?: "spatial_ref_sys_aggregate_fields";
  avg?: Maybe<Spatial_Ref_Sys_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Spatial_Ref_Sys_Max_Fields>;
  min?: Maybe<Spatial_Ref_Sys_Min_Fields>;
  stddev?: Maybe<Spatial_Ref_Sys_Stddev_Fields>;
  stddev_pop?: Maybe<Spatial_Ref_Sys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Spatial_Ref_Sys_Stddev_Samp_Fields>;
  sum?: Maybe<Spatial_Ref_Sys_Sum_Fields>;
  var_pop?: Maybe<Spatial_Ref_Sys_Var_Pop_Fields>;
  var_samp?: Maybe<Spatial_Ref_Sys_Var_Samp_Fields>;
  variance?: Maybe<Spatial_Ref_Sys_Variance_Fields>;
};

/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Spatial_Ref_Sys_Avg_Fields = {
  __typename?: "spatial_ref_sys_avg_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "spatial_ref_sys". All fields are combined with a logical 'AND'. */
export type Spatial_Ref_Sys_Bool_Exp = {
  _and?: InputMaybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  _not?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
  _or?: InputMaybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  auth_name?: InputMaybe<String_Comparison_Exp>;
  auth_srid?: InputMaybe<Int_Comparison_Exp>;
  proj4text?: InputMaybe<String_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  srtext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Constraint {
  /** unique or primary key constraint on columns "srid" */
  SpatialRefSysPkey = "spatial_ref_sys_pkey",
}

/** input type for incrementing numeric columns in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Inc_Input = {
  auth_srid?: InputMaybe<Scalars["Int"]>;
  srid?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Insert_Input = {
  auth_name?: InputMaybe<Scalars["String"]>;
  auth_srid?: InputMaybe<Scalars["Int"]>;
  proj4text?: InputMaybe<Scalars["String"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  srtext?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Spatial_Ref_Sys_Max_Fields = {
  __typename?: "spatial_ref_sys_max_fields";
  auth_name?: Maybe<Scalars["String"]>;
  auth_srid?: Maybe<Scalars["Int"]>;
  proj4text?: Maybe<Scalars["String"]>;
  srid?: Maybe<Scalars["Int"]>;
  srtext?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Spatial_Ref_Sys_Min_Fields = {
  __typename?: "spatial_ref_sys_min_fields";
  auth_name?: Maybe<Scalars["String"]>;
  auth_srid?: Maybe<Scalars["Int"]>;
  proj4text?: Maybe<Scalars["String"]>;
  srid?: Maybe<Scalars["Int"]>;
  srtext?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Mutation_Response = {
  __typename?: "spatial_ref_sys_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Spatial_Ref_Sys>;
};

/** on_conflict condition type for table "spatial_ref_sys" */
export type Spatial_Ref_Sys_On_Conflict = {
  constraint: Spatial_Ref_Sys_Constraint;
  update_columns?: Array<Spatial_Ref_Sys_Update_Column>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

/** Ordering options when selecting data from "spatial_ref_sys". */
export type Spatial_Ref_Sys_Order_By = {
  auth_name?: InputMaybe<Order_By>;
  auth_srid?: InputMaybe<Order_By>;
  proj4text?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  srtext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spatial_ref_sys */
export type Spatial_Ref_Sys_Pk_Columns_Input = {
  srid: Scalars["Int"];
};

/** select columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Select_Column {
  /** column name */
  AuthName = "auth_name",
  /** column name */
  AuthSrid = "auth_srid",
  /** column name */
  Proj4text = "proj4text",
  /** column name */
  Srid = "srid",
  /** column name */
  Srtext = "srtext",
}

/** input type for updating data in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Set_Input = {
  auth_name?: InputMaybe<Scalars["String"]>;
  auth_srid?: InputMaybe<Scalars["Int"]>;
  proj4text?: InputMaybe<Scalars["String"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  srtext?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Spatial_Ref_Sys_Stddev_Fields = {
  __typename?: "spatial_ref_sys_stddev_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Spatial_Ref_Sys_Stddev_Pop_Fields = {
  __typename?: "spatial_ref_sys_stddev_pop_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Spatial_Ref_Sys_Stddev_Samp_Fields = {
  __typename?: "spatial_ref_sys_stddev_samp_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spatial_Ref_Sys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spatial_Ref_Sys_Stream_Cursor_Value_Input = {
  auth_name?: InputMaybe<Scalars["String"]>;
  auth_srid?: InputMaybe<Scalars["Int"]>;
  proj4text?: InputMaybe<Scalars["String"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  srtext?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Spatial_Ref_Sys_Sum_Fields = {
  __typename?: "spatial_ref_sys_sum_fields";
  auth_srid?: Maybe<Scalars["Int"]>;
  srid?: Maybe<Scalars["Int"]>;
};

/** update columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Update_Column {
  /** column name */
  AuthName = "auth_name",
  /** column name */
  AuthSrid = "auth_srid",
  /** column name */
  Proj4text = "proj4text",
  /** column name */
  Srid = "srid",
  /** column name */
  Srtext = "srtext",
}

export type Spatial_Ref_Sys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spatial_Ref_Sys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spatial_Ref_Sys_Var_Pop_Fields = {
  __typename?: "spatial_ref_sys_var_pop_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Spatial_Ref_Sys_Var_Samp_Fields = {
  __typename?: "spatial_ref_sys_var_samp_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Spatial_Ref_Sys_Variance_Fields = {
  __typename?: "spatial_ref_sys_variance_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "sprayed" */
export type Sprayed = {
  __typename?: "sprayed";
  address_in_crack?: Maybe<Scalars["Int"]>;
  address_in_env?: Maybe<Scalars["Int"]>;
  address_in_env_close?: Maybe<Scalars["Int"]>;
  address_in_material?: Maybe<Scalars["String"]>;
  address_out_crack?: Maybe<Scalars["Int"]>;
  address_out_env?: Maybe<Scalars["Int"]>;
  address_out_env_close?: Maybe<Scalars["Int"]>;
  address_out_material?: Maybe<Scalars["String"]>;
  address_out_triatomines?: Maybe<Scalars["Int"]>;
  address_triatomines?: Maybe<Scalars["Int"]>;
  app_state: Scalars["String"];
  clean_area?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  collaborator?: Maybe<Collaborator>;
  collaborator_id: Scalars["uuid"];
  complaint_id?: Maybe<Scalars["uuid"]>;
  comprehensive_treatment_metadata?: Maybe<Scalars["jsonb"]>;
  created_at: Scalars["timestamptz"];
  experiment_id?: Maybe<Scalars["uuid"]>;
  family_chief?: Maybe<Scalars["String"]>;
  family_size?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  from_radio?: Maybe<Scalars["Boolean"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  geopoint?: Maybe<Geopoint>;
  geopoint_id: Scalars["uuid"];
  geopoint_unicode?: Maybe<Scalars["String"]>;
  house_bird?: Maybe<Scalars["Int"]>;
  house_cat?: Maybe<Scalars["Int"]>;
  house_cuy?: Maybe<Scalars["Int"]>;
  house_dog?: Maybe<Scalars["Int"]>;
  house_other_animal_1_name?: Maybe<Scalars["String"]>;
  house_other_animal_1_number?: Maybe<Scalars["Int"]>;
  house_other_animal_2_name?: Maybe<Scalars["String"]>;
  house_other_animal_2_number?: Maybe<Scalars["Int"]>;
  house_other_animal_3_name?: Maybe<Scalars["String"]>;
  house_other_animal_3_number?: Maybe<Scalars["Int"]>;
  house_other_animal_4_name?: Maybe<Scalars["String"]>;
  house_other_animal_4_number?: Maybe<Scalars["Int"]>;
  house_other_animal_5_name?: Maybe<Scalars["String"]>;
  house_other_animal_5_number?: Maybe<Scalars["Int"]>;
  house_other_animal_name?: Maybe<Scalars["String"]>;
  house_other_animal_number?: Maybe<Scalars["Int"]>;
  house_rabbit?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  insecticide?: Maybe<Scalars["String"]>;
  origin_from_complaint?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  residual_treatment_kind?: Maybe<Sprayed_Residual_Treatment_Kind_Enum>;
  roof_other_animal_1_name?: Maybe<Scalars["String"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_2_name?: Maybe<Scalars["String"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_3_name?: Maybe<Scalars["String"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_4_name?: Maybe<Scalars["String"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_5_name?: Maybe<Scalars["String"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_name?: Maybe<Scalars["String"]>;
  roof_other_animal_number?: Maybe<Scalars["Int"]>;
  roof_top_bird?: Maybe<Scalars["Int"]>;
  roof_top_cat?: Maybe<Scalars["Int"]>;
  roof_top_cuy?: Maybe<Scalars["Int"]>;
  roof_top_dog?: Maybe<Scalars["Int"]>;
  roof_top_rabbit?: Maybe<Scalars["Int"]>;
  spent_insecticides?: Maybe<Scalars["Int"]>;
  sprayed_intra_event?: Maybe<Scalars["jsonb"]>;
  sprayed_peri_event?: Maybe<Scalars["jsonb"]>;
  sprayer_id?: Maybe<Scalars["uuid"]>;
  sprayer_name?: Maybe<Scalars["String"]>;
  unicode_from_radio?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  workspace_metadata?: Maybe<Workspace_Metadata>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
  yard_bird?: Maybe<Scalars["Int"]>;
  yard_cat?: Maybe<Scalars["Int"]>;
  yard_cuy?: Maybe<Scalars["Int"]>;
  yard_dog?: Maybe<Scalars["Int"]>;
  yard_other_animal_1_name?: Maybe<Scalars["String"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_2_name?: Maybe<Scalars["String"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_3_name?: Maybe<Scalars["String"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_4_name?: Maybe<Scalars["String"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_5_name?: Maybe<Scalars["String"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_name?: Maybe<Scalars["String"]>;
  yard_other_animal_number?: Maybe<Scalars["Int"]>;
  yard_rabbit?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "sprayed" */
export type SprayedComprehensive_Treatment_MetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "sprayed" */
export type SprayedSprayed_Intra_EventArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "sprayed" */
export type SprayedSprayed_Peri_EventArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "sprayed" */
export type Sprayed_Aggregate = {
  __typename?: "sprayed_aggregate";
  aggregate?: Maybe<Sprayed_Aggregate_Fields>;
  nodes: Array<Sprayed>;
};

export type Sprayed_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Sprayed_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Sprayed_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Sprayed_Aggregate_Bool_Exp_Count>;
};

export type Sprayed_Aggregate_Bool_Exp_Bool_And = {
  arguments: Sprayed_Select_Column_Sprayed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Sprayed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sprayed_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Sprayed_Select_Column_Sprayed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Sprayed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sprayed_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sprayed_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Sprayed_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sprayed" */
export type Sprayed_Aggregate_Fields = {
  __typename?: "sprayed_aggregate_fields";
  avg?: Maybe<Sprayed_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sprayed_Max_Fields>;
  min?: Maybe<Sprayed_Min_Fields>;
  stddev?: Maybe<Sprayed_Stddev_Fields>;
  stddev_pop?: Maybe<Sprayed_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sprayed_Stddev_Samp_Fields>;
  sum?: Maybe<Sprayed_Sum_Fields>;
  var_pop?: Maybe<Sprayed_Var_Pop_Fields>;
  var_samp?: Maybe<Sprayed_Var_Samp_Fields>;
  variance?: Maybe<Sprayed_Variance_Fields>;
};

/** aggregate fields of "sprayed" */
export type Sprayed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sprayed_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "sprayed" */
export type Sprayed_Aggregate_Order_By = {
  avg?: InputMaybe<Sprayed_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sprayed_Max_Order_By>;
  min?: InputMaybe<Sprayed_Min_Order_By>;
  stddev?: InputMaybe<Sprayed_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sprayed_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sprayed_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sprayed_Sum_Order_By>;
  var_pop?: InputMaybe<Sprayed_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sprayed_Var_Samp_Order_By>;
  variance?: InputMaybe<Sprayed_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Sprayed_Append_Input = {
  comprehensive_treatment_metadata?: InputMaybe<Scalars["jsonb"]>;
  sprayed_intra_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_peri_event?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "sprayed" */
export type Sprayed_Arr_Rel_Insert_Input = {
  data: Array<Sprayed_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sprayed_On_Conflict>;
};

/** aggregate avg on columns */
export type Sprayed_Avg_Fields = {
  __typename?: "sprayed_avg_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "sprayed" */
export type Sprayed_Avg_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sprayed". All fields are combined with a logical 'AND'. */
export type Sprayed_Bool_Exp = {
  _and?: InputMaybe<Array<Sprayed_Bool_Exp>>;
  _not?: InputMaybe<Sprayed_Bool_Exp>;
  _or?: InputMaybe<Array<Sprayed_Bool_Exp>>;
  address_in_crack?: InputMaybe<Int_Comparison_Exp>;
  address_in_env?: InputMaybe<Int_Comparison_Exp>;
  address_in_env_close?: InputMaybe<Int_Comparison_Exp>;
  address_in_material?: InputMaybe<String_Comparison_Exp>;
  address_out_crack?: InputMaybe<Int_Comparison_Exp>;
  address_out_env?: InputMaybe<Int_Comparison_Exp>;
  address_out_env_close?: InputMaybe<Int_Comparison_Exp>;
  address_out_material?: InputMaybe<String_Comparison_Exp>;
  address_out_triatomines?: InputMaybe<Int_Comparison_Exp>;
  address_triatomines?: InputMaybe<Int_Comparison_Exp>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  clean_area?: InputMaybe<Int_Comparison_Exp>;
  collaborator?: InputMaybe<Collaborator_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  complaint_id?: InputMaybe<Uuid_Comparison_Exp>;
  comprehensive_treatment_metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  experiment_id?: InputMaybe<Uuid_Comparison_Exp>;
  family_chief?: InputMaybe<String_Comparison_Exp>;
  family_size?: InputMaybe<Int_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  from_radio?: InputMaybe<Boolean_Comparison_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint?: InputMaybe<Geopoint_Bool_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint_unicode?: InputMaybe<String_Comparison_Exp>;
  house_bird?: InputMaybe<Int_Comparison_Exp>;
  house_cat?: InputMaybe<Int_Comparison_Exp>;
  house_cuy?: InputMaybe<Int_Comparison_Exp>;
  house_dog?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_1_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_1_number?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_2_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_2_number?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_3_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_3_number?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_4_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_4_number?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_5_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_5_number?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_number?: InputMaybe<Int_Comparison_Exp>;
  house_rabbit?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insecticide?: InputMaybe<String_Comparison_Exp>;
  origin_from_complaint?: InputMaybe<String_Comparison_Exp>;
  reported_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  residual_treatment_kind?: InputMaybe<Sprayed_Residual_Treatment_Kind_Enum_Comparison_Exp>;
  roof_other_animal_1_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_1_number?: InputMaybe<Int_Comparison_Exp>;
  roof_other_animal_2_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_2_number?: InputMaybe<Int_Comparison_Exp>;
  roof_other_animal_3_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_3_number?: InputMaybe<Int_Comparison_Exp>;
  roof_other_animal_4_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_4_number?: InputMaybe<Int_Comparison_Exp>;
  roof_other_animal_5_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_5_number?: InputMaybe<Int_Comparison_Exp>;
  roof_other_animal_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_number?: InputMaybe<Int_Comparison_Exp>;
  roof_top_bird?: InputMaybe<Int_Comparison_Exp>;
  roof_top_cat?: InputMaybe<Int_Comparison_Exp>;
  roof_top_cuy?: InputMaybe<Int_Comparison_Exp>;
  roof_top_dog?: InputMaybe<Int_Comparison_Exp>;
  roof_top_rabbit?: InputMaybe<Int_Comparison_Exp>;
  spent_insecticides?: InputMaybe<Int_Comparison_Exp>;
  sprayed_intra_event?: InputMaybe<Jsonb_Comparison_Exp>;
  sprayed_peri_event?: InputMaybe<Jsonb_Comparison_Exp>;
  sprayer_id?: InputMaybe<Uuid_Comparison_Exp>;
  sprayer_name?: InputMaybe<String_Comparison_Exp>;
  unicode_from_radio?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  workspace_metadata_id?: InputMaybe<Uuid_Comparison_Exp>;
  yard_bird?: InputMaybe<Int_Comparison_Exp>;
  yard_cat?: InputMaybe<Int_Comparison_Exp>;
  yard_cuy?: InputMaybe<Int_Comparison_Exp>;
  yard_dog?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_1_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_1_number?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_2_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_2_number?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_3_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_3_number?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_4_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_4_number?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_5_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_5_number?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_number?: InputMaybe<Int_Comparison_Exp>;
  yard_rabbit?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "sprayed" */
export enum Sprayed_Constraint {
  /** unique or primary key constraint on columns "id" */
  SpreadPkey = "spread_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Sprayed_Delete_At_Path_Input = {
  comprehensive_treatment_metadata?: InputMaybe<Array<Scalars["String"]>>;
  sprayed_intra_event?: InputMaybe<Array<Scalars["String"]>>;
  sprayed_peri_event?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Sprayed_Delete_Elem_Input = {
  comprehensive_treatment_metadata?: InputMaybe<Scalars["Int"]>;
  sprayed_intra_event?: InputMaybe<Scalars["Int"]>;
  sprayed_peri_event?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Sprayed_Delete_Key_Input = {
  comprehensive_treatment_metadata?: InputMaybe<Scalars["String"]>;
  sprayed_intra_event?: InputMaybe<Scalars["String"]>;
  sprayed_peri_event?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "sprayed" */
export type Sprayed_Inc_Input = {
  address_in_crack?: InputMaybe<Scalars["Int"]>;
  address_in_env?: InputMaybe<Scalars["Int"]>;
  address_in_env_close?: InputMaybe<Scalars["Int"]>;
  address_out_crack?: InputMaybe<Scalars["Int"]>;
  address_out_env?: InputMaybe<Scalars["Int"]>;
  address_out_env_close?: InputMaybe<Scalars["Int"]>;
  address_out_triatomines?: InputMaybe<Scalars["Int"]>;
  address_triatomines?: InputMaybe<Scalars["Int"]>;
  clean_area?: InputMaybe<Scalars["Int"]>;
  family_size?: InputMaybe<Scalars["Int"]>;
  house_bird?: InputMaybe<Scalars["Int"]>;
  house_cat?: InputMaybe<Scalars["Int"]>;
  house_cuy?: InputMaybe<Scalars["Int"]>;
  house_dog?: InputMaybe<Scalars["Int"]>;
  house_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_number?: InputMaybe<Scalars["Int"]>;
  house_rabbit?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_number?: InputMaybe<Scalars["Int"]>;
  roof_top_bird?: InputMaybe<Scalars["Int"]>;
  roof_top_cat?: InputMaybe<Scalars["Int"]>;
  roof_top_cuy?: InputMaybe<Scalars["Int"]>;
  roof_top_dog?: InputMaybe<Scalars["Int"]>;
  roof_top_rabbit?: InputMaybe<Scalars["Int"]>;
  spent_insecticides?: InputMaybe<Scalars["Int"]>;
  yard_bird?: InputMaybe<Scalars["Int"]>;
  yard_cat?: InputMaybe<Scalars["Int"]>;
  yard_cuy?: InputMaybe<Scalars["Int"]>;
  yard_dog?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_number?: InputMaybe<Scalars["Int"]>;
  yard_rabbit?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "sprayed" */
export type Sprayed_Insert_Input = {
  address_in_crack?: InputMaybe<Scalars["Int"]>;
  address_in_env?: InputMaybe<Scalars["Int"]>;
  address_in_env_close?: InputMaybe<Scalars["Int"]>;
  address_in_material?: InputMaybe<Scalars["String"]>;
  address_out_crack?: InputMaybe<Scalars["Int"]>;
  address_out_env?: InputMaybe<Scalars["Int"]>;
  address_out_env_close?: InputMaybe<Scalars["Int"]>;
  address_out_material?: InputMaybe<Scalars["String"]>;
  address_out_triatomines?: InputMaybe<Scalars["Int"]>;
  address_triatomines?: InputMaybe<Scalars["Int"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  clean_area?: InputMaybe<Scalars["Int"]>;
  collaborator?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complaint_id?: InputMaybe<Scalars["uuid"]>;
  comprehensive_treatment_metadata?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  family_chief?: InputMaybe<Scalars["String"]>;
  family_size?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  from_radio?: InputMaybe<Scalars["Boolean"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  house_bird?: InputMaybe<Scalars["Int"]>;
  house_cat?: InputMaybe<Scalars["Int"]>;
  house_cuy?: InputMaybe<Scalars["Int"]>;
  house_dog?: InputMaybe<Scalars["Int"]>;
  house_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_number?: InputMaybe<Scalars["Int"]>;
  house_rabbit?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecticide?: InputMaybe<Scalars["String"]>;
  origin_from_complaint?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  residual_treatment_kind?: InputMaybe<Sprayed_Residual_Treatment_Kind_Enum>;
  roof_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_number?: InputMaybe<Scalars["Int"]>;
  roof_top_bird?: InputMaybe<Scalars["Int"]>;
  roof_top_cat?: InputMaybe<Scalars["Int"]>;
  roof_top_cuy?: InputMaybe<Scalars["Int"]>;
  roof_top_dog?: InputMaybe<Scalars["Int"]>;
  roof_top_rabbit?: InputMaybe<Scalars["Int"]>;
  spent_insecticides?: InputMaybe<Scalars["Int"]>;
  sprayed_intra_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_peri_event?: InputMaybe<Scalars["jsonb"]>;
  sprayer_id?: InputMaybe<Scalars["uuid"]>;
  sprayer_name?: InputMaybe<Scalars["String"]>;
  unicode_from_radio?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Obj_Rel_Insert_Input>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
  yard_bird?: InputMaybe<Scalars["Int"]>;
  yard_cat?: InputMaybe<Scalars["Int"]>;
  yard_cuy?: InputMaybe<Scalars["Int"]>;
  yard_dog?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_number?: InputMaybe<Scalars["Int"]>;
  yard_rabbit?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Sprayed_Max_Fields = {
  __typename?: "sprayed_max_fields";
  address_in_crack?: Maybe<Scalars["Int"]>;
  address_in_env?: Maybe<Scalars["Int"]>;
  address_in_env_close?: Maybe<Scalars["Int"]>;
  address_in_material?: Maybe<Scalars["String"]>;
  address_out_crack?: Maybe<Scalars["Int"]>;
  address_out_env?: Maybe<Scalars["Int"]>;
  address_out_env_close?: Maybe<Scalars["Int"]>;
  address_out_material?: Maybe<Scalars["String"]>;
  address_out_triatomines?: Maybe<Scalars["Int"]>;
  address_triatomines?: Maybe<Scalars["Int"]>;
  app_state?: Maybe<Scalars["String"]>;
  clean_area?: Maybe<Scalars["Int"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  complaint_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  family_chief?: Maybe<Scalars["String"]>;
  family_size?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  house_bird?: Maybe<Scalars["Int"]>;
  house_cat?: Maybe<Scalars["Int"]>;
  house_cuy?: Maybe<Scalars["Int"]>;
  house_dog?: Maybe<Scalars["Int"]>;
  house_other_animal_1_name?: Maybe<Scalars["String"]>;
  house_other_animal_1_number?: Maybe<Scalars["Int"]>;
  house_other_animal_2_name?: Maybe<Scalars["String"]>;
  house_other_animal_2_number?: Maybe<Scalars["Int"]>;
  house_other_animal_3_name?: Maybe<Scalars["String"]>;
  house_other_animal_3_number?: Maybe<Scalars["Int"]>;
  house_other_animal_4_name?: Maybe<Scalars["String"]>;
  house_other_animal_4_number?: Maybe<Scalars["Int"]>;
  house_other_animal_5_name?: Maybe<Scalars["String"]>;
  house_other_animal_5_number?: Maybe<Scalars["Int"]>;
  house_other_animal_name?: Maybe<Scalars["String"]>;
  house_other_animal_number?: Maybe<Scalars["Int"]>;
  house_rabbit?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  insecticide?: Maybe<Scalars["String"]>;
  origin_from_complaint?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  roof_other_animal_1_name?: Maybe<Scalars["String"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_2_name?: Maybe<Scalars["String"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_3_name?: Maybe<Scalars["String"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_4_name?: Maybe<Scalars["String"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_5_name?: Maybe<Scalars["String"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_name?: Maybe<Scalars["String"]>;
  roof_other_animal_number?: Maybe<Scalars["Int"]>;
  roof_top_bird?: Maybe<Scalars["Int"]>;
  roof_top_cat?: Maybe<Scalars["Int"]>;
  roof_top_cuy?: Maybe<Scalars["Int"]>;
  roof_top_dog?: Maybe<Scalars["Int"]>;
  roof_top_rabbit?: Maybe<Scalars["Int"]>;
  spent_insecticides?: Maybe<Scalars["Int"]>;
  sprayer_id?: Maybe<Scalars["uuid"]>;
  sprayer_name?: Maybe<Scalars["String"]>;
  unicode_from_radio?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
  yard_bird?: Maybe<Scalars["Int"]>;
  yard_cat?: Maybe<Scalars["Int"]>;
  yard_cuy?: Maybe<Scalars["Int"]>;
  yard_dog?: Maybe<Scalars["Int"]>;
  yard_other_animal_1_name?: Maybe<Scalars["String"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_2_name?: Maybe<Scalars["String"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_3_name?: Maybe<Scalars["String"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_4_name?: Maybe<Scalars["String"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_5_name?: Maybe<Scalars["String"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_name?: Maybe<Scalars["String"]>;
  yard_other_animal_number?: Maybe<Scalars["Int"]>;
  yard_rabbit?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "sprayed" */
export type Sprayed_Max_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_in_material?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_material?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complaint_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  family_chief?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_name?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_name?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_name?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_name?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_name?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_name?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insecticide?: InputMaybe<Order_By>;
  origin_from_complaint?: InputMaybe<Order_By>;
  reported_at?: InputMaybe<Order_By>;
  roof_other_animal_1_name?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_name?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_name?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_name?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_name?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_name?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  sprayer_id?: InputMaybe<Order_By>;
  sprayer_name?: InputMaybe<Order_By>;
  unicode_from_radio?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_name?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_name?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_name?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_name?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_name?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_name?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sprayed_Min_Fields = {
  __typename?: "sprayed_min_fields";
  address_in_crack?: Maybe<Scalars["Int"]>;
  address_in_env?: Maybe<Scalars["Int"]>;
  address_in_env_close?: Maybe<Scalars["Int"]>;
  address_in_material?: Maybe<Scalars["String"]>;
  address_out_crack?: Maybe<Scalars["Int"]>;
  address_out_env?: Maybe<Scalars["Int"]>;
  address_out_env_close?: Maybe<Scalars["Int"]>;
  address_out_material?: Maybe<Scalars["String"]>;
  address_out_triatomines?: Maybe<Scalars["Int"]>;
  address_triatomines?: Maybe<Scalars["Int"]>;
  app_state?: Maybe<Scalars["String"]>;
  clean_area?: Maybe<Scalars["Int"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  complaint_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  family_chief?: Maybe<Scalars["String"]>;
  family_size?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  house_bird?: Maybe<Scalars["Int"]>;
  house_cat?: Maybe<Scalars["Int"]>;
  house_cuy?: Maybe<Scalars["Int"]>;
  house_dog?: Maybe<Scalars["Int"]>;
  house_other_animal_1_name?: Maybe<Scalars["String"]>;
  house_other_animal_1_number?: Maybe<Scalars["Int"]>;
  house_other_animal_2_name?: Maybe<Scalars["String"]>;
  house_other_animal_2_number?: Maybe<Scalars["Int"]>;
  house_other_animal_3_name?: Maybe<Scalars["String"]>;
  house_other_animal_3_number?: Maybe<Scalars["Int"]>;
  house_other_animal_4_name?: Maybe<Scalars["String"]>;
  house_other_animal_4_number?: Maybe<Scalars["Int"]>;
  house_other_animal_5_name?: Maybe<Scalars["String"]>;
  house_other_animal_5_number?: Maybe<Scalars["Int"]>;
  house_other_animal_name?: Maybe<Scalars["String"]>;
  house_other_animal_number?: Maybe<Scalars["Int"]>;
  house_rabbit?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  insecticide?: Maybe<Scalars["String"]>;
  origin_from_complaint?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  roof_other_animal_1_name?: Maybe<Scalars["String"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_2_name?: Maybe<Scalars["String"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_3_name?: Maybe<Scalars["String"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_4_name?: Maybe<Scalars["String"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_5_name?: Maybe<Scalars["String"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_name?: Maybe<Scalars["String"]>;
  roof_other_animal_number?: Maybe<Scalars["Int"]>;
  roof_top_bird?: Maybe<Scalars["Int"]>;
  roof_top_cat?: Maybe<Scalars["Int"]>;
  roof_top_cuy?: Maybe<Scalars["Int"]>;
  roof_top_dog?: Maybe<Scalars["Int"]>;
  roof_top_rabbit?: Maybe<Scalars["Int"]>;
  spent_insecticides?: Maybe<Scalars["Int"]>;
  sprayer_id?: Maybe<Scalars["uuid"]>;
  sprayer_name?: Maybe<Scalars["String"]>;
  unicode_from_radio?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
  yard_bird?: Maybe<Scalars["Int"]>;
  yard_cat?: Maybe<Scalars["Int"]>;
  yard_cuy?: Maybe<Scalars["Int"]>;
  yard_dog?: Maybe<Scalars["Int"]>;
  yard_other_animal_1_name?: Maybe<Scalars["String"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_2_name?: Maybe<Scalars["String"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_3_name?: Maybe<Scalars["String"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_4_name?: Maybe<Scalars["String"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_5_name?: Maybe<Scalars["String"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_name?: Maybe<Scalars["String"]>;
  yard_other_animal_number?: Maybe<Scalars["Int"]>;
  yard_rabbit?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "sprayed" */
export type Sprayed_Min_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_in_material?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_material?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complaint_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  family_chief?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_name?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_name?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_name?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_name?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_name?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_name?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insecticide?: InputMaybe<Order_By>;
  origin_from_complaint?: InputMaybe<Order_By>;
  reported_at?: InputMaybe<Order_By>;
  roof_other_animal_1_name?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_name?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_name?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_name?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_name?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_name?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  sprayer_id?: InputMaybe<Order_By>;
  sprayer_name?: InputMaybe<Order_By>;
  unicode_from_radio?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_name?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_name?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_name?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_name?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_name?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_name?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sprayed" */
export type Sprayed_Mutation_Response = {
  __typename?: "sprayed_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Sprayed>;
};

/** on_conflict condition type for table "sprayed" */
export type Sprayed_On_Conflict = {
  constraint: Sprayed_Constraint;
  update_columns?: Array<Sprayed_Update_Column>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

/** Ordering options when selecting data from "sprayed". */
export type Sprayed_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_in_material?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_material?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  collaborator?: InputMaybe<Collaborator_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complaint_id?: InputMaybe<Order_By>;
  comprehensive_treatment_metadata?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  family_chief?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  from_radio?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint?: InputMaybe<Geopoint_Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_name?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_name?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_name?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_name?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_name?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_name?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insecticide?: InputMaybe<Order_By>;
  origin_from_complaint?: InputMaybe<Order_By>;
  reported_at?: InputMaybe<Order_By>;
  residual_treatment_kind?: InputMaybe<Order_By>;
  roof_other_animal_1_name?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_name?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_name?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_name?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_name?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_name?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  sprayed_intra_event?: InputMaybe<Order_By>;
  sprayed_peri_event?: InputMaybe<Order_By>;
  sprayer_id?: InputMaybe<Order_By>;
  sprayer_name?: InputMaybe<Order_By>;
  unicode_from_radio?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_name?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_name?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_name?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_name?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_name?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_name?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sprayed */
export type Sprayed_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Sprayed_Prepend_Input = {
  comprehensive_treatment_metadata?: InputMaybe<Scalars["jsonb"]>;
  sprayed_intra_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_peri_event?: InputMaybe<Scalars["jsonb"]>;
};

/** columns and relationships of "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind = {
  __typename?: "sprayed_residual_treatment_kind";
  comment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Aggregate = {
  __typename?: "sprayed_residual_treatment_kind_aggregate";
  aggregate?: Maybe<Sprayed_Residual_Treatment_Kind_Aggregate_Fields>;
  nodes: Array<Sprayed_Residual_Treatment_Kind>;
};

/** aggregate fields of "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Aggregate_Fields = {
  __typename?: "sprayed_residual_treatment_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sprayed_Residual_Treatment_Kind_Max_Fields>;
  min?: Maybe<Sprayed_Residual_Treatment_Kind_Min_Fields>;
};

/** aggregate fields of "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "sprayed_residual_treatment_kind". All fields are combined with a logical 'AND'. */
export type Sprayed_Residual_Treatment_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Bool_Exp>>;
  _not?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sprayed_residual_treatment_kind" */
export enum Sprayed_Residual_Treatment_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  SprayedResidualTreatmentKindPkey = "sprayed_residual_treatment_kind_pkey",
}

export enum Sprayed_Residual_Treatment_Kind_Enum {
  Closed = "closed",
  ComprehensiveTreatment = "comprehensive_treatment",
  Reluctanted = "reluctanted",
}

/** Boolean expression to compare columns of type "sprayed_residual_treatment_kind_enum". All fields are combined with logical 'AND'. */
export type Sprayed_Residual_Treatment_Kind_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Sprayed_Residual_Treatment_Kind_Enum>;
  _in?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Sprayed_Residual_Treatment_Kind_Enum>;
  _nin?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Enum>>;
};

/** input type for inserting data into table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Sprayed_Residual_Treatment_Kind_Max_Fields = {
  __typename?: "sprayed_residual_treatment_kind_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Sprayed_Residual_Treatment_Kind_Min_Fields = {
  __typename?: "sprayed_residual_treatment_kind_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Mutation_Response = {
  __typename?: "sprayed_residual_treatment_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Sprayed_Residual_Treatment_Kind>;
};

/** on_conflict condition type for table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_On_Conflict = {
  constraint: Sprayed_Residual_Treatment_Kind_Constraint;
  update_columns?: Array<Sprayed_Residual_Treatment_Kind_Update_Column>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "sprayed_residual_treatment_kind". */
export type Sprayed_Residual_Treatment_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sprayed_residual_treatment_kind */
export type Sprayed_Residual_Treatment_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "sprayed_residual_treatment_kind" */
export enum Sprayed_Residual_Treatment_Kind_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sprayed_Residual_Treatment_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sprayed_Residual_Treatment_Kind_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "sprayed_residual_treatment_kind" */
export enum Sprayed_Residual_Treatment_Kind_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Sprayed_Residual_Treatment_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sprayed_Residual_Treatment_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sprayed_Residual_Treatment_Kind_Bool_Exp;
};

/** select columns of table "sprayed" */
export enum Sprayed_Select_Column {
  /** column name */
  AddressInCrack = "address_in_crack",
  /** column name */
  AddressInEnv = "address_in_env",
  /** column name */
  AddressInEnvClose = "address_in_env_close",
  /** column name */
  AddressInMaterial = "address_in_material",
  /** column name */
  AddressOutCrack = "address_out_crack",
  /** column name */
  AddressOutEnv = "address_out_env",
  /** column name */
  AddressOutEnvClose = "address_out_env_close",
  /** column name */
  AddressOutMaterial = "address_out_material",
  /** column name */
  AddressOutTriatomines = "address_out_triatomines",
  /** column name */
  AddressTriatomines = "address_triatomines",
  /** column name */
  AppState = "app_state",
  /** column name */
  CleanArea = "clean_area",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  ComplaintId = "complaint_id",
  /** column name */
  ComprehensiveTreatmentMetadata = "comprehensive_treatment_metadata",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FamilyChief = "family_chief",
  /** column name */
  FamilySize = "family_size",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  FromRadio = "from_radio",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  HouseBird = "house_bird",
  /** column name */
  HouseCat = "house_cat",
  /** column name */
  HouseCuy = "house_cuy",
  /** column name */
  HouseDog = "house_dog",
  /** column name */
  HouseOtherAnimal_1Name = "house_other_animal_1_name",
  /** column name */
  HouseOtherAnimal_1Number = "house_other_animal_1_number",
  /** column name */
  HouseOtherAnimal_2Name = "house_other_animal_2_name",
  /** column name */
  HouseOtherAnimal_2Number = "house_other_animal_2_number",
  /** column name */
  HouseOtherAnimal_3Name = "house_other_animal_3_name",
  /** column name */
  HouseOtherAnimal_3Number = "house_other_animal_3_number",
  /** column name */
  HouseOtherAnimal_4Name = "house_other_animal_4_name",
  /** column name */
  HouseOtherAnimal_4Number = "house_other_animal_4_number",
  /** column name */
  HouseOtherAnimal_5Name = "house_other_animal_5_name",
  /** column name */
  HouseOtherAnimal_5Number = "house_other_animal_5_number",
  /** column name */
  HouseOtherAnimalName = "house_other_animal_name",
  /** column name */
  HouseOtherAnimalNumber = "house_other_animal_number",
  /** column name */
  HouseRabbit = "house_rabbit",
  /** column name */
  Id = "id",
  /** column name */
  Insecticide = "insecticide",
  /** column name */
  OriginFromComplaint = "origin_from_complaint",
  /** column name */
  ReportedAt = "reported_at",
  /** column name */
  ResidualTreatmentKind = "residual_treatment_kind",
  /** column name */
  RoofOtherAnimal_1Name = "roof_other_animal_1_name",
  /** column name */
  RoofOtherAnimal_1Number = "roof_other_animal_1_number",
  /** column name */
  RoofOtherAnimal_2Name = "roof_other_animal_2_name",
  /** column name */
  RoofOtherAnimal_2Number = "roof_other_animal_2_number",
  /** column name */
  RoofOtherAnimal_3Name = "roof_other_animal_3_name",
  /** column name */
  RoofOtherAnimal_3Number = "roof_other_animal_3_number",
  /** column name */
  RoofOtherAnimal_4Name = "roof_other_animal_4_name",
  /** column name */
  RoofOtherAnimal_4Number = "roof_other_animal_4_number",
  /** column name */
  RoofOtherAnimal_5Name = "roof_other_animal_5_name",
  /** column name */
  RoofOtherAnimal_5Number = "roof_other_animal_5_number",
  /** column name */
  RoofOtherAnimalName = "roof_other_animal_name",
  /** column name */
  RoofOtherAnimalNumber = "roof_other_animal_number",
  /** column name */
  RoofTopBird = "roof_top_bird",
  /** column name */
  RoofTopCat = "roof_top_cat",
  /** column name */
  RoofTopCuy = "roof_top_cuy",
  /** column name */
  RoofTopDog = "roof_top_dog",
  /** column name */
  RoofTopRabbit = "roof_top_rabbit",
  /** column name */
  SpentInsecticides = "spent_insecticides",
  /** column name */
  SprayedIntraEvent = "sprayed_intra_event",
  /** column name */
  SprayedPeriEvent = "sprayed_peri_event",
  /** column name */
  SprayerId = "sprayer_id",
  /** column name */
  SprayerName = "sprayer_name",
  /** column name */
  UnicodeFromRadio = "unicode_from_radio",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
  /** column name */
  YardBird = "yard_bird",
  /** column name */
  YardCat = "yard_cat",
  /** column name */
  YardCuy = "yard_cuy",
  /** column name */
  YardDog = "yard_dog",
  /** column name */
  YardOtherAnimal_1Name = "yard_other_animal_1_name",
  /** column name */
  YardOtherAnimal_1Number = "yard_other_animal_1_number",
  /** column name */
  YardOtherAnimal_2Name = "yard_other_animal_2_name",
  /** column name */
  YardOtherAnimal_2Number = "yard_other_animal_2_number",
  /** column name */
  YardOtherAnimal_3Name = "yard_other_animal_3_name",
  /** column name */
  YardOtherAnimal_3Number = "yard_other_animal_3_number",
  /** column name */
  YardOtherAnimal_4Name = "yard_other_animal_4_name",
  /** column name */
  YardOtherAnimal_4Number = "yard_other_animal_4_number",
  /** column name */
  YardOtherAnimal_5Name = "yard_other_animal_5_name",
  /** column name */
  YardOtherAnimal_5Number = "yard_other_animal_5_number",
  /** column name */
  YardOtherAnimalName = "yard_other_animal_name",
  /** column name */
  YardOtherAnimalNumber = "yard_other_animal_number",
  /** column name */
  YardRabbit = "yard_rabbit",
}

/** select "sprayed_aggregate_bool_exp_bool_and_arguments_columns" columns of table "sprayed" */
export enum Sprayed_Select_Column_Sprayed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FromRadio = "from_radio",
}

/** select "sprayed_aggregate_bool_exp_bool_or_arguments_columns" columns of table "sprayed" */
export enum Sprayed_Select_Column_Sprayed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FromRadio = "from_radio",
}

/** input type for updating data in table "sprayed" */
export type Sprayed_Set_Input = {
  address_in_crack?: InputMaybe<Scalars["Int"]>;
  address_in_env?: InputMaybe<Scalars["Int"]>;
  address_in_env_close?: InputMaybe<Scalars["Int"]>;
  address_in_material?: InputMaybe<Scalars["String"]>;
  address_out_crack?: InputMaybe<Scalars["Int"]>;
  address_out_env?: InputMaybe<Scalars["Int"]>;
  address_out_env_close?: InputMaybe<Scalars["Int"]>;
  address_out_material?: InputMaybe<Scalars["String"]>;
  address_out_triatomines?: InputMaybe<Scalars["Int"]>;
  address_triatomines?: InputMaybe<Scalars["Int"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  clean_area?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complaint_id?: InputMaybe<Scalars["uuid"]>;
  comprehensive_treatment_metadata?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  family_chief?: InputMaybe<Scalars["String"]>;
  family_size?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  from_radio?: InputMaybe<Scalars["Boolean"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  house_bird?: InputMaybe<Scalars["Int"]>;
  house_cat?: InputMaybe<Scalars["Int"]>;
  house_cuy?: InputMaybe<Scalars["Int"]>;
  house_dog?: InputMaybe<Scalars["Int"]>;
  house_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_number?: InputMaybe<Scalars["Int"]>;
  house_rabbit?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecticide?: InputMaybe<Scalars["String"]>;
  origin_from_complaint?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  residual_treatment_kind?: InputMaybe<Sprayed_Residual_Treatment_Kind_Enum>;
  roof_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_number?: InputMaybe<Scalars["Int"]>;
  roof_top_bird?: InputMaybe<Scalars["Int"]>;
  roof_top_cat?: InputMaybe<Scalars["Int"]>;
  roof_top_cuy?: InputMaybe<Scalars["Int"]>;
  roof_top_dog?: InputMaybe<Scalars["Int"]>;
  roof_top_rabbit?: InputMaybe<Scalars["Int"]>;
  spent_insecticides?: InputMaybe<Scalars["Int"]>;
  sprayed_intra_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_peri_event?: InputMaybe<Scalars["jsonb"]>;
  sprayer_id?: InputMaybe<Scalars["uuid"]>;
  sprayer_name?: InputMaybe<Scalars["String"]>;
  unicode_from_radio?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
  yard_bird?: InputMaybe<Scalars["Int"]>;
  yard_cat?: InputMaybe<Scalars["Int"]>;
  yard_cuy?: InputMaybe<Scalars["Int"]>;
  yard_dog?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_number?: InputMaybe<Scalars["Int"]>;
  yard_rabbit?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Sprayed_Stddev_Fields = {
  __typename?: "sprayed_stddev_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "sprayed" */
export type Sprayed_Stddev_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sprayed_Stddev_Pop_Fields = {
  __typename?: "sprayed_stddev_pop_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "sprayed" */
export type Sprayed_Stddev_Pop_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sprayed_Stddev_Samp_Fields = {
  __typename?: "sprayed_stddev_samp_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "sprayed" */
export type Sprayed_Stddev_Samp_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sprayed" */
export type Sprayed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sprayed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sprayed_Stream_Cursor_Value_Input = {
  address_in_crack?: InputMaybe<Scalars["Int"]>;
  address_in_env?: InputMaybe<Scalars["Int"]>;
  address_in_env_close?: InputMaybe<Scalars["Int"]>;
  address_in_material?: InputMaybe<Scalars["String"]>;
  address_out_crack?: InputMaybe<Scalars["Int"]>;
  address_out_env?: InputMaybe<Scalars["Int"]>;
  address_out_env_close?: InputMaybe<Scalars["Int"]>;
  address_out_material?: InputMaybe<Scalars["String"]>;
  address_out_triatomines?: InputMaybe<Scalars["Int"]>;
  address_triatomines?: InputMaybe<Scalars["Int"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  clean_area?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complaint_id?: InputMaybe<Scalars["uuid"]>;
  comprehensive_treatment_metadata?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  family_chief?: InputMaybe<Scalars["String"]>;
  family_size?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  from_radio?: InputMaybe<Scalars["Boolean"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  house_bird?: InputMaybe<Scalars["Int"]>;
  house_cat?: InputMaybe<Scalars["Int"]>;
  house_cuy?: InputMaybe<Scalars["Int"]>;
  house_dog?: InputMaybe<Scalars["Int"]>;
  house_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_number?: InputMaybe<Scalars["Int"]>;
  house_rabbit?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecticide?: InputMaybe<Scalars["String"]>;
  origin_from_complaint?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  residual_treatment_kind?: InputMaybe<Sprayed_Residual_Treatment_Kind_Enum>;
  roof_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_number?: InputMaybe<Scalars["Int"]>;
  roof_top_bird?: InputMaybe<Scalars["Int"]>;
  roof_top_cat?: InputMaybe<Scalars["Int"]>;
  roof_top_cuy?: InputMaybe<Scalars["Int"]>;
  roof_top_dog?: InputMaybe<Scalars["Int"]>;
  roof_top_rabbit?: InputMaybe<Scalars["Int"]>;
  spent_insecticides?: InputMaybe<Scalars["Int"]>;
  sprayed_intra_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_peri_event?: InputMaybe<Scalars["jsonb"]>;
  sprayer_id?: InputMaybe<Scalars["uuid"]>;
  sprayer_name?: InputMaybe<Scalars["String"]>;
  unicode_from_radio?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
  yard_bird?: InputMaybe<Scalars["Int"]>;
  yard_cat?: InputMaybe<Scalars["Int"]>;
  yard_cuy?: InputMaybe<Scalars["Int"]>;
  yard_dog?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_number?: InputMaybe<Scalars["Int"]>;
  yard_rabbit?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Sprayed_Sum_Fields = {
  __typename?: "sprayed_sum_fields";
  address_in_crack?: Maybe<Scalars["Int"]>;
  address_in_env?: Maybe<Scalars["Int"]>;
  address_in_env_close?: Maybe<Scalars["Int"]>;
  address_out_crack?: Maybe<Scalars["Int"]>;
  address_out_env?: Maybe<Scalars["Int"]>;
  address_out_env_close?: Maybe<Scalars["Int"]>;
  address_out_triatomines?: Maybe<Scalars["Int"]>;
  address_triatomines?: Maybe<Scalars["Int"]>;
  clean_area?: Maybe<Scalars["Int"]>;
  family_size?: Maybe<Scalars["Int"]>;
  house_bird?: Maybe<Scalars["Int"]>;
  house_cat?: Maybe<Scalars["Int"]>;
  house_cuy?: Maybe<Scalars["Int"]>;
  house_dog?: Maybe<Scalars["Int"]>;
  house_other_animal_1_number?: Maybe<Scalars["Int"]>;
  house_other_animal_2_number?: Maybe<Scalars["Int"]>;
  house_other_animal_3_number?: Maybe<Scalars["Int"]>;
  house_other_animal_4_number?: Maybe<Scalars["Int"]>;
  house_other_animal_5_number?: Maybe<Scalars["Int"]>;
  house_other_animal_number?: Maybe<Scalars["Int"]>;
  house_rabbit?: Maybe<Scalars["Int"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_number?: Maybe<Scalars["Int"]>;
  roof_top_bird?: Maybe<Scalars["Int"]>;
  roof_top_cat?: Maybe<Scalars["Int"]>;
  roof_top_cuy?: Maybe<Scalars["Int"]>;
  roof_top_dog?: Maybe<Scalars["Int"]>;
  roof_top_rabbit?: Maybe<Scalars["Int"]>;
  spent_insecticides?: Maybe<Scalars["Int"]>;
  yard_bird?: Maybe<Scalars["Int"]>;
  yard_cat?: Maybe<Scalars["Int"]>;
  yard_cuy?: Maybe<Scalars["Int"]>;
  yard_dog?: Maybe<Scalars["Int"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_number?: Maybe<Scalars["Int"]>;
  yard_rabbit?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "sprayed" */
export type Sprayed_Sum_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** update columns of table "sprayed" */
export enum Sprayed_Update_Column {
  /** column name */
  AddressInCrack = "address_in_crack",
  /** column name */
  AddressInEnv = "address_in_env",
  /** column name */
  AddressInEnvClose = "address_in_env_close",
  /** column name */
  AddressInMaterial = "address_in_material",
  /** column name */
  AddressOutCrack = "address_out_crack",
  /** column name */
  AddressOutEnv = "address_out_env",
  /** column name */
  AddressOutEnvClose = "address_out_env_close",
  /** column name */
  AddressOutMaterial = "address_out_material",
  /** column name */
  AddressOutTriatomines = "address_out_triatomines",
  /** column name */
  AddressTriatomines = "address_triatomines",
  /** column name */
  AppState = "app_state",
  /** column name */
  CleanArea = "clean_area",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  ComplaintId = "complaint_id",
  /** column name */
  ComprehensiveTreatmentMetadata = "comprehensive_treatment_metadata",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FamilyChief = "family_chief",
  /** column name */
  FamilySize = "family_size",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  FromRadio = "from_radio",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  HouseBird = "house_bird",
  /** column name */
  HouseCat = "house_cat",
  /** column name */
  HouseCuy = "house_cuy",
  /** column name */
  HouseDog = "house_dog",
  /** column name */
  HouseOtherAnimal_1Name = "house_other_animal_1_name",
  /** column name */
  HouseOtherAnimal_1Number = "house_other_animal_1_number",
  /** column name */
  HouseOtherAnimal_2Name = "house_other_animal_2_name",
  /** column name */
  HouseOtherAnimal_2Number = "house_other_animal_2_number",
  /** column name */
  HouseOtherAnimal_3Name = "house_other_animal_3_name",
  /** column name */
  HouseOtherAnimal_3Number = "house_other_animal_3_number",
  /** column name */
  HouseOtherAnimal_4Name = "house_other_animal_4_name",
  /** column name */
  HouseOtherAnimal_4Number = "house_other_animal_4_number",
  /** column name */
  HouseOtherAnimal_5Name = "house_other_animal_5_name",
  /** column name */
  HouseOtherAnimal_5Number = "house_other_animal_5_number",
  /** column name */
  HouseOtherAnimalName = "house_other_animal_name",
  /** column name */
  HouseOtherAnimalNumber = "house_other_animal_number",
  /** column name */
  HouseRabbit = "house_rabbit",
  /** column name */
  Id = "id",
  /** column name */
  Insecticide = "insecticide",
  /** column name */
  OriginFromComplaint = "origin_from_complaint",
  /** column name */
  ReportedAt = "reported_at",
  /** column name */
  ResidualTreatmentKind = "residual_treatment_kind",
  /** column name */
  RoofOtherAnimal_1Name = "roof_other_animal_1_name",
  /** column name */
  RoofOtherAnimal_1Number = "roof_other_animal_1_number",
  /** column name */
  RoofOtherAnimal_2Name = "roof_other_animal_2_name",
  /** column name */
  RoofOtherAnimal_2Number = "roof_other_animal_2_number",
  /** column name */
  RoofOtherAnimal_3Name = "roof_other_animal_3_name",
  /** column name */
  RoofOtherAnimal_3Number = "roof_other_animal_3_number",
  /** column name */
  RoofOtherAnimal_4Name = "roof_other_animal_4_name",
  /** column name */
  RoofOtherAnimal_4Number = "roof_other_animal_4_number",
  /** column name */
  RoofOtherAnimal_5Name = "roof_other_animal_5_name",
  /** column name */
  RoofOtherAnimal_5Number = "roof_other_animal_5_number",
  /** column name */
  RoofOtherAnimalName = "roof_other_animal_name",
  /** column name */
  RoofOtherAnimalNumber = "roof_other_animal_number",
  /** column name */
  RoofTopBird = "roof_top_bird",
  /** column name */
  RoofTopCat = "roof_top_cat",
  /** column name */
  RoofTopCuy = "roof_top_cuy",
  /** column name */
  RoofTopDog = "roof_top_dog",
  /** column name */
  RoofTopRabbit = "roof_top_rabbit",
  /** column name */
  SpentInsecticides = "spent_insecticides",
  /** column name */
  SprayedIntraEvent = "sprayed_intra_event",
  /** column name */
  SprayedPeriEvent = "sprayed_peri_event",
  /** column name */
  SprayerId = "sprayer_id",
  /** column name */
  SprayerName = "sprayer_name",
  /** column name */
  UnicodeFromRadio = "unicode_from_radio",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
  /** column name */
  YardBird = "yard_bird",
  /** column name */
  YardCat = "yard_cat",
  /** column name */
  YardCuy = "yard_cuy",
  /** column name */
  YardDog = "yard_dog",
  /** column name */
  YardOtherAnimal_1Name = "yard_other_animal_1_name",
  /** column name */
  YardOtherAnimal_1Number = "yard_other_animal_1_number",
  /** column name */
  YardOtherAnimal_2Name = "yard_other_animal_2_name",
  /** column name */
  YardOtherAnimal_2Number = "yard_other_animal_2_number",
  /** column name */
  YardOtherAnimal_3Name = "yard_other_animal_3_name",
  /** column name */
  YardOtherAnimal_3Number = "yard_other_animal_3_number",
  /** column name */
  YardOtherAnimal_4Name = "yard_other_animal_4_name",
  /** column name */
  YardOtherAnimal_4Number = "yard_other_animal_4_number",
  /** column name */
  YardOtherAnimal_5Name = "yard_other_animal_5_name",
  /** column name */
  YardOtherAnimal_5Number = "yard_other_animal_5_number",
  /** column name */
  YardOtherAnimalName = "yard_other_animal_name",
  /** column name */
  YardOtherAnimalNumber = "yard_other_animal_number",
  /** column name */
  YardRabbit = "yard_rabbit",
}

export type Sprayed_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Sprayed_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Sprayed_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Sprayed_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Sprayed_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sprayed_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Sprayed_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sprayed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sprayed_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sprayed_Var_Pop_Fields = {
  __typename?: "sprayed_var_pop_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "sprayed" */
export type Sprayed_Var_Pop_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sprayed_Var_Samp_Fields = {
  __typename?: "sprayed_var_samp_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "sprayed" */
export type Sprayed_Var_Samp_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sprayed_Variance_Fields = {
  __typename?: "sprayed_variance_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "sprayed" */
export type Sprayed_Variance_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** columns and relationships of "sprayer" */
export type Sprayer = {
  __typename?: "sprayer";
  auth_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  dni: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "sprayer" */
export type Sprayer_Aggregate = {
  __typename?: "sprayer_aggregate";
  aggregate?: Maybe<Sprayer_Aggregate_Fields>;
  nodes: Array<Sprayer>;
};

/** aggregate fields of "sprayer" */
export type Sprayer_Aggregate_Fields = {
  __typename?: "sprayer_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sprayer_Max_Fields>;
  min?: Maybe<Sprayer_Min_Fields>;
};

/** aggregate fields of "sprayer" */
export type Sprayer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sprayer_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "sprayer". All fields are combined with a logical 'AND'. */
export type Sprayer_Bool_Exp = {
  _and?: InputMaybe<Array<Sprayer_Bool_Exp>>;
  _not?: InputMaybe<Sprayer_Bool_Exp>;
  _or?: InputMaybe<Array<Sprayer_Bool_Exp>>;
  auth_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dni?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sprayer" */
export enum Sprayer_Constraint {
  /** unique or primary key constraint on columns "dni" */
  SprayerDniKey = "sprayer_dni_key",
  /** unique or primary key constraint on columns "id" */
  SprayerPkey = "sprayer_pkey",
}

/** input type for inserting data into table "sprayer" */
export type Sprayer_Insert_Input = {
  auth_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Sprayer_Max_Fields = {
  __typename?: "sprayer_max_fields";
  auth_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dni?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Sprayer_Min_Fields = {
  __typename?: "sprayer_min_fields";
  auth_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dni?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "sprayer" */
export type Sprayer_Mutation_Response = {
  __typename?: "sprayer_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Sprayer>;
};

/** on_conflict condition type for table "sprayer" */
export type Sprayer_On_Conflict = {
  constraint: Sprayer_Constraint;
  update_columns?: Array<Sprayer_Update_Column>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

/** Ordering options when selecting data from "sprayer". */
export type Sprayer_Order_By = {
  auth_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dni?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sprayer */
export type Sprayer_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "sprayer" */
export enum Sprayer_Select_Column {
  /** column name */
  AuthId = "auth_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Dni = "dni",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Phone = "phone",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "sprayer" */
export type Sprayer_Set_Input = {
  auth_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "sprayer" */
export type Sprayer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sprayer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sprayer_Stream_Cursor_Value_Input = {
  auth_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "sprayer" */
export enum Sprayer_Update_Column {
  /** column name */
  AuthId = "auth_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Dni = "dni",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Phone = "phone",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Sprayer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sprayer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sprayer_Bool_Exp;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars["Float"];
  from: Scalars["geography"];
  use_spheroid?: InputMaybe<Scalars["Boolean"]>;
};

export type St_D_Within_Input = {
  distance: Scalars["Float"];
  from: Scalars["geometry"];
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "Capture_Place" */
  Capture_Place: Array<Capture_Place>;
  /** fetch aggregated fields from the table: "Capture_Place" */
  Capture_Place_aggregate: Capture_Place_Aggregate;
  /** fetch data from the table: "Capture_Place" using primary key columns */
  Capture_Place_by_pk?: Maybe<Capture_Place>;
  /** fetch data from the table in a streaming manner: "Capture_Place" */
  Capture_Place_stream: Array<Capture_Place>;
  /** fetch data from the table: "Number_Triatomines_Captured" */
  Number_Triatomines_Captured: Array<Number_Triatomines_Captured>;
  /** fetch aggregated fields from the table: "Number_Triatomines_Captured" */
  Number_Triatomines_Captured_aggregate: Number_Triatomines_Captured_Aggregate;
  /** fetch data from the table: "Number_Triatomines_Captured" using primary key columns */
  Number_Triatomines_Captured_by_pk?: Maybe<Number_Triatomines_Captured>;
  /** fetch data from the table in a streaming manner: "Number_Triatomines_Captured" */
  Number_Triatomines_Captured_stream: Array<Number_Triatomines_Captured>;
  /** fetch data from the table: "Number_Triatomines_Inspected" */
  Number_Triatomines_Inspected: Array<Number_Triatomines_Inspected>;
  /** fetch aggregated fields from the table: "Number_Triatomines_Inspected" */
  Number_Triatomines_Inspected_aggregate: Number_Triatomines_Inspected_Aggregate;
  /** fetch data from the table: "Number_Triatomines_Inspected" using primary key columns */
  Number_Triatomines_Inspected_by_pk?: Maybe<Number_Triatomines_Inspected>;
  /** fetch data from the table in a streaming manner: "Number_Triatomines_Inspected" */
  Number_Triatomines_Inspected_stream: Array<Number_Triatomines_Inspected>;
  /** fetch data from the table: "Number_Triatomines_Positive" */
  Number_Triatomines_Positive: Array<Number_Triatomines_Positive>;
  /** fetch aggregated fields from the table: "Number_Triatomines_Positive" */
  Number_Triatomines_Positive_aggregate: Number_Triatomines_Positive_Aggregate;
  /** fetch data from the table: "Number_Triatomines_Positive" using primary key columns */
  Number_Triatomines_Positive_by_pk?: Maybe<Number_Triatomines_Positive>;
  /** fetch data from the table in a streaming manner: "Number_Triatomines_Positive" */
  Number_Triatomines_Positive_stream: Array<Number_Triatomines_Positive>;
  /** fetch data from the table: "Place_Place" */
  Place_Place: Array<Place_Place>;
  /** fetch aggregated fields from the table: "Place_Place" */
  Place_Place_aggregate: Place_Place_Aggregate;
  /** fetch data from the table: "Place_Place" using primary key columns */
  Place_Place_by_pk?: Maybe<Place_Place>;
  /** fetch data from the table in a streaming manner: "Place_Place" */
  Place_Place_stream: Array<Place_Place>;
  /** fetch data from the table: "Place_Triatomines_Experiment_Record" */
  Place_Triatomines_Experiment_Record: Array<Place_Triatomines_Experiment_Record>;
  /** fetch aggregated fields from the table: "Place_Triatomines_Experiment_Record" */
  Place_Triatomines_Experiment_Record_aggregate: Place_Triatomines_Experiment_Record_Aggregate;
  /** fetch data from the table: "Place_Triatomines_Experiment_Record" using primary key columns */
  Place_Triatomines_Experiment_Record_by_pk?: Maybe<Place_Triatomines_Experiment_Record>;
  /** fetch data from the table in a streaming manner: "Place_Triatomines_Experiment_Record" */
  Place_Triatomines_Experiment_Record_stream: Array<Place_Triatomines_Experiment_Record>;
  /** fetch data from the table: "Place_children" */
  Place_children: Array<Place_Children>;
  /** fetch aggregated fields from the table: "Place_children" */
  Place_children_aggregate: Place_Children_Aggregate;
  /** fetch data from the table: "Place_children" using primary key columns */
  Place_children_by_pk?: Maybe<Place_Children>;
  /** fetch data from the table in a streaming manner: "Place_children" */
  Place_children_stream: Array<Place_Children>;
  /** fetch data from the table: "Place_files" */
  Place_files: Array<Place_Files>;
  /** fetch aggregated fields from the table: "Place_files" */
  Place_files_aggregate: Place_Files_Aggregate;
  /** fetch data from the table: "Place_files" using primary key columns */
  Place_files_by_pk?: Maybe<Place_Files>;
  /** fetch data from the table in a streaming manner: "Place_files" */
  Place_files_stream: Array<Place_Files>;
  /** fetch data from the table: "Place" */
  Place_kind: Array<Place_Kind>;
  /** fetch aggregated fields from the table: "Place" */
  Place_kind_aggregate: Place_Kind_Aggregate;
  /** fetch data from the table: "Place" using primary key columns */
  Place_kind_by_pk?: Maybe<Place_Kind>;
  /** fetch data from the table in a streaming manner: "Place" */
  Place_kind_stream: Array<Place_Kind>;
  /** fetch data from the table: "Place_record" */
  Place_record: Array<Place_Record>;
  /** fetch aggregated fields from the table: "Place_record" */
  Place_record_aggregate: Place_Record_Aggregate;
  /** fetch data from the table: "Place_record" using primary key columns */
  Place_record_by_pk?: Maybe<Place_Record>;
  /** fetch data from the table in a streaming manner: "Place_record" */
  Place_record_stream: Array<Place_Record>;
  /** fetch data from the table: "Triatomines" */
  Triatomines: Array<Triatomines>;
  /** fetch data from the table: "Triatomines_Experiment_Record" */
  Triatomines_Experiment_Record: Array<Triatomines_Experiment_Record>;
  /** fetch data from the table: "Triatomines_Experiment_Record_Place" */
  Triatomines_Experiment_Record_Place: Array<Triatomines_Experiment_Record_Place>;
  /** fetch aggregated fields from the table: "Triatomines_Experiment_Record_Place" */
  Triatomines_Experiment_Record_Place_aggregate: Triatomines_Experiment_Record_Place_Aggregate;
  /** fetch data from the table: "Triatomines_Experiment_Record_Place" using primary key columns */
  Triatomines_Experiment_Record_Place_by_pk?: Maybe<Triatomines_Experiment_Record_Place>;
  /** fetch data from the table in a streaming manner: "Triatomines_Experiment_Record_Place" */
  Triatomines_Experiment_Record_Place_stream: Array<Triatomines_Experiment_Record_Place>;
  /** fetch aggregated fields from the table: "Triatomines_Experiment_Record" */
  Triatomines_Experiment_Record_aggregate: Triatomines_Experiment_Record_Aggregate;
  /** fetch data from the table: "Triatomines_Experiment_Record" using primary key columns */
  Triatomines_Experiment_Record_by_pk?: Maybe<Triatomines_Experiment_Record>;
  /** fetch data from the table: "Triatomines_Experiment_Record_directus_users" */
  Triatomines_Experiment_Record_directus_users: Array<Triatomines_Experiment_Record_Directus_Users>;
  /** fetch aggregated fields from the table: "Triatomines_Experiment_Record_directus_users" */
  Triatomines_Experiment_Record_directus_users_aggregate: Triatomines_Experiment_Record_Directus_Users_Aggregate;
  /** fetch data from the table: "Triatomines_Experiment_Record_directus_users" using primary key columns */
  Triatomines_Experiment_Record_directus_users_by_pk?: Maybe<Triatomines_Experiment_Record_Directus_Users>;
  /** fetch data from the table in a streaming manner: "Triatomines_Experiment_Record_directus_users" */
  Triatomines_Experiment_Record_directus_users_stream: Array<Triatomines_Experiment_Record_Directus_Users>;
  /** fetch data from the table in a streaming manner: "Triatomines_Experiment_Record" */
  Triatomines_Experiment_Record_stream: Array<Triatomines_Experiment_Record>;
  /** fetch aggregated fields from the table: "Triatomines" */
  Triatomines_aggregate: Triatomines_Aggregate;
  /** fetch data from the table: "Triatomines" using primary key columns */
  Triatomines_by_pk?: Maybe<Triatomines>;
  /** fetch data from the table in a streaming manner: "Triatomines" */
  Triatomines_stream: Array<Triatomines>;
  /** fetch data from the table: "advertisement" */
  advertisement: Array<Advertisement>;
  /** fetch aggregated fields from the table: "advertisement" */
  advertisement_aggregate: Advertisement_Aggregate;
  /** fetch data from the table: "advertisement" using primary key columns */
  advertisement_by_pk?: Maybe<Advertisement>;
  /** fetch data from the table in a streaming manner: "advertisement" */
  advertisement_stream: Array<Advertisement>;
  /** fetch data from the table: "collaborator" */
  collaborator: Array<Collaborator>;
  /** fetch aggregated fields from the table: "collaborator" */
  collaborator_aggregate: Collaborator_Aggregate;
  /** fetch data from the table: "collaborator" using primary key columns */
  collaborator_by_pk?: Maybe<Collaborator>;
  /** fetch data from the table in a streaming manner: "collaborator" */
  collaborator_stream: Array<Collaborator>;
  /** fetch data from the table: "complaint" */
  complaint: Array<Complaint>;
  /** fetch aggregated fields from the table: "complaint" */
  complaint_aggregate: Complaint_Aggregate;
  /** fetch data from the table: "complaint" using primary key columns */
  complaint_by_pk?: Maybe<Complaint>;
  /** fetch data from the table: "complaint_kind" */
  complaint_kind: Array<Complaint_Kind>;
  /** fetch aggregated fields from the table: "complaint_kind" */
  complaint_kind_aggregate: Complaint_Kind_Aggregate;
  /** fetch data from the table: "complaint_kind" using primary key columns */
  complaint_kind_by_pk?: Maybe<Complaint_Kind>;
  /** fetch data from the table in a streaming manner: "complaint_kind" */
  complaint_kind_stream: Array<Complaint_Kind>;
  /** fetch data from the table: "complaint_source" */
  complaint_source: Array<Complaint_Source>;
  /** fetch aggregated fields from the table: "complaint_source" */
  complaint_source_aggregate: Complaint_Source_Aggregate;
  /** fetch data from the table: "complaint_source" using primary key columns */
  complaint_source_by_pk?: Maybe<Complaint_Source>;
  /** fetch data from the table in a streaming manner: "complaint_source" */
  complaint_source_stream: Array<Complaint_Source>;
  /** fetch data from the table in a streaming manner: "complaint" */
  complaint_stream: Array<Complaint>;
  /** execute function "compute_group" which returns "geopoint" */
  compute_group: Array<Geopoint>;
  /** execute function "compute_group" and query aggregates on result of table type "geopoint" */
  compute_group_aggregate: Geopoint_Aggregate;
  /** fetch data from the table: "distances" */
  distances: Array<Distances>;
  /** fetch aggregated fields from the table: "distances" */
  distances_aggregate: Distances_Aggregate;
  /** fetch data from the table: "distances" using primary key columns */
  distances_by_pk?: Maybe<Distances>;
  /** fetch data from the table in a streaming manner: "distances" */
  distances_stream: Array<Distances>;
  /** fetch data from the table: "estate_kind" */
  estate_kind: Array<Estate_Kind>;
  /** fetch aggregated fields from the table: "estate_kind" */
  estate_kind_aggregate: Estate_Kind_Aggregate;
  /** fetch data from the table: "estate_kind" using primary key columns */
  estate_kind_by_pk?: Maybe<Estate_Kind>;
  /** fetch data from the table in a streaming manner: "estate_kind" */
  estate_kind_stream: Array<Estate_Kind>;
  /** fetch data from the table: "experiment" */
  experiment: Array<Experiment>;
  /** fetch aggregated fields from the table: "experiment" */
  experiment_aggregate: Experiment_Aggregate;
  /** fetch data from the table: "experiment" using primary key columns */
  experiment_by_pk?: Maybe<Experiment>;
  /** fetch data from the table in a streaming manner: "experiment" */
  experiment_stream: Array<Experiment>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geography_columns" */
  geography_columns_stream: Array<Geography_Columns>;
  /** An array relationship */
  geogroup: Array<Geogroup>;
  /** An aggregate relationship */
  geogroup_aggregate: Geogroup_Aggregate;
  /** fetch data from the table: "geogroup" using primary key columns */
  geogroup_by_pk?: Maybe<Geogroup>;
  /** fetch data from the table in a streaming manner: "geogroup" */
  geogroup_stream: Array<Geogroup>;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geometry_columns" */
  geometry_columns_stream: Array<Geometry_Columns>;
  /** fetch data from the table: "geopoint" */
  geopoint: Array<Geopoint>;
  /** fetch aggregated fields from the table: "geopoint" */
  geopoint_aggregate: Geopoint_Aggregate;
  /** fetch data from the table: "geopoint" using primary key columns */
  geopoint_by_pk?: Maybe<Geopoint>;
  /** fetch data from the table in a streaming manner: "geopoint" */
  geopoint_stream: Array<Geopoint>;
  /** fetch data from the table: "inspection" */
  inspection: Array<Inspection>;
  /** fetch aggregated fields from the table: "inspection" */
  inspection_aggregate: Inspection_Aggregate;
  /** fetch data from the table: "inspection" using primary key columns */
  inspection_by_pk?: Maybe<Inspection>;
  /** fetch data from the table: "inspection_event_kind" */
  inspection_event_kind: Array<Inspection_Event_Kind>;
  /** fetch aggregated fields from the table: "inspection_event_kind" */
  inspection_event_kind_aggregate: Inspection_Event_Kind_Aggregate;
  /** fetch data from the table: "inspection_event_kind" using primary key columns */
  inspection_event_kind_by_pk?: Maybe<Inspection_Event_Kind>;
  /** fetch data from the table in a streaming manner: "inspection_event_kind" */
  inspection_event_kind_stream: Array<Inspection_Event_Kind>;
  /** fetch data from the table: "inspection_interview_kind" */
  inspection_interview_kind: Array<Inspection_Interview_Kind>;
  /** fetch aggregated fields from the table: "inspection_interview_kind" */
  inspection_interview_kind_aggregate: Inspection_Interview_Kind_Aggregate;
  /** fetch data from the table: "inspection_interview_kind" using primary key columns */
  inspection_interview_kind_by_pk?: Maybe<Inspection_Interview_Kind>;
  /** fetch data from the table in a streaming manner: "inspection_interview_kind" */
  inspection_interview_kind_stream: Array<Inspection_Interview_Kind>;
  /** fetch data from the table: "inspection_place_kind" */
  inspection_place_kind: Array<Inspection_Place_Kind>;
  /** fetch aggregated fields from the table: "inspection_place_kind" */
  inspection_place_kind_aggregate: Inspection_Place_Kind_Aggregate;
  /** fetch data from the table: "inspection_place_kind" using primary key columns */
  inspection_place_kind_by_pk?: Maybe<Inspection_Place_Kind>;
  /** fetch data from the table in a streaming manner: "inspection_place_kind" */
  inspection_place_kind_stream: Array<Inspection_Place_Kind>;
  /** fetch data from the table: "inspection_reluctant_kind" */
  inspection_reluctant_kind: Array<Inspection_Reluctant_Kind>;
  /** fetch aggregated fields from the table: "inspection_reluctant_kind" */
  inspection_reluctant_kind_aggregate: Inspection_Reluctant_Kind_Aggregate;
  /** fetch data from the table: "inspection_reluctant_kind" using primary key columns */
  inspection_reluctant_kind_by_pk?: Maybe<Inspection_Reluctant_Kind>;
  /** fetch data from the table in a streaming manner: "inspection_reluctant_kind" */
  inspection_reluctant_kind_stream: Array<Inspection_Reluctant_Kind>;
  /** fetch data from the table: "inspection_state" */
  inspection_state: Array<Inspection_State>;
  /** fetch aggregated fields from the table: "inspection_state" */
  inspection_state_aggregate: Inspection_State_Aggregate;
  /** fetch data from the table: "inspection_state" using primary key columns */
  inspection_state_by_pk?: Maybe<Inspection_State>;
  /** fetch data from the table in a streaming manner: "inspection_state" */
  inspection_state_stream: Array<Inspection_State>;
  /** fetch data from the table in a streaming manner: "inspection" */
  inspection_stream: Array<Inspection>;
  /** fetch data from the table: "microred" */
  microred: Array<Microred>;
  /** fetch aggregated fields from the table: "microred" */
  microred_aggregate: Microred_Aggregate;
  /** fetch data from the table: "microred" using primary key columns */
  microred_by_pk?: Maybe<Microred>;
  /** fetch data from the table in a streaming manner: "microred" */
  microred_stream: Array<Microred>;
  /** fetch data from the table: "observation" */
  observation: Array<Observation>;
  /** fetch aggregated fields from the table: "observation" */
  observation_aggregate: Observation_Aggregate;
  /** fetch data from the table: "observation" using primary key columns */
  observation_by_pk?: Maybe<Observation>;
  /** fetch data from the table in a streaming manner: "observation" */
  observation_stream: Array<Observation>;
  /** fetch data from the table: "place" */
  place: Array<Place>;
  /** fetch aggregated fields from the table: "place" */
  place_aggregate: Place_Aggregate;
  /** fetch data from the table: "place" using primary key columns */
  place_by_pk?: Maybe<Place>;
  /** fetch data from the table in a streaming manner: "place" */
  place_stream: Array<Place>;
  /** An array relationship */
  role_children: Array<Role_Children>;
  /** An aggregate relationship */
  role_children_aggregate: Role_Children_Aggregate;
  /** fetch data from the table: "role_children" using primary key columns */
  role_children_by_pk?: Maybe<Role_Children>;
  /** fetch data from the table in a streaming manner: "role_children" */
  role_children_stream: Array<Role_Children>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table in a streaming manner: "spatial_ref_sys" */
  spatial_ref_sys_stream: Array<Spatial_Ref_Sys>;
  /** An array relationship */
  sprayed: Array<Sprayed>;
  /** An aggregate relationship */
  sprayed_aggregate: Sprayed_Aggregate;
  /** fetch data from the table: "sprayed" using primary key columns */
  sprayed_by_pk?: Maybe<Sprayed>;
  /** fetch data from the table: "sprayed_residual_treatment_kind" */
  sprayed_residual_treatment_kind: Array<Sprayed_Residual_Treatment_Kind>;
  /** fetch aggregated fields from the table: "sprayed_residual_treatment_kind" */
  sprayed_residual_treatment_kind_aggregate: Sprayed_Residual_Treatment_Kind_Aggregate;
  /** fetch data from the table: "sprayed_residual_treatment_kind" using primary key columns */
  sprayed_residual_treatment_kind_by_pk?: Maybe<Sprayed_Residual_Treatment_Kind>;
  /** fetch data from the table in a streaming manner: "sprayed_residual_treatment_kind" */
  sprayed_residual_treatment_kind_stream: Array<Sprayed_Residual_Treatment_Kind>;
  /** fetch data from the table in a streaming manner: "sprayed" */
  sprayed_stream: Array<Sprayed>;
  /** fetch data from the table: "sprayer" */
  sprayer: Array<Sprayer>;
  /** fetch aggregated fields from the table: "sprayer" */
  sprayer_aggregate: Sprayer_Aggregate;
  /** fetch data from the table: "sprayer" using primary key columns */
  sprayer_by_pk?: Maybe<Sprayer>;
  /** fetch data from the table in a streaming manner: "sprayer" */
  sprayer_stream: Array<Sprayer>;
  /** An array relationship */
  user_roles: Array<User_Roles>;
  /** An aggregate relationship */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table in a streaming manner: "user_roles" */
  user_roles_stream: Array<User_Roles>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "work_sessions" */
  work_sessions: Array<Work_Sessions>;
  /** fetch aggregated fields from the table: "work_sessions" */
  work_sessions_aggregate: Work_Sessions_Aggregate;
  /** fetch data from the table: "work_sessions" using primary key columns */
  work_sessions_by_pk?: Maybe<Work_Sessions>;
  /** fetch data from the table in a streaming manner: "work_sessions" */
  work_sessions_stream: Array<Work_Sessions>;
  /** An array relationship */
  workspace: Array<Workspace>;
  /** An aggregate relationship */
  workspace_aggregate: Workspace_Aggregate;
  /** fetch data from the table: "workspace" using primary key columns */
  workspace_by_pk?: Maybe<Workspace>;
  /** fetch data from the table: "workspace_metadata" */
  workspace_metadata: Array<Workspace_Metadata>;
  /** fetch aggregated fields from the table: "workspace_metadata" */
  workspace_metadata_aggregate: Workspace_Metadata_Aggregate;
  /** fetch data from the table: "workspace_metadata" using primary key columns */
  workspace_metadata_by_pk?: Maybe<Workspace_Metadata>;
  /** fetch data from the table in a streaming manner: "workspace_metadata" */
  workspace_metadata_stream: Array<Workspace_Metadata>;
  /** fetch data from the table: "workspace_owners" */
  workspace_owners: Array<Workspace_Owners>;
  /** fetch aggregated fields from the table: "workspace_owners" */
  workspace_owners_aggregate: Workspace_Owners_Aggregate;
  /** fetch data from the table: "workspace_owners" using primary key columns */
  workspace_owners_by_pk?: Maybe<Workspace_Owners>;
  /** fetch data from the table in a streaming manner: "workspace_owners" */
  workspace_owners_stream: Array<Workspace_Owners>;
  /** fetch data from the table in a streaming manner: "workspace" */
  workspace_stream: Array<Workspace>;
};

export type Subscription_RootCapture_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Capture_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Capture_Place_Order_By>>;
  where?: InputMaybe<Capture_Place_Bool_Exp>;
};

export type Subscription_RootCapture_Place_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Capture_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Capture_Place_Order_By>>;
  where?: InputMaybe<Capture_Place_Bool_Exp>;
};

export type Subscription_RootCapture_Place_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCapture_Place_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Capture_Place_Stream_Cursor_Input>>;
  where?: InputMaybe<Capture_Place_Bool_Exp>;
};

export type Subscription_RootNumber_Triatomines_CapturedArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Captured_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Captured_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Captured_Bool_Exp>;
};

export type Subscription_RootNumber_Triatomines_Captured_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Captured_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Captured_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Captured_Bool_Exp>;
};

export type Subscription_RootNumber_Triatomines_Captured_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootNumber_Triatomines_Captured_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Number_Triatomines_Captured_Stream_Cursor_Input>>;
  where?: InputMaybe<Number_Triatomines_Captured_Bool_Exp>;
};

export type Subscription_RootNumber_Triatomines_InspectedArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Inspected_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Inspected_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Inspected_Bool_Exp>;
};

export type Subscription_RootNumber_Triatomines_Inspected_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Inspected_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Inspected_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Inspected_Bool_Exp>;
};

export type Subscription_RootNumber_Triatomines_Inspected_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootNumber_Triatomines_Inspected_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Number_Triatomines_Inspected_Stream_Cursor_Input>>;
  where?: InputMaybe<Number_Triatomines_Inspected_Bool_Exp>;
};

export type Subscription_RootNumber_Triatomines_PositiveArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Positive_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Positive_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Positive_Bool_Exp>;
};

export type Subscription_RootNumber_Triatomines_Positive_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Number_Triatomines_Positive_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Number_Triatomines_Positive_Order_By>>;
  where?: InputMaybe<Number_Triatomines_Positive_Bool_Exp>;
};

export type Subscription_RootNumber_Triatomines_Positive_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootNumber_Triatomines_Positive_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Number_Triatomines_Positive_Stream_Cursor_Input>>;
  where?: InputMaybe<Number_Triatomines_Positive_Bool_Exp>;
};

export type Subscription_RootPlace_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Place_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Place_Order_By>>;
  where?: InputMaybe<Place_Place_Bool_Exp>;
};

export type Subscription_RootPlace_Place_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Place_Order_By>>;
  where?: InputMaybe<Place_Place_Bool_Exp>;
};

export type Subscription_RootPlace_Place_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootPlace_Place_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Place_Place_Stream_Cursor_Input>>;
  where?: InputMaybe<Place_Place_Bool_Exp>;
};

export type Subscription_RootPlace_Triatomines_Experiment_RecordArgs = {
  distinct_on?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Order_By>>;
  where?: InputMaybe<Place_Triatomines_Experiment_Record_Bool_Exp>;
};

export type Subscription_RootPlace_Triatomines_Experiment_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Triatomines_Experiment_Record_Order_By>>;
  where?: InputMaybe<Place_Triatomines_Experiment_Record_Bool_Exp>;
};

export type Subscription_RootPlace_Triatomines_Experiment_Record_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootPlace_Triatomines_Experiment_Record_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Place_Triatomines_Experiment_Record_Stream_Cursor_Input>>;
  where?: InputMaybe<Place_Triatomines_Experiment_Record_Bool_Exp>;
};

export type Subscription_RootPlace_ChildrenArgs = {
  distinct_on?: InputMaybe<Array<Place_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Children_Order_By>>;
  where?: InputMaybe<Place_Children_Bool_Exp>;
};

export type Subscription_RootPlace_Children_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Children_Order_By>>;
  where?: InputMaybe<Place_Children_Bool_Exp>;
};

export type Subscription_RootPlace_Children_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootPlace_Children_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Place_Children_Stream_Cursor_Input>>;
  where?: InputMaybe<Place_Children_Bool_Exp>;
};

export type Subscription_RootPlace_FilesArgs = {
  distinct_on?: InputMaybe<Array<Place_Files_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Files_Order_By>>;
  where?: InputMaybe<Place_Files_Bool_Exp>;
};

export type Subscription_RootPlace_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Files_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Files_Order_By>>;
  where?: InputMaybe<Place_Files_Bool_Exp>;
};

export type Subscription_RootPlace_Files_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootPlace_Files_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Place_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Place_Files_Bool_Exp>;
};

export type Subscription_RootPlace_KindArgs = {
  distinct_on?: InputMaybe<Array<Place_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Kind_Order_By>>;
  where?: InputMaybe<Place_Kind_Bool_Exp>;
};

export type Subscription_RootPlace_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Kind_Order_By>>;
  where?: InputMaybe<Place_Kind_Bool_Exp>;
};

export type Subscription_RootPlace_Kind_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootPlace_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Place_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Place_Kind_Bool_Exp>;
};

export type Subscription_RootPlace_RecordArgs = {
  distinct_on?: InputMaybe<Array<Place_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Record_Order_By>>;
  where?: InputMaybe<Place_Record_Bool_Exp>;
};

export type Subscription_RootPlace_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Record_Order_By>>;
  where?: InputMaybe<Place_Record_Bool_Exp>;
};

export type Subscription_RootPlace_Record_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootPlace_Record_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Place_Record_Stream_Cursor_Input>>;
  where?: InputMaybe<Place_Record_Bool_Exp>;
};

export type Subscription_RootTriatominesArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Order_By>>;
  where?: InputMaybe<Triatomines_Bool_Exp>;
};

export type Subscription_RootTriatomines_Experiment_RecordArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Bool_Exp>;
};

export type Subscription_RootTriatomines_Experiment_Record_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Place_Bool_Exp>;
};

export type Subscription_RootTriatomines_Experiment_Record_Place_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Place_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Place_Bool_Exp>;
};

export type Subscription_RootTriatomines_Experiment_Record_Place_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootTriatomines_Experiment_Record_Place_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Triatomines_Experiment_Record_Place_Stream_Cursor_Input>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Place_Bool_Exp>;
};

export type Subscription_RootTriatomines_Experiment_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Bool_Exp>;
};

export type Subscription_RootTriatomines_Experiment_Record_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTriatomines_Experiment_Record_Directus_UsersArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Bool_Exp>;
};

export type Subscription_RootTriatomines_Experiment_Record_Directus_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Experiment_Record_Directus_Users_Order_By>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Bool_Exp>;
};

export type Subscription_RootTriatomines_Experiment_Record_Directus_Users_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootTriatomines_Experiment_Record_Directus_Users_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Triatomines_Experiment_Record_Directus_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Directus_Users_Bool_Exp>;
};

export type Subscription_RootTriatomines_Experiment_Record_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Triatomines_Experiment_Record_Stream_Cursor_Input>>;
  where?: InputMaybe<Triatomines_Experiment_Record_Bool_Exp>;
};

export type Subscription_RootTriatomines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Triatomines_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Triatomines_Order_By>>;
  where?: InputMaybe<Triatomines_Bool_Exp>;
};

export type Subscription_RootTriatomines_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTriatomines_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Triatomines_Stream_Cursor_Input>>;
  where?: InputMaybe<Triatomines_Bool_Exp>;
};

export type Subscription_RootAdvertisementArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

export type Subscription_RootAdvertisement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

export type Subscription_RootAdvertisement_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootAdvertisement_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Advertisement_Stream_Cursor_Input>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

export type Subscription_RootCollaboratorArgs = {
  distinct_on?: InputMaybe<Array<Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Collaborator_Order_By>>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

export type Subscription_RootCollaborator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Collaborator_Order_By>>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

export type Subscription_RootCollaborator_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCollaborator_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Collaborator_Stream_Cursor_Input>>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

export type Subscription_RootComplaintArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

export type Subscription_RootComplaint_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

export type Subscription_RootComplaint_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootComplaint_KindArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Kind_Order_By>>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

export type Subscription_RootComplaint_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Kind_Order_By>>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

export type Subscription_RootComplaint_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootComplaint_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Complaint_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

export type Subscription_RootComplaint_SourceArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Source_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Source_Order_By>>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

export type Subscription_RootComplaint_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Source_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Source_Order_By>>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

export type Subscription_RootComplaint_Source_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootComplaint_Source_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Complaint_Source_Stream_Cursor_Input>>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

export type Subscription_RootComplaint_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Complaint_Stream_Cursor_Input>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

export type Subscription_RootCompute_GroupArgs = {
  args: Compute_Group_Args;
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Subscription_RootCompute_Group_AggregateArgs = {
  args: Compute_Group_Args;
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Subscription_RootDistancesArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

export type Subscription_RootDistances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

export type Subscription_RootDistances_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootDistances_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Distances_Stream_Cursor_Input>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

export type Subscription_RootEstate_KindArgs = {
  distinct_on?: InputMaybe<Array<Estate_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Estate_Kind_Order_By>>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

export type Subscription_RootEstate_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Estate_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Estate_Kind_Order_By>>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

export type Subscription_RootEstate_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootEstate_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Estate_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

export type Subscription_RootExperimentArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Subscription_RootExperiment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Subscription_RootExperiment_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootExperiment_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Experiment_Stream_Cursor_Input>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Subscription_RootGeography_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};

export type Subscription_RootGeography_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};

export type Subscription_RootGeography_Columns_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Geography_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};

export type Subscription_RootGeogroupArgs = {
  distinct_on?: InputMaybe<Array<Geogroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geogroup_Order_By>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

export type Subscription_RootGeogroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geogroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geogroup_Order_By>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

export type Subscription_RootGeogroup_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootGeogroup_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Geogroup_Stream_Cursor_Input>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

export type Subscription_RootGeometry_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};

export type Subscription_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};

export type Subscription_RootGeometry_Columns_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Geometry_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};

export type Subscription_RootGeopointArgs = {
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Subscription_RootGeopoint_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Subscription_RootGeopoint_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootGeopoint_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Geopoint_Stream_Cursor_Input>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Subscription_RootInspectionArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

export type Subscription_RootInspection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

export type Subscription_RootInspection_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootInspection_Event_KindArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Event_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Event_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Event_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Event_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Event_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Event_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Event_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Event_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootInspection_Event_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Inspection_Event_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Inspection_Event_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Interview_KindArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Interview_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Interview_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Interview_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Interview_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Interview_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Interview_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Interview_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Interview_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootInspection_Interview_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Inspection_Interview_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Inspection_Interview_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Place_KindArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Place_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Place_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Place_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Place_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Place_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Place_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Place_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Place_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootInspection_Place_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Inspection_Place_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Inspection_Place_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Reluctant_KindArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Reluctant_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Reluctant_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Reluctant_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Reluctant_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Reluctant_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Reluctant_Kind_Order_By>>;
  where?: InputMaybe<Inspection_Reluctant_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_Reluctant_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootInspection_Reluctant_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Inspection_Reluctant_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Inspection_Reluctant_Kind_Bool_Exp>;
};

export type Subscription_RootInspection_StateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_State_Order_By>>;
  where?: InputMaybe<Inspection_State_Bool_Exp>;
};

export type Subscription_RootInspection_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_State_Order_By>>;
  where?: InputMaybe<Inspection_State_Bool_Exp>;
};

export type Subscription_RootInspection_State_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootInspection_State_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Inspection_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Inspection_State_Bool_Exp>;
};

export type Subscription_RootInspection_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Inspection_Stream_Cursor_Input>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

export type Subscription_RootMicroredArgs = {
  distinct_on?: InputMaybe<Array<Microred_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Microred_Order_By>>;
  where?: InputMaybe<Microred_Bool_Exp>;
};

export type Subscription_RootMicrored_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Microred_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Microred_Order_By>>;
  where?: InputMaybe<Microred_Bool_Exp>;
};

export type Subscription_RootMicrored_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootMicrored_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Microred_Stream_Cursor_Input>>;
  where?: InputMaybe<Microred_Bool_Exp>;
};

export type Subscription_RootObservationArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

export type Subscription_RootObservation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

export type Subscription_RootObservation_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootObservation_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Observation_Stream_Cursor_Input>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

export type Subscription_RootPlaceArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

export type Subscription_RootPlace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

export type Subscription_RootPlace_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootPlace_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Place_Stream_Cursor_Input>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

export type Subscription_RootRole_ChildrenArgs = {
  distinct_on?: InputMaybe<Array<Role_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Children_Order_By>>;
  where?: InputMaybe<Role_Children_Bool_Exp>;
};

export type Subscription_RootRole_Children_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Children_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Children_Order_By>>;
  where?: InputMaybe<Role_Children_Bool_Exp>;
};

export type Subscription_RootRole_Children_By_PkArgs = {
  parent_id: Scalars["uuid"];
  role_id: Scalars["uuid"];
};

export type Subscription_RootRole_Children_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Role_Children_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Children_Bool_Exp>;
};

export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootSpatial_Ref_SysArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

export type Subscription_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

export type Subscription_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars["Int"];
};

export type Subscription_RootSpatial_Ref_Sys_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Spatial_Ref_Sys_Stream_Cursor_Input>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

export type Subscription_RootSprayedArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

export type Subscription_RootSprayed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

export type Subscription_RootSprayed_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSprayed_Residual_Treatment_KindArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Order_By>>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

export type Subscription_RootSprayed_Residual_Treatment_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Order_By>>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

export type Subscription_RootSprayed_Residual_Treatment_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootSprayed_Residual_Treatment_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Sprayed_Residual_Treatment_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

export type Subscription_RootSprayed_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Sprayed_Stream_Cursor_Input>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

export type Subscription_RootSprayerArgs = {
  distinct_on?: InputMaybe<Array<Sprayer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayer_Order_By>>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

export type Subscription_RootSprayer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayer_Order_By>>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

export type Subscription_RootSprayer_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSprayer_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Sprayer_Stream_Cursor_Input>>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

export type Subscription_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Roles_By_PkArgs = {
  role_id: Scalars["uuid"];
  user_id: Scalars["uuid"];
};

export type Subscription_RootUser_Roles_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<User_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootWork_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

export type Subscription_RootWork_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

export type Subscription_RootWork_Sessions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootWork_Sessions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Work_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

export type Subscription_RootWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

export type Subscription_RootWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

export type Subscription_RootWorkspace_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootWorkspace_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

export type Subscription_RootWorkspace_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

export type Subscription_RootWorkspace_Metadata_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootWorkspace_Metadata_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Workspace_Metadata_Stream_Cursor_Input>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

export type Subscription_RootWorkspace_OwnersArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Owners_Order_By>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

export type Subscription_RootWorkspace_Owners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Owners_Order_By>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

export type Subscription_RootWorkspace_Owners_By_PkArgs = {
  owner_id: Scalars["uuid"];
  workspace_id: Scalars["uuid"];
};

export type Subscription_RootWorkspace_Owners_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Workspace_Owners_Stream_Cursor_Input>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

export type Subscription_RootWorkspace_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Workspace_Stream_Cursor_Input>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["time"]>;
  _gt?: InputMaybe<Scalars["time"]>;
  _gte?: InputMaybe<Scalars["time"]>;
  _in?: InputMaybe<Array<Scalars["time"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["time"]>;
  _lte?: InputMaybe<Scalars["time"]>;
  _neq?: InputMaybe<Scalars["time"]>;
  _nin?: InputMaybe<Array<Scalars["time"]>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamp"]>;
  _gt?: InputMaybe<Scalars["timestamp"]>;
  _gte?: InputMaybe<Scalars["timestamp"]>;
  _in?: InputMaybe<Array<Scalars["timestamp"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamp"]>;
  _lte?: InputMaybe<Scalars["timestamp"]>;
  _neq?: InputMaybe<Scalars["timestamp"]>;
  _nin?: InputMaybe<Array<Scalars["timestamp"]>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamptz"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]>>;
};

/** columns and relationships of "user_roles" */
export type User_Roles = {
  __typename?: "user_roles";
  /** An object relationship */
  role: Roles;
  role_id: Scalars["uuid"];
  /** An object relationship */
  user: Users;
  user_id: Scalars["uuid"];
};

/** aggregated selection of "user_roles" */
export type User_Roles_Aggregate = {
  __typename?: "user_roles_aggregate";
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
};

export type User_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Roles_Aggregate_Bool_Exp_Count>;
};

export type User_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<User_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_Fields = {
  __typename?: "user_roles_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "user_roles" */
export type User_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Roles_Max_Order_By>;
  min?: InputMaybe<User_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_roles" */
export type User_Roles_Arr_Rel_Insert_Input = {
  data: Array<User_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  _not?: InputMaybe<User_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum User_Roles_Constraint {
  /** unique or primary key constraint on columns "role_id", "user_id" */
  UserRolesPkey = "user_roles_pkey",
}

/** input type for inserting data into table "user_roles" */
export type User_Roles_Insert_Input = {
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type User_Roles_Max_Fields = {
  __typename?: "user_roles_max_fields";
  role_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "user_roles" */
export type User_Roles_Max_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Roles_Min_Fields = {
  __typename?: "user_roles_min_fields";
  role_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "user_roles" */
export type User_Roles_Min_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_roles" */
export type User_Roles_Mutation_Response = {
  __typename?: "user_roles_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Roles>;
};

/** on_conflict condition type for table "user_roles" */
export type User_Roles_On_Conflict = {
  constraint: User_Roles_Constraint;
  update_columns?: Array<User_Roles_Update_Column>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_roles */
export type User_Roles_Pk_Columns_Input = {
  role_id: Scalars["uuid"];
  user_id: Scalars["uuid"];
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  RoleId = "role_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "user_roles" */
export type User_Roles_Set_Input = {
  role_id?: InputMaybe<Scalars["uuid"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "user_roles" */
export type User_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Roles_Stream_Cursor_Value_Input = {
  role_id?: InputMaybe<Scalars["uuid"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "user_roles" */
export enum User_Roles_Update_Column {
  /** column name */
  RoleId = "role_id",
  /** column name */
  UserId = "user_id",
}

export type User_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Roles_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: "users";
  created_at: Scalars["timestamptz"];
  email: Scalars["String"];
  hashed_password: Scalars["String"];
  id: Scalars["uuid"];
  recover_password_token?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  user_roles: Array<User_Roles>;
  /** An aggregate relationship */
  user_roles_aggregate: User_Roles_Aggregate;
};

/** columns and relationships of "users" */
export type UsersUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: "users_aggregate";
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: "users_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  hashed_password?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  recover_password_token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_roles?: InputMaybe<User_Roles_Bool_Exp>;
  user_roles_aggregate?: InputMaybe<User_Roles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = "users_email_key",
  /** unique or primary key constraint on columns "id" */
  UsersPkey = "users_pkey",
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  hashed_password?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  recover_password_token?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user_roles?: InputMaybe<User_Roles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: "users_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  hashed_password?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  recover_password_token?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: "users_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  hashed_password?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  recover_password_token?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: "users_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  hashed_password?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recover_password_token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_roles_aggregate?: InputMaybe<User_Roles_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  HashedPassword = "hashed_password",
  /** column name */
  Id = "id",
  /** column name */
  RecoverPasswordToken = "recover_password_token",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  hashed_password?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  recover_password_token?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  hashed_password?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  recover_password_token?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  HashedPassword = "hashed_password",
  /** column name */
  Id = "id",
  /** column name */
  RecoverPasswordToken = "recover_password_token",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]>;
  _gt?: InputMaybe<Scalars["uuid"]>;
  _gte?: InputMaybe<Scalars["uuid"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["uuid"]>;
  _lte?: InputMaybe<Scalars["uuid"]>;
  _neq?: InputMaybe<Scalars["uuid"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]>>;
};

/** columns and relationships of "work_sessions" */
export type Work_Sessions = {
  __typename?: "work_sessions";
  branch?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  comments?: Maybe<Scalars["String"]>;
  created_at: Scalars["timestamptz"];
  geogroup_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  issued_at: Scalars["date"];
  kind: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  user?: Maybe<Scalars["String"]>;
  work_percent: Scalars["float8"];
};

/** aggregated selection of "work_sessions" */
export type Work_Sessions_Aggregate = {
  __typename?: "work_sessions_aggregate";
  aggregate?: Maybe<Work_Sessions_Aggregate_Fields>;
  nodes: Array<Work_Sessions>;
};

/** aggregate fields of "work_sessions" */
export type Work_Sessions_Aggregate_Fields = {
  __typename?: "work_sessions_aggregate_fields";
  avg?: Maybe<Work_Sessions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Work_Sessions_Max_Fields>;
  min?: Maybe<Work_Sessions_Min_Fields>;
  stddev?: Maybe<Work_Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Sessions_Sum_Fields>;
  var_pop?: Maybe<Work_Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Sessions_Var_Samp_Fields>;
  variance?: Maybe<Work_Sessions_Variance_Fields>;
};

/** aggregate fields of "work_sessions" */
export type Work_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Work_Sessions_Avg_Fields = {
  __typename?: "work_sessions_avg_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "work_sessions". All fields are combined with a logical 'AND'. */
export type Work_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Work_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Work_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Work_Sessions_Bool_Exp>>;
  branch?: InputMaybe<String_Comparison_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  issued_at?: InputMaybe<Date_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
  work_percent?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "work_sessions" */
export enum Work_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkSessionsPkey = "work_sessions_pkey",
}

/** input type for incrementing numeric columns in table "work_sessions" */
export type Work_Sessions_Inc_Input = {
  work_percent?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "work_sessions" */
export type Work_Sessions_Insert_Input = {
  branch?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  comments?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  issued_at?: InputMaybe<Scalars["date"]>;
  kind?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user?: InputMaybe<Scalars["String"]>;
  work_percent?: InputMaybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Work_Sessions_Max_Fields = {
  __typename?: "work_sessions_max_fields";
  branch?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  comments?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  issued_at?: Maybe<Scalars["date"]>;
  kind?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Scalars["String"]>;
  work_percent?: Maybe<Scalars["float8"]>;
};

/** aggregate min on columns */
export type Work_Sessions_Min_Fields = {
  __typename?: "work_sessions_min_fields";
  branch?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  comments?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  issued_at?: Maybe<Scalars["date"]>;
  kind?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Scalars["String"]>;
  work_percent?: Maybe<Scalars["float8"]>;
};

/** response of any mutation on the table "work_sessions" */
export type Work_Sessions_Mutation_Response = {
  __typename?: "work_sessions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Sessions>;
};

/** on_conflict condition type for table "work_sessions" */
export type Work_Sessions_On_Conflict = {
  constraint: Work_Sessions_Constraint;
  update_columns?: Array<Work_Sessions_Update_Column>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "work_sessions". */
export type Work_Sessions_Order_By = {
  branch?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issued_at?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  work_percent?: InputMaybe<Order_By>;
};

/** primary key columns input for table: work_sessions */
export type Work_Sessions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "work_sessions" */
export enum Work_Sessions_Select_Column {
  /** column name */
  Branch = "branch",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  Comments = "comments",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  Id = "id",
  /** column name */
  IssuedAt = "issued_at",
  /** column name */
  Kind = "kind",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  User = "user",
  /** column name */
  WorkPercent = "work_percent",
}

/** input type for updating data in table "work_sessions" */
export type Work_Sessions_Set_Input = {
  branch?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  comments?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  issued_at?: InputMaybe<Scalars["date"]>;
  kind?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user?: InputMaybe<Scalars["String"]>;
  work_percent?: InputMaybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Work_Sessions_Stddev_Fields = {
  __typename?: "work_sessions_stddev_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Work_Sessions_Stddev_Pop_Fields = {
  __typename?: "work_sessions_stddev_pop_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Work_Sessions_Stddev_Samp_Fields = {
  __typename?: "work_sessions_stddev_samp_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "work_sessions" */
export type Work_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Work_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Work_Sessions_Stream_Cursor_Value_Input = {
  branch?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  comments?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  issued_at?: InputMaybe<Scalars["date"]>;
  kind?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user?: InputMaybe<Scalars["String"]>;
  work_percent?: InputMaybe<Scalars["float8"]>;
};

/** aggregate sum on columns */
export type Work_Sessions_Sum_Fields = {
  __typename?: "work_sessions_sum_fields";
  work_percent?: Maybe<Scalars["float8"]>;
};

/** update columns of table "work_sessions" */
export enum Work_Sessions_Update_Column {
  /** column name */
  Branch = "branch",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  Comments = "comments",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  Id = "id",
  /** column name */
  IssuedAt = "issued_at",
  /** column name */
  Kind = "kind",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  User = "user",
  /** column name */
  WorkPercent = "work_percent",
}

export type Work_Sessions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Work_Sessions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Work_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Sessions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Work_Sessions_Var_Pop_Fields = {
  __typename?: "work_sessions_var_pop_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Work_Sessions_Var_Samp_Fields = {
  __typename?: "work_sessions_var_samp_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Work_Sessions_Variance_Fields = {
  __typename?: "work_sessions_variance_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "workspace" */
export type Workspace = {
  __typename?: "workspace";
  created_at: Scalars["timestamptz"];
  /** An array relationship */
  experiments: Array<Experiment>;
  /** An aggregate relationship */
  experiments_aggregate: Experiment_Aggregate;
  /** An object relationship */
  group?: Maybe<Geogroup>;
  group_id: Scalars["uuid"];
  id: Scalars["uuid"];
  kind?: Maybe<Scalars["String"]>;
  /** An array relationship */
  metadata: Array<Workspace_Metadata>;
  /** An aggregate relationship */
  metadata_aggregate: Workspace_Metadata_Aggregate;
  /** An object relationship */
  owner?: Maybe<Collaborator>;
  owner_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  owners: Array<Workspace_Owners>;
  /** An aggregate relationship */
  owners_aggregate: Workspace_Owners_Aggregate;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "workspace" */
export type WorkspaceExperimentsArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceExperiments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceMetadataArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceMetadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceOwnersArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Owners_Order_By>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Owners_Order_By>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

/** aggregated selection of "workspace" */
export type Workspace_Aggregate = {
  __typename?: "workspace_aggregate";
  aggregate?: Maybe<Workspace_Aggregate_Fields>;
  nodes: Array<Workspace>;
};

export type Workspace_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workspace_Aggregate_Bool_Exp_Count>;
};

export type Workspace_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workspace_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Workspace_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workspace" */
export type Workspace_Aggregate_Fields = {
  __typename?: "workspace_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Workspace_Max_Fields>;
  min?: Maybe<Workspace_Min_Fields>;
};

/** aggregate fields of "workspace" */
export type Workspace_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workspace_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "workspace" */
export type Workspace_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workspace_Max_Order_By>;
  min?: InputMaybe<Workspace_Min_Order_By>;
};

/** input type for inserting array relation for remote table "workspace" */
export type Workspace_Arr_Rel_Insert_Input = {
  data: Array<Workspace_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};

/** Boolean expression to filter rows from the table "workspace". All fields are combined with a logical 'AND'. */
export type Workspace_Bool_Exp = {
  _and?: InputMaybe<Array<Workspace_Bool_Exp>>;
  _not?: InputMaybe<Workspace_Bool_Exp>;
  _or?: InputMaybe<Array<Workspace_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  experiments?: InputMaybe<Experiment_Bool_Exp>;
  experiments_aggregate?: InputMaybe<Experiment_Aggregate_Bool_Exp>;
  group?: InputMaybe<Geogroup_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  metadata_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Bool_Exp>;
  owner?: InputMaybe<Collaborator_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  owners?: InputMaybe<Workspace_Owners_Bool_Exp>;
  owners_aggregate?: InputMaybe<Workspace_Owners_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "workspace" */
export enum Workspace_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkspacePkey = "workspace_pkey",
}

/** input type for inserting data into table "workspace" */
export type Workspace_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiments?: InputMaybe<Experiment_Arr_Rel_Insert_Input>;
  group?: InputMaybe<Geogroup_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Workspace_Metadata_Arr_Rel_Insert_Input>;
  owner?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars["uuid"]>;
  owners?: InputMaybe<Workspace_Owners_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Workspace_Max_Fields = {
  __typename?: "workspace_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  owner_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "workspace" */
export type Workspace_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_Metadata = {
  __typename?: "workspace_metadata";
  app_state: Scalars["String"];
  /** An array relationship */
  children: Array<Workspace_Metadata>;
  /** An aggregate relationship */
  children_aggregate: Workspace_Metadata_Aggregate;
  created_at?: Maybe<Scalars["timestamptz"]>;
  data: Scalars["jsonb"];
  /** An object relationship */
  experiment?: Maybe<Experiment>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  /** An object relationship */
  geogroup?: Maybe<Geogroup>;
  geogroup_id: Scalars["uuid"];
  /** An object relationship */
  geopoint?: Maybe<Geopoint>;
  geopoint_id: Scalars["uuid"];
  id: Scalars["uuid"];
  kind: Scalars["String"];
  /** An object relationship */
  parent?: Maybe<Workspace_Metadata>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataChildrenArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataChildren_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataDataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "workspace_metadata" */
export type Workspace_Metadata_Aggregate = {
  __typename?: "workspace_metadata_aggregate";
  aggregate?: Maybe<Workspace_Metadata_Aggregate_Fields>;
  nodes: Array<Workspace_Metadata>;
};

export type Workspace_Metadata_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workspace_Metadata_Aggregate_Bool_Exp_Count>;
};

export type Workspace_Metadata_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workspace_metadata" */
export type Workspace_Metadata_Aggregate_Fields = {
  __typename?: "workspace_metadata_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Workspace_Metadata_Max_Fields>;
  min?: Maybe<Workspace_Metadata_Min_Fields>;
};

/** aggregate fields of "workspace_metadata" */
export type Workspace_Metadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "workspace_metadata" */
export type Workspace_Metadata_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workspace_Metadata_Max_Order_By>;
  min?: InputMaybe<Workspace_Metadata_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Workspace_Metadata_Append_Input = {
  data?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "workspace_metadata" */
export type Workspace_Metadata_Arr_Rel_Insert_Input = {
  data: Array<Workspace_Metadata_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_Metadata_On_Conflict>;
};

/** Boolean expression to filter rows from the table "workspace_metadata". All fields are combined with a logical 'AND'. */
export type Workspace_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Workspace_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Workspace_Metadata_Bool_Exp>>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  children?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  children_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  experiment?: InputMaybe<Experiment_Bool_Exp>;
  experiment_id?: InputMaybe<Uuid_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  geogroup?: InputMaybe<Geogroup_Bool_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint?: InputMaybe<Geopoint_Bool_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  parent?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "workspace_metadata" */
export enum Workspace_Metadata_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkspaceMetadataPkey = "workspace_metadata_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Workspace_Metadata_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Workspace_Metadata_Delete_Elem_Input = {
  data?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Workspace_Metadata_Delete_Key_Input = {
  data?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "workspace_metadata" */
export type Workspace_Metadata_Insert_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  children?: InputMaybe<Workspace_Metadata_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data?: InputMaybe<Scalars["jsonb"]>;
  experiment?: InputMaybe<Experiment_Obj_Rel_Insert_Input>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup?: InputMaybe<Geogroup_Obj_Rel_Insert_Input>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent?: InputMaybe<Workspace_Metadata_Obj_Rel_Insert_Input>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Workspace_Metadata_Max_Fields = {
  __typename?: "workspace_metadata_max_fields";
  app_state?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "workspace_metadata" */
export type Workspace_Metadata_Max_Order_By = {
  app_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workspace_Metadata_Min_Fields = {
  __typename?: "workspace_metadata_min_fields";
  app_state?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "workspace_metadata" */
export type Workspace_Metadata_Min_Order_By = {
  app_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workspace_metadata" */
export type Workspace_Metadata_Mutation_Response = {
  __typename?: "workspace_metadata_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Workspace_Metadata>;
};

/** input type for inserting object relation for remote table "workspace_metadata" */
export type Workspace_Metadata_Obj_Rel_Insert_Input = {
  data: Workspace_Metadata_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_Metadata_On_Conflict>;
};

/** on_conflict condition type for table "workspace_metadata" */
export type Workspace_Metadata_On_Conflict = {
  constraint: Workspace_Metadata_Constraint;
  update_columns?: Array<Workspace_Metadata_Update_Column>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "workspace_metadata". */
export type Workspace_Metadata_Order_By = {
  app_state?: InputMaybe<Order_By>;
  children_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  experiment?: InputMaybe<Experiment_Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup?: InputMaybe<Geogroup_Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint?: InputMaybe<Geopoint_Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent?: InputMaybe<Workspace_Metadata_Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workspace_metadata */
export type Workspace_Metadata_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Workspace_Metadata_Prepend_Input = {
  data?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "workspace_metadata" */
export enum Workspace_Metadata_Select_Column {
  /** column name */
  AppState = "app_state",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Data = "data",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

/** input type for updating data in table "workspace_metadata" */
export type Workspace_Metadata_Set_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data?: InputMaybe<Scalars["jsonb"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "workspace_metadata" */
export type Workspace_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workspace_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workspace_Metadata_Stream_Cursor_Value_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data?: InputMaybe<Scalars["jsonb"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "workspace_metadata" */
export enum Workspace_Metadata_Update_Column {
  /** column name */
  AppState = "app_state",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Data = "data",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

export type Workspace_Metadata_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Workspace_Metadata_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Workspace_Metadata_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Workspace_Metadata_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Workspace_Metadata_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Workspace_Metadata_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workspace_Metadata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workspace_Metadata_Bool_Exp;
};

/** aggregate min on columns */
export type Workspace_Min_Fields = {
  __typename?: "workspace_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  owner_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "workspace" */
export type Workspace_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workspace" */
export type Workspace_Mutation_Response = {
  __typename?: "workspace_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Workspace>;
};

/** input type for inserting object relation for remote table "workspace" */
export type Workspace_Obj_Rel_Insert_Input = {
  data: Workspace_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};

/** on_conflict condition type for table "workspace" */
export type Workspace_On_Conflict = {
  constraint: Workspace_Constraint;
  update_columns?: Array<Workspace_Update_Column>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** Ordering options when selecting data from "workspace". */
export type Workspace_Order_By = {
  created_at?: InputMaybe<Order_By>;
  experiments_aggregate?: InputMaybe<Experiment_Aggregate_Order_By>;
  group?: InputMaybe<Geogroup_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  metadata_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Order_By>;
  owner?: InputMaybe<Collaborator_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  owners_aggregate?: InputMaybe<Workspace_Owners_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "workspace_owners" */
export type Workspace_Owners = {
  __typename?: "workspace_owners";
  /** An object relationship */
  owner?: Maybe<Collaborator>;
  owner_id: Scalars["uuid"];
  workspace_id: Scalars["uuid"];
};

/** aggregated selection of "workspace_owners" */
export type Workspace_Owners_Aggregate = {
  __typename?: "workspace_owners_aggregate";
  aggregate?: Maybe<Workspace_Owners_Aggregate_Fields>;
  nodes: Array<Workspace_Owners>;
};

export type Workspace_Owners_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workspace_Owners_Aggregate_Bool_Exp_Count>;
};

export type Workspace_Owners_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Workspace_Owners_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workspace_owners" */
export type Workspace_Owners_Aggregate_Fields = {
  __typename?: "workspace_owners_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Workspace_Owners_Max_Fields>;
  min?: Maybe<Workspace_Owners_Min_Fields>;
};

/** aggregate fields of "workspace_owners" */
export type Workspace_Owners_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "workspace_owners" */
export type Workspace_Owners_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workspace_Owners_Max_Order_By>;
  min?: InputMaybe<Workspace_Owners_Min_Order_By>;
};

/** input type for inserting array relation for remote table "workspace_owners" */
export type Workspace_Owners_Arr_Rel_Insert_Input = {
  data: Array<Workspace_Owners_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_Owners_On_Conflict>;
};

/** Boolean expression to filter rows from the table "workspace_owners". All fields are combined with a logical 'AND'. */
export type Workspace_Owners_Bool_Exp = {
  _and?: InputMaybe<Array<Workspace_Owners_Bool_Exp>>;
  _not?: InputMaybe<Workspace_Owners_Bool_Exp>;
  _or?: InputMaybe<Array<Workspace_Owners_Bool_Exp>>;
  owner?: InputMaybe<Collaborator_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "workspace_owners" */
export enum Workspace_Owners_Constraint {
  /** unique or primary key constraint on columns "owner_id", "workspace_id" */
  WorkspaceOwnersPkey = "workspace_owners_pkey",
}

/** input type for inserting data into table "workspace_owners" */
export type Workspace_Owners_Insert_Input = {
  owner?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars["uuid"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Workspace_Owners_Max_Fields = {
  __typename?: "workspace_owners_max_fields";
  owner_id?: Maybe<Scalars["uuid"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "workspace_owners" */
export type Workspace_Owners_Max_Order_By = {
  owner_id?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workspace_Owners_Min_Fields = {
  __typename?: "workspace_owners_min_fields";
  owner_id?: Maybe<Scalars["uuid"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "workspace_owners" */
export type Workspace_Owners_Min_Order_By = {
  owner_id?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workspace_owners" */
export type Workspace_Owners_Mutation_Response = {
  __typename?: "workspace_owners_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Workspace_Owners>;
};

/** on_conflict condition type for table "workspace_owners" */
export type Workspace_Owners_On_Conflict = {
  constraint: Workspace_Owners_Constraint;
  update_columns?: Array<Workspace_Owners_Update_Column>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

/** Ordering options when selecting data from "workspace_owners". */
export type Workspace_Owners_Order_By = {
  owner?: InputMaybe<Collaborator_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workspace_owners */
export type Workspace_Owners_Pk_Columns_Input = {
  owner_id: Scalars["uuid"];
  workspace_id: Scalars["uuid"];
};

/** select columns of table "workspace_owners" */
export enum Workspace_Owners_Select_Column {
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  WorkspaceId = "workspace_id",
}

/** input type for updating data in table "workspace_owners" */
export type Workspace_Owners_Set_Input = {
  owner_id?: InputMaybe<Scalars["uuid"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "workspace_owners" */
export type Workspace_Owners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workspace_Owners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workspace_Owners_Stream_Cursor_Value_Input = {
  owner_id?: InputMaybe<Scalars["uuid"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "workspace_owners" */
export enum Workspace_Owners_Update_Column {
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  WorkspaceId = "workspace_id",
}

export type Workspace_Owners_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workspace_Owners_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workspace_Owners_Bool_Exp;
};

/** primary key columns input for table: workspace */
export type Workspace_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "workspace" */
export enum Workspace_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  GroupId = "group_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "workspace" */
export type Workspace_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  group_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  owner_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "workspace" */
export type Workspace_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workspace_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workspace_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  group_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  owner_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "workspace" */
export enum Workspace_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  GroupId = "group_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Workspace_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workspace_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workspace_Bool_Exp;
};

export type ExperimentsByGeogroupQueryVariables = Exact<{
  geogroups: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type ExperimentsByGeogroupQuery = {
  __typename?: "query_root";
  experiment: Array<{ __typename?: "experiment"; id: any; created_at: any; kind?: string | null }>;
};

export type GroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GroupsQuery = {
  __typename?: "query_root";
  geogroup: Array<{ __typename?: "geogroup"; id: any; name: string; geometry?: any | null }>;
};

export type TableWorkspaceMetadataQueryVariables = Exact<{
  input?: InputMaybe<Workspace_Metadata_Bool_Exp>;
}>;

export type TableWorkspaceMetadataQuery = {
  __typename?: "query_root";
  workspace_metadata: Array<{
    __typename?: "workspace_metadata";
    id: any;
    kind: string;
    data: any;
    created_at?: any | null;
    geogroup?: { __typename?: "geogroup"; id: any; name: string } | null;
    geopoint?: {
      __typename?: "geopoint";
      id: any;
      unicode?: string | null;
      lat: any;
      lng: any;
    } | null;
    experiment?: { __typename?: "experiment"; id: any; created_at: any } | null;
    workspace?: {
      __typename?: "workspace";
      id: any;
      owner?: {
        __typename?: "collaborator";
        id: any;
        auth_id?: string | null;
        name?: string | null;
        email: string;
      } | null;
    } | null;
    parent?: {
      __typename?: "workspace_metadata";
      id: any;
      kind: string;
      data: any;
      geogroup?: { __typename?: "geogroup"; id: any; name: string } | null;
      geopoint?: { __typename?: "geopoint"; id: any; unicode?: string | null } | null;
      experiment?: { __typename?: "experiment"; id: any; created_at: any } | null;
      workspace?: {
        __typename?: "workspace";
        id: any;
        owner?: {
          __typename?: "collaborator";
          id: any;
          auth_id?: string | null;
          name?: string | null;
          email: string;
        } | null;
      } | null;
    } | null;
  }>;
};

export type WorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspacesQuery = {
  __typename?: "query_root";
  workspace: Array<{
    __typename?: "workspace";
    id: any;
    owner?: { __typename?: "collaborator"; email: string; name?: string | null } | null;
    group?: { __typename?: "geogroup"; name: string } | null;
  }>;
};

export type WorkspacebyGeoGroupandExperimentsQueryVariables = Exact<{
  experiments?: InputMaybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
  geogroups: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type WorkspacebyGeoGroupandExperimentsQuery = {
  __typename?: "query_root";
  workspace: Array<{
    __typename?: "workspace";
    id: any;
    kind?: string | null;
    owner?: {
      __typename?: "collaborator";
      name?: string | null;
      id: any;
      auth_id?: string | null;
      email: string;
    } | null;
  }>;
};

export const ExperimentsByGeogroupDocument = gql`
  query ExperimentsByGeogroup($geogroups: [uuid!]!) {
    experiment(where: { geogroup_id: { _in: $geogroups } }, order_by: { created_at: asc }) {
      id
      created_at
      kind
    }
  }
`;

export function useExperimentsByGeogroupQuery(
  options: Omit<Urql.UseQueryArgs<ExperimentsByGeogroupQueryVariables>, "query">
) {
  return Urql.useQuery<ExperimentsByGeogroupQuery>({
    query: ExperimentsByGeogroupDocument,
    ...options,
  });
}
export const GroupsDocument = gql`
  query Groups {
    geogroup(order_by: { name: asc }) {
      id
      name
      geometry
    }
  }
`;

export function useGroupsQuery(options?: Omit<Urql.UseQueryArgs<GroupsQueryVariables>, "query">) {
  return Urql.useQuery<GroupsQuery>({ query: GroupsDocument, ...options });
}
export const TableWorkspaceMetadataDocument = gql`
  query TableWorkspaceMetadata($input: workspace_metadata_bool_exp) {
    workspace_metadata(where: $input) {
      id
      kind
      data
      created_at
      geogroup {
        id
        name
      }
      geopoint {
        id
        unicode
        lat
        lng
      }
      experiment {
        id
        created_at
      }
      workspace {
        id
        owner {
          id
          auth_id
          name
          email
        }
      }
      parent {
        id
        kind
        data
        geogroup {
          id
          name
        }
        geopoint {
          id
          unicode
        }
        experiment {
          id
          created_at
        }
        workspace {
          id
          owner {
            id
            auth_id
            name
            email
          }
        }
      }
    }
  }
`;

export function useTableWorkspaceMetadataQuery(
  options?: Omit<Urql.UseQueryArgs<TableWorkspaceMetadataQueryVariables>, "query">
) {
  return Urql.useQuery<TableWorkspaceMetadataQuery>({
    query: TableWorkspaceMetadataDocument,
    ...options,
  });
}
export const WorkspacesDocument = gql`
  query Workspaces {
    workspace(where: { owner: { email: { _neq: "amaru@minsky.cc" } } }) {
      id
      owner {
        email
        name
      }
      group {
        name
      }
    }
  }
`;

export function useWorkspacesQuery(
  options?: Omit<Urql.UseQueryArgs<WorkspacesQueryVariables>, "query">
) {
  return Urql.useQuery<WorkspacesQuery>({ query: WorkspacesDocument, ...options });
}
export const WorkspacebyGeoGroupandExperimentsDocument = gql`
  query WorkspacebyGeoGroupandExperiments($experiments: [uuid!], $geogroups: [uuid!]!) {
    workspace(
      where: { experiments: { id: { _in: $experiments } }, group: { id: { _in: $geogroups } } }
    ) {
      id
      kind
      owner {
        name
        id
        auth_id
        email
      }
    }
  }
`;

export function useWorkspacebyGeoGroupandExperimentsQuery(
  options: Omit<Urql.UseQueryArgs<WorkspacebyGeoGroupandExperimentsQueryVariables>, "query">
) {
  return Urql.useQuery<WorkspacebyGeoGroupandExperimentsQuery>({
    query: WorkspacebyGeoGroupandExperimentsDocument,
    ...options,
  });
}
